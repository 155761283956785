import { stat } from 'fs'
import { FC, useEffect } from 'react'

type Props = {
  isAccepted?: boolean
  count?:any
}

const UserTwoStepsCell: FC<Props> = ({ isAccepted, count }) =>{
return (
  <div className='d-flex align-items-center'>
    {isAccepted === true && <span className='badge badge-light-success fw-bold  me-2 px-4 py-3'>Accepted</span>}
    {isAccepted === null && <span className='badge badge-secondary fw-bold  me-2 px-4 py-3'>Pending</span>}
    {isAccepted === false && <span className='badge badge-light-danger fw-bold  me-2 px-4 py-3'>Rejected</span>}
     <span className='text-muted fs-8'> 
     {count ===0 && ''}
      {count ===1 && '1 prior quote'}
      {count > 1 && `${count} prior quotes`}
      </span>
  </div>
)
}
export { UserTwoStepsCell }
