// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import {Price} from './Price'
import {Pricecopy} from './Pricecopy'
import {Idcell} from './idcell'
const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='job#' className='min-w-125px' />,
    id: 'id',
    Cell: ({...props}) => <Idcell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Full Name' className='min-w-125px' />,
    id: 'firstName',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='name' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Date' className='min-w-125px' />,
    id: 'created',
    Cell: ({...props}) => <UserLastLoginCell created={props.data[props.row.index].created} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='price' className='min-w-125px' />
    ),
    id: 'price',
    Cell: ({...props}) => <Pricecopy user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <UserTwoStepsCell status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='payout' className='min-w-125px' />
    ),
    id: 'payout',
    Cell: ({...props}) => <Price user={props.data[props.row.index]} />,
  },


  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
  //   ),
  //   id: 'actions',
  //   Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {usersColumns}
