import { Alert } from "bootstrap";
import Swal from "sweetalert2";

export const handleApiErrors = (object: any) => {
if(object){
    const errorMessage = object;
    Swal.fire({
        text: errorMessage,
        icon: 'error',
        showConfirmButton: true,
        width: '25rem',
    }) 
}

    if(object.status ===400){
        
        const errorField = Object.keys(object.errors)[0];
          console.log(errorField)
            Swal.fire({
                text: 'errorMessage',
                icon: 'error',
                showConfirmButton: true,
                width: '25rem',
            })
    }
    if(object.status ===409){
        const errorField = Object.keys(object.errors)[0];
          console.log(errorField)
            Swal.fire({
                text: 'errorMessage',
                icon: 'error',
                showConfirmButton: true,
                width: '25rem',
            })
    }
    if(object.status ===402){

        Swal.fire({
            text: "You have to subscribe first",
            icon: 'error',
            showConfirmButton: true,
            width: '25rem',
        })

       }else{
        if (object.errors) {
            const errorField = Object.keys(object.errors)[0];
            const errorMessage = object?.errors[0];
            Swal.fire({
                text: errorMessage,
                icon: 'error',
                showConfirmButton: true,
                width: '25rem',
            })
        }
        else if (object.message) {
            Swal.fire({
                text: object.message,
                icon: 'error',
                showConfirmButton: true,
                width: '25rem',
            })
        }
        else if (object.title) {
            Swal.fire({
                text: object.title,
                icon: 'error',
                showConfirmButton: true,
                width: '25rem',
            })
        }
    }
  
}