import React, { useRef, useEffect, FC } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

type Props = {
  startDate: string;
  endDate: string;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  flatpickrInstanceRef: any;
};

const FlatpickrRange: FC<Props> = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  flatpickrInstanceRef,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  // const flatpickrInstanceRef = useRef<flatpickr.Instance | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      flatpickrInstanceRef.current = flatpickr(inputRef.current, {
        mode: 'range',
        dateFormat: 'm-d-Y',
        enableTime: false,
        onChange: function (selectedDates, dateStr) {
          if (selectedDates.length === 2) {
            setStartDate(selectedDates[0].toISOString().split('T')[0]);
            setEndDate(selectedDates[1].toISOString().split('T')[0]);
          }
        },
      });
    }

    return () => {
      if (flatpickrInstanceRef.current) {
        flatpickrInstanceRef.current.destroy();
        flatpickrInstanceRef.current = null;
      }
    };
  }, [inputRef, setStartDate, setEndDate]);

  const handleReset = () => {
    if (flatpickrInstanceRef.current) {
      flatpickrInstanceRef.current.clear();
      setStartDate('');
      setEndDate('');
    }
  };

  return (
    <div className="fv-row mb-8">
      <div className="position-relative d-flex align-items-center">
        <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4">
          <span className="path1" />
          <span className="path2" />
          <span className="path3" />
          <span className="path4" />
          <span className="path5" />
          <span className="path6" />
        </i>
        <input
          className="form-control form-control-solid ps-12 w-100"
          placeholder="Select date range"
          name="date-range"
          ref={inputRef}
          readOnly
          type="text"
          value={`${startDate} ${startDate && 'To'} ${endDate}`}
        />
        {startDate && (
          <FontAwesomeIcon
            icon={faTimes}
            id='kt_datepicker_clearyy'
            className="position-absolute top-50 end-0 translate-middle me-4 cursor-pointer"
            onClick={handleReset}
          />
        )}
      </div>
    </div>
  );
};

export { FlatpickrRange };
