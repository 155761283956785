/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../helpers'
import preval from 'preval.macro'
import moment from 'moment'
import {useAuth} from '../../../app/modules/auth'

const Footer: FC = () => {
  const {classes} = useLayout()
  const {currentUser} = useAuth()

  return (
    <div className='footer py-4 d-flex flex-column bg-light-primary' id='kt_footer'>
      <div className='container-fluid'>
        {/*begin::Row*/}
        <div className='row py-10 py-lg-20'>
          {/*begin::Col*/}
          <div className='col-lg-6'>
            {/*begin::Navs*/}
            <div className='d-flex justify-content-start'>
              {/*begin::Links*/}
              <div className='d-flex fw-semibold flex-column me-20'>
                {/*begin::Subtitle*/}
                <h4 className='fw-bold text-info opacity-50 mb-6'>Discover</h4>
                {/*end::Subtitle*/}
                {/*begin::Link*/}
                {!currentUser && (
                  <Link
                    to='/become-a-handyman'
                    onClick={() => {
                      window.scrollTo({top: 0, behavior: 'smooth'})
                    }}
                    className='text-info  text-hover-primary fs-5 mb-6'
                  >
                    Become a Handyman
                  </Link>
                )}
                {/*end::Link*/}
                {/*begin::Link*/}
                <Link to='/services' className='text-info  text-hover-primary fs-5 mb-6'>
                  All Services
                </Link>
                {/*end::Link*/}
                {/*begin::Link*/}
                <Link to='/landing' className='text-info  text-hover-primary fs-5 mb-6'>
                  For Customer
                </Link>
                {/*end::Link*/}
              </div>
              {/*end::Links*/}
              {/*begin::Links*/}
              <div className='d-flex fw-semibold flex-column ms-lg-20'>
                {/*begin::Subtitle*/}
                <h4 className='fw-bold text-info opacity-50 mb-6'>Company</h4>
                {/*end::Subtitle*/}
                <Link to='/About-us' className='text-info  text-hover-primary fs-5 mb-6'>
                  About us
                </Link>
                {/*end::Link*/}
                {/*begin::Link*/}
                <Link to='/Contact-us' className='text-info  text-hover-primary fs-5 mb-6'>
                  Contact
                </Link>
                {/*end::Link*/}
                {/*begin::Link*/}
                <Link to='/faq' className='text-info  text-hover-primary fs-5 mb-6'>
                  FAQ
                </Link>
              </div>
              {/*end::Links*/}
            </div>
            {/*end::Navs*/}
          </div>
          {/*end::Col*/}
          {/*begin::Col*/}
          <div className='col-lg-6 pe-lg-16 mb-10 mb-lg-0'>
            {/*begin::Subtitle*/}
            <h4 className='fw-bold text-info opacity-50 mb-6'>Download our app</h4>
            {/*end::Subtitle*/}
            <h4 className='fw-bold text-info mb-6'>
              Tackle your to-do list wherever you are with Handyman's mobile app.
            </h4>
            <div className='d-flex justify-content-start'>
              <a
                href='https://apps.apple.com/us/app/handyman-get-more-done/id6468819311'
                target='_blank'
              >
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/AppStore.png')}
                  className='logo-default h-40px me-4'
                />
              </a>
              <a
                href='https://play.google.com/store/apps/details?id=com.handldapp.mobile&pli=1'
                target='_blank'
              >
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/GooglePlay.png')}
                  className='logo-default h-40px'
                />
              </a>
            </div>
          </div>
          {/*end::Col*/}
        </div>
        {/*end::Row*/}
      </div>

      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='d-flex align-items-center order-2 order-md-1 flex-wrap'>
          <div>
            <Link to='/'>
              <img
                alt='Logo'
                src={toAbsoluteUrl('/media/logos/footer-logo.png')}
                className='logo-default h-30px'
              />
            </Link>
            <span className='text-info fw-semibold ms-2'>
              {new Date().getFullYear()} &copy; All rights reserved
            </span>
          </div>
          <small className='mx-5'>
            Build Version <strong>{process.env.REACT_APP_VERSION}</strong> / Build Date:{' '}
            <strong>
              {moment(preval`module.exports = new Date().toLocaleDateString("en-US");`).format(
                'MM/DD/YYYY'
              )}
            </strong>
          </small>
        </div>
        {/* end::Copyright */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
