import React, {FC, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Flatpickr} from '../../../../global/Flatpickr '

const shema = Yup.object().shape({
  requestedDate: Yup.string().required('Date is required'),
  availableTimes: Yup.string().required('Time is required'),
})

type Props = {
  setStep: (step: number) => void
  billing: any
  setBilling: (billing: any) => void
  handleClose: () => void
  availableTimesArray: string[]
  setAvailableTimesArray: (availableTimesArray: string[]) => void
}
const DateAndTime: FC<Props> = ({
  setStep,
  billing,
  setBilling,
  handleClose,
  availableTimesArray,
  setAvailableTimesArray,
}) => {
  const today = new Date().toISOString().slice(0, 10)
  const tomorrow = new Date(new Date().getTime() + 48 * 60 * 60 * 1000).toISOString().slice(0, 10)
  const afterTomorrow = new Date(new Date().getTime() + 72 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 10)
  const threeDays = new Date(new Date().getTime() + 96 * 60 * 60 * 1000).toISOString().slice(0, 10)

  const formik = useFormik({
    initialValues: billing,
    validationSchema: shema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setStep(2)
    },
  })

  useEffect(() => {
    let availableTimes = ''
    availableTimesArray.forEach((time, index) => {
      if (index === availableTimesArray.length - 1) {
        availableTimes += time
      } else {
        availableTimes += time + ', '
      }
    })
    setBilling({...billing, availableTimes})
    formik.setFieldValue('availableTimes', availableTimes)
  }, [availableTimesArray])

  return (
    <div className='p-6 mx-5'>
      <div>
        <div className='mb-8 mt-8'>
          <h3 className='ml-5'>Date & Time</h3>
          <label className='fw-semibold text-muted'>
            Provide some more details about Date & Time
          </label>
        </div>
      </div>

      <div className='row mb-7 flex-wrap'>
        <label className='fw-bold fs-5 mb-5 required'>Date</label>
        <div className='row'>
          <div className='col'>
            <label
              className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6'
              data-kt-button='true'
            >
              <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='today'
                  checked={billing.dateType == 'today'}
                  onChange={() => {
                    setBilling({...billing, requestedDate: today, dateType: 'today'})
                    formik.setFieldValue('requestedDate', today)
                  }}
                />
              </span>
              <span className='ms-5'>
                <span className='fs-4 fw-bold text-gray-800 d-block'>Today</span>
              </span>
            </label>
          </div>
          <div className='col'>
            <label
              className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6'
              data-kt-button='true'
            >
              <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='within_2_day'
                  checked={billing.dateType == 'tomorrow'}
                  onChange={() => {
                    setBilling({
                      ...billing,
                      requestedDate: tomorrow,
                      dateType: 'tomorrow',
                    })
                    formik.setFieldValue('requestedDate', tomorrow)
                  }}
                />
              </span>
              <span className='ms-5'>
                <span className='fs-4 fw-bold text-gray-800 d-block'>Within 2 days</span>
              </span>
            </label>
          </div>
          <div className='col'>
            <label
              className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6'
              data-kt-button='true'
            >
              <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='within_3_day'
                  checked={billing.dateType == 'afterTomorrow'}
                  onChange={() => {
                    setBilling({
                      ...billing,
                      requestedDate: afterTomorrow,
                      dateType: 'afterTomorrow',
                    })
                    formik.setFieldValue('requestedDate', afterTomorrow)
                  }}
                />
              </span>
              <span className='ms-5'>
                <span className='fs-4 fw-bold text-gray-800 d-block'>Within 3 days</span>
              </span>
            </label>
          </div>
          <div className='col'>
            <label
              className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6'
              data-kt-button='true'
            >
              <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='within_4_day'
                  checked={billing.dateType == 'threeDays'}
                  onChange={() => {
                    setBilling({
                      ...billing,
                      requestedDate: threeDays,
                      dateType: 'threeDays',
                    })
                    formik.setFieldValue('requestedDate', threeDays)
                  }}
                />
              </span>
              <span className='ms-5'>
                <span className='fs-4 fw-bold text-gray-800 d-block'>Within 4 days</span>
              </span>
            </label>
          </div>
          <div className='col'>
            <label
              className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6'
              data-kt-button='true'
            >
              <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='choose_date'
                  checked={billing.dateType == 'chooseDate'}
                  onChange={() => {
                    setBilling({...billing, requestedDate: '', dateType: 'chooseDate'})
                    formik.setFieldValue('requestedDate', '')
                  }}
                />
              </span>
              <span className='ms-5'>
                <span className='fs-4 fw-bold text-gray-800 d-block'>Choose a date</span>
              </span>
            </label>
          </div>
        </div>
        {billing.dateType == 'chooseDate' && (
          <div className='row mt-5'>
            <div className='col'>
              <Flatpickr
                selectedDate={billing.requestedDate}
                setSelectedDate={(date: string) => {
                  setBilling({...billing, requestedDate: date})
                  formik.setFieldValue('requestedDate', date)
                }}
              />
            </div>
          </div>
        )}

        {formik.touched.requestedDate && formik.errors.requestedDate && (
          <div className='fv-plugins-message-container'>
            <span className='text-danger'>{formik.errors.requestedDate as string}</span>
          </div>
        )}
      </div>

      <div className='row mb-7'>
        <label className='fw-bold fs-5 mb-5 required'>When do you need this done?</label>
        <div className='row'>
          <div className='col-xl-3 col-lg-3 col-md-6 col-12 mt-3'>
            <label className='form-check form-check-inline form-check-solid me-5'>
              <input
                className='form-check-input'
                name='Morning'
                type='checkbox'
                checked={availableTimesArray.includes('Morning (8am - 12pm)')}
                onChange={(e) => {
                  if (e.target.checked) {
                    setAvailableTimesArray([...availableTimesArray, 'Morning (8am - 12pm)'])
                  }
                  if (!e.target.checked) {
                    setAvailableTimesArray(
                      availableTimesArray.filter((item) => item !== 'Morning (8am - 12pm)')
                    )
                  }
                }}
              />
              <span className='fw-bold ps-2 fs-6'>Morning (8am - 12pm)</span>
            </label>
          </div>
          <div className='col-xl-3 col-lg-3 col-md-6 col-12 mt-3'>
            <label className='form-check form-check-inline form-check-solid me-5'>
              <input
                className='form-check-input'
                name='Afternoon'
                type='checkbox'
                checked={availableTimesArray.includes('Afternoon (12pm - 5pm)')}
                onChange={(e) => {
                  if (e.target.checked) {
                    setAvailableTimesArray([...availableTimesArray, 'Afternoon (12pm - 5pm)'])
                  }
                  if (!e.target.checked) {
                    setAvailableTimesArray(
                      availableTimesArray.filter((item) => item !== 'Afternoon (12pm - 5pm)')
                    )
                  }
                }}
              />
              <span className='fw-bold ps-2 fs-6'>Afternoon (12pm - 5pm)</span>
            </label>
          </div>
          <div className='col-xl-3 col-lg-3 col-md-6 col-12 mt-3'>
            <label className='form-check form-check-inline form-check-solid me-5'>
              <input
                className='form-check-input'
                name='communication[]'
                type='checkbox'
                checked={availableTimesArray.includes('Evening (5pm - 9:30pm)')}
                onChange={(e) => {
                  if (e.target.checked) {
                    setAvailableTimesArray([...availableTimesArray, 'Evening (5pm - 9:30pm)'])
                  }
                  if (!e.target.checked) {
                    setAvailableTimesArray(
                      availableTimesArray.filter((item) => item !== 'Evening (5pm - 9:30pm)')
                    )
                  }
                }}
              />
              <span className='fw-bold ps-2 fs-6'>Evening (5pm - 9:30pm)</span>
            </label>
          </div>
          <div className='col-xl-3 col-lg-3 col-md-6 col-12 mt-3'>
            <label className='form-check form-check-inline form-check-solid me-5'>
              <input
                className='form-check-input'
                name='Flexible'
                type='checkbox'
                checked={availableTimesArray.includes('Flexible')}
                onChange={(e) => {
                  if (e.target.checked) {
                    setAvailableTimesArray([...availableTimesArray, 'Flexible'])
                  }
                  if (!e.target.checked) {
                    setAvailableTimesArray(availableTimesArray.filter((item) => item !== 'Flexible'))
                  }
                }}
              />
              <span className='fw-bold ps-2 fs-6'>Flexible</span>
            </label>
          </div>
        </div>
        {formik.touched.availableTimes && formik.errors.availableTimes && (
          <div className='fv-plugins-message-container'>
            <span className='text-danger'>{formik.errors.availableTimes as string}</span>
          </div>
        )}
      </div>

      <div className='d-flex justify-content-between my-5 py-5'>
        <button
          onClick={() => {
            handleClose()
          }}
          className='btn btn-sm btn-secondary px-5'
        >
          Back
        </button>
        <button
          onClick={() => {
            formik.handleSubmit()
          }}
          className='btn btn-sm btn-primary px-5'
        >
          Next
        </button>
      </div>
    </div>
  )
}

export default DateAndTime
