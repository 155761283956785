import { type } from 'os'
import { toAbsoluteUrl } from '../../../../../_handld/helpers'
import { FC, useEffect } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment';
import { getFromatdate } from '../../../../../_handld/helpers/GlobalHelpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from "react-inlinesvg";

type Props = {
  data: any
  customerId: any
}
const JobDetailsModalForm: FC<Props> = (data: any, customerId: any) => {
  const requesteddate = data?.data?.requestedDate;
  let date = '---'; // Default value if requesteddate is null or undefined

  if (requesteddate) {
    const splitdate = requesteddate.split("T");
    const datee = splitdate[0];
    // date = moment(datee).format("MM-DD-YYYY");
    const format = getFromatdate();
    if (format) {
      date = moment(datee).format(format);
    }
  }
  return (
    <>
      <div className='row'>
        <div className='d-flex justify-content-between mb-5'>
          <div className=''>
            <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center mb-2'>Customer</div>
            {data?.data?.customerId != null ? <Link
              to={`/backoffice/customers-management/details/${data?.data?.customerId}`}
              className='menu-link fw-semibold fs-6'
            >
              {data?.data?.firstName === undefined ? '' : data?.data?.firstName} {''}{' '}
              {data?.data?.lastName === undefined ? '' : data?.data?.lastName}
            </Link> : (
              <p className='text-gray-400 fw-semibold fs-6'>
                {data?.data?.firstName === undefined ? '' : data?.data?.firstName} {''}{' '}
                {data?.data?.lastName === undefined ? '' : data?.data?.lastName}
              </p>)

            }
          </div>

          <div className='col-4'>
            <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center'>Created date</div>
            <div className="d-flex align-items-center">
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.created ? moment(data?.data?.created).format(getFromatdate() as string) : '---'}</p>
              <div className="font-weight-bolder mb-7">
                <OverlayTrigger
                  key={"right"}
                  placement={"right"}
                  overlay={
                    <Tooltip id={`tooltip-${"placement"}`}>
                      {getFromatdate()}
                    </Tooltip>
                  }
                >
                  <span
                    style={{ cursor: "pointer" }}
                    className="svg-icon menu-icon svg-icon-info"
                  >
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/Code/Info-circle.svg"
                      )}
                    />
                  </span>
                </OverlayTrigger>
              </div>
            </div>
          </div>

        </div>

        {data?.data?.serviceCode === 'TVWA' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Size of TV (inches)
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.sizeInch}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Wall-Mount on hand
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.wallMountOnHand === true ? 'Yes' : ''} {data?.data?.wallMountOnHand === false ? 'No' : ''}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of Tvs
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Confirm Wall-Mount is correct specific to size of their TV
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.wallSpecificSize === true ? 'Yes' : ''} {data?.data?.wallSpecificSize === false ? 'No' : ''}</p>
            </div>
            {data?.data?.wallType && <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Wall Info TV is being mounted to
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.wallType}</p>
            </div>}
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>
          </>

        )}
        {data?.data?.serviceCode === 'SHEL' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Shelves are from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            {/* <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Custom
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>..</p>
            </div> */}
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of Shelves
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div>
            <div className='separator separator-dashed'></div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Length of Shelves
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.length}</p>
            </div>
            {data?.data?.wallType && <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Wall Info Shelf is being mounted to
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.wallType}</p>
            </div>}
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Placement
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.placementDifficulty === true ?
                (

                  <div className='symbol symbol-60px symbol-lg-90px symbol-fixed position-relative me-4'>
                    <img src={toAbsoluteUrl(data?.data?.placementDifficultyPicture)} alt={data?.data?.placementDifficultyPicture} />
                  </div>


                )
                : ''} {data?.data?.placementDifficulty === false ? 'No placement difficulty' : ''}</p>
            </div>
            <div className='separator separator-dashed'></div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'ARTP' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of Pictures
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Size
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.size}</p>
            </div>
            {data?.data?.wallType && <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Wall Info Art/Picture(s)  being mounted to
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.wallType}</p>
            </div>}
            {/* <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Custom
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>..</p>
            </div> */}
            <div className='separator separator-dashed'></div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Placement
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.placementDifficulty === true ?
                (

                  <div className='symbol symbol-60px symbol-lg-90px symbol-fixed position-relative me-4'>
                    <img src={toAbsoluteUrl(data?.data?.placementDifficultyPicture)} alt={data?.data?.placementDifficultyPicture} />
                  </div>


                )
                : ''} {data?.data?.placementDifficulty === false ? 'No placement difficulty' : ''}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'CABI' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Cabinets are from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Assembly of Cabinet Required
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.assemblyRequired === true ? 'Yes' : ''} {data?.data?.assemblyRequired === false ? 'No' : ''}</p>
            </div>
            <div className='separator separator-dashed'></div>
            {data?.data?.wallType && <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Wall Info Cabinet(s)  being mounted to
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.wallType}</p>
            </div>}
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Placement:
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.placementDifficulty === true ?
                (

                  <div className='symbol symbol-60px symbol-lg-90px symbol-fixed position-relative me-4'>
                    <img src={toAbsoluteUrl(data?.data?.placementDifficultyPicture)} alt={data?.data?.placementDifficultyPicture} />
                  </div>


                )
                : ''} {data?.data?.placementDifficulty === false ? 'No placement difficulty' : ''}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address:
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'ELEC' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Link input for electric fireplace make/model
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7 text-break'>{data?.data?.itemLink}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Assembly of Fireplace required
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.assemblyRequired === true ? 'Yes' : ''} {data?.data?.assemblyRequired === false ? 'No' : ''}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Depending on height/placement will someone be on hand to assist Handyman in lifting fireplace to the mounting bracket
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.assistHandlr === true ? 'Yes' : ''} {data?.data?.assistHandlr === false ? 'No' : ''}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address:
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>


          </>

        )}
        {data?.data?.serviceCode === 'WALLL' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Wall-Lamp(s) are from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Placement
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.placementDifficulty === true ?
                (

                  <div className='symbol symbol-60px symbol-lg-90px symbol-fixed position-relative me-4'>
                    <img src={toAbsoluteUrl(data?.data?.placementDifficultyPicture)} alt={data?.data?.placementDifficultyPicture} />
                  </div>


                )
                : ''} {data?.data?.placementDifficulty === false ? 'No placement difficulty' : ''}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>
          </>
        )}
        {data?.data?.serviceCode === 'CURT' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of Curtain Rods
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of windows
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfWindows}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Sizes of windows (width and height)
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>Height:{data?.data?.height} {'  '}  /  Width:{data?.data?.width}</p>
            </div>
            <div className='separator separator-dashed'></div>
            {data?.data?.wallType && <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Wall Info Curtain Rod(s)  being mounted to
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.wallType}</p>
            </div>}
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Placement
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.placementDifficulty === true ?
                (

                  <div className='symbol symbol-60px symbol-lg-90px symbol-fixed position-relative me-4'>
                    <img src={toAbsoluteUrl(data?.data?.placementDifficultyPicture)} alt={data?.data?.placementDifficultyPicture} />
                  </div>


                )
                : ''} {data?.data?.placementDifficulty === false ? 'No placement difficulty' : ''}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>
          </>

        )}
        {data?.data?.serviceCode === 'WALLMI' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number                            </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of Curtain Rods
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Sizes of Wall-Mirror (width and height)
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>Height:{data?.data?.height} {''}  /  Width:{data?.data?.width}</p>
            </div>
            <div className='separator separator-dashed'></div>
            {data?.data?.wallType && <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Wall Info Wall-Mirror(s)  being mounted to
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.wallType}</p>
            </div>}
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Placement
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.placementDifficulty === true ?
                (

                  <div className='symbol symbol-60px symbol-lg-90px symbol-fixed position-relative me-4'>
                    <img src={toAbsoluteUrl(data?.data?.placementDifficultyPicture)} alt={data?.data?.placementDifficultyPicture} />
                  </div>


                )
                : ''} {data?.data?.placementDifficulty === false ? 'No placement difficulty' : ''}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>
          </>

        )}
        {data?.data?.serviceCode === 'SMAL' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Items/Description
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.items}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of small items
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems ? data?.data?.numberOfItems : '---'}</p>
            </div>
            {/* <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of small items
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div> */}
            {data?.data?.wallType && <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Wall Info: item(s) being mounted to:
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.wallType}</p>
            </div>}

            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'KINGWS' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Bed is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Does this Model require Headboard to be Wall-Mounted?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>  {data?.data?.headboardWallMounted === true ? 'Yes' : ''} {data?.data?.headboardWallMounted === false ? 'No' : ''}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address:
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>
            <div className='separator separator-dashed'></div>


          </>

        )}
        {data?.data?.serviceCode === 'KING' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Bed is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
              Confirmation NO storage drawers on this model
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.storageDrawers === true ? 'Yes' : ''} {data?.data?.storageDrawers === false ? 'No' : ''}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Does this Model require Headboard to be Wall-Mounted?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>  {data?.data?.headboardWallMounted === true ? 'Yes' : ''} {data?.data?.headboardWallMounted === false ? 'No' : ''}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'QUEEWS' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Bed is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Does this Model require Headboard to be Wall-Mounted?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>  {data?.data?.headboardWallMounted === true ? 'Yes' : ''} {data?.data?.headboardWallMounted === false ? 'No' : ''}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>
          </>

        )}
        {data?.data?.serviceCode === 'QUEE' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Bed is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
              Confirmation NO storage drawers on this model
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.storageDrawers === true ? 'Yes' : ''} {data?.data?.storageDrawers === false ? 'No' : ''}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Does this Model require Headboard to be Wall-Mounted?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>  {data?.data?.headboardWallMounted === true ? 'Yes' : ''} {data?.data?.headboardWallMounted === false ? 'No' : ''}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>
          </>

        )}
        {data?.data?.serviceCode === 'FULLWS' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Bed is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Does this Model require Headboard to be Wall-Mounted?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>  {data?.data?.headboardWallMounted === true ? 'Yes' : ''} {data?.data?.headboardWallMounted === false ? 'No' : ''}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'FULL' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Bed is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
              Confirmation NO storage drawers on this model
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.storageDrawers === true ? 'Yes' : ''} {data?.data?.storageDrawers === false ? 'No' : ''}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Does this Model require Headboard to be Wall-Mounted?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>  {data?.data?.headboardWallMounted === true ? 'Yes' : ''} {data?.data?.headboardWallMounted === false ? 'No' : ''}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>
          </>

        )}
        {data?.data?.serviceCode === 'SECTWS' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Sofa is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'SECT' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Sofa is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'THRE' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Sofa is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'TWOP' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Sofa is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'DINIT4C' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Table & chairs from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>
        )}
        {data?.data?.serviceCode === 'DESKT' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Table & chairs from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'DESKAC' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Desk & chair from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>
        )}
        {data?.data?.serviceCode === 'DESKND' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Desk & chair from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'DESKC' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Desk & chair from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'CONS' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Table & chairs from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'COFF' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Table & chairs from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'SIDE' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Table & chairs from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'BARS' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Table & chairs from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'NIGH' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                (Item) is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'DRES6' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                (Item) is from              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'DRES4' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                (Item) is from              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">

                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'RCLN' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Surface: What type of surface do you need cleaned?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.size}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state} {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}

        {data?.data?.serviceCode === 'DCLN' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Surface: What type of surface do you need cleaned?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.size}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'SCLN' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Surface: What type of surface do you need cleaned?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.size}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'MHLP' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Boxes: How many boxes do you need moved?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.size}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Vehicle Requirements
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.vehiculeNeeded ? data?.data?.vehiculeNeeded : '--'}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                End address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.endAddress1} <br />
                {data?.data?.endAddress2 ? <> {data?.data?.endAddress2},{' '}</> : ''}
                {data?.data?.endCity}, {data?.data?.endState}, {data?.data?.endZipCode}, {data?.data?.endCountry}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'OTLI' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
              Number of outlets to be installed?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'LFRPL' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
              Number of light fixtures to be replaced?              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'CLFI' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of Ceiling fans to be installed
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'ELTAR' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'CBRPI' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'FRPRP' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of faucets to be repaired or replaced
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'TOIRP' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of toilets to be replaced
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'LKRP' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of leaks to be repaired
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'IDWMC' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of appliances to be installed
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'HWHIM' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of hot water heaters to be inspected or maintained
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'PPINS' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of pipes to be insulated
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'SHRP' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of shower heads to be replaced
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'PNTSR' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Type of Coat: (Single Coat / Tow Coat)
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.coatType}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Type (without cleaning and surface / with cleaning and surface)
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.type}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'PNTMR' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Type of Coat: (Single Coat / Tow Coat)
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.coatType}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Type (without cleaning and surface / with cleaning and surface)
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.type}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state}, {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'GRDPLN' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Surface
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.size}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state} {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'LWCFRT' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Surface
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.size}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state} {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.serviceCode === 'LWNMWN' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Surface              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.size}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className="d-flex align-items-center">
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.requestedDate ? date : '  ---  '}</p>
                <div className="font-weight-bolder mb-7">
                  <OverlayTrigger
                    key={"right"}
                    placement={"right"}
                    overlay={
                      <Tooltip id={`tooltip-${"placement"}`}>
                        {getFromatdate()}
                      </Tooltip>
                    }
                  >
                    <span
                      style={{ cursor: "pointer" }}
                      className="svg-icon menu-icon svg-icon-info"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/Code/Info-circle.svg"
                        )}
                      />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.availableTimes ? data?.data?.availableTimes : '  ---  '}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'> {data?.data?.address1} <br />
                {data?.data?.address2 ? (<> {data?.data?.address2}<br /></>) : ''}
                {data?.data?.city}, {data?.data?.state} {data?.data?.zipCode}, {data?.data?.country}</p>
            </div>

          </>

        )}
        {data?.data?.handlerId &&
          (<>
            <div className='col-4 mt-3'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center mb-2'>Handyman</div>
              <div className="d-flex align-items-center mb-2">
                <div className="symbol symbol-50px  overflow-hidden me-3">
                  {data?.data?.handlerPicture ? (
                    <div className='symbol-label'>
                      <img
                        src={`${data?.data?.handlerPicture}`}
                        className='w-100'
                        style={{ objectFit: 'contain' }}
                      />
                    </div>
                  ) : (
                    <div className='symbol-label'>
                      <img
                        src={toAbsoluteUrl('/media/svg/avatars/blank.svg')}
                        className='w-100'
                        style={{ objectFit: 'contain' }}
                      />
                    </div>
                  )}
                </div>
                <Link
                  to={`/backoffice/handyman-management/details/${data?.data?.handlerId}`}
                  className='menu-link fw-semibold fs-6'
                >
                  {data?.data?.handlerName === undefined ? '' : data?.data?.handlerName} {''}{' '}
                </Link>

              </div>

            </div>

          </>)
        }

        <div className='separator separator-dashed'></div>
        <div className='col-4'>
          <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
            Additional information
          </div>
          <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.additionalDetails ? data?.data?.additionalDetails : 'No Additional information'}</p>
        </div>
        {data?.data?.skills && (
          <>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Skills needed              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.skills.replace(/;/g, ', ')}</p>
            </div>
          </>
        )
        }
        <div className='separator separator-dashed'></div>
        <div className='col-12'>
          <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
            <span className='me-5'>Review</span>
            <div className='border border-gray-300 border-dashed rounded py-1 px-1 me-7'>
              <div className='fs-6 text-gray-800 fw-bold'>
                {data?.data?.review?.rate === 0 || data?.data?.review === null ? (
                  <span>
                    <i className='bi bi-star text-warning' /> -
                  </span>
                ) : (
                  <span>
                    <i className='bi bi-star-fill text-warning' /> {data?.data?.review?.rate}
                  </span>
                )}
              </div>
            </div>
          </div>
          <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.review?.notes}</p>
        </div>
        {/* <div className='col-12'>
          <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
            <span className='me-5'>Reviews</span>
            <div className='border border-gray-300 border-dashed rounded py-1 px-1 me-7'>
              <div className='fs-6 text-gray-800 fw-bold'>
                {data?.data?.review?.rate === 0 || data?.data?.review === null ? (
                  <span>
                    <i className='bi bi-star text-warning' /> -
                  </span>
                ) : (
                  <span>
                    <i className='bi bi-star-fill text-warning' /> {data?.data?.review?.rate}
                  </span>
                )}
              </div>
            </div>
          </div>
          <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.data?.review?.notes}</p>
        </div> */}

      </div>
    </>
  )
}

export { JobDetailsModalForm }
