import axios from 'axios'
import {useFormik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {ReactSelectStyles} from '../../global/ReactSelectStyles'
import Select from 'react-select'
import * as Yup from 'yup'
import PhoneInput from 'react-phone-input-2'
const API_URL = process.env.REACT_APP_API_URL
const getCountries = (): Promise<any> => {
  return axios.get(`${API_URL}/api/Generic/Countries`)
}

const ServiceAddressSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Wrong email format').required('Email is required'),
  phone: Yup.string().required('Phone is required'),
  address1: Yup.string().required('Address Line 1 is required'),
  city: Yup.string().required('City is required'),
  zipcode: Yup.string().required('Postal code is required'),
  state: Yup.string().required('Province is required'),
})

type Props = {
  hideSelectService: boolean
  setHideSelectService: (hideSelectService: boolean) => void
  hideServiceDetails: boolean
  setHideServiceDetails: (hideServiceDetails: boolean) => void
  hideServiceAddress: boolean
  setHideServiceAddress: (hideServiceAddress: boolean) => void
  setStep: (step: number) => void
  address: any
  setAddress: (jobs: any) => void
  auth?: any
}
const ServiceAddress: FC<Props> = ({
  hideSelectService,
  setHideSelectService,
  hideServiceDetails,
  setHideServiceDetails,
  setHideServiceAddress,
  hideServiceAddress,
  setStep,
  address,
  setAddress,
  auth,
}) => {
  const [states, setStates] = useState<any>([])
  const {data: countries} = useQuery('countries', getCountries, {
    refetchOnWindowFocus: false,
  })

  const handleGetStates = (country: number) => {
    axios.get(`${API_URL}/api/Generic/States?name=${country}`).then((res) => {
      setStates(res.data.states)
    })
  }

  useEffect(() => {
    if (address?.country) {
      handleGetStates(address?.country)
    }
  }, [address?.country])

  const countriesOptions = countries?.data?.countries?.map((country: any) => {
    return {
      label: country.name,
      value: country.id,
    }
  })
  const statesOptions = states?.map((state: any) => {
    return {
      label: state.name,
      value: state.name,
    }
  })

  useEffect(() => {
    if (address) {
      formik.setFieldValue('firstName', address?.firstName)
      formik.setFieldValue('lastName', address?.lastName)
      formik.setFieldValue('email', address?.email)
      formik.setFieldValue('phone', address?.phone)
      formik.setFieldValue('address1', address?.address1)
      formik.setFieldValue('address2', address?.address2)
      formik.setFieldValue('city', address?.city)
      formik.setFieldValue('zipcode', address?.zipcode)
      formik.setFieldValue('state', address?.state)
    }
  }, [address])
  const formik = useFormik({
    initialValues: address,
    validationSchema: ServiceAddressSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setStep(2)
    },
  })

  return (
    <div>
      <div className='card shadow mb-5 mb-xl-10 border border-dashed' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Service Address</h3>
          </div>

          {hideSelectService && hideServiceDetails && hideServiceAddress && (
            <button className='btn btn-light-primary btn-sm align-self-center'>Edit</button>
          )}
        </div>

        <div className={`card-body p-9 ${hideServiceAddress && 'd-none'}`}>
          <div className='row mb-7'>
            <label className='fw-semibold text-muted'>
              Provide some more details about the selected service
            </label>
          </div>

          <div className='row mb-7'>
            <div className='col-6'>
              <label className='fw-bold fs-5 mb-5 required'>First Name</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid '
                placeholder='First Name'
                value={address?.firstName}
                onChange={(e) => {
                  setAddress({...address, firstName: e.target.value})
                  formik.setFieldValue('firstName', e.target.value)
                }}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger'>{formik.errors.firstName as string}</span>
                </div>
              )}
            </div>
            <div className='col-6'>
              <label className='fw-bold fs-5 mb-5 required'>Last Name</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid '
                placeholder='Last Name'
                value={address?.lastName}
                onChange={(e) => {
                  setAddress({...address, lastName: e.target.value})
                  formik.setFieldValue('lastName', e.target.value)
                }}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger'>{formik.errors.lastName as string}</span>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-7'>
            <div className='col-6'>
              <label className='fw-bold fs-5 mb-5  required'>Email</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid '
                placeholder='Email'
                value={address?.email}
                onChange={(e) => setAddress({...address, email: e.target.value})}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger'>{formik.errors.email as string}</span>
                </div>
              )}
            </div>
            <div className='col-6'>
              <label className='fw-bold fs-5 mb-5 required'>Phone</label>
              <PhoneInput
                preferredCountries={['us', 'ca', 'ma']}
                inputStyle={{
                  width: '100%',
                  padding: '1.7rem',
                  paddingLeft: '4rem',
                  backgroundColor: '#f9f9f9',
                  borderColor: '#f9f9f9',
                }}
                country={'ca'}
                value={address?.phone}
                onChange={(value) => {
                  setAddress({ ...address, phone: value });
                }}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger'>{formik.errors.phone as string}</span>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-7'>
            <label className='fw-bold fs-5 mb-5 required'>Address Line 1</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Address Line 1'
              value={address?.address1}
              onChange={(e) => setAddress({...address, address1: e.target.value})}
            />
            {formik.touched.address1 && formik.errors.address1 && (
              <div className='fv-plugins-message-container'>
                <span className='text-danger'>{formik.errors.address1 as string}</span>
              </div>
            )}
          </div>
          <div className='row mb-7'>
            <label className='fw-bold fs-5 mb-5'>Address Line 2</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Address Line 2'
              value={address?.address2}
              onChange={(e) => setAddress({...address, address2: e.target.value})}
            />
          </div>
          <div className='row mb-7'>
            <div className='col-6'>
              <label className='fw-bold fs-5 mb-5 required'>City</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid '
                placeholder='City'
                value={address?.city}
                onChange={(e) => setAddress({...address, city: e.target.value})}
              />
              {formik.touched.city && formik.errors.city && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger'>{formik.errors.city as string}</span>
                </div>
              )}
            </div>
            <div className='col-6'>
              <label className='fw-bold fs-5 mb-5 required'>Postal Code</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid '
                placeholder='Postal Code'
                value={address?.zipcode}
                onChange={(e) => {
                  setAddress({...address, zipcode: e.target.value})
                  formik.setFieldValue('zipcode', e.target.value)
                }}
              />
              {formik.touched.zipcode && formik.errors.zipcode && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger'>{formik.errors.zipcode as string}</span>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-7'>
            <div className='col-6'>
              <label className='fw-bold fs-5 mb-5 required'>Province</label>
              <Select
                options={statesOptions}
                styles={ReactSelectStyles}
                name='state'
                placeholder='Province'
                value={statesOptions?.find((option: any) => option.label === address?.state)}
                onChange={(option: any) => {
                  setAddress({...address, state: option.label})
                }}
              />
              {formik.touched.state && formik.errors.state && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger'>{formik.errors.state as string}</span>
                </div>
              )}
            </div>
            <div className='col-6'>
              <label className='fw-bold fs-5 mb-5 required'>Country</label>
              <Select
                options={countriesOptions}
                styles={ReactSelectStyles}
                name='country'
                placeholder='Country'
                value={countriesOptions?.find((option: any) => option.label === address?.country)}
                onChange={(option: any) => {
                  setAddress({...address, country: option.label, countryId: option.value})
                }}
              />
              {formik.touched.country && formik.errors.country && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger'>{formik.errors.country as string}</span>
                </div>
              )}
            </div>
          </div>

          <div className='row mt-5 pt-5'>
            <div className='col-12 d-flex justify-content-center'>
              <button
                onClick={() => {
                  formik.handleSubmit()
                }}
                className='btn btn-primary btn-sm w-25'
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServiceAddress
