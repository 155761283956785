import {Col, Container, Row, Spinner} from 'react-bootstrap'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_handld/helpers'
import clsx from 'clsx'
import Swal from 'sweetalert2'

import {ReactNode, useEffect, useState} from 'react'
import QueryFetchPost, {ModelStateMessage} from '../../../../global/index2'
import formatPhoneNumber from '../../../../global/formatPhoneNumber'
const initialValues = {
  Name: '',
  email: '',
  Subject: '',
  Message: '',
}

const ContactUsSchema = Yup.object().shape({
  Name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  Subject: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Subject is required'),
})
type Model = {
  name: string
  email: string
  message: string
  subject: string
}
const ContactUs = () => {
  const [subject, setsubject] = useState<any>()
  const [name, setname] = useState<any>()
  const [message, setmessage] = useState<any>()
  const [email, setemail] = useState<any>()
  const [loading, setLoading] = useState(false)

  const BASE_URL = process.env.REACT_APP_BASE_URL

  const handleSubmit = async () => {
    setLoading(true)
    try {
      await QueryFetchPost({
        link: `${BASE_URL}/api/Generic/Form`,
        data: {
          name: name,
          email: email,
          subject: subject,
          message: message,
        },
        method: 'POST',
        key: 'Contactus',
        signal: undefined,
        isFormData: false,
      }).then((response: any) => {
        if (response[0] === 200) {
          Swal.fire({
            text: `Your application has been received and will be taken as consideration!`,
            icon: 'success',
            showConfirmButton: true,
            timer: 2000,
            width: '25rem',
          })
          setemail('')
          setmessage('')
          setsubject('')
          setname('')
          setLoading(false)
        } else {
          ModelStateMessage(response)
          setLoading(false)
        }
      })
    } catch (error) {
      console.error('Error during form submission:', error)
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ContactUsSchema,
    onSubmit: async (values: any) => {
      handleSubmit()
    },
  })
  return (
    <div className='card shadow mb-5 border border-dashed '>
      <div className='container'>
        <Row className='align-items-center py-15'>
          <Col xl='6' lg='6' md='12' className='pe-10'>
            <img
              className='w-100 card-rounded h-650px'
              src={toAbsoluteUrl('/media/logos/Contact.jpg')}
              alt=''
            />
          </Col>
          <Col xl='6' lg='6' md='12'>
            <h1 className='fw-bold text-dark mb-9'>Send Us Email</h1>
            <p className='lead fw-bold'>
              {' '}
              Email: <a href='mailto:support@gohandyman.ca'>support@gohandyman.ca</a>
            </p>
            <p className='lead fw-bold'>
              {' '}
              Phone: <a href='tel:+16472063243'>{formatPhoneNumber('+14161231234')}</a>{' '}
            </p>
            <div>
              <p className='fs-5 text-muted fw-semibold'>
                Give us a call or drop by anytime, we usually respond to all enquiries within 24
                hours on business days.
              </p>
            </div>
            <div className='separator mx-1 my-4'></div>
            <form onSubmit={formik.handleSubmit}>
              <div className='row mb-5'>
                <div className='col-md-6 fv-row fv-plugins-icon-container'>
                  <label className='fs-5 fw-semibold mb-2 required'>Name</label>
                  <input
                    name='Name'
                    value={name}
                    type='text'
                    onChange={(e) => {
                      formik.handleChange(e)
                      setname(e.target.value)
                    }}
                    placeholder='Name'
                    className={clsx(
                      'form-control form-control-solid',
                      {
                        'is-invalid': formik.touched.Name && formik.errors.Name,
                      },
                      {
                        'is-valid': formik.touched.Name && !formik.errors.Name,
                      }
                    )}
                  />
                  {formik.touched.Name && formik.errors.Name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.Name as ReactNode}</span>
                      </div>
                    </div>
                  )}

                  <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                </div>
                <div className='col-md-6 fv-row fv-plugins-icon-container'>
                  <label className='fs-5 fw-semibold mb-2 required'>Email</label>
                  <input
                    name='email'
                    type='email'
                    value={email}
                    placeholder='Email'
                    onChange={(e) => {
                      formik.handleChange(e)
                      setemail(e.target.value)
                    }}
                    className={clsx(
                      'form-control form-control-solid ',
                      {'is-invalid': formik.touched.email && formik.errors.email},
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.email as ReactNode}</span>
                      </div>
                    </div>
                  )}

                  <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
                </div>
              </div>
              <div className='d-flex flex-column mb-5 fv-row'>
                <label className='fs-5 fw-semibold mb-2 required'>Subject</label>
                <input
                  placeholder='Subject'
                  name='Subject'
                  value={subject}
                  onChange={(e) => {
                    formik.handleChange(e)
                    setsubject(e.target.value)
                  }}
                  className={clsx(
                    'form-control form-control-solid',
                    {'is-invalid': formik.touched.Subject && formik.errors.Subject},
                    {
                      'is-valid': formik.touched.Subject && !formik.errors.Subject,
                    }
                  )}
                />
                {formik.touched.Subject && formik.errors.Subject && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.Subject as ReactNode}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='d-flex flex-column mb-10 fv-row fv-plugins-icon-container'>
                <label className='fs-6 fw-semibold mb-2'>Message</label>
                <textarea
                  className='form-control form-control-solid '
                  rows={6}
                  name='Message'
                  value={message}
                  placeholder='Message'
                  onChange={(e) => {
                    formik.handleChange(e)
                    setmessage(e.target.value)
                  }}
                />
                <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback' />
              </div>
              <button type='submit' className='btn btn-primary'>
                Send Feedback
                {loading && (
                  <Spinner
                    as='span'
                    animation='border'
                    className='mb-1 ml-5'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                )}
              </button>
            </form>
          </Col>
        </Row>
      </div>
    </div>
  )
}
export default ContactUs
