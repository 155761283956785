
import { Link } from 'react-router-dom'
import { User } from '../../core/_models'
import { FC } from 'react'

type Props = {
  user: User
}
const Emailpage: FC<Props>=({user})=>{
return(
    <>
    <a href={`mailto:${user.email}`}>{user.email}</a>
 </>
)
}
export {Emailpage}