import React from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {checkIsActive, KTIcon} from '../../../helpers'
import axios from 'axios'
import Swal from 'sweetalert2'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
}) => {
  const {pathname} = useLocation()

  const handleCheckBuilVersion = async () => {
    const version = process.env.REACT_APP_VERSION
    const API_URL = process.env.REACT_APP_API_URL
    const res = await axios.get(`${API_URL}/api/Generic/AppVersion`)
    if (window.location.hostname !== 'localhost') {
      if (res.data !== version) {
        Swal.fire({
          text: 'New build version released. clearing cache now ...',
          icon: 'info',
          showConfirmButton: true,
          timer: 3000,
          width: '35rem',
        })
        setTimeout(function () {
          if (caches) {
            caches.keys().then((names) => {
              for (const name of names) {
                caches.delete(name)
              }
            })
          }
          window.location.reload()
        }, 3000)
      }
    }
  }

  return (
    <div className='menu-item me-lg-1'>
      <Link
        onClick={() => {
          handleCheckBuilVersion()
        }}
        className={clsx('menu-link py-3', {
          active: checkIsActive(pathname, to),
        })}
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}

        {icon && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}

        {fontIcon && (
          <span className='menu-icon'>
            <i className={clsx('bi fs-3', fontIcon)}></i>
          </span>
        )}

        <span
          className={`${
            pathname.includes('backoffice') ? 'menu-title backoffice-title' : 'menu-title'
          }`}
        >
          {title}
        </span>

        {hasArrow && <span className='menu-arrow'></span>}
      </Link>
    </div>
  )
}

export {MenuItem}
