/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../_handld/helpers'
import {handleApiErrors} from '../../../global/HandleApiErrors'
import {useAuth} from '../../auth'
import {login} from '../../auth/core/_requests'
import SVG from 'react-inlinesvg'
import { logInWithEmailAndPassword } from '../../apps/ChatComp/firebaseHelper'

const loginSchema = Yup.object().shape({
  username: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  username: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

type Props = {
  auth?: any
  setAuth: (auth: any) => void
}

export const LoginPage: FC<Props> = ({auth, setAuth}) => {
  const [loading, setLoading] = useState(false)
  const {saveAuth} = useAuth()
  const [isShowPassword, setShowPassword] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.username, values.password)
        document.getElementById('post-login-close-btn')?.click()
        document.getElementById('post-next-btn')?.click()
        await logInWithEmailAndPassword(
          values.username,
          `pass-${values.username?.replace(" ", "")}`,
          auth
        );
        setAuth(auth)
        localStorage.setItem('User', JSON.stringify(auth))
        // saveAuth(auth)
        // const {data: user} = await getUserByToken(auth.api_token)
        // setCurrentUser(auth)
        // setTimeout(() => {
        //
        // }, 500)
      } catch (error: any) {
        handleApiErrors(error?.response?.data)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center'>
        {/* begin::Logo */}
        <a href='#' className='mb-12 text-center'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/default-dark.svg')}
            className='theme-dark-show h-45px'
          />
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/default.svg')}
            className='theme-light-show h-45px mb-10'
          ></img>
        </a>
        {/* end::Logo */}
      </div>
      {/* begin::Heading */}
      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.username && formik.errors.username},
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
          type='email'
          name='username'
          autoComplete='off'
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container text-danger'>
            <span role='alert'>{formik.errors.username}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <div className='input-group '>
          <input
            type={isShowPassword ? 'text' : 'password'}
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          <span
            className='input-group-text align-self-center cursor-pointer'
            onClick={(e) => setShowPassword(!isShowPassword)}
          >
            {!isShowPassword ? (
              <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
            ) : (
              <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
            )}
          </span>
        </div>
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Login</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <span
          onClick={() => {
            document.getElementById('post-signup-btn')?.click()
          }}
          className='link-primary cursor-pointer'
        >
          Sign up
        </span>
      </div>
      {/* begin::Separator */}
      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or</span>
      </div>
      {/* end::Separator */}

      <div className='d-grid mb-10'>
        <button
          onClick={() => {
            document.getElementById('post-login-close-btn')?.click()
            document.getElementById('post-next-btn')?.click()
          }}
          type='button'
          id='kt_sign_in_submit'
          className='btn btn-info'
          disabled={loading}
        >
          <span className='indicator-label'>Continue as a guest</span>
        </button>
      </div>
    </form>
  )
}
