import {FC, useEffect, useState} from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {KTSVG, toAbsoluteUrl} from '../../../../_handld/helpers'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import {ReactSelectStyles2} from '../../../global/ReactSelectStyles'
import Select from 'react-select'

const BASE_URL = process.env.REACT_APP_BASE_URL

const Services: FC = () => {
  const navigate = useNavigate()
  const [serviceName, setServiceName] = useState([])
  const [serviceList, setServiceList] = useState([])

  const handleGetServiceName = async () => {
    try {
      const serivces = await axios.get(`${BASE_URL}/api/Jobs/GetLandingService`)
      const serviceName = serivces.data?.services.map((service: any) => {
        return {value: service.id, label: service.name, code: service.code}
      })
      setServiceName(serviceName)
    } catch (e) {
      console.log(e)
    } finally {
    }
  }
  const handleGetLandingList = async () => {
    try {
      const List = await axios.get(`${BASE_URL}/api/Jobs/GetLandingList`)
      // const serviceName = serivces.data?.services.map((service: any) => {
      //   return {value: service.id, label: service.name, code: service.code}
      // })
      setServiceList(List?.data?.services)
    } catch (e) {
      console.log(e)
    } finally {
    }
  }
  useEffect(() => {
    handleGetLandingList()
    handleGetServiceName()
  }, [])
  return (
    <>
      <section className='bg-light-primary mx-4 rounded-4'>
        <Container>
          <Row className='align-items-center'>
            <Col xl='12' lg='12' md='12'>
              <div className='mb-5 mb-lg-10 py-10 py-lg-20'>
                {/*begin::Title*/}
                <h1 className='text-info lh-base fw-bold fs-2x fs-lg-3x mb-6'>
                  Handyman keeps your home in great shape, inside and out.
                </h1>
                {/*end::Title*/}
                <h1 className='text-info lh-base fw-normal mb-15'>
                  Choose a service to get started.
                </h1>
                <div className='fv-row mb-8'>
                  <Select
                    placeholder='I need help with...'
                    name='services'
                    options={serviceName}
                    styles={ReactSelectStyles2}
                    value={''}
                    onChange={(e: any) => {
                      document.getElementById('post-job-btn')?.click()
                      document.getElementById('handleGetProfile')?.click()
                      navigate(`/landing/service/${e.value}`)
                    }}
                  />
                </div>
                <div className='d-flex justify-content-start flex-wrap'>
                  {serviceName &&
                    serviceName.map((service: any) => {
                      return (
                        <button
                          className='btn btn-outline btn-outline-primary btn-active-light-primary me-3 mb-4'
                          onClick={() => {
                            document.getElementById('post-job-btn')?.click()
                            document.getElementById('handleGetProfile')?.click()
                            navigate(`/landing/service/${service.value}`)
                          }}
                        >
                          {service.label}
                        </button>
                      )
                    })}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <div className='card bg-transparent shadow-none'>
              <div className='card-body py-10 py-lg-15 px-0 text-center'>
                <div className='mb-17'>
                  {/*begin::Row*/}
                  <div className='row g-10'>
                    {/*begin::Col*/}
                    {serviceList?.map((service: any) => (
                      <div
                        className='col-md-3 mb-6 cursor-pointer'
                        onClick={() => {
                          console.log(service?.id)
                          document.getElementById('post-job-btn')?.click()
                          document.getElementById('handleGetProfile')?.click()
                          navigate(`/landing/service/${service?.id}`)
                        }}
                      >
                        <div className='card-xl-stretch me-md-6  bg-white pb-8 card-rounded'>
                          <div
                            className='overlay-wrapper bgi-no-repeat bgi-position-center bgi-size-cover card-rounded-top min-h-175px'
                            style={{
                              backgroundImage: `url('${toAbsoluteUrl(`${service?.picture}`)}')`,
                            }}
                          ></div>
                          <div className='mt-5 px-5 text-center'>
                            <div className='fs-4 text-dark fw-bold text-hover-primary text-dark lh-base'>
                              {service?.name}
                            </div>

                            <div className='fw-semibold fs-5 text-gray-600 text-dark mt-3'>
                              <KTSVG
                                path='/media/icons/duotune/finance/fin010.svg'
                                className='svg-icon svg-icon-2 me-1  '
                              />
                              Avg. project :
                              {' ' + service?.averagePrice === null ? '' : service?.averagePrice}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/*end::Row*/}
                </div>
              </div>
              {/*end::Body*/}
            </div>
          </Row>
        </Container>
      </section>
    </>
  )
}
export {Services}
