import React, {FC, useEffect, useState} from 'react'
import CreatableSelect from 'react-select/creatable'
import {ReactSelectStyles} from '../../../global/ReactSelectStyles'
import {toAbsoluteUrl} from '../../../../_handld/helpers'
import DropeZoneV1 from '../../../../global/DropZoneV1'

type Props = {
  job: any
  index: number
  handleChange: (index: number, field: string, value: any) => void
}
const WallMount: FC<Props> = ({job, handleChange, index}) => {
  const options = [
    {value: 'Concrete', label: 'Concrete'},
    {value: 'Drywall', label: 'Drywall'},
    {value: 'Unsure', label: 'Unsure'},
    {value: 'Partition Wall', label: 'Partition Wall'},
  ]

  return (
    <div>
      {job.category === 'TVWA' && (
        <>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Size of TV:</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Inches'
              value={job?.sizeInch || ''}
              onChange={(e) => {
                const newValue = e.target.value
                if (/^\d+$/.test(newValue)) {
                  handleChange(index, 'sizeInch', newValue)
                } else {
                  handleChange(index, 'sizeInch', '')
                }
              }}
            />
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Number of Tvs:</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Number of Tvs'
              value={job?.numberOfItems || ''}
              onChange={(e) => {
                const newValue = e.target.value
                if (newValue === '' || /^\d+$/.test(newValue)) {
                  handleChange(index, 'numberOfItems', newValue)
                }
              }}
            />
          </div>
        </>
      )}

      {job.category === 'SHEL' && (
        <>
          {' '}
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Shelves are from:</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='eg: IKEA/Wayfair/Structube etc'
              value={job?.itemsFrom}
              onChange={(e) => handleChange(index, 'itemsFrom', e.target.value)}
            />
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Model Name/Number</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='LACK/Union Rustic/ etc'
              value={job?.model}
              onChange={(e) => handleChange(index, 'model', e.target.value)}
            />
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Number of Shelves:</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Number of Shelves'
              value={job?.numberOfItems || ''}
              onChange={(e) => {
                const newValue = e.target.value
                if (newValue === '' || /^\d+$/.test(newValue)) {
                  handleChange(index, 'numberOfItems', newValue)
                }
              }}
            />
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Length of Shelves:</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Length of Shelves:'
              value={job?.length || ''}
              onChange={(e) => {
                const newValue = e.target.value
                if (/^\d+$/.test(newValue)) {
                  handleChange(index, 'length', newValue)
                } else {
                  handleChange(index, 'length', '')
                }
              }}
            />
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3'>
              Placement: Is installation above stairwell/fireplace or an area with limited/difficult
              access:
            </label>
            <div className='d-flex mx-5'>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`placementDifficulty${index}`}
                  checked={!job?.placementDifficulty}
                  value={job?.placementDifficulty}
                  onChange={(e) => handleChange(index, 'placementDifficulty', false)}
                />
                <span className='fw-bold ps-2 fs-6'>No</span>
              </label>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  name='placementDifficulty'
                  type='radio'
                  value={job?.placementDifficulty}
                  checked={job?.placementDifficulty}
                  onChange={(e) => handleChange(index, 'placementDifficulty', true)}
                />
                <span className='fw-bold ps-2 fs-6'>Yes</span>
              </label>
            </div>
          </div>
          {job?.placementDifficulty && (
            <div className='row mb-7 pb-3'>
              <DropeZoneV1 job={job} handleChange={handleChange} index={index} />
            </div>
          )}
        </>
      )}

      {job.category === 'ARTP' && (
        <>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Number of pictures:</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Number of pictures'
              value={job?.numberOfItems || ''}
              onChange={(e) => {
                const newValue = e.target.value
                if (newValue === '' || /^\d+$/.test(newValue)) {
                  handleChange(index, 'numberOfItems', newValue)
                }
              }}
            />
          </div>

          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Size:</label>
            <div
              id='kt_content_container'
              className='d-flex flex-column-fluid align-items-start container-xxl'
            >
              <div className='content flex-row-fluid' id='kt_content'>
                <div className='d-flex flex-column flex-xl-row'>
                  <div className='d-flex flex-row-fluid me-xl-9 mb-10 mb-xl-0'>
                    <div className='card card-flush card-p-0 bg-transparent border-0'>
                      <div className='card-body'>
                        <ul className='nav nav-pills d-flex justify-content-between nav-pills-custom gap-3 mb-6'>
                          <li
                            className='nav-item mb-3 me-0'
                            onClick={() => handleChange(index, 'size', 'M')}
                          >
                            <a
                              className={`nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg ${
                                job?.size === 'M' && 'active'
                              }`}
                              data-bs-toggle='pill'
                              style={{width: 200, height: 230}}
                            >
                              <div className='nav-icon mb-3'>
                                <img
                                  src={toAbsoluteUrl('/media/image.jpg')}
                                  className='w-80px h-100px'
                                  alt=''
                                />
                              </div>
                              <div className=''>
                                <span className='text-gray-800 fw-bold fs-2 d-block'>M</span>
                                <span className='text-gray-400 fw-semibold fs-7'>
                                  17.7" / 12.6"
                                </span>
                              </div>
                            </a>
                          </li>
                          <li
                            className='nav-item mb-3 me-0'
                            onClick={() => handleChange(index, 'size', 'L')}
                          >
                            <a
                              className={`nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg ${
                                job?.size === 'L' && 'active'
                              }`}
                              data-bs-toggle='pill'
                              style={{width: 200, height: 230}}
                            >
                              <div className='nav-icon mb-3'>
                                <img
                                  src={toAbsoluteUrl('/media/image.jpg')}
                                  className='w-90px h-100px'
                                  alt=''
                                />
                              </div>
                              <div className=''>
                                <span className='text-gray-800 fw-bold fs-2 d-block'>L</span>
                                <span className='text-gray-400 fw-semibold fs-7'>
                                  26.6" / 18.9"
                                </span>
                              </div>
                            </a>
                          </li>
                          <li
                            className='nav-item mb-3 me-0'
                            onClick={() => handleChange(index, 'size', 'XL')}
                          >
                            <a
                              className={`nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg ${
                                job?.size === 'XL' && 'active'
                              }`}
                              data-bs-toggle='pill'
                              style={{width: 200, height: 230}}
                            >
                              <div className='nav-icon mb-3'>
                                <img
                                  src={toAbsoluteUrl('/media/image.jpg')}
                                  className='w-100px h-100px'
                                  alt=''
                                />
                              </div>
                              <div className=''>
                                <span className='text-gray-800 fw-bold fs-2 d-block'>XL</span>
                                <span className='text-gray-400 fw-semibold fs-7'>
                                  35.4" / 25.2"
                                </span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3'>
              Placement: Is installation above stairwell/fireplace or an area with limited/difficult
              access:
            </label>
            <div className='d-flex mx-5'>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='radio'
                  checked={!job?.placementDifficulty}
                  value={job?.placementDifficulty}
                  onChange={(e) => handleChange(index, 'placementDifficulty', false)}
                />
                <span className='fw-bold ps-2 fs-6'>No</span>
              </label>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='radio'
                  value={job?.placementDifficulty}
                  checked={job?.placementDifficulty}
                  onChange={(e) => handleChange(index, 'placementDifficulty', true)}
                />
                <span className='fw-bold ps-2 fs-6'>Yes</span>
              </label>
            </div>
          </div>
          {job?.placementDifficulty && (
            <div className='row mb-7 pb-3'>
              <DropeZoneV1 job={job} handleChange={handleChange} index={index} />
            </div>
          )}
        </>
      )}

      {job.category === 'CABI' && (
        <>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Cabinets are from:</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='eg: IKEA/Wayfair/Structube etc'
              value={job?.itemsFrom}
              onChange={(e) => handleChange(index, 'itemsFrom', e.target.value)}
            />
          </div>

          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Model Name/Number</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='EG IKEA-Malm'
              value={job?.model}
              onChange={(e) => handleChange(index, 'model', e.target.value)}
            />
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3'>Assembly of Cabinet Required:</label>
            <div className='d-flex mx-5'>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  name={`assemblyRequired${index + 1}`}
                  type='radio'
                  checked={!job?.assemblyRequired}
                  value={job?.assemblyRequired}
                  onChange={(e) => handleChange(index, 'assemblyRequired', false)}
                />
                <span className='fw-bold ps-2 fs-6'>No</span>
              </label>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  name={`assemblyRequired${index}`}
                  type='radio'
                  value={job?.assemblyRequired}
                  checked={job?.assemblyRequired}
                  onChange={(e) => handleChange(index, 'assemblyRequired', true)}
                />
                <span className='fw-bold ps-2 fs-6'>Yes</span>
              </label>
            </div>
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3'>
              Placement: Is installation above stairwell/fireplace or an area with limited/difficult
              access:
            </label>
            <div className='d-flex mx-5'>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`placementDifficulty${index}`}
                  checked={!job?.placementDifficulty}
                  value={job?.placementDifficulty}
                  onChange={(e) => handleChange(index, 'placementDifficulty', false)}
                />
                <span className='fw-bold ps-2 fs-6'>No</span>
              </label>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`placementDifficulty${index + 1}`}
                  value={job?.placementDifficulty}
                  checked={job?.placementDifficulty}
                  onChange={(e) => handleChange(index, 'placementDifficulty', true)}
                />
                <span className='fw-bold ps-2 fs-6'>Yes</span>
              </label>
            </div>
          </div>
          {job?.placementDifficulty && (
            <div className='row mb-7 pb-3'>
              <DropeZoneV1 job={job} handleChange={handleChange} index={index} />
            </div>
          )}
        </>
      )}

      {job.category === 'ELEC' && (
        <>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>
              Link input for electric fireplace make/model:
            </label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder=''
              value={job?.itemLink}
              onChange={(e) => handleChange(index, 'itemLink', e.target.value)}
            />
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3'>Assembly of Fireplace required:</label>
            <div className='d-flex mx-5'>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  name={`assemblyRequired${index}`}
                  type='radio'
                  value={job?.assemblyRequired}
                  checked={job?.assemblyRequired}
                  onChange={(e) => handleChange(index, 'assemblyRequired', true)}
                />
                <span className='fw-bold ps-2 fs-6'>Yes</span>
              </label>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  name={`assemblyRequired${index + 1}`}
                  type='radio'
                  checked={!job?.assemblyRequired}
                  value={job?.assemblyRequired}
                  onChange={(e) => handleChange(index, 'assemblyRequired', false)}
                />
                <span className='fw-bold ps-2 fs-6'>No</span>
              </label>
            </div>
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3'>
              Depending on height/placement will someone be on hand to assist Handyman in lifting
              fireplace to the mounting bracket:
            </label>
            <div className='d-flex mx-5'>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  name={`assistHandlr${index}`}
                  type='radio'
                  value={job?.assistHandlr}
                  checked={job?.assistHandlr}
                  onChange={(e) => handleChange(index, 'assistHandlr', true)}
                />
                <span className='fw-bold ps-2 fs-6'>Yes</span>
              </label>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  name={`assistHandlr${index + 1}`}
                  type='radio'
                  checked={!job?.assistHandlr}
                  value={job?.assistHandlr}
                  onChange={(e) => handleChange(index, 'assistHandlr', false)}
                />
                <span className='fw-bold ps-2 fs-6'>No</span>
              </label>
            </div>
          </div>
        </>
      )}

      {job.category === 'WALLL' && (
        <>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Wall-Lamp(s) are from:</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='eg: IKEA/Wayfair/Structube etc'
              value={job?.itemsFrom}
              onChange={(e) => handleChange(index, 'itemsFrom', e.target.value)}
            />
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Model Name/Number</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='LACK/Union Rustic/ etc'
              value={job?.model}
              onChange={(e) => handleChange(index, 'model', e.target.value)}
            />
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3'>
              Placement: Is installation above stairwell/fireplace or an area with limited/difficult
              access:
            </label>
            <div className='d-flex mx-5'>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`placementDifficulty${index}`}
                  checked={!job?.placementDifficulty}
                  value={job?.placementDifficulty}
                  onChange={(e) => handleChange(index, 'placementDifficulty', false)}
                />
                <span className='fw-bold ps-2 fs-6'>No</span>
              </label>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`placementDifficulty${index + 1}`}
                  value={job?.placementDifficulty}
                  checked={job?.placementDifficulty}
                  onChange={(e) => handleChange(index, 'placementDifficulty', true)}
                />
                <span className='fw-bold ps-2 fs-6'>Yes</span>
              </label>
            </div>
          </div>
          {job?.placementDifficulty && (
            <div className='row mb-7 pb-3'>
              <DropeZoneV1 job={job} handleChange={handleChange} index={index} />
            </div>
          )}
        </>
      )}

      {job.category === 'CURT' && (
        <>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Number of Curtain Rods:</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Number of Curtain Rods'
              value={job?.numberOfItems || ''}
              onChange={(e) => {
                const newValue = e.target.value
                if (newValue === '' || /^\d+$/.test(newValue)) {
                  handleChange(index, 'numberOfItems', newValue)
                }
              }}
            />
          </div>

          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Number of windows:</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Number of windows'
              value={job?.numberOfWindows || ''}
              onChange={(e) => {
                const newValue = e.target.value
                if (/^\d+$/.test(newValue)) {
                  handleChange(index, 'numberOfWindows', newValue)
                } else {
                  handleChange(index, 'numberOfWindows', '')
                }
              }}
            />
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3'>Sizes of windows:</label>
            <div className='row'>
              <div className='col-md-6'>
                <label className='mb-3 required'>Width:</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid '
                  placeholder='Width'
                  value={job?.width || ''}
                  onChange={(e) => {
                    const newValue = e.target.value
                    if (/^\d+$/.test(newValue)) {
                      handleChange(index, 'width', newValue)
                    } else {
                      handleChange(index, 'width', '')
                    }
                  }}
                />
              </div>
              <div className='col-md-6'>
                <label className='mb-3 required'>Height:</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid '
                  placeholder='Height'
                  value={job?.height || ''}
                  onChange={(e) => {
                    const newValue = e.target.value
                    if (/^\d+$/.test(newValue)) {
                      handleChange(index, 'height', newValue)
                    } else {
                      handleChange(index, 'height', '')
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3'>
              Placement: Is installation above stairwell/fireplace or an area with limited/difficult
              access:
            </label>
            <div className='d-flex mx-5'>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`placementDifficulty${index}`}
                  checked={!job?.placementDifficulty}
                  value={job?.placementDifficulty}
                  onChange={(e) => handleChange(index, 'placementDifficulty', false)}
                />
                <span className='fw-bold ps-2 fs-6'>No</span>
              </label>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`placementDifficulty${index + 1}`}
                  value={job?.placementDifficulty}
                  checked={job?.placementDifficulty}
                  onChange={(e) => handleChange(index, 'placementDifficulty', true)}
                />
                <span className='fw-bold ps-2 fs-6'>Yes</span>
              </label>
            </div>
          </div>
          {job?.placementDifficulty && (
            <div className='row mb-7 pb-3'>
              <DropeZoneV1 job={job} handleChange={handleChange} index={index} />
            </div>
          )}
        </>
      )}

      {job.category === 'WALLMI' && (
        <>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Model Name/Number:</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Model Name/Number'
              value={job?.model}
              onChange={(e) => handleChange(index, 'model', e.target.value)}
            />
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Number of Curtain Rods:</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Number of Curtain Rods'
              value={job?.numberOfItems || ''}
              onChange={(e) => {
                const newValue = e.target.value
                if (newValue === '' || /^\d+$/.test(newValue)) {
                  handleChange(index, 'numberOfItems', newValue)
                }
              }}
            />
          </div>

          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3'>Sizes of Wall-Mirror:</label>
            <div className='row'>
              <div className='col-md-6'>
                <label className='mb-3 required'>Width:</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid '
                  placeholder='Width'
                  value={job?.width || ''}
                  onChange={(e) => {
                    const newValue = e.target.value
                    if (/^\d+$/.test(newValue)) {
                      handleChange(index, 'width', newValue)
                    } else {
                      handleChange(index, 'width', '')
                    }
                  }}
                />
              </div>
              <div className='col-md-6'>
                <label className='mb-3 required'>Height:</label>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid '
                  placeholder='Height'
                  value={job?.height || ''}
                  onChange={(e) => {
                    const newValue = e.target.value
                    if (/^\d+$/.test(newValue)) {
                      handleChange(index, 'height', newValue)
                    } else {
                      handleChange(index, 'height', '')
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3'>
              Placement: Is installation above stairwell/fireplace or an area with limited/difficult
              access:
            </label>
            <div className='d-flex mx-5'>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`placementDifficulty${index}`}
                  checked={!job?.placementDifficulty}
                  value={job?.placementDifficulty}
                  onChange={(e) => handleChange(index, 'placementDifficulty', false)}
                />
                <span className='fw-bold ps-2 fs-6'>No</span>
              </label>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  type='radio'
                  name={`placementDifficulty${index + 1}`}
                  value={job?.placementDifficulty}
                  checked={job?.placementDifficulty}
                  onChange={(e) => handleChange(index, 'placementDifficulty', true)}
                />
                <span className='fw-bold ps-2 fs-6'>Yes</span>
              </label>
            </div>
          </div>
          {job?.placementDifficulty && (
            <div className='row mb-7 pb-3'>
              <DropeZoneV1 job={job} handleChange={handleChange} index={index} />
            </div>
          )}
        </>
      )}

      {job.category === 'SMAL' && (
        <>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3 required'>Number of small items:</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Number of small items'
              value={job?.numberOfItems || ''}
              onChange={(e) => {
                const newValue = e.target.value
                if (newValue === '' || /^\d+$/.test(newValue)) {
                  handleChange(index, 'numberOfItems', newValue)
                }
              }}
            />
          </div>
          <div className='row mb-7 pb-3'>
            <div className='col-12'>
              <label className='fw-bold fs-5 mb-3 required'>Items/Description</label>
              <textarea
                className='form-control form-control-lg form-control-solid '
                rows={4}
                name='items'
                value={job?.items}
                onChange={(e) => handleChange(index, 'items', e.target.value)}
              />
            </div>
          </div>
        </>
      )}

      <div className='row mb-7 pb-3'>
        <label className='fw-bold fs-5 mb-3'>
          {job.category === 'TVWA' && 'Wall Info TV is being mounted to:'}
          {job.category === 'SHEL' && 'Wall Info Shelf are being mounted to:'}
          {job.category === 'ARTP' && 'Wall Info Art/Picture(s)  is being mounted to:'}
          {job.category === 'CABI' && 'Wall Info Cabinet(s) are being mounted to:'}
          {job.category === 'WALLMI' && 'Wall Info Wall-Mirror(s)  being mounted to:'}
          {job.category === 'CURT' && 'Wall Info Curtain Rod(s)  being mounted to:'}
          {job.category === 'SMAL' && 'Wall Info: item(s)  being mounted to:'}
        </label>
        {job.category === 'ELEC' || job.category === 'WALLL' ? (
          ''
        ) : (
          <CreatableSelect
            isClearable
            options={options}
            styles={ReactSelectStyles}
            value={
              options.find((option) => option.value === job?.wallType) || {
                value: job?.wallType,
                label: job?.wallType,
              }
            }
            onChange={(e) => handleChange(index, 'wallType', e?.value)}
            onCreateOption={(e) => handleChange(index, 'wallType', e)}
          />
        )}
      </div>
      {job.category === 'TVWA' && (
        <>
          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3'>Wall-Mount on hand:</label>
            <div className='d-flex mx-5'>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  name={`wallMountOnHand${index}`}
                  type='radio'
                  checked={!job?.wallMountOnHand}
                  value={job?.wallMountOnHand}
                  onChange={(e) => handleChange(index, 'wallMountOnHand', false)}
                />
                <span className='fw-bold ps-2 fs-6'>No</span>
              </label>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  name={`wallMountOnHand${index + 1}`}
                  type='radio'
                  checked={job?.wallMountOnHand}
                  value={job?.wallMountOnHand}
                  onChange={(e) => handleChange(index, 'wallMountOnHand', true)}
                />
                <span className='fw-bold ps-2 fs-6'>Yes</span>
              </label>
            </div>
          </div>

          <div className='row mb-7 pb-3'>
            <label className='fw-bold fs-5 mb-3'>
              Confirm Wall-Mount is correct specific to size of their TV:
            </label>
            <div className='d-flex mx-5'>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  name={`wallSpecificSize${index}`}
                  type='radio'
                  checked={!job?.wallSpecificSize}
                  value={job?.wallSpecificSize}
                  onChange={(e) => handleChange(index, 'wallSpecificSize', false)}
                />
                <span className='fw-bold ps-2 fs-6'>No</span>
              </label>
              <label className='form-check form-check-inline form-check-solid me-5'>
                <input
                  className='form-check-input'
                  name={`wallSpecificSize${index + 1}`}
                  type='radio'
                  checked={job?.wallSpecificSize}
                  value={job?.wallSpecificSize}
                  onChange={(e) => handleChange(index, 'wallSpecificSize', true)}
                />
                <span className='fw-bold ps-2 fs-6'>Yes</span>
              </label>
            </div>
          </div>
        </>
      )}
      <div className='row mb-7 pb-3'>
        <div className='col-12'>
          <label className='fw-bold fs-5 mb-3'>Additional Notes/Info</label>
          <textarea
            className='form-control form-control-lg form-control-solid '
            rows={4}
            name='additionalDetails'
            value={job?.additionalDetails}
            onChange={(e) => handleChange(index, 'additionalDetails', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default WallMount
