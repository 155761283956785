import React, {useEffect, useState} from 'react'
import PostJob from './PostJob'
import {useAuth} from '../auth'
import axios from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL
const PostJobWrapper = () => {
  const {currentUser} = useAuth()

  const [jobs, setJobs] = useState<any[]>([
    {
      additionalDetails: '',
      serviceId: 0,
      serviceId2: 0,
      serviceName: '',
      serviceCategoryName: '',
      serviceTypeCategoryName: '',
      code: '',
      typeCode: '',
      category: '',
      seviceCategory: 0,
      seriveTypeCategory: 0,
      serviceFirstChildren: [],
      serviceSecondChildren: [],
      vehiculeNeeded: '',
      sizeInch: null,
      wallMountOnHand: false,
      wallSpecificSize: false,
      wallType: '',
      itemsFrom: '',
      model: '',
      numberOfItems: null,
      length: null,
      placementDifficulty: false,
      placementDifficultyPicture: '',
      size: '',
      assemblyRequired: false,
      itemLink: '',
      assistHandlr: false,
      numberOfWindows: null,
      width: null,
      height: null,
      items: '',
      headboardWallMounted: false,
      storageDrawers: false,
      EndAddress1: '',
      EndAddress2: '',
      EndCity: '',
      EndState: 'Ontario',
      EndZipcode: '',
      EndCountry: 'Canada',
      CoatType: '',
      Type: '',
    },
  ])
  const [quote, setQuote] = React.useState<any>([])
  const [isFirst, setIsFirst] = React.useState<any>(true)
  const [profile, setProfile] = React.useState<any>([])
  const [address, setAddress] = useState<any>({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address1: '',
    address2: '',
    city: '',
    state: 'Ontario',
    country: 'Canada',
    zipcode: '',
    countryId: 40,
  })
  const [availableTimesArray, setAvailableTimesArray] = React.useState<string[]>([])
  const [jobId, setJobId] = useState<any>('')
  const [auth, setAuth] = useState<any>({})
  const [billing, setBilling] = useState<any>({
    cardToken: '',
    cardId: '',
    address1: '',
    address2: '',
    zipcode: '',
    city: '',
    state: '',
    country: 'Canada',
    coupon: '',
    jobsGroupId: jobId,
    quotesGroupId: quote?.id || null,
    email: '',
    requestedDate: '',
    availableTimes: '',
    dateType: '',
    countryId: 40,
    sameAddress: false,
  })

  useEffect(() => {
    setBilling({
      ...billing,
      quotesGroupId: quote?.id,
    })
  }, [quote])

  useEffect(() => {
    setBilling({
      ...billing,
      jobsGroupId: jobId,
    })
  }, [jobId])

  const handleGetProfile = async () => {
    const profile = await axios.get(`${BASE_URL}/api/Account/Profile`)
    setProfile(profile.data)
  }

  useEffect(() => {
    if (auth) {
      setAddress({
        ...address,
        firstName: auth.firstName,
        lastName: auth.lastName,
        phone: auth.phoneNumber,
        email: auth.email,
        address1: auth.address1,
        address2: auth.address2,
        city: auth.city,
        state: auth.state || 'Ontario',
        zipcode: auth.zipCode,
        country: auth.country || 'Canada',
      })
    }
  }, [auth])

  useEffect(() => {
    if (currentUser && profile?.profile) {
      setAddress({
        ...address,
        firstName: profile?.profile?.firstName,
        lastName: profile?.profile?.lastName,
        phone: profile?.profile?.phoneNumber,
        email: profile?.profile?.email,
        address1: profile?.profile?.address1,
        address2: profile?.profile?.address2,
        city: profile?.profile?.city,
        state: profile?.profile?.state || 'Ontario',
        zipcode: profile?.profile?.zipCode,
        country: profile?.profile?.country || 'Canada',
      })
    }
  }, [currentUser, profile?.profile])
  return (
    <div>
      <button
        hidden
        id='handleGetProfile'
        onClick={() => {
          if (currentUser) {
            handleGetProfile()
          }
        }}
      >
        Get profile
      </button>
      <PostJob
        jobsList={jobs}
        jobs={jobs}
        setJobs={setJobs}
        address={address}
        setAddress={setAddress}
        auth={auth}
        setAuth={setAuth}
        jobId={jobId}
        setJobId={setJobId}
        billing={billing}
        setBilling={setBilling}
        quote={quote}
        setQuote={setQuote}
        availableTimesArray={availableTimesArray}
        setAvailableTimesArray={setAvailableTimesArray}
        isFirst={isFirst}
        setIsFirst={setIsFirst}
      />
    </div>
  )
}

export default PostJobWrapper
