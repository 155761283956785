import {useQuery} from 'react-query'
import {QuotesEditModalForm} from './QuotesEditModalForm'
import {useListView} from '../core/ListViewProvider'
import {getUserById} from '../core/_requests'
import {QUERIES, isNotEmpty} from '../../../../../_handld/helpers'
import QueryFetchPost from '../../../../../global/index2'
import { useEffect, useState } from 'react'
import { handleApiErrors } from '../../../../global/HandleApiErrors'

const QuotesEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const[data,setdata]=useState<any>()
  const GetProfile = async () => {
    await QueryFetchPost({
      link: `${BASE_URL}/api/BackOffice/DetailsQuotesGroup/${itemIdForUpdate}`,
      data: undefined,
      method: 'GET',
      key: 'RegisterEmail',
      signal: undefined,
      isFormData: false,
    }).then((response) => {
      if (response[0] === 200) {
        setdata(response[1].quotesGroup)
      } else {
        handleApiErrors(response)
      }
    })
  }
  useEffect(() => {
    GetProfile()
  }, [itemIdForUpdate])



  if (!itemIdForUpdate && !data) {
    return <QuotesEditModalForm  data={data}/>
  }

  if (itemIdForUpdate && data) {
    return <QuotesEditModalForm  data={data}/>
  }

  return null
}

export {QuotesEditModalFormWrapper}
