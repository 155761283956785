import { Container, Form } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import axios from 'axios'
import { Button, Modal } from 'react-bootstrap'
import Swal from 'sweetalert2'
import CustomDropzone from '../../../../_handld/partials/handlr/CustomDropzone'
import { Link, useNavigate } from 'react-router-dom'
import { Formik, useFormik } from 'formik'
import PerfectScrollbar from 'react-perfect-scrollbar'

import clsx from 'clsx'
import { CountryStateGlobal } from '../../../../global/CountryState'
import QueryFetchPost, { ModelStateMessage } from '../../../../global/index2'
import { handleApiErrors } from '../../../global/HandleApiErrors'
import { Mode } from 'fs'
import DropeZoneV2 from '../../../../global/DropeZoneV2'
import { TagsInput } from 'react-tag-input-component'
import PhoneInput from 'react-phone-input-2'
import Select from 'react-select'
import { ReactSelectStyles, ReactSelectStyles2 } from '../../../global/ReactSelectStyles'

// type Props = {
//     isDrawer?: boolean
// }
type Model = {
  firstName: string
  lastName: string
  address1: string
  address2: string
  phoneNumber: string
  City: string
  State: any
  zipCode: string
  country: any
  Email: string
  legalWorkCanada: boolean
  ownToolsEquipment: boolean
  criminalConviction: boolean
  skills: any
  acceptTerms: boolean
  BackgroundCheck: boolean
}
const handlrschema = Yup.object().shape({
  address1: Yup.string().required('Address is required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
  Email: Yup.string().required('Email is required'),
  lastName: Yup.string().required('Last Name is required'),
  firstName: Yup.string().required('First Name is required'),
})

const BecomeHandlr = () => {
  const perfectScrollbarOptions = {
    wheelSpeed: 2,
    wheelPropagation: false,
  }
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const [files, setFiles] = useState<any>([])
  // const [selectedSkills, setSelectedSkills] = useState<string[]>([])
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const navigate = useNavigate()
  const [countryy, setcountryy] = useState('')
  const [userId, setUserId] = useState('')
  const [statee, setstatee] = useState('')
  const [company, setcompany] = useState('')
  const [loading] = useState(false)
  const [selectedSkills, setSelectedSkills] = useState([]);

  const initialValues = {
    firstName: '',
    lastName: '',
    address1: '',
    phoneNumber: '',
    address2: '',
    City: '',
    State: '',
    zipCode: '',
    country: '',
    Email: '',
    skills: [],
    legalWorkCanada: true,
    ownToolsEquipment: true,
    criminalConviction: true,
    acceptTerms: false,
    BackgroundCheck: true,
  }
  const [selectedCountry, setSelectedCountry] = useState('ca')
  const [Skillsname, setSkillsname] = useState([])

  const handleGetSkillsName = async () => {
    try {
      const data = await axios.get(`${BASE_URL}/api/handlr/GetViewModel`)
      console.log(data?.data?.skills)
      const Skillsname = data?.data?.skills.map((skill: any) => {
        return { value: skill, label: skill }
      })
      setSkillsname(Skillsname)
    } catch (e) {
      console.log(e)
    } finally {
    }
  }
  const extractSkillNames = (selectedOptions:any) => {
    return selectedOptions.map((option:any) => option.value);
  };
  useEffect(() => {
    handleGetSkillsName()
  }, [])
 
  const handleSelectChange = (selectedOptions:any) => {
    setSelectedSkills(selectedOptions);
  };
  const handleSubmit = async (credentials: Model) => {
    const selectedSkillNames = extractSkillNames(selectedSkills);
    try {
      await QueryFetchPost({
        link: `${BASE_URL}/api/Account/RegisterHandlr`,
        data: {
          firstName: credentials.firstName,
          lastName: credentials.lastName,
          address1: credentials.address1,
          address2: credentials.address2,
          City: credentials.City,
          phoneNumber: credentials.phoneNumber,
          State: statee,
          zipCode: credentials.zipCode,
          country: countryy,
          Email: credentials.Email,
          legalWorkCanada: credentials.legalWorkCanada,
          ownToolsEquipment: credentials.ownToolsEquipment,
          criminalConviction: credentials.criminalConviction,
          skills: selectedSkillNames,
          BackgroundCheck: credentials.BackgroundCheck,
          phoneNumberPrefix: selectedCountry,
        },
        method: 'POST',
        key: 'RegisterEmail',
        signal: undefined,
        isFormData: false,
      }).then((response: any) => {
        setUserId(response[1].userId)
        if (response[0] === 200) {
          Swal.fire({
            text: `Your application has been received and will be taken as consideration!`,
            icon: 'success',
            showConfirmButton: true,
            timer: 2000,
            width: '25rem',
          })
          setTimeout(() => {
            navigate('/landing', { replace: true })
          }, 1500)
        } else {
          ModelStateMessage(response)
        }
      })
    } catch (error) {
      console.error('Error during form submission:', error)
    }
  }
  useEffect(() => {
    if (userId) handleattachment(userId)
  }, [userId])

  const handleattachment = (Id: any) => {
    const formData = new (FormData as any)()
    files.forEach((file: any, i: any) => {
      formData.append('files', file)
    })
    axios
      .post(`${BASE_URL}/api/Account/AttachedFileHandld/${Id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.status != 200) {
          ModelStateMessage(response)
        }
      })
  }
  const handleCheckboxChange = () => {
    setAcceptTerms(!acceptTerms)
  }
  const handlePhoneChange = (value: string, country: any) => {
    setSelectedCountry(country.countryCode) // Update the selected country
    // Update formik value if needed
  }
  return (
    <>
      <Container>
        <>
          <div className='toolbar py-5 py-lg-15' id='kt_toolbar'>
            <div id='kt_toolbar_container' className='d-flex flex-stack '>
              <div className='page-title d-flex flex-column'>
                <h1 className='d-flex text-info fw-bold my-1'>Handyman Sign Up</h1>
              </div>
            </div>
          </div>

          <Formik
            validationSchema={handlrschema}
            initialValues={initialValues}
            onSubmit={(values) => {
              handleSubmit(values)
            }}
          >
            {({
              handleSubmit,
              values,
              getFieldProps,
              handleChange,
              handleBlur,
              setFieldValue,
              isSubmitting,
              isValid,
              touched,
              errors,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className='card shadow mb-5 border border-dashed '>
                    <div className='container'>
                      <div className='row mt-9'>
                        <div className='col-6'>
                          <div className='mb-3 bg-grey'>
                            <label className='form-label required'>First Name</label>
                            <input
                              type='text'
                              id='firstName'
                              placeholder='First Name'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.firstName}
                              className={clsx(
                                'form-control form-control-solid',
                                {
                                  'is-invalid': touched.firstName && errors.firstName,
                                },
                                {
                                  'is-valid': touched.firstName && !errors.firstName,
                                }
                              )}
                            />
                            {touched.firstName &&
                              errors.firstName &&
                              typeof errors.firstName === 'string' && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>{errors.firstName}</span>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>

                        <div className='col-6'>
                          <div className='mb-3'>
                            <label className='form-label required'>Last Name</label>
                            <input
                              id='lastName'
                              type='text'
                              placeholder='last Name'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.lastName}
                              className={clsx(
                                'form-control form-control-solid',
                                {
                                  'is-invalid': touched.firstName && errors.firstName,
                                },
                                {
                                  'is-valid': touched.firstName && !errors.firstName,
                                }
                              )}
                            />
                            {touched.lastName &&
                              errors.lastName &&
                              typeof errors.lastName === 'string' && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>{errors.lastName}</span>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className='mb-3'>
                        <label htmlFor='AddressLine1' className='required'>Address Line 1</label>
                        <input
                          type='text'
                          placeholder='Address Line 1'
                          id='address1'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.address1}
                          className={clsx(
                            'form-control form-control-solid',
                            {
                              'is-invalid': touched.address1 && errors.address1,
                            },
                            {
                              'is-valid': touched.address1 && !errors.address1,
                            }
                          )}
                        />
                        {touched.address1 &&
                          errors.address1 &&
                          typeof errors.address1 === 'string' && (
                            <span className='text-danger' role='alert'>
                              {errors.address1}
                            </span>
                          )}
                      </div>
                      <div className='mb-3'>
                        <label htmlFor='AddressLine2'>Address Line 2</label>
                        <input
                          id='address2'
                          placeholder='Address Line 2'
                          type='text'
                          onBlur={handleBlur}
                          className='form-control form-control-solid'
                          onChange={handleChange}
                          value={values.address2}
                        />
                        {touched.address2 &&
                          errors.address2 &&
                          typeof errors.address2 === 'string' && (
                            <span className='text-danger' role='alert'>
                              {errors.address2}
                            </span>
                          )}
                      </div>
                      <div className='row mb-6'>
                        <div className='col-lg-12 fv-row'>
                          <CountryStateGlobal
                            setstatee={setstatee}
                            statee={statee}
                            setcountryy={setcountryy}
                            countryy={countryy}
                          />
                        </div>
                      </div>
                      <div className='row mt-9'>
                        <div className='col-6'>
                          <div className='mb-3'>
                            <label className='form-label'>City</label>
                            <input
                              type='text'
                              id='City'
                              placeholder='City'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.City}
                              className={clsx(
                                'form-control form-control-solid',
                                {
                                  'is-invalid': touched.City && errors.City,
                                },
                                {
                                  'is-valid': touched.City && !errors.City,
                                }
                              )}
                            />
                            {touched.City && errors.City && typeof errors.City === 'string' && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{errors.City}</span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='mb-3'>
                            <label className='form-label'>Postal Code</label>
                            <input
                              type='text'
                              id='zipCode'
                              placeholder='Postal Code'
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.zipCode}
                              className={clsx(
                                'form-control form-control-solid',
                                {
                                  'is-invalid': touched.zipCode && errors.zipCode,
                                },
                                {
                                  'is-valid': touched.zipCode && !errors.zipCode,
                                }
                              )}
                            />
                            {touched.zipCode &&
                              errors.zipCode &&
                              typeof errors.zipCode === 'string' && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>{errors.zipCode}</span>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className='row mt-9'>
                        <div className='col-6'>
                          <div className='mb-3'>
                            <label htmlFor='Email' className='form-label required'>
                              Email
                            </label>
                            <input
                              type='email'
                              id='Email'
                              onBlur={handleBlur}
                              placeholder='Email'
                              onChange={handleChange}
                              value={values.Email}
                              className={clsx(
                                'form-control form-control-solid',
                                {
                                  'is-invalid': touched.Email && errors.Email,
                                },
                                {
                                  'is-valid': touched.Email && !errors.Email,
                                }
                              )}
                            />
                            {touched.Email && errors.Email && typeof errors.Email === 'string' && (
                              <span className='text-danger' role='alert'>
                                {errors.Email}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className='col-6'>
                          <div className='mb-3'>
                            <label htmlFor='phoneNumber' className='form-label required'>
                              Phone Number
                            </label>
                            <PhoneInput
                              preferredCountries={['us', 'ca', 'ma']}
                              inputStyle={{
                                width: '100%',
                                padding: '1.7rem',
                                paddingLeft: '4rem',
                                backgroundColor: '#f9f9f9',
                                borderColor: '#f9f9f9',
                              }}
                              country={'ca'}
                              value={company}
                              onChange={(value: any, country: any) => {
                                setSelectedCountry(country.countryCode)
                                setcompany(value)
                                setFieldValue('phoneNumber', value) // Update formik value
                              }}
                            />
                            {touched.phoneNumber &&
                              errors.phoneNumber &&
                              typeof errors.phoneNumber === 'string' && (
                                <span className='text-danger' role='alert'>
                                  {errors.phoneNumber}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className='mb-3'>
                        <label className='form-label'>Skills:</label>
                        <div className='my-2'>
                          <Select
                           placeholder='Select your skill(s)...'
                            name='Skills'
                            options={Skillsname}
                            styles={ReactSelectStyles}
                            value={selectedSkills}
                            isSearchable={true}
                            isMulti={true}
                            onChange={handleSelectChange}
                          />
                        </div>
                      </div>

                      <div className='row mb-3'>
                        <label className='col-form-label required fw-bold'>
                          Are you legally entitled to work in Canada?
                        </label>
                        <div className='col-12'>
                          <div className='form-check form-check-custom form-check-sm'>
                            <input
                              className='form-check-input'
                              defaultChecked={true}
                              type='radio'
                              name='legalWorkCanada'
                              id='legalWorkCanada'
                              checked={values.legalWorkCanada}
                              onChange={(e: any) => {
                                setFieldValue('legalWorkCanada', e.target.checked)
                              }}
                            />
                            <label className='form-check-label' htmlFor='flexRadioDefault'>
                              Yes
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-sm mt-5'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='legalWorkCanada'
                              id='legalWorkCanada'
                              checked={values.legalWorkCanada === false ? true : false}
                              onChange={(e: any) => {
                                if (e.target.checked === true) {
                                  setFieldValue('legalWorkCanada', false)
                                }
                              }}
                            />
                            <label className='form-check-label' htmlFor='flexRadioChecked'>
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-form-label required fw-bold'>
                          Do you have your own tools and equipment to complete jobs for Handyman?
                        </label>
                        <div className='col-12'>
                          <div className='form-check form-check-custom form-check-sm'>
                            <input
                              className='form-check-input'
                              defaultChecked={true}
                              type='radio'
                              name='ownToolsEquipment'
                              id='ownToolsEquipment'
                              checked={values.ownToolsEquipment}
                              onChange={(e: any) => {
                                setFieldValue('ownToolsEquipment', e.target.checked)
                              }}
                            />
                            <label className='form-check-label' htmlFor='flexRadioDefault'>
                              Yes
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-sm mt-5'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='ownToolsEquipment'
                              id='ownToolsEquipment'
                              checked={values.ownToolsEquipment === false ? true : false}
                              onChange={(e: any) => {
                                if (e.target.checked === true) {
                                  setFieldValue('ownToolsEquipment', false)
                                }
                              }}
                            />
                            <label className='form-check-label' htmlFor='flexRadioChecked'>
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-form-label required fw-bold'>
                          Question: Are you willing to complete a police background check?{' '}
                        </label>
                        <div className='col-12'>
                          <div className='form-check form-check-custom form-check-sm'>
                            <input
                              className='form-check-input'
                              defaultChecked={true}
                              type='radio'
                              name='BackgroundCheck'
                              id='BackgroundCheck'
                              checked={values.BackgroundCheck}
                              onChange={(e: any) => {
                                setFieldValue('BackgroundCheck', e.target.checked)
                              }}
                            />
                            <label className='form-check-label' htmlFor='flexRadioDefault'>
                              Yes
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-sm mt-5'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='BackgroundCheck'
                              id='BackgroundCheck'
                              checked={values.BackgroundCheck === false ? true : false}
                              onChange={(e: any) => {
                                if (e.target.checked === true) {
                                  setFieldValue('BackgroundCheck', false)
                                }
                              }}
                            />
                            <label className='form-check-label' htmlFor='flexRadioChecked'>
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className='col-form-label required fw-bold'>
                          Have you ever been convicted of a criminal offence?
                        </label>
                        <div className='col-12'>
                          <div className='form-check form-check-custom form-check-sm'>
                            <input
                              className='form-check-input'
                              defaultChecked={true}
                              type='radio'
                              name='criminalConviction'
                              id='criminalConviction'
                              checked={values.criminalConviction}
                              onChange={(e: any) => {
                                setFieldValue('criminalConviction', e.target.checked)
                              }}
                            />
                            <label className='form-check-label' htmlFor='flexRadioDefault'>
                              Yes
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-sm mt-5'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='criminalConviction'
                              id='criminalConviction'
                              checked={values.criminalConviction === false ? true : false}
                              onChange={(e: any) => {
                                if (e.target.checked === true) {
                                  setFieldValue('criminalConviction', false)
                                }
                              }}
                            />
                            <label className='form-check-label' htmlFor='flexRadioChecked'>
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='row mb-3'>
                        <label className=' col-form-label  fw-bold '>File Upload </label>
                        <DropeZoneV2 files={files} setFiles={setFiles} />
                        <span className='text-muted'>
                          Not required but great putting a face to a name!
                        </span>
                      </div>
                      <div className='fv-row mb-8'>
                        <label
                          className='form-check form-check-inline'
                          htmlFor='kt_login_toc_agree'
                        >
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='kt_login_toc_agree'
                            {...getFieldProps('acceptTerms')}
                            checked={acceptTerms}
                            onChange={handleCheckboxChange}
                          />
                          <span>
                            <label
                              onClick={handleShow}
                              className='ml-1 text-primary cursor-pointer '
                            >
                              {' I have read and agree to the Terms of Service'}
                            </label>
                          </span>
                        </label>
                        {touched.acceptTerms && errors.acceptTerms && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              <span role='alert'>{errors.acceptTerms}</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button
                          type='submit'
                          id='kt_sign_up_submit'
                          className='btn btn-primary '
                          disabled={!isValid || !acceptTerms}
                        >
                          {!loading && <span className='indicator-label'>Submit</span>}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...{' '}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )
            }}
          </Formik>

          <Modal show={show} onHide={handleClose} className='xl '>
            <Modal.Header closeButton className='py-4 px-3'>
              <Modal.Title className='ml-10 h3 cursor-pointer'>Terms of service</Modal.Title>
            </Modal.Header>

            <Modal.Body className='p-0'>
              <div className='login-form login-signin' style={{ display: 'block' }}>
                <div className='card card-custom card-stretch' id='kt_page_stretched_card'>
                  <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className='scroll px-5'
                    style={{ maxHeight: '50vh', position: 'relative' }}
                  >
                    <>
                      <div className='card-body px-3'>
                        <>
                          <div className='rich-text-block-2 w-richtext'>
                            <p>
                              <strong>Welcome to Handyman.</strong>
                            </p>
                            <p>
                              Terms of Service | Independent Contractor Agreement | Privacy Policy
                            </p>
                            <p>
                              <strong>ACCEPTANCE OF TERMS:</strong> These Terms of Service
                              constitute an agreement that is legally binding between you and
                              Handyman Inc. (“Company” or Handyman) administering your use of the
                              Handyman Platform (as defined below), Company’s website (
                              <a href='https://www.gohandyman.ca'>https://www.gohandyman.ca</a>
                              ). The Handyman Inc. Platform and all related services provided by
                              Company, and the website, including without limitation
                              communications and information (whether in writing, orally, or other
                              means) provided by Company to potential and current Handyman’s and
                              Clients (both as defined below) in relation to the services, are
                              together hereinafter collectively referred to as the “Handyman
                              Platform.” Personal data that you submit, or that we collect about
                              you is governed by the Handyman Privacy Policy (“Privacy Policy”). By
                              using the Handyman Platform, you have acknowledged that you have
                              reviewed the Privacy Policy. Your use of the Handyman Platform
                              constitutes your acceptance of and agreement to all the terms and
                              conditions in these Terms of Service, Privacy Policy, Independent
                              Contractor Agreement, and any future amendments and additions to
                              this Agreement (as defined below) as we may update from time to
                              time.
                            </p>
                            <p>
                              BY ENTERING INTO THIS AGREEMENT, YOU CLEARLY AND EXPRESSLY
                              ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND, AND AGREE, WITHOUT
                              LIMITATION OR QUALIFICATION, TO BE BOUND BY THIS AGREEMENT. IF YOU
                              DO NOT AGREE TO BE BOUND BY THIS AGREEMENT, YOU MAY NOT USE OR
                              ACCESS THE HANDLYMAN PLATFORM. You must be at least 18 years of age and
                              capable and competent in entering into binding contracts in order to
                              use and access the Handyman Platform. If you are under the age of 18,
                              you may not use or access the Handyman Platform.
                            </p>
                            <p>
                              <strong>ACKNOWLEDGMENTS :</strong>
                            </p>
                            <p>
                              User hereby acknowledges that:
                              <ul>
                                <li>
                                  Your agreement that Handyman’s are not employees of the Handyman
                                  Platform. Handyman’s are their own independent contractors. (See
                                  Independent Contractor Agreement)
                                </li>
                                <li>
                                  Your acknowledgement and agreement that Clients and not Handyman,
                                  supervise and monitor a Handyman’s work.
                                </li>
                                <li>
                                  Your acknowledgment that it is the responsibility of Client and
                                  Handyman to notify (via the Handyman Platform) when a job is
                                  completed.
                                </li>
                                <li>
                                  Your acknowledgement in becoming a Handyman that you are capable
                                  and competent to meet the needs in each job you accept.
                                </li>
                                <li>
                                  Your acknowledgement as a Client that it is your responsibility
                                  to ensure a safe and adequate working area for Handyman.
                                </li>
                                <li>
                                  Your acknowledgement of any disputes to be resolved on a case by
                                  case basis by Handyman Inc.
                                </li>
                              </ul>
                            </p>
                            <p>
                              <strong>DESCRIPTION OF SERVICES:</strong> Handyman Inc is an online
                              communications platform which provides an avenue for Clients to
                              connect with Handyman’s. “Clients” are individuals and/or businesses
                              looking to hire a Handyman to pick-up, deliver, and assemble their
                              product(s). “Handyman’s” are individuals who possess the ability and
                              the means to pick-up, deliver, and assemble a customer’s product(s).
                              Hereinafter, unless otherwise specified, both Clients and Handyman’s
                              will be referred to as Users. Once the terms of a job are accepted
                              with another User, both Users form a Service Agreement directly
                              between the two.
                            </p>
                            <p>
                              <strong>USER VETTING AND REPRESENTATIONS:</strong>
                            </p>
                            <p>
                              In the sole discretion of Handyman Inc, Users may be subjected to an
                              extensive vetting process before they can use the Handyman Platform.
                              Vetting process may include, but not limited to police background
                              checks, employment history, and related work experience. Although
                              Handyman Inc confirms the identity of each Handyman upon registration,
                              Handyman cannot confirm that each User who responds to a job is who
                              they claim to be. Any person who agrees to and responds to a job in
                              the guise of registered Handyman, will be prosecuted to the fullest
                              extent of the law. It is the sole responsibility of the Client to
                              confirm that the Handyman is who they say they are. Any interaction
                              with other Users requires the need to exercise caution and common
                              sense, in order to protect personal safety, data, and property.
                              Handyman will not be liable for any false or misleading statements
                              made by Users on the Handyman Platform.
                            </p>
                            <p>
                              <strong>DISCLAIMER OF WARRANTY; LIMITATION OF LIABILITY:</strong>
                            </p>
                            <ul role='list'>
                              <li>
                                USER EXPRESSLY AGREES THAT USE OF{' '}
                                <a href='https://www.gohandyman.ca'>https://www.gohandyman.ca</a> IS AT
                                USER'S SOLE RISK. NEITHER HANDLD , ITS AFFILIATES NOR ANY OF THEIR
                                RESPECTIVE EMPLOYEES, AGENTS, THIRD PARTY CONTENT PROVIDERS OR
                                LICENSORS WARRANT THAT
                                <a href='https://www.gohandyman.ca'> https://www.gohandyman.ca</a> WILL BE
                                UNINTERRUPTED OR ERROR FREE; NOR DO THEY MAKE ANY WARRANTY AS TO
                                THE RESULTS THAT MAY BE OBTAINED FROM USE OF
                                <a href='https://www.gohandyman.ca'> https://www.gohandyman.ca</a>, OR AS
                                TO THE ACCURACY, RELIABILITY OR CONTENT OF ANY INFORMATION,
                                SERVICE, OR MERCHANDISE PROVIDED THROUGH
                                <a href='https://www.gohandyman.ca'> https://www.gohandyman.ca</a>
                              </li>
                              <li>
                                <a href='https://www.gohandyman.ca'> https://www.gohandyman.ca</a> IS
                                PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY KIND,
                                EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
                                WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR
                                FITNESS FOR A PARTICULAR PURPOSE, OTHER THAN THOSE WARRANTIES
                                WHICH ARE IMPLIED BY AND INCAPABLE OF EXCLUSION, RESTRICTION OR
                                MODIFICATION UNDER THE LAWS APPLICABLE TO THIS AGREEMENT.
                              </li>
                              <li>
                                THIS DISCLAIMER OF LIABILITY APPLIES TO ANY DAMAGES OR INJURY
                                CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION,
                                INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR
                                TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR
                                DESTRUCTION OR UNAUTHORIZED ACCESS TO
                                <a href='https://www.gohandyman.ca'> https://www.gohandyman.ca</a>,
                                ALTERATION OF, OR USE OF RECORD, WHETHER FOR BREACH OF CONTRACT,
                                TORTIOUS BEHAVIOR, NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION.
                                USER SPECIFICALLY ACKNOWLEDGES THAT HANDLYMAN IS NOT LIABLE FOR THE
                                DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER USERS OR
                                THIRD-PARTIES AND THAT THE RISK OF INJURY FROM THE FOREGOING RESTS
                                ENTIRELY WITH USER.
                              </li>
                            </ul>
                            <h5>6. REPRESENTATIONS AND WARRANTIES</h5>
                            <p>User represents and acknowledges that:</p>
                            <ul>
                              <li>s/he is over the age of 18.</li>
                              <li>s/he is legally eligible to work in Canada.</li>
                              <li>s/he is capable of entering into binding contracts.</li>
                              <li>
                                User has the capability, ability, and the means to perform the
                                agreed upon duties and requirements in each job user accept.
                              </li>
                              <li>
                                Users will not be inebriated by any substance when fulfilling the
                                requirements and duties of the agreed upon job.
                              </li>
                              <li>
                                Where user enters into this Agreement on behalf of a company or
                                other organization, user warrants that s/he has authority to act
                                on behalf of that entity, and to bind that entity to this
                                Agreement.
                              </li>
                              <li>
                                User further represents and warrants that user has read,
                                understand, and agrees to be bound by these Terms of Service, and
                                Privacy Policy in order to register, use, and access the Handyman
                                Platform.
                              </li>
                              <li>
                                User hereby warrant and represent that user will respect the
                                privacy, property, and data protection rights of Users in respect
                                to the information provided on job detail agreements. Users will
                                not save information of other Users.
                              </li>
                              <li>
                                User warrants and represents that s/he will act professionally and
                                responsibly in user interactions with other Users.
                              </li>
                              <li>
                                User further represents and acknowledges that when using and
                                accessing the Handyman Platform, user will act in accordance to
                                applicable Municipal, Provincial, and Federal laws, and always in
                                good faith.
                              </li>
                              <li>
                                User hereby warrants and represents that, other than as fully and
                                promptly disclosed to Handyman Inc as set forth below, users does
                                not have any motivation, status, or interest which Handyman may
                                reasonably wish to know about in connection with the Handyman
                                Platform, including without limitation, if user are using or will
                                or intend to use the Handyman Platform for any journalistic,
                                investigative, or unlawful purpose.
                              </li>
                              <li>
                                User hereby warrant and represent that user will promptly disclose
                                to Handyman in writing any such motivation, status, or interest,
                                whether existing prior to registration or as arises during user
                                use of the Handyman Platform.
                              </li>
                              <li>
                                In addition, user represents and warrants that user will provide
                                timely, high-quality services to Handyman Clients.
                              </li>
                              <li>
                                User will only accept jobs that user have the availability,
                                capability, means, skills and expertise to complete jobs in a
                                professional, high-quality manner.
                              </li>
                              <li>
                                User also represents and warrants that s/he will provide the
                                services safely and in accordance with all applicable laws,
                                including but not limited to being in a sober state of mind upon
                                arrival, and possessing legal eligibility to work in Canada.
                              </li>
                            </ul>
                          </div>
                        </>
                      </div>
                    </>
                  </PerfectScrollbar>
                  <div className='card-footer'>
                    <div className='form-group'>
                      <label className='form-check form-check-inline'>
                        <input
                          className='form-check-input me-5 cursor-pointer'
                          type='checkbox'
                          name='acceptTerms'
                          checked={acceptTerms}
                          onChange={handleCheckboxChange}
                        />
                        <span />I agree to the Terms &amp; Conditions
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer className='py-2'>
              <Button variant='link' onClick={handleClose} size='sm'>
                {'Close'}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </Container>
    </>
  )
}

export default BecomeHandlr
