import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_handld/layout/core'
import DashbaordCards from './cards/DashbaordCards'
import {TableLatest} from './table/TableLatest'
import {StatisticsChart} from './StatisticsChart'
import {JobsStatus} from './JobsStatus'

const DashboardPage = () => (
  <>
    <DashbaordCards />
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-12'>
        <StatisticsChart className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-6 col-lg-6 col-md-12'>
        <TableLatest className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
      <div className='col-xl-6 col-lg-6 col-md-12'>
        <JobsStatus className='card-xl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
  </>
)

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
