import {Container, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_handld/helpers'
import {useAuth} from '../../auth'

const AboutUs = () => {
  const {currentUser} = useAuth()

  return (
    <>
      <Container>
        <Row className='py-15'>
          <section>
            <div className='text-center mb-15'>
              <h3 className='fs-3hx text-dark mb-5'>Hello from Handyman.</h3>
              <div className='fs-5 text-muted fw-semibold'>
                <p className='lead' style={{textAlign: 'center'}}>
                  Thank you so much for visiting and checking us out! We are beyond <br />
                  grateful to be considered in being the service you choose to put together <br />a
                  piece of your home.
                </p>
              </div>
            </div>
          </section>
          <section>
            <div className='card-body'>
              <div className='mb-18'>
                <div className='mb-10'>
                  <img
                    className='w-100 mh-500px card-rounded'
                    src={toAbsoluteUrl('/media/logos/car.jpg')}
                    alt=''
                    style={{objectFit: 'cover'}}
                  />
                </div>
                <div className='text-center mb-15'>
                  <h3 className='fs-2hx text-dark mb-5'>Services built on passion and integrity</h3>
                  <div className='fs-5 text-muted fw-semibold'>
                    We take being invited into your home very seriously. Our Handymen are all well{' '}
                    <br />
                    informed and trained on the importance of maintaining a professional and <br />
                    respectful presence.
                  </div>
                  {/*end::Text*/}
                </div>
                {/*end::Description*/}
              </div>
            </div>
          </section>
          <section>
            <div className='text-center mb-12'>
              <h3 className='fs-2hx text-dark mb-5'>What drives us</h3>
              <div className='fs-5 text-muted fw-semibold'>
                Providing Value, Connecting Communities, Creating Opportunities
              </div>
            </div>
          </section>
          <section className=' container'>
            <div className='mb-16'>
              <div className='row g-10 mt-10 '>
                <div className='col-md-4'>
                  <div className='card-xl-stretch me-md-6 '>
                    <div className='m-0'>
                      <p className='fs-4 text-dark fw-bold text-hover-primary text-dark lh-base'>
                        Providing Value
                      </p>
                      <div className='fw-semibold fs-5 text-gray-600 text-dark mt-3 mb-5'>
                        Our most important key benefit we can provide our customers is time. There
                        are so many cool and exciting things to do in this city. Assembling
                        furniture is not one of them. Leave that to us.
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='card-xl-stretch mx-md-3 '>
                    <div className='m-0'>
                      <p className='fs-4 text-dark fw-bold text-hover-primary text-dark lh-base'>
                        Connecting Communities
                      </p>
                      <div className='fw-semibold fs-5 text-gray-600 text-dark mt-3 mb-5'>
                        One of the results of living in a vibrant, modern, upbeat city is that it
                        attracts many talented and capable people. We are all neighbours and all
                        value being a part of a thriving, warm, friendly community.
                        <br />
                        <br />
                        We connect these people with skills and capabilities, to people who are in
                        need of their services; in the safest, most cost efficient way possible.
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='card-xl-stretch ms-md-6'>
                    <div className='m-0'>
                      <p className='fs-4 text-dark fw-bold text-hover-primary text-dark lh-base'>
                        Providing Opportunities
                      </p>
                      <div className='fw-semibold fs-5 text-gray-600 text-dark mt-3 mb-5'>
                        A source of secondary income is empowering. Having the ability to earn an
                        additional income to make life more comfortable, is a certain way to get the
                        most out of life.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='mt-10'>
            <div className='text-center mb-15'>
              <h3 className='fs-1hx text-dark mb-5'>We're looking for Handymen!</h3>
              <div className='fs-5 text-muted fw-semibold'>
                <p className='lead' style={{textAlign: 'center'}}>
                  Our Handyman recruitment window is currently OPEN. We carefully moderate <br />
                  how many Handymen are active at a time. This is to help ensure our trusted <br />
                  Handymen are able to accept as many jobs as they are willing and able.
                </p>
                {!currentUser && (
                  <Link to='/become-a-handyman' className='btn btn-primary'>
                    Become a Handyman
                  </Link>
                )}
              </div>
            </div>
          </section>
        </Row>
      </Container>
    </>
  )
}
export default AboutUs
