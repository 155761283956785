import React, {FC, useState} from 'react'
import {Container} from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import {RegisterPage} from './RegisterPage'
import {ConfrmEmail} from './ConfrmEmail'
type Props = {}
const SignupModal: FC<Props> = ({}) => {
  const [showConfirm, setShowConfirm] = useState(false)
  const [show, setShow] = useState(false)
  const handleShow = () => {
    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <button
        hidden
        id='post-signup-btn'
        onClick={handleShow}
        className='btn btn-primary rounded-pill btn-sm me-3'
      >
        Open
      </button>
      <button
        hidden
        id='post-signup-close-btn'
        onClick={() => {
          handleClose()
          setShowConfirm(false)
        }}
        className='btn btn-primary rounded-pill btn-sm me-3'
      >
        Close
      </button>
      <Modal
        show={show}
        size='lg'
        onHide={() => {
          setShow(false)
          setShowConfirm(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className='mw-75'>
            {showConfirm ? (
              <div className='h-600px'>
                <ConfrmEmail />
              </div>
            ) : (
              <RegisterPage setShowConfirm={setShowConfirm} />
            )}
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SignupModal
