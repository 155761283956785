import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_handld/helpers'
import {Container, Row} from 'react-bootstrap'

const FAQ: FC = () => {
  return (
    <>
      <Container>
        <Row className='py-15'>
          <div className='card'>
            {/*begin::Body*/}
            <div className='card-body p-10 p-lg-15'>
              {/*begin::Classic content*/}
              <div className='mb-13'>
                {/*begin::Intro*/}
                <div className='mb-15'>
                  {/*begin::Title*/}
                  <h4 className='fs-2x text-gray-800 w-bolder mb-6'>Frequesntly Asked Questions</h4>
                  {/*end::Title*/}
                  {/*begin::Text*/}
                  <p className='fw-semibold fs-4 text-gray-600 mb-2'>
                    First, a disclaimer – the entire process of writing a blog post often takes more
                    than a couple of hours, even if you can type eighty words as per minute and your
                    writing skills are sharp.
                  </p>
                  {/*end::Text*/}
                </div>
                {/*end::Intro*/}
                {/*begin::Row*/}
                <div className='row mb-20 pb-20 align-items-center'>
                  {/*begin::Col*/}
                  <div className='col-md-6 pe-md-10 mb-10 mb-md-0'>
                    {/*begin::Title*/}
                    <h2 className='text-gray-800 fw-bold mb-4'>For Handymen</h2>
                    {/*end::Title*/}
                    {/*begin::Accordion*/}
                    {/*begin::Section*/}
                    <div className='m-0'>
                      {/*begin::Heading*/}
                      <div
                        className='d-flex align-items-center collapsible py-3 toggle mb-0 collapsed'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_job_4_1'
                        aria-expanded='false'
                      >
                        {/*begin::Icon*/}
                        <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                          <i className='ki-duotone ki-minus-square toggle-on text-primary fs-1'>
                            <span className='path1' />
                            <span className='path2' />
                          </i>
                          <i className='ki-duotone ki-plus-square toggle-off fs-1'>
                            <span className='path1' />
                            <span className='path2' />
                            <span className='path3' />
                          </i>
                        </div>
                        {/*end::Icon*/}
                        {/*begin::Title*/}
                        <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>
                          When do we get paid?
                        </h4>
                        {/*end::Title*/}
                      </div>
                      {/*end::Heading*/}
                      {/*begin::Body*/}
                      <div id='kt_job_4_1' className='fs-6 ms-1 collapse' style={{}}>
                        {/*begin::Text*/}
                        <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                          Payout is sent as soon as the job has been completed. No processing times
                          or delays. Receive payout right away.
                        </div>
                        {/*end::Text*/}
                      </div>
                      {/*end::Content*/}
                      {/*begin::Separator*/}
                      <div className='separator separator-dashed' />
                      {/*end::Separator*/}
                    </div>
                    {/*end::Section*/}
                    {/*begin::Section*/}
                    <div className='m-0'>
                      {/*begin::Heading*/}
                      <div
                        className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_job_4_2'
                      >
                        {/*begin::Icon*/}
                        <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                          <i className='ki-duotone ki-minus-square toggle-on text-primary fs-1'>
                            <span className='path1' />
                            <span className='path2' />
                          </i>
                          <i className='ki-duotone ki-plus-square toggle-off fs-1'>
                            <span className='path1' />
                            <span className='path2' />
                            <span className='path3' />
                          </i>
                        </div>
                        {/*end::Icon*/}
                        {/*begin::Title*/}
                        <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>
                          Can anyone become a Handyman?
                        </h4>
                        {/*end::Title*/}
                      </div>
                      {/*end::Heading*/}
                      {/*begin::Body*/}
                      <div id='kt_job_4_2' className='collapse  fs-6 ms-1'>
                        {/*begin::Text*/}
                        <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                          All Handymen have to successfully complete a vetting process complete with
                          reference checks and police background check. Individuals who are capable,
                          reliable, and personable have the most success on the platform as a
                          Handyman; in earned income, and enjoying the work environment.
                        </div>
                        {/*end::Text*/}
                      </div>
                      {/*end::Content*/}
                      {/*begin::Separator*/}
                      <div className='separator separator-dashed' />
                      {/*end::Separator*/}
                    </div>
                    {/*end::Section*/}
                  </div>
                  {/*end::Col*/}
                  {/*begin::Col*/}
                  <div className='col-md-6 ps-md-10'>
                    <img
                      alt='For Handyman'
                      src={toAbsoluteUrl('/media/logos/forHandlr.jpg')}
                      className=' h-300px w-100 rounded'
                    />
                  </div>
                </div>
                <div className='row mb-12 align-items-center'>
                  <div className='col-md-6 ps-md-10'>
                    <img
                      alt='For Handyman'
                      src={toAbsoluteUrl('/media/logos/forCustomer.jpg')}
                      className=' h-300px w-100 rounded'
                      style={{objectFit: 'cover'}}
                    />
                  </div>
                  {/*begin::Col*/}
                  <div className='col-md-6 ps-md-10'>
                    {/*begin::Title*/}
                    <h2 className='text-gray-800 fw-bold mb-4'>For Customers</h2>
                    {/*end::Title*/}
                    {/*begin::Accordion*/}
                    {/*begin::Section*/}
                    <div className='m-0'>
                      {/*begin::Heading*/}
                      <div
                        className='d-flex align-items-center collapsible py-3 toggle mb-0 collapsed'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_job_5_1'
                        aria-expanded='false'
                      >
                        {/*begin::Icon*/}
                        <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                          <i className='ki-duotone ki-minus-square toggle-on text-primary fs-1'>
                            <span className='path1' />
                            <span className='path2' />
                          </i>
                          <i className='ki-duotone ki-plus-square toggle-off fs-1'>
                            <span className='path1' />
                            <span className='path2' />
                            <span className='path3' />
                          </i>
                        </div>
                        {/*end::Icon*/}
                        {/*begin::Title*/}
                        <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>
                          Do I pay the Handyman?
                        </h4>
                        {/*end::Title*/}
                      </div>
                      {/*end::Heading*/}
                      {/*begin::Body*/}
                      <div id='kt_job_5_1' className='fs-6 ms-1 collapse' style={{}}>
                        {/*begin::Text*/}
                        <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                          No, there is no exchange of money between you the client, and the Handyman.
                          You are sent an email through the WAVE accounting platform with a secure
                          link, where payment can be made by bank ETF or credit card.
                        </div>
                        {/*end::Text*/}
                      </div>
                      {/*end::Content*/}
                      {/*begin::Separator*/}
                      <div className='separator separator-dashed' />
                      {/*end::Separator*/}
                    </div>
                    {/*end::Section*/}
                    {/*begin::Section*/}
                    <div className='m-0'>
                      {/*begin::Heading*/}
                      <div
                        className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_job_5_2'
                      >
                        {/*begin::Icon*/}
                        <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                          <i className='ki-duotone ki-minus-square toggle-on text-primary fs-1'>
                            <span className='path1' />
                            <span className='path2' />
                          </i>
                          <i className='ki-duotone ki-plus-square toggle-off fs-1'>
                            <span className='path1' />
                            <span className='path2' />
                            <span className='path3' />
                          </i>
                        </div>
                        {/*end::Icon*/}
                        {/*begin::Title*/}
                        <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>
                          Are Handymen insured?
                        </h4>
                        {/*end::Title*/}
                      </div>
                      <div id='kt_job_5_2' className='collapse  fs-6 ms-1'>
                        <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                          Yes, all Handymen are insured.
                        </div>
                      </div>
                      <div className='separator separator-dashed' />
                    </div>
                    <div className='m-0'>
                      <div
                        className='d-flex align-items-center collapsible py-3 toggle collapsed mb-0'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_job_5_3'
                      >
                        <div className='btn btn-sm btn-icon mw-20px btn-active-color-primary me-5'>
                          <i className='ki-duotone ki-minus-square toggle-on text-primary fs-1'>
                            <span className='path1' />
                            <span className='path2' />
                          </i>
                          <i className='ki-duotone ki-plus-square toggle-off fs-1'>
                            <span className='path1' />
                            <span className='path2' />
                            <span className='path3' />
                          </i>
                        </div>
                        <h4 className='text-gray-700 fw-bold cursor-pointer mb-0'>
                          How much is the service?
                        </h4>
                      </div>
                      <div id='kt_job_5_3' className='collapse  fs-6 ms-1'>
                        <div className='mb-4 text-gray-600 fw-semibold fs-6 ps-10'>
                          Because clients have different requirements and requests in each specific
                          job, after the service details are inputted a prompt quote is drafted and
                          sent. When the client approves the quote, booking confirmation with job
                          details are sent to the responding Handyman.
                        </div>
                      </div>
                      <div className='separator separator-dashed' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  )
}
export {FAQ}
