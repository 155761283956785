import React, {FC, useState} from 'react'
import {Container} from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import ServiceAddress from './ServiceAddress'
import Confirmation from './Confirmation'
import {toAbsoluteUrl} from '../../../_handld/helpers'
import Quotes from './Quotes'
import DateAndTime from './DateAndTime'
import BookingConfirmation from './BookingConfirmation'
import AddJobs from './AddJobs'
import LoginModal from './login/LoginModal'
import {useAuth} from '../auth'
import {Link, useNavigate} from 'react-router-dom'
import SignupModal from './login/SignupModal'
type Props = {
  jobs: any
  setJobs: (job: any) => void
  address: any
  setAddress: (address: any) => void
  jobsList?: any
  auth?: any
  setAuth: (auth: any) => void
  jobId?: any
  setJobId: (quoteId: any) => void
  billing?: any
  setBilling: (billing: any) => void
  quote?: any
  setQuote: (quote: any) => void
  availableTimesArray?: any
  setAvailableTimesArray: (availableTimesArray: any) => void
  isFirst: boolean
  setIsFirst: (isFirst: boolean) => void
}
const PostJob: FC<Props> = ({
  jobs,
  setJobs,
  address,
  setAddress,
  jobsList,
  auth,
  setAuth,
  setJobId,
  jobId,
  billing,
  setBilling,
  quote,
  setQuote,
  availableTimesArray,
  setAvailableTimesArray,
  isFirst,
  setIsFirst,
}) => {
  const {saveAuth, setCurrentUser} = useAuth()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const [hideSelectService, setHideSelectService] = useState(false)
  const [hideServiceDetails, setHideServiceDetails] = useState(true)
  const [hideServiceAddress, setHideServiceAddress] = useState(true)
  const [step, setStep] = useState(1)
  const handleShow = () => {
    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
  }

  const goBack = () => {
    navigate(-1)
  }

  return (
    <>
      <button
        hidden
        id='post-job-btn'
        onClick={handleShow}
        className='btn btn-primary rounded-pill btn-sm me-3'
      >
        Post a Job
      </button>
      <button
        hidden
        id='post-job-cancel-btn'
        onClick={() => {
          if (auth?.firstName) {
            localStorage.setItem('User', JSON.stringify(auth))
            setAuth(auth)
            setCurrentUser(auth)
          }

          setShow(false)
          setStep(1)
          setHideSelectService(false)
          setHideServiceDetails(true)
          setHideServiceAddress(true)
          setJobs([
            {
              additionalDetails: '',
              serviceId: 0,
              serviceId2: 0,
              serviceName: '',
              serviceCategoryName: '',
              serviceTypeCategoryName: '',
              code: '',
              typeCode: '',
              category: '',
              seviceCategory: 0,
              seriveTypeCategory: 0,
              serviceFirstChildren: [],
              serviceSecondChildren: [],
              vehiculeNeeded: '',
              sizeInch: null,
              wallMountOnHand: false,
              wallSpecificSize: false,
              wallType: '',
              itemsFrom: '',
              model: '',
              numberOfItems: null,
              length: null,
              placementDifficulty: false,
              placementDifficultyPicture: '',
              size: '',
              assemblyRequired: false,
              itemLink: '',
              assistHandlr: false,
              numberOfWindows: null,
              width: null,
              height: null,
              items: '',
              headboardWallMounted: false,
              storageDrawers: false,
              EndAddress1: '',
              EndAddress2: '',
              EndCity: '',
              EndState: 'Ontario',
              EndZipcode: '',
              EndCountry: 'Canada',
              CoatType: '',
              Type: '',
            },
          ])
        }}
        className='btn btn-primary rounded-pill btn-sm me-3'
      >
        Close Job
      </button>
      <Modal
        show={show}
        fullscreen={true}
        onHide={() => {
          if (auth?.firstName) {
            localStorage.setItem('User', JSON.stringify(auth))
            setAuth(auth)
            setCurrentUser(auth)
          }

          setShow(false)
          setStep(1)
          setHideSelectService(false)
          setHideServiceDetails(true)
          setHideServiceAddress(true)
          setJobs([
            {
              additionalDetails: '',
              serviceId: 0,
              serviceId2: 0,
              serviceName: '',
              serviceCategoryName: '',
              serviceTypeCategoryName: '',
              code: '',
              typeCode: '',
              category: '',
              seviceCategory: 0,
              seriveTypeCategory: 0,
              serviceFirstChildren: [],
              serviceSecondChildren: [],
              vehiculeNeeded: '',
              sizeInch: null,
              wallMountOnHand: false,
              wallSpecificSize: false,
              wallType: '',
              itemsFrom: '',
              model: '',
              numberOfItems: null,
              length: null,
              placementDifficulty: false,
              placementDifficultyPicture: '',
              size: '',
              assemblyRequired: false,
              itemLink: '',
              assistHandlr: false,
              numberOfWindows: null,
              width: null,
              height: null,
              items: '',
              headboardWallMounted: false,
              storageDrawers: false,
              EndAddress1: '',
              EndAddress2: '',
              EndCity: '',
              EndState: 'Ontario',
              EndZipcode: '',
              EndCountry: 'Canada',
              CoatType: '',
              Type: '',
            },
          ])

          setAddress({
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            address1: '',
            address2: '',
            city: '',
            state: 'Ontario',
            country: 'Canada',
            zipcode: '',
            countryId: 40,
          })

          setBilling({
            cardToken: '',
            cardId: '',
            address1: '',
            address2: '',
            zipcode: '',
            city: '',
            state: '',
            country: 'Canada',
            coupon: '',
            jobsGroupId: jobId,
            quotesGroupId: quote?.id || null,
            email: '',
            requestedDate: '',
            availableTimes: '',
            dateType: '',
            countryId: 40,
            sameAddress: false,
          })
          setAvailableTimesArray([])
          setIsFirst(true)
          if (window.location.pathname.includes('/landing/service/')) {
            goBack()
          }
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Container>
              <Link to='/'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/logos/demo2.png')}
                  className='logo-default h-40px'
                />
              </Link>
            </Container>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            {step === 1 && (
              <>
                {/* <SelectService
                  hideSelectService={hideSelectService}
                  setHideSelectService={setHideSelectService}
                  hideServiceDetails={hideServiceDetails}
                  setHideServiceDetails={setHideServiceDetails}
                  hideServiceAddress={hideServiceAddress}
                  setHideServiceAddress={setHideServiceAddress}
                  jobs={jobs}
                  setJobs={setJobs}
                /> */}
                <AddJobs
                  hideSelectService={hideSelectService}
                  setHideSelectService={setHideSelectService}
                  hideServiceDetails={hideServiceDetails}
                  setHideServiceDetails={setHideServiceDetails}
                  hideServiceAddress={hideServiceAddress}
                  setHideServiceAddress={setHideServiceAddress}
                  jobs={jobs}
                  setJobs={setJobs}
                  auth={auth}
                />
                <ServiceAddress
                  hideSelectService={hideSelectService}
                  setHideSelectService={setHideSelectService}
                  hideServiceDetails={hideServiceDetails}
                  setHideServiceDetails={setHideServiceDetails}
                  hideServiceAddress={hideServiceAddress}
                  setHideServiceAddress={setHideServiceAddress}
                  setStep={setStep}
                  address={address}
                  setAddress={setAddress}
                  auth={auth}
                />
              </>
            )}
            {step === 2 && (
              <>
                <Confirmation
                  setStep={setStep}
                  address={address}
                  jobsList={jobs}
                  auth={auth}
                  setJobId={setJobId}
                />
              </>
            )}
            {step === 3 && (
              <>
                <Quotes
                  setStep={setStep}
                  jobId={jobId}
                  jobs={jobs}
                  address={address}
                  quote={quote}
                  setQuote={setQuote}
                  isFirst={isFirst}
                  setIsFirst={setIsFirst}
                />
              </>
            )}
            {step === 4 && (
              <>
                <DateAndTime
                  setStep={setStep}
                  setBilling={setBilling}
                  billing={billing}
                  availableTimesArray={availableTimesArray}
                  setAvailableTimesArray={setAvailableTimesArray}
                />
              </>
            )}
            {step === 5 && (
              <>
                <BookingConfirmation
                  setStep={setStep}
                  billing={billing}
                  setBilling={setBilling}
                  address={address}
                  quote={quote}
                  setAvailableTimesArray={setAvailableTimesArray}
                  setIsFirst={setIsFirst}
                />
              </>
            )}
            <LoginModal auth={auth} setAuth={setAuth} />
            <SignupModal />
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PostJob
