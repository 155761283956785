import React, {FC, useState} from 'react'
import {Container} from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import {LoginPage} from './LoginPage'
type Props = {
  auth?: any
  setAuth: (auth: any) => void
}
const LoginModal: FC<Props> = ({auth, setAuth}) => {
  const [show, setShow] = useState(false)
  const handleShow = () => {
    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
  }

  return (
    <>
      <button
        hidden
        id='post-login-btn'
        onClick={handleShow}
        className='btn btn-primary rounded-pill btn-sm me-3'
      >
        Open
      </button>
      <button
        hidden
        id='post-login-close-btn'
        onClick={handleClose}
        className='btn btn-primary rounded-pill btn-sm me-3'
      >
        Close
      </button>
      <Modal
        show={show}
        size='lg'
        onHide={() => {
          setShow(false)
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className='mw-75'>
            <LoginPage auth={auth} setAuth={setAuth} />
          </Container>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default LoginModal
