/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {
  HeaderUserMenu,
  // Search,
} from '../../../partials'
import {Link, useLocation} from 'react-router-dom'
import {Navbar1} from './Navbar1'
import {useAuth} from '../../../../app/modules/auth'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
  toolbarButtonHeightClass = 'btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p',
  toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px',
  toolbarButtonIconSizeClass = 'fs-1'

const Topbar: FC = () => {
  const {currentUser} = useAuth()
  const {pathname} = useLocation()
  const isBackOffice = currentUser && !pathname.includes('backoffice')
  const baseUrl = isBackOffice ? '/customer/chat' : '/backoffice/chat'
  const linkTo = `${baseUrl}/private-chat`
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        {/* Search */}
        {/* <div className={clsx('d-flex align-items-stretch', toolbarButtonMarginClass)}>
                <Search />
              </div> */}

        {/* CHAT */}
        {isBackOffice && (
          <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
            {/* begin::Menu wrapper */}
            <Link
              to={linkTo}
              className={clsx(
                {
                  'btn btn-icon btn-active-light-primary btn-custom position-relative w-30px h-30px w-md-40px h-md-40px':
                    true,
                  'backoffice-title': pathname.includes('backoffice'),
                },
                toolbarButtonHeightClass
              )}
            >
              <KTIcon iconName='message-text-2' className={toolbarButtonIconSizeClass} />

              <span className='bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink'></span>
            </Link>
            {/* end::Menu wrapper */}
          </div>
        )}

        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <Navbar1 />
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>
    </div>
  )
}

export {Topbar}
