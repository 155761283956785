import {PageLink, PageTitle} from '../../../_handld/layout/core'
import { QuotelistWrapper } from './jobs-list/Quotelist'


const jobBreadcrumbs: Array<PageLink> = [
  {
    title: 'Quotes Management',
    path: '/backoffice/jobs-management',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const quotesPage = () => {
  return (
    <>      <PageTitle breadcrumbs={jobBreadcrumbs}>Quotes list</PageTitle>
       <QuotelistWrapper />
    </>
  )
}

export default quotesPage
