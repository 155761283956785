import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_handld/helpers'
import { useQueryClient } from 'react-query'

type Props = {
  index: number
  job: any
  handleChange: (index: number, field: string, value: any) => void
}
const HomeCleaning: FC<Props> = ({job, handleChange, index}) => {
  return (
    <div className={`card-body`}>
      <div className='row mb-7'>
        <label className='fw-semibold text-muted'>
          Provide some more details about the selected service
        </label>
      </div>
      <div className='row'>
        <div className='row mb-7 pb-3'>
          <label className='fw-bold fs-5 mb-3 required'>
            Surface: What type of surface do you need cleaned?
          </label>
          <div
            id='kt_content_container'
            className='d-flex flex-column-fluid align-items-start container-xxl'
          >
            <div className='content flex-row-fluid' id='kt_content'>
              <div className='d-flex flex-column flex-xl-row'>
                <div className='d-flex flex-row-fluid me-xl-9 mb-10 mb-xl-0'>
                  <div className='card card-flush card-p-0 bg-transparent border-0'>
                    <div className='card-body'>
                      <ul className='nav nav-pills d-flex justify-content-between nav-pills-custom gap-3 mb-6'>
                        <li
                          className='nav-item mb-3 me-0'
                          onClick={() =>
                            handleChange(index, 'size', 'Small : Around 300 to 600 square feet')
                          }
                        >
                          <a
                            className={`nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg ${
                              job?.size === 'Small : Around 300 to 600 square feet' && 'active'
                            }`}
                            data-bs-toggle='pill'
                            style={{width: 200, height: 250}}
                          >
                            <div className='nav-icon mb-3'>
                              <img
                                src={toAbsoluteUrl('/media/SHouse.jpg')}
                                className='w-150px h-160px'
                                alt=''
                              />
                            </div>
                            <div className=''>
                              <span className='text-gray-800 fw-bold fs-2 d-block'>Small</span>
                              <span className='text-gray-400 fw-semibold fs-7'>
                                300 to 600 square feet
                              </span>
                            </div>
                          </a>
                        </li>
                        <li
                          className='nav-item mb-3 me-0'
                          onClick={() =>
                            handleChange(index, 'size', 'Medium : 600 to 1,000 square feet')
                          }
                        >
                          <a
                            className={`nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg ${
                              job?.size === 'Medium : 600 to 1,000 square feet' && 'active'
                            }`}
                            data-bs-toggle='pill'
                            style={{width: 200, height: 250}}
                          >
                            <div className='nav-icon mb-3'>
                              <img
                                src={toAbsoluteUrl('/media/MHouse.jpg')}
                                className='w-150px h-160px'
                                alt=''
                              />
                            </div>
                            <div className=''>
                              <span className='text-gray-800 fw-bold fs-2 d-block'>Medium</span>
                              <span className='text-gray-400 fw-semibold fs-7'>
                                600 to 1,000 square feet
                              </span>
                            </div>
                          </a>
                        </li>
                        <li
                          className='nav-item mb-3 me-0'
                          onClick={() =>
                            handleChange(index, 'size', 'Large : over 1,000 square feet')
                          }
                        >
                          <a
                            className={`nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg ${
                              job?.size === 'Large : over 1,000 square feet' && 'active'
                            }`}
                            data-bs-toggle='pill'
                            style={{width: 200, height: 250}}
                          >
                            <div className='nav-icon mb-3'>
                              <img
                                src={toAbsoluteUrl('/media/LHouse.jpg')}
                                className='w-150px h-160px'
                                alt=''
                              />
                            </div>
                            <div className=''>
                              <span className='text-gray-800 fw-bold fs-2 d-block'>Large</span>
                              <span className='text-gray-400 fw-semibold fs-7'>
                                Over 1,000 square feet
                              </span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row mb-7 pb-3'>
        <div className='col-12'>
          <label className='fw-bold fs-5 mb-3'>Additional Notes/Info</label>
          <textarea
            className='form-control form-control-lg form-control-solid '
            rows={4}
            name='additionalDetails'
            value={job?.additionalDetails}
            onChange={(e) => handleChange(index, 'additionalDetails', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default HomeCleaning
