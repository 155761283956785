/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { User } from '../../core/_models'
import { toAbsoluteUrl } from '../../../../../../_handld/helpers'
import { Link } from 'react-router-dom'

type Props = {
  user: User
}

const UserInfoCell: FC<Props> = ({ user }) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {user.profilePicture ? (
          <div className='symbol-label'>
            <img src={`${user.profilePicture}`} alt={user.name} className='w-100' style={{objectFit:"contain"}} />
          </div>
        ) : (
          <div className='symbol-label'>
            <img
              src={toAbsoluteUrl('/media/svg/avatars/blank.svg')}
              alt={user.name}
              className='w-100' />
          </div>
        )}
      </a>
    </div>
    <div className='d-flex flex-column text-start'>


      <Link to={`/backoffice/customers-management/details/${user.id}`} className='menu-link'>
        {user.firstName + ' ' + user.lastName}
      </Link>
      <span style={{ whiteSpace: 'nowrap' }}>{user.skills}</span>
    </div>
  </div>
)

export { UserInfoCell }
