/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useQueryResponseLoading, useQueryResponsePagination} from '../../core/QueryResponseProvider'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useEffect, useMemo, useState} from 'react'

const mappedLabel = (label: string): string => {
  if (label === '&laquo; Previous') {
    return 'Previous'
  }

  if (label === 'Next &raquo;') {
    return 'Next'
  }

  return label
}

const UsersListPagination = () => {
  const pagination = useQueryResponsePagination()
  const [totalcount, settotalcount] = useState<any>([])
  const [rowsPerPage, setRowsPerPage] = useState(10) // State for selected number of rows
  const isLoading = useQueryResponseLoading()
  const {updateState} = useQueryRequest()
  const {pageCount, pageNumber} = pagination || {}
  useEffect(() => {
    settotalcount(pagination)
  }, [pagination])
  const paginationLinks = useMemo(() => {
    const links = []

    const displayedPages = 5 // Number of pages to display (excluding ellipsis)

    // Calculate the range of pages to display
    let startPage = Math.max(1, pageNumber - Math.floor(displayedPages / 2))
    let endPage = Math.min(pageCount, startPage + displayedPages - 1)

    // Adjust the range if it exceeds the bounds
    const totalPages = endPage - startPage + 1
    if (totalPages < displayedPages) {
      if (startPage === 1) {
        endPage = Math.min(pageCount, startPage + displayedPages - 1)
      } else {
        startPage = Math.max(1, endPage - displayedPages + 1)
      }
    }

    // Add ellipsis if necessary
    if (startPage > 1) {
      links.push({
        label: '...',
        page: startPage - 1,
      })
    }

    // Add page links
    for (let i = startPage; i <= endPage; i++) {
      links.push({
        label: i.toString(),
        page: i,
      })
    }

    // Add ellipsis if necessary
    if (endPage < pageCount) {
      links.push({
        label: '...',
        page: endPage + 1,
      })
    }

    // add Previous link
    links.unshift({
      label: 'Previous',
      page: pageNumber > 1 ? pageNumber - 1 : null,
    })

    // add Next link
    links.push({
      label: 'Next',
      page: pageNumber < pageCount ? pageNumber + 1 : null,
    })

    return links
  }, [pageCount, pageNumber])

  const updatePage = (pageNumber: number | null) => {
    if (!pageNumber || isLoading || pagination.pageNumber === pageNumber) {
      return
    }

    updateState({pageNumber, pageSize: pagination.pageSize || 10})
  }

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedRowsPerPage = parseInt(event.target.value, 10) as 10 | 30 | 50 | 100
    setRowsPerPage(selectedRowsPerPage)
    updateState({pageNumber: 1, pageSize: selectedRowsPerPage}) // Update pagination state when rows per page changes
  }

  return (
    <div className='row'>
      <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
        <span className='me-5'>Total rows: {totalcount.totalItemCount}</span>
        <div className='mr-5'>
          {/* Add the dropdown/select for selecting the number of rows */}
          <label className='me-3' htmlFor='rowsPerPageSelect'>Rows per page:</label>
          <select id='rowsPerPageSelect' value={rowsPerPage} onChange={handleRowsPerPageChange}>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
      <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
        <div id='kt_table_users_paginate'>
          <ul className='pagination'>
            {paginationLinks.map((link) => (
              <li
                key={link.label}
                className={clsx('page-item', {
                  active: pagination.pageNumber === link.page,
                  disabled: isLoading || link.page === null,
                  previous: link.label === 'Previous',
                  next: link.label === 'Next',
                })}
              >
                <a
                  className={clsx('page-link', {
                    'page-text': link.label === 'Previous' || link.label === 'Next',
                    'me-5': link.label === 'Previous',
                  })}
                  onClick={() => updatePage(link.page)}
                  style={{cursor: 'pointer'}}
                >
                  {mappedLabel(link.label)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {UsersListPagination}
