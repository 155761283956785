import PhoneNumber from 'libphonenumber-js'

const formatPhoneNumber = (phoneNumber: any) => {
    try {
      const formattedNumber = PhoneNumber(phoneNumber, 'US')?.formatInternational()
      return formattedNumber
    } catch (error) {
      console.error('Invalid phone number:', error)
      return phoneNumber // Return the original phone number in case of an error.
    }
  }

export default formatPhoneNumber