import {useEffect, useState} from 'react'
import {KTIcon} from '../../../../_handld/helpers'
import {JobDetailsModalForm} from './JobDetailsModalForm'
import {useListView} from '../jobs-list/core/ListViewProvider'
import {JobsEditModalHeader} from './jobsEditModalHeader'
import {JobsEditModalFormWrapper} from './jobsEditModalFormWrapper'
import QueryFetchPost from '../../../../global/index2'

const JobDetailsModal = () => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [data, setdata] = useState<any>()
  const [customerId, setcustomerId] = useState<any>()
  const GetJobsdetails = async (id: any) => {
    await QueryFetchPost({
      link: `${BASE_URL}/api/BackOffice/DetailsJob/${id}`,
      data: undefined,
      method: 'GET',
      key: 'GetJobs',
      signal: undefined,
      isFormData: false,
    }).then((response: any) => {
      setdata(response[1])
    })
  }
  useEffect(() => {
    GetJobsdetails(itemIdForUpdate)
  }, [itemIdForUpdate])

  const refetch = () => {
    GetJobsdetails(itemIdForUpdate)
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered modal-lg'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            <JobsEditModalHeader data={data} />
            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 '>
              <JobsEditModalFormWrapper
                data={data}
                itemIdForUpdate={itemIdForUpdate}
                refetch={refetch}
              />
            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {JobDetailsModal}
