import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_handld/helpers'
import {useQueryClient} from 'react-query'

type Props = {
  index: number
  job: any
  handleChange: (index: number, field: string, value: any) => void
}
const Gardening: FC<Props> = ({job, handleChange, index}) => {
  return (
    <div className={`card-body`}>
      <div className='row mb-7'>
        <label className='fw-semibold text-muted'>
          Provide some more details about the selected service
        </label>
      </div>
      <div className='mb-7 pb-3'>
        <label className='fw-bold fs-5 mb-3 required'>Surface:</label>
        {job?.category !== 'GRDPLN' && (
          <div className='d-flex flex-column mx-5'>
            <label className='form-check form-check-inline form-check-solid me-5 mb-5'>
              <input
                className='form-check-input'
                name={`size${index}`}
                type='radio'
                checked={job?.size === 'Small Yard (Approximately 1,000 square feet)'}
                value={job?.size}
                onChange={(e) =>
                  handleChange(index, 'size', 'Small Yard (Approximately 1,000 square feet)')
                }
              />
              <span className='fw-bold ps-2 fs-6'>
                Small Yard (Approximately 1,000 square feet)
              </span>
            </label>
            <label className='form-check form-check-inline form-check-solid me-5 mb-5'>
              <input
                className='form-check-input'
                name={`size${index + 1}`}
                type='radio'
                checked={job?.size === 'Medium-Sized Yard (Approximately 5,000 square feet)'}
                value={job?.size}
                onChange={(e) =>
                  handleChange(index, 'size', 'Medium-Sized Yard (Approximately 5,000 square feet)')
                }
              />
              <span className='fw-bold ps-2 fs-6'>
                Medium-Sized Yard (Approximately 5,000 square feet)
              </span>
            </label>
            <label className='form-check form-check-inline form-check-solid me-5 mb-5'>
              <input
                className='form-check-input'
                name={`size${index + 1}`}
                type='radio'
                checked={job?.size === 'Large Yard (Approximately 10,000 square feet)'}
                value={job?.size}
                onChange={(e) =>
                  handleChange(index, 'size', 'Large Yard (Approximately 10,000 square feet)')
                }
              />
              <span className='fw-bold ps-2 fs-6'>
                Large Yard (Approximately 10,000 square feet)
              </span>
            </label>
          </div>
        )}
        {job?.category === 'GRDPLN' && (
          <div className='d-flex flex-column mx-5'>
            <label className='form-check form-check-inline form-check-solid me-5 mb-5'>
              <input
                className='form-check-input'
                name={`size${index}`}
                type='radio'
                checked={job?.size === 'Small Garden Bed (Approximately 50 square feet)'}
                value={job?.size}
                onChange={(e) =>
                  handleChange(index, 'size', 'Small Garden Bed (Approximately 50 square feet)')
                }
              />
              <span className='fw-bold ps-2 fs-6'>
                Small Garden Bed (Approximately 50 square feet)
              </span>
            </label>
            <label className='form-check form-check-inline form-check-solid me-5 mb-5'>
              <input
                className='form-check-input'
                name={`size${index + 1}`}
                type='radio'
                checked={job?.size === 'Medium-Sized Garden (Approximately 200 square feet)'}
                value={job?.size}
                onChange={(e) =>
                  handleChange(index, 'size', 'Medium-Sized Garden (Approximately 200 square feet)')
                }
              />
              <span className='fw-bold ps-2 fs-6'>
                Medium-Sized Garden (Approximately 200 square feet)
              </span>
            </label>
            <label className='form-check form-check-inline form-check-solid me-5 mb-5'>
              <input
                className='form-check-input'
                name={`size${index + 1}`}
                type='radio'
                checked={job?.size === 'Large Garden (Approximately 500 square feet)'}
                value={job?.size}
                onChange={(e) =>
                  handleChange(index, 'size', 'Large Garden (Approximately 500 square feet)')
                }
              />
              <span className='fw-bold ps-2 fs-6'>
                Large Garden (Approximately 500 square feet)
              </span>
            </label>
          </div>
        )}
      </div>
      <div className='row mb-7 pb-3'>
        <div className='col-12'>
          <label className='fw-bold fs-5 mb-3'>Additional Notes/Info</label>
          <textarea
            className='form-control form-control-lg form-control-solid '
            rows={4}
            name='additionalDetails'
            value={job?.additionalDetails}
            onChange={(e) => handleChange(index, 'additionalDetails', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default Gardening
