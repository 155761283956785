import {PageLink, PageTitle} from '../../../_handld/layout/core'
import {CustomerListWrapper} from './customer-list/CustomerList'

const customerBreadcrumbs: Array<PageLink> = [
  {
    title: 'Customer Management',
    path: '/backoffice/customer-management',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CustomerPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={customerBreadcrumbs}>Customer list</PageTitle>
      <CustomerListWrapper />
    </>
  )
}

export default CustomerPage
