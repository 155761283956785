import React, {useState, useEffect} from 'react'
import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderNotificationsMenu, HeaderUserMenu} from '../../../partials'
import QueryFetchPost from '../../../../global/index2'
import { handleApiErrors } from '../../../../global/HandleApiErrors'

// import {useLayout} from '../../core'

interface Model{
  firstName:string,
  lastName:string,
  email:string,
  profilePicture:null
  token: string
}
const BASE_URL = process.env.REACT_APP_BASE_URL
const AUTH_LOCAL_STORAGE_KEY = 'User'
const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const Navbar1 = () => {
  const [imageApi, setimageApi] = useState<any>(null)
  const [showPassword, setShowPassword] = useState<Model | undefined>()
  const GetProfile = async () => {
    await QueryFetchPost({
      link: `${BASE_URL}/api/Account/Profile`,
      data: undefined,
      method: 'GET',
      key: 'RegisterEmail',
      signal: undefined,
      isFormData: false,
    }).then((response) => {
      if (response[0] === 200) {
        if (response[1].profile.profilePicture) {
          setimageApi(response[1].profile.profilePicture)
          localStorage.setItem(
            "dateFormat",
            response[1]?.profile?.dateFormat !== null &&
            response[1]?.profile?.dateFormat !== undefined &&
            response[1]?.profile?.dateFormat !== ""
              && response[1]?.profile?.dateFormat
          );
        } else {
          setimageApi(null)
        }
      } else {
        handleApiErrors(response)
      }
    })
  }
  useEffect(() => {
    GetProfile()
  }, [])
  return (
    <div className='app-navbar flex-shrink-0'>
      

      <div className={clsx('app-navbar-item d-flex', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <div
            className='h-25px w-25px rounded'
            data-kt-image-input='true'
            style={{backgroundImage: 'url(/media/avatars/blank.png)'}}
          >
            {imageApi === null ? (
              <div
                className=' h-25px w-25px rounded '
                style={{backgroundImage: 'url(/media/avatars/blank.png)'}}
              />
            ) : (
              <img
                alt='Logo'
                className='h-25px w-25px rounded '
                src={toAbsoluteUrl(imageApi)}
              />
            )}
          </div>
        </div>
    
       <div className='separator my-2 '> </div>
       <button
          id='testtest2'
          className= 'btn tn-light h-20px w-30px ml-20px'
          onClick={() => {
            document?.getElementById('testtest')?.click();
            setimageApi(localStorage.getItem('profilePicture'))
            GetProfile()
            setShowPassword(JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}'))
          }}
     
        ></button>
        </div>
      </div>
  )
}

export {Navbar1}
