import React, {FC} from 'react'

type Props = {
  index: number
  job: any
  handleChange: (index: number, field: string, value: any) => void
}
const FurnitureAssembly: FC<Props> = ({job, handleChange, index}) => {
  return (
    <div className={`card-body`}>
      <div className='row mb-7'>
        <label className='fw-semibold text-muted'>
          Provide some more details about the selected service
        </label>
      </div>
      <div className='row mb-7 pb-3'>
        <label className='fw-bold fs-5 mb-3 required'>
          {job.category === 'BEDS' && 'Bed is from:'}
          {job.category === 'SOFA' && 'Sofa is from:'}
          {(job.category === 'DINIF' || job.category === 'TABL' || job.category === 'SEAT') &&
            'Table & chair from:'}
          {job.category === 'FDESK' && 'Desk & chair from:'}
          {job.category === 'BEDR' && '(item) is from:'}
        </label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid '
          placeholder='eg: IKEA/Wayfair/Structube etc'
          value={job?.itemsFrom}
          onChange={(e) => handleChange(index, 'itemsFrom', e.target.value)}
        />
      </div>
      <div className='row mb-7 pb-3'>
        <label className='fw-bold fs-5 mb-3 required'>Model Name/Number</label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid '
          placeholder='EG IKEA-Malm'
          value={job?.model}
          onChange={(e) => handleChange(index, 'model', e.target.value)}
        />
      </div>

      {job?.category === 'BEDS' && (
        <div className='row mb-7 pb-3'>
          <label className='fw-bold fs-5 mb-3'>
            Does this Model require Headboard to be Wall-Mounted?
          </label>
          <div className='d-flex mx-5'>
            <label className='form-check form-check-inline form-check-solid me-5'>
              <input
                className='form-check-input'
                name={`headboardWallMounted${index}`}
                type='radio'
                value={job?.headboardWallMounted}
                checked={job?.headboardWallMounted}
                onChange={(e) => handleChange(index, 'headboardWallMounted', true)}
              />
              <span className='fw-bold ps-2 fs-6'>Yes</span>
            </label>
            <label className='form-check form-check-inline form-check-solid me-5'>
              <input
                className='form-check-input'
                name={`headboardWallMounted${index + 1}`}
                type='radio'
                checked={!job?.headboardWallMounted}
                value={job?.headboardWallMounted}
                onChange={(e) => handleChange(index, 'headboardWallMounted', false)}
              />
              <span className='fw-bold ps-2 fs-6'>No</span>
            </label>
          </div>
        </div>
      )}

      {(job?.typeCode === 'KING' || job?.typeCode === 'QUEE' || job?.typeCode === 'FULL') && (
        <div className='row mb-3'>
          <div className='mb-10'>
            <div className='form-check form-switch form-check-custom form-check-solid '>
              <input
                className='form-check-input me-5'
                type='checkbox'
                name='storageDrawers'
                checked={job?.storageDrawers}
                onChange={(e) => {
                  handleChange(index, 'storageDrawers', !JSON.parse(job?.storageDrawers))
                }}
                id='flexSwitchDefault'
              />
              <label className='fw-bold fs-5' htmlFor='flexSwitchDefault'>
                Confirmation NO storage drawers on this model
              </label>
            </div>
          </div>
        </div>
      )}

      <div className='row mb-7 pb-3'>
        <div className='col-12'>
          <label className='fw-bold fs-5 mb-3'>Additional Notes/Info</label>
          <textarea
            className='form-control form-control-lg form-control-solid '
            rows={4}
            name='additionalDetails'
            value={job?.additionalDetails}
            onChange={(e) => handleChange(index, 'additionalDetails', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default FurnitureAssembly
