import React, {FC} from 'react'

type Props = {
  index: number
  job: any
  handleChange: (index: number, field: string, value: any) => void
}
const ElectricalHelp: FC<Props> = ({job, handleChange, index}) => {
  return (
    <div className={`card-body`}>
      <div className='row mb-7'>
        <label className='fw-semibold text-muted'>
          Provide some more details about the selected service
        </label>
      </div>

      {(job?.category === 'OTLI' || job?.category === 'LFRPL' || job?.category === 'CLFI') && (
        <div className='row mb-7 pb-3'>
          <label className='fw-bold fs-5 mb-3 required'>
            {job?.category === 'OTLI' && 'Number of outlets to be installed?'}
            {job?.category === 'LFRPL' && 'Number of light fixtures to be replaced?'}
            {job?.category === 'CLFI' && 'Number of ceiling fans to be installed?'}
          </label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid '
            placeholder='Enter number of items'
            value={job?.numberOfItems || ''}
            onChange={(e) => {
              const newValue = e.target.value
              if (newValue === '' || /^\d+$/.test(newValue)) {
                handleChange(index, 'numberOfItems', newValue)
              }
            }}
          />
        </div>
      )}

      <div className='row mb-7 pb-3'>
        <div className='col-12'>
          <label className='fw-bold fs-5 mb-3'>Additional Notes/Info</label>
          <textarea
            className='form-control form-control-lg form-control-solid '
            rows={4}
            name='additionalDetails'
            value={job?.additionalDetails}
            onChange={(e) => handleChange(index, 'additionalDetails', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default ElectricalHelp
