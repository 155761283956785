// import Swal from "sweetalert2"
import {useEffect,useState } from "react";
import React from "react";
import { Row, Form, Col} from "react-bootstrap";
import Select, { ActionMeta, SingleValue } from "react-select";
import QueryFetchPost from "./index2";
import { ReactSelectStyles } from "../app/global/ReactSelectStyles";


const BASE_URL = process.env.REACT_APP_BASE_URL

type OptionType = { value: string; label: string };

  type CountryStateGlobalProps = {
    setcountryy:any;
    setstatee:any;
    countryy: string | undefined;
    statee: string | undefined;
    className?: string;
  };
  
  export const CountryStateGlobal: React.FC<CountryStateGlobalProps> = ({
    countryy,
    setcountryy,
    statee,
    setstatee,
    className = "",
  }) => {
    const [countries, setCountries] = useState<OptionType[]>([]);
    const [states, setStates] = useState<OptionType[]>([]);
  
    useEffect(() => {
      handleGetCoutries();
    }, []);
  

    useEffect(() => {
        setcountryy(countryy)
      handleGetCoutries();
    }, []);
    const handleGetCoutries = async () => {
      await  QueryFetchPost({
        link: `${BASE_URL}/api/Generic/Countries`,
        data: undefined,
        method: 'GET',
        key: 'handleGetCoutries',
        signal: undefined,
        isFormData: false,
      })
        .then((data) => {
          if (data[0] === 200) {
            const countriesList = data[1]?.countries?.map((country:any) => {
              return { value: country?.name, label: country?.name };
            });
            setCountries(countriesList || []);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
  
    const  handleGetStates = async (value:any) => {
      await  QueryFetchPost({
        link: `${BASE_URL}/api/Generic/States?name=${value}`,
        data: undefined,
        method: 'GET',
        key: 'handleGetStates',
        signal: undefined,
        isFormData: false,
      })
        .then((data) => {
          if (data[0] === 200) {
            const stateList = data[1]?.states?.map((state :any) => {
              return { value: state?.name, label: state?.name };
            });
            setStates(stateList || []);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const cuurentUser: any = localStorage.getItem('User')
  const role: any = JSON.parse(cuurentUser)?.roles[0]
    const handleCountryChange = (selectedOption: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
        setcountryy(selectedOption?.value);
      // setTimeout(() => {
      //   setstatee("");
      // }, 500);
      handleGetStates(selectedOption?.value);
    };
  
    const handleStateChange = (selectedOption: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
        setstatee(selectedOption?.value);
    };
  
    
      useEffect(() => {
        if (countryy) {
          handleGetStates(countryy);
        }else{
          setcountryy("Canada")
          setstatee("Ontario")
          handleGetStates("Canada");
        }
      }, [countryy,statee]);
    return (
      <Row className={className}>
        <Col>
          <Form.Group controlId="formState">
          <Form.Label className="mb-1 font-size-sm">Province</Form.Label>
  
            <Select
              value={states.find((option) => option.value === statee)}
              options={states}
              onChange={handleStateChange}
              isClearable
              isSearchable
              styles={ReactSelectStyles}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="formCountry">            
          <Form.Label className="mb-1 font-size-sm">Country</Form.Label>
  
            <Select
              value={countries.find((option) => option.value === countryy)}
              options={countries}
              onChange={handleCountryChange}
              isSearchable
              isClearable
              styles={ReactSelectStyles}

            />
          </Form.Group>
        </Col>
        
      </Row>
    );
  };