import {useQuery} from 'react-query'
import { useListView } from '../jobs-list/core/ListViewProvider'
import { FC, useEffect, useState } from 'react'
import { JobDetailsModalForm } from './JobDetailsModalForm'
import QueryFetchPost from '../../../../global/index2'

type Props = {
  data: any
  refetch: any
  itemIdForUpdate: any
}

const JobsEditModalFormWrapper:FC<Props> = ({data, refetch, itemIdForUpdate}) => {
  if (!itemIdForUpdate && !data) {
    return <JobDetailsModalForm  data={data} refetch={refetch}/>
  }

  if (itemIdForUpdate && data) {
    return <JobDetailsModalForm   data={data} refetch={refetch}/>
  }

  return null
}

export {JobsEditModalFormWrapper}
