import { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { initialUser, User } from '../core/_models'
import { useListView } from '../core/ListViewProvider'
import { Getviewmodel, createUser, updateUser } from '../core/_requests'
import { useQueryResponse } from '../core/QueryResponseProvider'
import { isNotEmpty } from '../../../../../_handld/helpers'
import { UsersListLoading } from '../../../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import QueryFetchPost, { ModelStateMessage } from '../../../../../global/index2'
import Swal from 'sweetalert2'
import { Spinner } from 'react-bootstrap'

type Props = {
  isUserLoading: boolean
  user: User
  statusOptions?:any
  userStatus?:any
}
const BASE_URL = process.env.REACT_APP_BASE_URL
const StatusEditModalForm: FC<Props> = ({ user, isUserLoading ,statusOptions}) => {
  const { setItemIdForUpdate,itemIdForUpdate } = useListView()
  const [userStatus, setUserStatus] = useState<any>(null);
  const [loading, setLoading] = useState(false)
  const { refetch } = useQueryResponse()
  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }
  const getUserStatus = async(id:any) => {
    await QueryFetchPost({
     link: `${BASE_URL}/api/BackOffice/GetStatus/${id}`,
     data:undefined,
     method: 'GET',
     key: 'getstatususer',
     signal: undefined,
     isFormData: false,
   }).then((response: any) => {
 setUserStatus(response[1]);
   })
 }

useEffect(() => {
  getUserStatus(itemIdForUpdate)
}, [itemIdForUpdate]);

  const getUserStatusById = async(values:any) => {
    setLoading(true)
    await QueryFetchPost({
     link: `${BASE_URL}/api/BackOffice/ChangeStatus`,
     data:{
      id:user.id,
      statusId:values.status
     },
     method: 'POST',
     key: 'Changestatus',
     signal: undefined,
     isFormData: false,
   }).then((response: any) => {
if(response[0]===200){ 
  cancel()
  Swal.fire({
    text: `The Handyman status has been changed successfully`,
    icon: 'success',
    showConfirmButton: true,
    timer: 2000,
    width: '25rem',
  })
  setLoading(false)
refetch()
} else {
  ModelStateMessage(response)
}
})

 }
 useEffect(() => {
  if (userStatus) {
    // Set the initial value of Formik's 'status' field when userStatus changes
    formik.setFieldValue('status', userStatus?.id);
  }
}, [userStatus]);
    const formik = useFormik({
      initialValues: {
        status: userStatus?.id
      },
      // validationSchema: editUserSchema,
      onSubmit: async (values, { setSubmitting }) => {
        setSubmitting(true)
        getUserStatusById(values)
      },
    })
    const handleStatusChange = (newStatus: any) => {
      formik.setFieldValue('status', newStatus);
    }

    return (
      <>
        <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7'
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            <div
              className='row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-9'
              data-kt-buttons='true'
              data-kt-buttons-target="[data-kt-button='true']"
              data-kt-initialized={1}
            >
             
                 {statusOptions?.map((statusitem:any) => (
                  <div className='col' key={statusitem?.id}>
                    <label className='btn btn-outline btn-outline-dashed btn-active-light-primary d-flex text-start p-6' data-kt-button='true'>
                      {/* begin::Radio */}
                      <span className='form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1'>
                      <input
                className='form-check-input'
                type='radio'
                name='status'
                value={statusitem.id}
                checked={formik.values.status === statusitem.id}
                onChange={() => {
                  handleStatusChange(statusitem.id);
                }}
              />

                      </span>
                      <span className='ms-5'>
                        <span className='fs-4 fw-bold text-gray-800 d-block'>{statusitem?.name}</span>
                      </span>
                      {/* end::Info */}
                    </label>
                    {/*end::Option*/}
                  </div>
                ))}
              </div>
            </div>
            <div className='d-flex justify-content-end pt-15'>
              <button
                type='reset'
                onClick={() => cancel()}
                className='btn btn-light me-3'
                data-kt-users-modal-action='cancel'
                disabled={formik.isSubmitting || isUserLoading}
              >
                Cancel
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>Apply</span>
             {loading &&(
                                      <Spinner
                                      as='span'
                                      animation='border'
                                      className='mb-1 ml-5'
                                      size='sm'
                                      role='status'
                                      aria-hidden='true'
                                    />
             )
             }
              </button>
            </div>
            {/* end::Actions */}
        </form>
        {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
      </>
    )
  }

  export { StatusEditModalForm }
