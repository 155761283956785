import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, Navigate, useNavigate} from 'react-router-dom'
import {Formik, useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import { Spinner } from 'react-bootstrap'
import Swal from 'sweetalert2'
import QueryFetchPost, { ModelStateMessage } from '../../../../global/index2'

interface formModel {
  email: string
}
const BASE_URL=process.env.REACT_APP_BASE_URL
const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleSubmit = async (formData: formModel) => {
    setLoading(true)
    try {
      await QueryFetchPost({
        link: `${BASE_URL}/api/Account/ForgetPassword`,
        data: formData,
        method: 'POST',
        key: 'forgotPassword',
        signal: undefined,
        isFormData: false,
      }).then((response:any) => {
        if (response[0] === 200) {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'We have sent you an email with a link to reset your password',
          })
          setTimeout(() => {
            navigate('/auth/login', {replace: true})
          }, 1500);
          // setLoading(false);
          setLoading(false)
        } else {
          ModelStateMessage(response)
          setLoading(false)
        }
      })
    } catch (error) {
      Swal.fire('Error', 'An error occurred. please try again later.', 'error')
    }
  }
  return (
    <Formik<formModel>
    validationSchema={forgotPasswordSchema}
    initialValues={{
      email: '',
    }}
    onSubmit={(values) => {
      handleSubmit(values)
    }}
  >
    {({
      handleSubmit,
      values,
      handleChange,
      handleBlur,
      setFieldValue,
      isSubmitting,
      isValid,
      touched,
      errors,
    }) => {
      return (<form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          Enter your email to reset your password.
        </div>
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
        <input
          type='email'
          id='email'
          placeholder='Email'
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.email}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': touched.email && errors.email},
            {
              'is-valid': touched.email && !errors.email,
            }
          )}
        />
        
        {touched.email && errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>Submit</span>
          {loading && (
                        <Spinner
                          as='span'
                          animation='border'
                          className='mb-1 ml-5'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={isSubmitting || !isValid}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
    
  )}
          }
</Formik>)
}
