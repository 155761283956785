import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {KTCard, KTIcon, initialQueryState, useDebounce} from '../../../../_handld/helpers'
import {Quotetable} from './table/Quotetable'
import {FC, useEffect, useState} from 'react'
import QueryFetchPost from '../../../../global/index2'
import Select from 'react-select'
import {ReactSelectStyles} from '../../../global/ReactSelectStyles'
import clsx from 'clsx'
import {QuotesEditModal} from './quotes-edit-modal/QuotesEditModal'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

type Props = {
  search?: string
}
const Quotelist:FC<Props> = ({search}) => {
  const {itemIdForUpdate} = useListView()
  const {updateState} = useQueryRequest()
  const debouncedSearchTerm = useDebounce(search, 150)

  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && search !== undefined) {
        updateState({ search: debouncedSearchTerm, ...initialQueryState })
      }
    },
    [debouncedSearchTerm]
  )


  return (
    <>
      <KTCard>
        <Quotetable />
      </KTCard>
      {itemIdForUpdate !== undefined && <QuotesEditModal />}
    </>
  )
}

const QuotelistWrapper = () => {
  const [status, setstatus] = useState<any>([])
  const [showSearchComponent, setShowSearchComponent] = useState<any>(false)
  const [date, setdate] = useState<any>()
  const [StatusId, setStatusId] = useState<string | null | undefined>()
  const [statusOptions, setStatusOptions] = useState<any[] | undefined>([])
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [search, setsearch] = useState<string | undefined>('')

  const Getviewmodel = async () => {
    await QueryFetchPost({
      link: `${BASE_URL}/api/BackOffice/JobsStatuses`,
      data: undefined,
      method: 'GET',
      key: 'Modelstatus',
      signal: undefined,
      isFormData: false,
    }).then((response: any) => {
      setstatus(response[1].statuses)
    })
  }
  const resetData = () => {
    setsearch('')
    setstatus(null)
    setdate(null)
    setStatusId(null)
  }
  useEffect(() => {
    Getviewmodel()
  }, [])

  useEffect(() => {
    if (status) {
      setStatusOptions(
        status.map((status: any) => ({value: status.statusId, label: status.statusName}))
      )
    }
  }, [status])



  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <div className='row'>
            <div
              className={`col-xl-3 col-lg-3 col-md-12 ${
                showSearchComponent ? 'filter-container' : 'filter-container hidden'
              }`}
            >
              {showSearchComponent && (
                <KTCard>
                  <div className='px-7 py-5' data-kt-user-table-filter='form'>
                    {/* begin::Input group */}
                    <div className='mb-10'>
                      <label className='form-label fs-6 fw-bold'>Search:</label>
                      <div className='d-flex align-items-center position-relative my-1'>
                        <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                        <input
                          type='text'
                          data-kt-user-table-filter='search'
                          className='form-control form-control-solid ps-14'
                          value={search}
                          onChange={(e) => {
                            setsearch(e.target.value)
                          }}
                          placeholder='Search...'
                        />
                      </div>
                    </div>

                    <div className='d-flex justify-content-end'>
                      <button
                        onClick={resetData}
                        type='button'
                        className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                        data-kt-menu-dismiss='true'
                        data-kt-user-table-filter='reset'
                      >
                        Reset
                      </button>
                      {/* <button
                        type='button'
                        className='btn btn-primary fw-bold px-6'
                        data-kt-menu-dismiss='true'
                        data-kt-user-table-filter='filter'
                      >
                        Apply
                      </button> */}
                    </div>
                    {/* end::Actions */}
                  </div>
                </KTCard>
              )}
            </div>
            <div
              className={
                showSearchComponent
                  ? 'col-xl-9 col-lg-9 col-md-12'
                  : 'col-xl-12 col-lg-12 col-md-12'
              }
            >
              <OverlayTrigger
                key='copy-to-clipboard'
                placement='top'
                overlay={
                  <Tooltip id='tooltip-copy-to-clipboard'>
                    {showSearchComponent ? 'Close Filter' : 'Filter'}
                  </Tooltip>
                }
              >
                <button
                  type='button'
                  className={`btn btn-light-primary btn-sm px-2 position-absolute z-index-1 end-1 filter-button ${
                    showSearchComponent ? 'clicked' : ''
                  }`}
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  onClick={() => setShowSearchComponent(!showSearchComponent)}
                  style={{transform: 'translateX(-14px)'}}
                >
                  <KTIcon
                    iconName={showSearchComponent ? 'abstract-11' : 'filter'}
                    className='fs-2 pe-0'
                  />
                </button>
              </OverlayTrigger>

              <Quotelist search={search} />
            </div>
          </div>
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}
export {QuotelistWrapper}
