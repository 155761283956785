import {FC} from 'react'
import {User} from '../../core/_models'

type Props = {
  user: User
}
const Pricecopy: FC<Props> = ({user}) => {
  return (
    <>
      {user?.initPirce ? (
        <>
          <span className='text-danger text-decoration-line-through'>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(user?.initPirce)}
          </span>{' '}
          /{' '}
          <span className='text-muted ml-2'>
            {new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(user?.price)}
          </span>
        </>
      ) : (
        <>
          {new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
          }).format(user?.price)}
        </>
      )}
    </>
  )
}
export {Pricecopy}
