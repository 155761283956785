import axios, {AxiosResponse} from 'axios'
import {User, UsersQueryResponse} from './_models'
import { ID, Response, TeamPassword } from '../../../../../_handld/helpers'
import { useState } from 'react'
const BASE_URL = process.env.REACT_APP_BASE_URL
const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${BASE_URL}/api/BackOffice/GetHandlrs`
const USER_PASSWORD_URL = `${BASE_URL}​/api​/BackOffice​/Password`
const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}
const Getviewmodel = (): Promise<UsersQueryResponse> => {
  return axios
    .get(`${BASE_URL}/api/BackOffice/GetViewModel`)
    .then((data: AxiosResponse<UsersQueryResponse>) => data.data)
}
const getUserById = (id: ID): Promise<User | undefined> => {
  return axios
    .get(`${BASE_URL}/api/BackOffice/DetailsHandlr/${id}`)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const updateUserPassword = (teamPassword: TeamPassword): Promise<TeamPassword | undefined> => {
  return axios
    .put(`${BASE_URL}/api/BackOffice/Password`, teamPassword)
    .then((response: AxiosResponse<Response<TeamPassword>>) => response.data)
    .then((response: Response<TeamPassword>) => response.data)
}

const updateUserStatus = (Values:any)=> {
  return axios
    .put(`${BASE_URL}/api/BackOffice/ChangeStatus`, Values)
    .then((response: AxiosResponse<Response<TeamPassword>>) => response.data)
    .then((response: Response<TeamPassword>) => response.data)
}
export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser, updateUserPassword,updateUserStatus,Getviewmodel}
