import {FC, useEffect, useState} from 'react'
import { useListView } from '../../core/ListViewProvider'
import QueryFetchPost from '../../../../../../global/index2'
import { handleApiErrors } from '../../../../../global/HandleApiErrors'

type Props = {
    id?:any 
  }
const Idcell: FC<Props> = ({id}) => {
    const {setItemIdForUpdate, setPasswordIdForUpdate,itemIdForUpdate} = useListView()

    const openEditModal = () => {
        setItemIdForUpdate(id)
      }
   return <div className='cursor-pointer'>

<a className='menu-link px-3' onClick={openEditModal}>
            {id}
          </a></div> 

   }
   export {Idcell}
   