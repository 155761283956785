import moment from 'moment';
import {FC} from 'react'
import { getFromatdate } from '../../../../../../_handld/helpers/GlobalHelpers';

type Props = {
  created?: string
}

const UserLastLoginCell2: FC<Props> = ({created}) => {
  let date='---'
  const format = getFromatdate();
  if (format) {
     date = moment(created).format(format);
  }return(
  
    <div className='badge badge-light fw-bolder'>{created && date}</div>
    )
}
export {UserLastLoginCell2}
