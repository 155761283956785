import { FC, useEffect } from 'react'
import { HeaderWrapper } from '../../../_handld/layout/components/header/HeaderWrapper'
import { Footer } from '../../../_handld/layout/components/Footer'
import { Content } from './components/content'
import { useAuth } from '../auth'
import { Services } from './components/services'
import { useLocation } from 'react-router-dom'
import BecomeHandlr from './components/BecomeHandlr'
import { FAQ } from './components/faq'
import ContactUs from './components/Contact'
import AboutUs from './components/Aboutus'
import { PrivacyPolicy } from './components/PrivacyPolicy'
import { TermsAndConditions } from './components/TermsAndConditions'

const LandingPage: FC = () => {
  const { currentUser } = useAuth()
  const { pathname } = useLocation()

  return (
    <div className='page d-flex flex-row flex-column-fluid'>
      <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
        {!currentUser ? (
          <>
            <HeaderWrapper />
          </>
        ) : (
          ''
        )}
        <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
          {pathname.includes('landing') ? <Content /> : null}
          {pathname.includes('service') ? <Services /> : null}
          {pathname.includes('become-a-handyman') ? <BecomeHandlr /> : null}
          {pathname.includes('faq') ? <FAQ /> : null}
          {pathname.includes('About-us') ? <AboutUs /> : null}
          {pathname.includes('Contact-us') ? <ContactUs /> : null}
          {pathname.includes('privacy-policy') ? <PrivacyPolicy /> : null}
          {pathname.includes('terms-and-conditions') ? <TermsAndConditions /> : null}
        </div>
        {!currentUser ? (
          <>
            <Footer />
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}
export { LandingPage }
