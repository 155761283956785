import React, {FC, useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'

const thumbsContainer: any = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
}

const thumb: any = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

const thumbInner: any = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img: any = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

const deleteButton: any = {
  position: 'relative',
  top: '-85px',
  right: '20px',
  padding: '4px 6px 4px 6px',
  background: '#f1416c',
  color: 'black',
  height: '21.13px',
  borderRadius: '50%',
  cursor: 'pointer',
}

type Props = {
  job: any
  handleChange: any
  index: any
}

const DropeZoneV1: FC<Props> = ({job , handleChange , index}) => {
  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      handleChange(index, 'files', acceptedFiles)
    },
  })

  const deleteImage = () => {
      handleChange(index, 'files', [])
  }

  const thumbs = job?.files?.map((file: any) => (
    <div key={file.name}>
      <div style={thumb}>
        <div style={thumbInner}>
          {/* <img
            src={file.preview || job?.files[0]?.preview}
            style={img}
            // Revoke data uri after image is loaded
            onLoad={() => {
              URL.revokeObjectURL(file.preview || job?.files[0]?.preview)
            }}
          /> */}
          <img
            style={img}
            src={URL.createObjectURL(file)}
          />
        </div>
      </div>
      <i style={deleteButton} onClick={deleteImage} className='fas fa-times text-danger bg-light-danger text-hover-danger'></i>
    </div>
  ))

  useEffect(() => {
    return () => {
      if (job?.files) {
        URL.revokeObjectURL(job?.files.preview)
      }
    }
  }, [job])

  return (
    <div>
      <section className='container'>
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <div className='dz-message needsclick'>
            <i className='ki-duotone ki-file-up text-primary fs-3x'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <div className='ms-4'>
              <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                Drop a photo here or click to upload.
              </h3>
              <span className='fs-7 fw-semibold text-gray-400'>
                Click or drag a file to this area to upload.
              </span>
            </div>
          </div>
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </section>
    </div>
  )
}

export default DropeZoneV1
