import {FC} from 'react'
import {Container, Row} from 'react-bootstrap'

const PrivacyPolicy: FC = () => {
  return (
    <>
      <Container>
        <Row className='py-15'>
          <div className='card'>
            {/*begin::Body*/}
            <div className='card-body p-10 p-lg-15'>
              <div className=''>
                <h1>Privacy Policy</h1>
                <p className='fw-bolder text-gray-700'>Effective date: [Insert Effective Date]</p>
                <p className='fw-bolder text-gray-700'>
                  This Privacy Policy describes how Handyman Inc. ("Company" or "Handyman")
                  collects, uses, and shares personal information when you use the Handyman Platform
                  (defined below) or visit our website at{' '}
                  <a href='https://www.gohandyman.ca'>https://www.gohandyman.ca</a>. By using the
                  Handyman Platform, you agree to the practices described in this policy.
                </p>
                <h1>Information We Collect</h1>
                <p className='fw-bolder text-gray-700'>
                  We may collect and process the following information:
                </p>
                <ul className='fw-bolder text-gray-700'>
                  <li>
                    Your contact information, including name, email address, and phone number.
                  </li>
                  <li>
                    Information you provide when creating an account, including your username and
                    password.
                  </li>
                  <li>
                    Transaction information related to your use of the Handyman Platform, including
                    details about the jobs you complete or request, payment information, and billing
                    details.
                  </li>
                  <li>
                    Information related to your communications with Handyman and other users through
                    the Handyman Platform, including messages, chat transcripts, and support
                    requests.
                  </li>
                  <li>Information collected through cookies and similar tracking technologies.</li>
                  <li>
                    Location information, which may be collected through your device's GPS or other
                    location-based technologies when you use the Handyman mobile application.
                  </li>
                  <li>
                    Information from third-party services, such as background checks, identity
                    verification services, and public databases.
                  </li>
                </ul>
                <h1>How We Use Your Information</h1>
                <p className='fw-bolder text-gray-700'>
                  We may use your personal information for the following purposes:
                </p>
                <ul className='fw-bolder text-gray-700'>
                  <li>To provide, maintain, and improve the Handyman Platform and our services.</li>
                  <li>To facilitate job requests, job completion, and payments.</li>
                  <li>
                    To communicate with you, respond to your inquiries, and provide customer
                    support.
                  </li>
                  <li>To send you important notices, updates, and promotional materials.</li>
                  <li>
                    To verify your identity, conduct background checks, and ensure compliance with
                    our policies and legal requirements.
                  </li>
                  <li>
                    To monitor and analyze usage patterns, perform data analytics, and conduct
                    research.
                  </li>
                  <li>To detect and prevent fraud, abuse, and security incidents.</li>
                  <li>To comply with applicable laws and regulations.</li>
                </ul>
                <h1>How We Share Your Information</h1>
                <p className='fw-bolder text-gray-700'>
                  We may share your personal information with:
                </p>
                <ul className='fw-bolder text-gray-700'>
                  <li>
                    Other users of the Handyman Platform, such as Clients and Handymen, as necessary
                    to facilitate job requests.
                  </li>
                  <li>
                    Service providers, contractors, and business partners who assist us in providing
                    the Handyman Platform and related services.
                  </li>
                  <li>
                    Law enforcement agencies, government authorities, or other third parties in
                    response to legal requests or to protect our rights and safety.
                  </li>
                  <li>
                    Third-party companies that provide identity verification, background check, and
                    similar services.
                  </li>
                  <li>Other third parties with your consent or as directed by you.</li>
                </ul>
                <h1>Your Choices</h1>
                <p className='fw-bolder text-gray-700'>
                  You have choices regarding your personal information:
                </p>
                <ul className='fw-bolder text-gray-700'>
                  <li>
                    You can access and update some of your personal information through your
                    Handyman account settings.
                  </li>
                  <li>
                    You may choose to disable location tracking, but this may affect certain
                    features of the Handyman Platform.
                  </li>
                  <li>
                    You can opt-out of receiving promotional communications from us by following the
                    instructions in our emails or by contacting us directly.
                  </li>
                  <li>
                    You may request the deletion of your account and personal information, subject
                    to certain legal and operational requirements.
                  </li>
                </ul>
                <h1>Security</h1>
                <p className='fw-bolder text-gray-700'>
                  We take reasonable measures to protect your personal information, but no data
                  transmission over the internet is completely secure. You are responsible for
                  keeping your password confidential and for ensuring the security of your Handyman
                  account.
                </p>
                <h1>Changes to This Privacy Policy</h1>
                <p className='fw-bolder text-gray-700'>
                  We may update this Privacy Policy from time to time to reflect changes in our
                  practices or for other operational, legal, or regulatory reasons. We will notify
                  you of any material changes by posting the updated policy on our website or
                  through other appropriate means.
                </p>
                <h1>User Data</h1>
                <p className='fw-bolder text-gray-700'>
                  Handyman Inc. does not collect, use, or share any personal and sensitive user data
                  through the Handyman Platform.
                </p>
                <h1>Contact Us</h1>
                <p className='fw-bolder text-gray-700'>
                  If you have any questions or concerns about this Privacy Policy or our privacy
                  practices, please contact us at [Insert Contact Information].
                </p>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  )
}
export {PrivacyPolicy}
