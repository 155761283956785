import {FC} from 'react'
import {Container, Row} from 'react-bootstrap'

const TermsAndConditions: FC = () => {
  return (
    <>
      <Container>
        <Row className='py-15'>
          <div className='card'>
            {/*begin::Body*/}
            <div className='card-body p-10 p-lg-15'>
              <div className='rich-text-block-2 w-richtext'>
                <h1>Welcome to Handyman.</h1>
                <p className='fw-bolder text-gray-700'>
                  Terms of Service | Independent Contractor Agreement | Privacy Policy
                </p>
                <p className='fw-bolder text-gray-700'>
                  <h1>ACCEPTANCE OF TERMS:</h1> These Terms of Service constitute an agreement that
                  is legally binding between you and Handyman Inc. (“Company” or Handyman)
                  administering your use of the Handyman Platform (as defined below), Company’s
                  website (<a href='https://www.gohandyman.ca'>https://www.gohandyman.ca</a>
                  ). The Handyman Inc. Platform and all related services provided by Company, and
                  the website, including without limitation communications and information (whether
                  in writing, orally, or other means) provided by Company to potential and current
                  Handyman’s and Clients (both as defined below) in relation to the services, are
                  together hereinafter collectively referred to as the Handyman Platform.” Personal
                  data that you submit, or that we collect about you is governed by the Handyman
                  Privacy Policy (“Privacy Policy”). By using the Handyman Platform, you have
                  acknowledged that you have reviewed the Privacy Policy. Your use of the Handyman
                  Platform constitutes your acceptance of and agreement to all the terms and
                  conditions in these Terms of Service, Privacy Policy, Independent Contractor
                  Agreement, and any future amendments and additions to this Agreement (as defined
                  below) as we may update from time to time.
                </p>
                <p className='fw-bolder text-gray-700'>
                  BY ENTERING INTO THIS AGREEMENT, YOU CLEARLY AND EXPRESSLY ACKNOWLEDGE THAT YOU
                  HAVE READ, UNDERSTAND, AND AGREE, WITHOUT LIMITATION OR QUALIFICATION, TO BE BOUND
                  BY THIS AGREEMENT. IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT, YOU MAY NOT
                  USE OR ACCESS THE HANDYMAN PLATFORM. You must be at least 18 years of age and
                  capable and competent in entering into binding contracts in order to use and
                  access the Handyman Platform. If you are under the age of 18, you may not use or
                  access the Handyman Platform.
                </p>
                <h1>ACKNOWLEDGMENTS:</h1>
                <p className='fw-bolder text-gray-700'>
                  User hereby acknowledges that:
                  <ul>
                    <li>
                      Your agreement that Handyman’s are not employees of the Handyman Platform.
                      Handyman’s are their own independent contractors. (See Independent Contractor
                      Agreement)
                    </li>
                    <li>
                      Your acknowledgement and agreement that Clients and not Handyman, supervise
                      and monitor a Handyman’s work.
                    </li>
                    <li>
                      Your acknowledgment that it is the responsibility of Client and Handyman to
                      notify (via the Handyman Platform) when a job is completed.
                    </li>
                    <li>
                      Your acknowledgement in becoming a Handyman that you are capable and competent
                      to meet the needs in each job you accept.
                    </li>
                    <li>
                      Your acknowledgement as a Client that it is your responsibility to ensure a
                      safe and adequate working area for Handyman.
                    </li>
                    <li>
                      Your acknowledgement of any disputes to be resolved on a case by case basis by
                      Handyman Inc.
                    </li>
                  </ul>
                </p>
                <p className='fw-bolder text-gray-700'>
                  <h1>DESCRIPTION OF SERVICES:</h1> Handyman Inc is an online communications
                  platform which provides an avenue for Clients to connect with Handyman’s.
                  “Clients” are individuals and/or businesses looking to hire a Handyman to pick-up,
                  deliver, and assemble their product(s). “Handyman’s” are individuals who possess
                  the ability and the means to pick-up, deliver, and assemble a customer’s
                  product(s). Hereinafter, unless otherwise specified, both Clients and Handyman’s
                  will be referred to as Users. Once the terms of a job are accepted with another
                  User, both Users form a Service Agreement directly between the two.
                </p>
                <h1>USER VETTING AND REPRESENTATIONS:</h1>
                <p className='fw-bolder text-gray-700'>
                  In the sole discretion of Handyman Inc, Users may be subjected to an extensive
                  vetting process before they can use the Handyman Platform. Vetting process may
                  include, but not limited to police background checks, employment history, and
                  related work experience. Although Handyman Inc confirms the identity of each
                  Handyman upon registration, Handyman cannot confirm that each User who responds to
                  a job is who they claim to be. Any person who agrees to and responds to a job in
                  the guise of registered Handyman, will be prosecuted to the fullest extent of the
                  law. It is the sole responsibility of the Client to confirm that the Handyman is
                  who they say they are. Any interaction with other Users requires the need to
                  exercise caution and common sense, in order to protect personal safety, data, and
                  property. Handyman will not be liable for any false or misleading statements made
                  by Users on the Handyman Platform.
                </p>
                <p>
                  <h1>DISCLAIMER OF WARRANTY; LIMITATION OF LIABILITY:</h1>
                </p>
                <ul role='list' className='fw-bolder text-gray-700'>
                  <li>
                    USER EXPRESSLY AGREES THAT USE OF{' '}
                    <a href='https://www.gohandyman.ca'>https://www.gohandyman.ca</a> IS AT USER'S
                    SOLE RISK. NEITHER HANDLD , ITS AFFILIATES NOR ANY OF THEIR RESPECTIVE
                    EMPLOYEES, AGENTS, THIRD PARTY CONTENT PROVIDERS OR LICENSORS WARRANT THAT
                    <a href='https://www.gohandyman.ca'> https://www.gohandyman.ca</a> WILL BE
                    UNINTERRUPTED OR ERROR FREE; NOR DO THEY MAKE ANY WARRANTY AS TO THE RESULTS
                    THAT MAY BE OBTAINED FROM USE OF
                    <a href='https://www.gohandyman.ca'> https://www.gohandyman.ca</a>, OR AS TO THE
                    ACCURACY, RELIABILITY OR CONTENT OF ANY INFORMATION, SERVICE, OR MERCHANDISE
                    PROVIDED THROUGH
                    <a href='https://www.gohandyman.ca'> https://www.gohandyman.ca</a>
                  </li>
                  <li>
                    <a href='https://www.gohandyman.ca'> https://www.gohandyman.ca</a> IS PROVIDED
                    ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                    INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF
                    MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OTHER THAN THOSE WARRANTIES
                    WHICH ARE IMPLIED BY AND INCAPABLE OF EXCLUSION, RESTRICTION OR MODIFICATION
                    UNDER THE LAWS APPLICABLE TO THIS AGREEMENT.
                  </li>
                  <li>
                    THIS DISCLAIMER OF LIABILITY APPLIES TO ANY DAMAGES OR INJURY CAUSED BY ANY
                    FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY
                    IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT
                    OR DESTRUCTION OR UNAUTHORIZED ACCESS TO
                    <a href='https://www.gohandyman.ca'> https://www.gohandyman.ca</a>, ALTERATION
                    OF, OR USE OF RECORD, WHETHER FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR,
                    NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION. USER SPECIFICALLY ACKNOWLEDGES
                    THAT HANDYMAN IS NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF
                    OTHER USERS OR THIRD-PARTIES AND THAT THE RISK OF INJURY FROM THE FOREGOING
                    RESTS ENTIRELY WITH USER.
                  </li>
                </ul>
                <h1>REPRESENTATIONS AND WARRANTIES</h1>
                <p className='fw-bolder text-gray-700'>User represents and acknowledges that:</p>
                <ul className='fw-bolder text-gray-700'>
                  <li>s/he is over the age of 18.</li>
                  <li>s/he is legally eligible to work in Canada.</li>
                  <li>s/he is capable of entering into binding contracts.</li>
                  <li>
                    User has the capability, ability, and the means to perform the agreed upon
                    duties and requirements in each job user accept.
                  </li>
                  <li>
                    Users will not be inebriated by any substance when fulfilling the requirements
                    and duties of the agreed upon job.
                  </li>
                  <li>
                    Where user enters into this Agreement on behalf of a company or other
                    organization, user warrants that s/he has authority to act on behalf of that
                    entity, and to bind that entity to this Agreement.
                  </li>
                  <li>
                    User further represents and warrants that user has read, understand, and agrees
                    to be bound by these Terms of Service, and Privacy Policy in order to register,
                    use, and access the Handyman Platform.
                  </li>
                  <li>
                    User hereby warrant and represent that user will respect the privacy, property,
                    and data protection rights of Users in respect to the information provided on
                    job detail agreements. Users will not save information of other Users.
                  </li>
                  <li>
                    User warrants and represents that s/he will act professionally and responsibly
                    in user interactions with other Users.
                  </li>
                  <li>
                    User further represents and acknowledges that when using and accessing the
                    Handyman Platform, user will act in accordance to applicable Municipal,
                    Provincial, and Federal laws, and always in good faith.
                  </li>
                  <li>
                    User hereby warrants and represents that, other than as fully and promptly
                    disclosed to Handyman Inc as set forth below, users does not have any
                    motivation, status, or interest which Handyman may reasonably wish to know about
                    in connection with the Handyman Platform, including without limitation, if user
                    are using or will or intend to use the Handyman Platform for any journalistic,
                    investigative, or unlawful purpose.
                  </li>
                  <li>
                    User hereby warrant and represent that user will promptly disclose to Handyman
                    in writing any such motivation, status, or interest, whether existing prior to
                    registration or as arises during user use of the Handyman Platform.
                  </li>
                  <li>
                    In addition, user represents and warrants that user will provide timely,
                    high-quality services to Handyman Clients.
                  </li>
                  <li>
                    User will only accept jobs that user have the availability, capability, means,
                    skills and expertise to complete jobs in a professional, high-quality manner.
                  </li>
                  <li>
                    User also represents and warrants that s/he will provide the services safely and
                    in accordance with all applicable laws, including but not limited to being in a
                    sober state of mind upon arrival, and possessing legal eligibility to work in
                    Canada.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  )
}
export {TermsAndConditions}
