import React, {FC, useEffect} from 'react'
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js'
import {StripeCardElement, StripeCardElementOptions} from '@stripe/stripe-js'
import Swal from 'sweetalert2'

type Props = {
  handleSubmit2: (data: any) => void
  billing: any
  formik?: any
}
const CheckoutForm: FC<Props> = ({handleSubmit2, billing , formik}) => {
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const cardElement = elements.getElement(CardElement)

    if (!cardElement) {
      // Handle the case where the card element is missing
      return
    }

    const result = await stripe.createToken(cardElement as StripeCardElement, {
      name: formik.values.cardName
    })

    if (result.error) {
      Swal.fire({
        text: result.error.message,
        icon: 'info',
        showConfirmButton: true,
        timer: 3000,
        width: '25rem',
      })
    } else {
      // Use the token to make a payment or save the card
      // setCardToken(result.token)
      const obj = {...billing}
      obj.cardToken = result.token?.id
      obj.cardId = result.token?.card?.id
      handleSubmit2(obj)
    }
  }

  const cardElementOptions: StripeCardElementOptions = {
    hidePostalCode: true,
  }
  return (
    <form onSubmit={handleSubmit}>
      <div
        className='custom-card-element-container'
        style={{
          display: 'block',
          width: '100%',
          padding: '0.975rem 1rem',
          fontSize: '1.1rem',
          fontWeight: 500,
          lineHeight: 1.5,
          color: '#5e6278',
          backgroundColor: '#f9f9f9',
          backgroundClip: 'padding-box',
          border: '#e1e3ea',
          borderRadius: '0.475rem',
          boxShadow: 'none',
          transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
          outline: 'none',
        }}
      >
        <CardElement options={cardElementOptions} className='custom-card-element' />
      </div>
      <button id='pay_now' hidden={true} className='btn btn-sm btn-primary mt-5' type='submit'>
        Pay Now
      </button>
    </form>
  )
}

export default CheckoutForm
