import clsx from 'clsx'
import {FC, useEffect, useState} from 'react'
import {OverlayTrigger, Spinner, Tooltip} from 'react-bootstrap'
import Swal from 'sweetalert2'
import QueryFetchPost, {ModelStateMessage} from '../../../../../global/index2'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'
import { getFromatdate } from '../../../../../_handld/helpers/GlobalHelpers'
import { toAbsoluteUrl } from '../../../../../_handld/helpers'
import SVG from "react-inlinesvg";
import moment from 'moment'

type Props = {
  data: any
}

const QuotesEditModalForm: FC<Props> = ({data}) => {
  const [price, setprice] = useState<any>()
  const [totalPrice, setTotalPrice] = useState(0)
  const {setItemIdForUpdate, itemIdForUpdatePassword} = useListView()
  const {refetch} = useQueryResponse()
  
  const Created = data?.created;
  let date = '---'; // Default value if requesteddate is null or undefined

  if (Created) {
    const splitdate = Created.split("T");
    const datee = splitdate[0];
    // date = moment(datee).format("MM-DD-YYYY");
    const format = getFromatdate();
    if (format) {
      date = moment(datee).format(format);
    }
  }
  const [modifiedPrices, setModifiedPrices] = useState<{[id: number]: number}>({})
  const [modifiedPriceArrayOnChange, setModifiedPriceArrayOnChange] = useState<
    {id: number; price: number}[]
  >([])
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    let initialTotal = 0
    if (data?.quotes) {
      data.quotes.forEach((job: any) => {
        initialTotal += job.price
      })
    }
    setTotalPrice(initialTotal)
  }, [data]) 
  useEffect(()=>{
    console.log(totalPrice)

  })
  // useEffect(() => {
  //   if (modifiedPriceArrayOnChange && modifiedPriceArrayOnChange.length > 0) {
  //     let newTotal = 0;
  //     modifiedPriceArrayOnChange.forEach((job: any) => {
  //       newTotal += job.price;
  //     });
  //     setTotalPrice(newTotal);
  //   } else {
  //     setTotalPrice(0); // Set total to 0 when there are no modified prices
  //   }
  // }, [modifiedPriceArrayOnChange]);

  const handlePriceChange = (id: number, value: number) => {
    const roundedValue= parseFloat(value.toFixed(2));

    setModifiedPrices((prevPrices) => ({
      ...prevPrices,
      [id]: roundedValue,
    }))

    const newModifiedPriceArray = Object.entries({
      ...modifiedPrices,
      [id]: value,
    }).map(([id, price]) => ({
      id: parseInt(id),
      price,
    }))
    setModifiedPriceArrayOnChange(newModifiedPriceArray)

    let newTotal = 0
    data?.quotes?.forEach((job: any) => {
      const modifiedPrice = newModifiedPriceArray.find((modifiedJob) => modifiedJob.id === job.id)
      const priceToUse = modifiedPrice ? modifiedPrice.price : job.price

      if (!isNaN(priceToUse) && priceToUse !== '') {
        newTotal += parseFloat(priceToUse)
      }
    })
    setTotalPrice(newTotal)
  }

  const cancel = (withRefresh?: boolean) => {
    setItemIdForUpdate?.(undefined)
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      // Create an array that includes all quotes
      const allQuotes = data.quotes.map((quote:any) => {
        const modifiedPrice = modifiedPriceArrayOnChange.find((item) => item.id === quote.id);
        if (modifiedPrice) {
          // If there is a modified price, use it
          return { id: quote.id, price: modifiedPrice.price };
        } else {
          // If there is no modified price, use the original price
          return { id: quote.id, price: quote.price };
        }
      });
      await QueryFetchPost({
        link: `${BASE_URL}/api/BackOffice/EditQuote`,
        data: {
          id: data.id,
          quotes: allQuotes,
        },
        method: 'POST',
        key: 'EditQuote',
        signal: undefined,
        isFormData: false,
      }).then((response: any) => {
        if (response[0] === 200) {
          Swal.fire({
            text: `Changes were made successfully!`,
            icon: 'success',
            showConfirmButton: true,
            timer: 2000,
            width: '25rem',
          })
          refetch()
          setLoading(false)
          cancel()
        } else {
          ModelStateMessage(response)
          setLoading(false)
        }
      })
    } catch (error) {
      console.error('Error during form submission:', error)
    }
  }
  useEffect(()=>{
    console.log(data.state)
  })

  return (
    <>
      {data?.isAccepted === true ? (
        <div className='mt-n1'>
          <div className='d-flex flex-stack pb-10'>
            <div className='fw-bold fs-3 text-gray-800 mb-8 me-3'>
              Quote #{data?.id}
              <span className='me-3'>
                {data?.isAccepted === true && (
                  <span className='badge badge-light-success fw-bold  mx-2 px-4 py-3'>
                    Accepted
                  </span>
                )}
                {data?.isAccepted === null && (
                  <span className='badge badge-secondary fw-bold  me-2 px-4 py-3'>Pending</span>
                )}
                {data?.isAccepted === false && (
                  <span className='badge badge-light-danger fw-bold  me-2 px-4 py-3'>Rejected</span>
                )}
              </span>
            </div>
          </div>
          <div className='m-0'>
            <div className='row g-5 mb-5'>
              <div className='col-sm-6'>
                <div className='fw-semibold fs-7 text-gray-600 mb-1'>Date:</div>
                <div className='fw-bold fs-6 text-gray-800'>
                <div className="d-flex align-items-center">
              <p className=' fs-6 mt-1 mb-7'>{data?.created && date}</p>
              <div className="font-weight-bolder mb-7">
                        <OverlayTrigger
                          key={"right"}
                          placement={"right"}
                          overlay={
                            <Tooltip id={`tooltip-${"placement"}`}>
                              {getFromatdate()}
                            </Tooltip>
                          }
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            className="svg-icon menu-icon svg-icon-info"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/Code/Info-circle.svg"
                              )}
                            />
                          </span>
                        </OverlayTrigger>
                      </div>
                      </div>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='fw-semibold fs-7 text-gray-600 mb-1'>Address:</div>
                <div className='fw-bold fs-6'>
                {data?.address1} <br />
               { data?.address2 ? <>{data?.address2} <br/></>:''}
                  {data?.city},{data?.state}, {data?.zipCode},{data?.country} <br />
                </div>
              </div>
            </div>

            <div className='flex-grow-1 mb-5 pb-5'>
              <div className='table-responsive border-bottom mb-9'>
                <table className='table mb-3'>
                  <thead>
                    <tr className='border-bottom fs-6 fw-bold text-muted'>
                      <th className='min-w-175px pb-2'>Items</th>
                      <th className='min-w-175px'>price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.quotes?.map((job: any, index: any) => (
                      <tr className='fw-bold text-gray-700 fs-5 ' key={index}>
                        <td className='d-flex align-items-center pt-6 pe-10 me-10'>
                          {job?.service}
                        </td>
                        <td className='align-items-center pt-6 pe-10 me-10'>
                          {' '}
                          <div className='fw-semibold pe-10 text-gray-600 fs-7'></div>
                          <div className=' fw-bold fs-6 text-gray-800'>
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            }).format(job?.price)}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='d-flex justify-content-end'>
                <div className='mw-300px'>
                  <div className='d-flex flex-stack'>
                    <div className='fw-semibold pe-10 text-gray-600 fs-7'>Total</div>
                    <div className='text-end fw-bold fs-6 text-gray-800'>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(data?.price)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-between my-5 py-5'>
            <button onClick={() => cancel()} className='btn btn-sm btn-secondary px-5'>
              Cancel
            </button>
          </div>
        </div>
      ) : (
        <div className='mt-n1'>
          <div className='d-flex flex-stack pb-10 align-items-center'>
            <div className='fw-bold fs-3 text-gray-800 mb-8 '>
              <span className='mt-2'>Quote #{data?.id}</span>
              <span className='ms-3'>
                {data?.isAccepted === true && (
                  <span className='badge badge-light-success fw-bold  me-2 px-4 py-3'>
                    Accepted
                  </span>
                )}
                {data?.isAccepted === null && (
                  <span className='badge badge-secondary fw-bold  me-2 px-4 py-3'>Pending</span>
                )}
                {data?.isAccepted === false && (
                  <span className='badge badge-light-danger fw-bold  me-2 px-4 py-3'>Rejected</span>
                )}
              </span>
            </div>
          </div>
          <div className='m-0'>
            <div className='row g-5 mb-5'>
              <div className='col-sm-6'>
                <div className='fw-semibold fs-7 text-gray-600 mb-1'>Date:</div>
                <div className='fw-bold fs-6 text-gray-800'>
                <div className="d-flex align-items-center">
              <p className=' fs-6 mt-1 mb-7'>{data?.created && date}</p>
              <div className="font-weight-bolder mb-7">
                        <OverlayTrigger
                          key={"right"}
                          placement={"right"}
                          overlay={
                            <Tooltip id={`tooltip-${"placement"}`}>
                              {getFromatdate()}
                            </Tooltip>
                          }
                        >
                          <span
                            style={{ cursor: "pointer" }}
                            className="svg-icon menu-icon svg-icon-info"
                          >
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/Code/Info-circle.svg"
                              )}
                            />
                          </span>
                        </OverlayTrigger>
                      </div>
                      </div>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='fw-semibold fs-7 text-gray-600 mb-1'>Address:</div>
                <div className='fw-bold fs-6'>
                  {data?.address1} <br />
               { data?.address2 ? <>{data?.address2} <br/></>:''} 
                  {data?.city},{data?.state}, {data?.zipCode},{data?.country} <br />
                </div>
              </div>
            </div>
            <div className='flex-grow-1 mb-5 pb-5'>
              <div className='table-responsive border-bottom mb-9'>
                <table className='table mb-3'>
                  <thead>
                    <tr className='border-bottom fs-6 fw-bold text-muted'>
                      <th className='min-w-175px pb-2'>Items</th>
                      <th className='min-w-175px'>price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.quotes?.map((job: any, index: any) => (
                      <tr className='fw-bold text-gray-700 fs-5 ' key={index}>
                        <td className='d-flex align-items-center pt-6 pe-10 me-10'>
                          {job?.service}
                        </td>

                        <td>
                          <div className='input-group mb-5'>
                            <span className='input-group-text bg-transparent' id='basic-addon1'>
                              $
                            </span>
                            <input
                              type='number'
                              value={
                                modifiedPrices[job?.id] !== undefined
                                  ? modifiedPrices[job?.id]
                                  : job.price.toFixed(2)
                              }
                              id={job?.id}
                              placeholder='Change price'
                              onChange={(e) => {
                                handlePriceChange(job.id, parseFloat(e.target.value))
                              }}
                              className={clsx('form-control bg-transparent')}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className='d-flex justify-content-end'>
                <div className='mw-300px'>
                  <div className='d-flex flex-stack'>
                    <div className='fw-semibold pe-10 text-gray-600 fs-7'>Total</div>
                    <div className='text-end fw-bold fs-6 text-gray-800'>
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(totalPrice)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-between my-5 py-5'>
            <button onClick={() => cancel()} className='btn btn-sm btn-secondary px-5'>
              Cancel
            </button>
            <button onClick={() => handleSubmit()} className='btn btn-sm btn-primary text-end '>
              Save Changes
              {loading && (
                <Spinner
                  as='span'
                  animation='border'
                  className='mb-1 ml-5'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export {QuotesEditModalForm}
