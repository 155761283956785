import axios from 'axios'
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import DateAndTime from './DateAndTime'
import Billing from './Billing'

type Props = {
  show: boolean
  handleClose: () => void
  billing: any
  setBilling: (billing: any) => void
  clientSecret: string
  handleGetJobs: () => void
  availableTimesArray?: any
  setAvailableTimesArray: (availableTimesArray: any) => void
  quoteId?: any
}
const AccepteQuteModal: FC<Props> = ({
  show,
  handleClose,
  billing,
  setBilling,
  clientSecret,
  handleGetJobs,
  availableTimesArray,
  setAvailableTimesArray,
  quoteId,
}) => {
  const [step, setStep] = useState(1)
  return (
    <div>
      <Modal show={show} onHide={handleClose} size='xl'>
        <Modal.Header closeButton>
          <Modal.Title>Accept Quote #{quoteId}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === 1 && (
            <DateAndTime
              billing={billing}
              setBilling={setBilling}
              setStep={setStep}
              handleClose={handleClose}
              availableTimesArray={availableTimesArray}
              setAvailableTimesArray={setAvailableTimesArray}
            />
          )}
          {step === 2 && (
            <Billing
              handleGetJobs={handleGetJobs}
              billing={billing}
              setBilling={setBilling}
              setStep={setStep}
              handleClose={handleClose}
              clientSecret={clientSecret}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AccepteQuteModal
