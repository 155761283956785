import React from 'react'
import { Basics } from './Basics'
import { Customers } from './Customers'

const WraperDetails = () => {
  return (
    <>
      <div className='row'>
        <div className='col-xl-4 col-lg-4 col-md-12'>
          <Basics />
        </div>
        <div className='col-xl-8 col-lg-8 col-md-12'>
            <Customers />
        </div>
      </div>
    </>
  )
}

export default WraperDetails
