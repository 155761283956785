import {FC} from 'react'
import { User } from '../../core/_models'

type Props = {
    user: User
  }
const Price: FC<Props> = ({user}) => {
   return <>
    
    {
                            user?.payout ? (
                              new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              }).format(user?.payout)
                            ) : (
                              '--'
                            )
                          }
</>
   }
   export {Price}
   