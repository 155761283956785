import React, { useEffect, useState } from 'react'
import QueryFetchPost from '../../../../global/index2'
import { handleApiErrors } from '../../../global/HandleApiErrors'

const DashbaordCards = () => {
  const [counts, setcounts] = useState<any>()
  const BASE_URL = process.env.REACT_APP_BASE_URL

  const Getcounts = async () => {
    try {
  
      await QueryFetchPost({
        link: `${BASE_URL}/api/BackOffice/Counts`,
        data: undefined,
        method: 'GET',
        key: 'GetJobs',
        signal: undefined,
        isFormData: false,
      }).then((response: any) => {
        setcounts(response[1])
      })
    }
    catch (error) {
    handleApiErrors(error)
    } 
  }
  useEffect(()=>{
    Getcounts()

  },[])
  return (
    <div className='row g-5 g-xl-8'>
      <div className='col-xl-3'>
        <div className='card card-flush mb-xl-10'>
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='pb-3 fw-bold fs-6'>Total Jobs</span>
              <div className='d-flex align-items-center'>
                <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{counts?.totalJobs}</span> <br />
              </div>
              <div className='d-flex align-items-center'>
                <span className='text-gray-400 pt-1 fw-semibold fs-6'>Jobs</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-xl-3'>
        <div className='card card-flush mb-xl-10'>
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='pb-3 fw-bold fs-6'>Total Customers</span>
              <div className='d-flex align-items-center'>
                <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{counts?.totalCustomers}</span> <br />
              </div>
              <div className='d-flex align-items-center'>
                <span className='text-gray-400 pt-1 fw-semibold fs-6'>Customers</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-xl-3'>
        <div className='card card-flush mb-xl-10'>
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='pb-3 fw-bold fs-6'>Total Handymen</span>
              <div className='d-flex align-items-center'>
                <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{counts?.totalHandlr}</span> <br />
              </div>
              <div className='d-flex align-items-center'>
                <span className='text-gray-400 pt-1 fw-semibold fs-6'>Handymen</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-xl-3'>
        <div className='card card-flush mb-xl-10'>
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='pb-3 fw-bold fs-6'>Total Revenue</span>
              <div className='d-flex align-items-center'>
                <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>
                {counts?.totalRevenue ?
                          (      new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                }).format(counts?.totalRevenue))
                              :'--'}
             </span> <br />
              </div>
              <div className='d-flex align-items-center'>
                <span className='text-gray-400 pt-1 fw-semibold fs-6'>Revenue</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashbaordCards
