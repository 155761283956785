import {PageLink, PageTitle} from '../../../../_handld/layout/core'
import WraperDetails from './WraperDetails'

const handlrBreadcrumbs: Array<PageLink> = [
  {
    title: 'Customers Management',
    path: '/backoffice/handyman-management/details/:id',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CustomerDetailsPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={handlrBreadcrumbs}>Customer details</PageTitle>
      <WraperDetails />
    </>
  )
}

export default CustomerDetailsPage
