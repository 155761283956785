import {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_handld/helpers'

type Props = {
  rate?: number
}

const UserLastLoginCell: FC<Props> = ({rate}) => (
  <>
    {rate === 0 ? (
      <div className='rating-label checked'>
        <div className='d-flex rating-label me-2 checked'>
          <span>
            <i className='bi bi-star text-warning' />
            {''} -
          </span>
        </div>
      </div>
    ) : (
      <div className='rating-label checked'>
        <i className='bi bi-star-fill text-warning' />
        {''} {rate}
      </div>
    )}
  </>
)

export {UserLastLoginCell}
