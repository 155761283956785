import firebase from 'firebase'
import {AppString} from '../Component/Const'
import {fetchPushToken, updateProfilePicture} from '../firebaseHelper'
const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_AUTH_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
}
if (!firebase.apps.length) {
  firebase.initializeApp(config)
}
firebase.firestore().settings({
  timestampsInSnapshots: true,
})
const publicKey = process.env.REACT_APP_PUBLIC_KEY
export const requestForToken = async () => {
  if (firebase.messaging.isSupported()) {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      const messagingCc = firebase.messaging()
      let currentToken = ''
      try {
        currentToken = await messagingCc.getToken({vapidKey: publicKey})
        //update pushtoken
        if (currentToken) {
          try {
            //save in collection users
            const id = localStorage.getItem(AppString.ID)
            const result = await myFirestore
              .collection(AppString.NODE_USERS)
              .where(AppString.ID, '==', id)
              .get()
            if (result.docs.length > 0) {
              //get data from localStorage
              var dataUser = JSON.parse( localStorage.getItem("User"));
              await firebase.firestore()
                .collection('users')
                .doc(id)
                .update({
                  pushToken: currentToken,
                  nickname: `${dataUser?.firstName} ${dataUser?.lastName}`,
                  photoUrl: dataUser?.profilePicture,
                })
              if (currentToken) {
                // await fetchPushToken(id, currentToken)
                localStorage.setItem(AppString.PUSH_TOKEN, currentToken)
              }
            }
          } catch (err) {
            console.error('err', err)
          }
        }
      } catch (error) {
        console.log('An error occurred while retrieving token.', error)
      }
      return currentToken
    }
  }
}

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = (setData) =>
  new Promise((resolve) => {
    if (firebase.messaging.isSupported()) {
      firebase.messaging().onMessage((payload) => {
        resolve(payload)
        setData(payload)
      })
    }
  })
let messagingExport = {}
if (firebase.messaging.isSupported()) {
  messagingExport = firebase.messaging()
}
export const messaging = messagingExport
export const myFirebase = firebase
export const myFirestore = firebase.firestore()
export const myStorage = firebase.storage()
