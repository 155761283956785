/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {IconUserModel} from '../../../../app/modules/profile/ProfileModels'
import {UsersList} from '../../../../app/modules/profile/components/UsersList'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  badgeColor: string
  status: string
  title: string
  description: string
  date: string
  budget: string
  handlrAvatar: string
  handlrName: string
  handlrSkills: string
  users?: Array<IconUserModel>
}

const Card2: FC<Props> = ({
  badgeColor,
  status,
  title,
  description,
  date,
  budget,
  handlrAvatar,
  handlrName,
  handlrSkills,
  users = undefined,
}) => {
  return (
    <Link
      to={'/customer/jobs/details/2'}
      className='card border border-2 border-gray-300 border-hover'
    >
      <div className='card-header border-0 pt-9'>
        <div className='card-title m-0'>
          <div className='symbol symbol-50px w-50px bg-light'>
            <span className={`badge badge-light-${badgeColor} fw-bold me-auto px-4 py-3`}>
              {status}
            </span>
          </div>
        </div>
      </div>

      <div className='card-body p-9'>
        <div className='fs-3 fw-bold text-dark'>{title}</div>

        <p className='text-gray-400 fw-semibold fs-5 mt-1 mb-7'>{description}</p>

        <div className='d-flex flex-wrap mb-5'>
          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
            <div className='fs-6 text-gray-800 fw-bold'>{date}</div>
            <div className='fw-semibold text-gray-400'>Due Date</div>
          </div>

          <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
            <div className='fs-6 text-gray-800 fw-bold'>${budget}</div>
            <div className='fw-semibold text-gray-400'>Quote</div>
          </div>
        </div>

        <div>
          <div className='d-flex align-items-center'>
            <div className='symbol symbol-50px me-3'>
              <img src={toAbsoluteUrl(handlrAvatar)} alt='Pic' />
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <h6 className='text-gray-800 fw-bold text-hover-primary mb-1 fs-6'>{handlrName}</h6>
              <span className='text-gray-400 fw-semibold d-block fs-7'>{handlrSkills}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export {Card2}
