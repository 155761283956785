import {PageLink, PageTitle} from '../../../_handld/layout/core'
import { JobListWrapper } from './jobs-list/JobList'

const jobBreadcrumbs: Array<PageLink> = [
  {
    title: 'Jobs Management',
    path: '/backoffice/jobs-management',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const JobPage = () => {
  return (
    <>
      <PageTitle breadcrumbs={jobBreadcrumbs}>Jobs list</PageTitle>
      <JobListWrapper />
    </>
  )
}

export default JobPage
