import React, {useEffect, useState} from 'react'
import {KTCard, KTSVG, toAbsoluteUrl} from '../../../../_handld/helpers'
import {JobDetailsModal} from './job-details-modal/JobDetailsModal'
import {useParams} from 'react-router-dom'
import QueryFetchPost from '../../../../global/index2'
import ReactPaginate from 'react-paginate'
import './../../handlr-management/handlr-details/style.css'
import {Card} from 'react-bootstrap'
import Select from 'react-select'
import axios from 'axios'
export const Jobs = () => {
  const [jobId, setJobId] = useState<any>()
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(true)
  const params = useParams<any>()
  const {id} = params
  const [data, setdata] = useState<any[]>([])
  const [pagecount, setpagecount] = useState<any>()
  const [dataa, setdataa] = useState<any>()
  const [search, setSearch] = useState<string>('')
  const [statusId, setStatusId] = useState<string>('')
  const [status, setStatus] = useState<any>([])

  const handleGetStatus = () => {
    axios.get(`${BASE_URL}/api/Jobs/GetViewModel?fromHandymanDetails=${true}`).then((res) => {
      const status = res?.data?.status.map((item: any) => {
        return {value: item.id, label: item.name}
      })
      setStatus(status)
    })
  }

  useEffect(() => {
    handleGetStatus()
  }, [])

  const GetJobs = async (id: any) => {
    try {
      setLoading(true)
      await QueryFetchPost({
        link: `${BASE_URL}/api/BackOffice/GetJobs?handlrId=${id}&statusId=${statusId}&search=${search}&pageNumber=${currentPage}&pageSize=${rowsPerPage}`,
        data: undefined,
        method: 'GET',
        key: 'GetJobs',
        signal: undefined,
        isFormData: false,
      }).then((response: any) => {
        setdata(response[1].data)
        setdataa(response[1].pagination)
        setpagecount(response[1].pagination.pageCount)
      })
    } catch (error) {
      // Handle error if needed
    } finally {
      setLoading(false) // End loading
    }
  }
  useEffect(() => {
    if ((loading === false && data === null) || []) {
      setCurrentPage(1)
    }
  }, [rowsPerPage])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      GetJobs(id)
    }, 500)
    return () => clearTimeout(timeOutId)
  }, [id, currentPage, rowsPerPage, search, statusId])

  function handlePageClick({selected: selectedPage}: any) {
    setCurrentPage(selectedPage + 1)
  }

  const handleRowsPerPageChange = (event: any) => {
    const newValue = parseInt(event.target.value)
    setRowsPerPage(newValue)
  }

  return (
    <KTCard className='bg-transparent shadow-none'>
      {loading?
       <div className='d-flex align-items-center justify-content-center h-250px'>
            <h1 className='text-muted'>Loading...</h1>
        </div>:
      <div>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='col-4 mb-8'>
          {/* <label className='mb-3'>Search:</label> */}
          <input
            type='text'
            className='form-control form-control-sm '
            placeholder='Search...'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className='col-4 mb-8'>
          <Select
            placeholder='Filter by'
            options={status}
            value={
              status?.filter(function (option: any) {
                return option.value === statusId
              })[0]
            }
            onChange={(e) => {
              if (e) {
                setStatusId(e.value)
              } else {
                setStatusId('')
              }
            }}
            isClearable={true}
          />
        </div>
      </div>
      {Array.isArray(data) && data.length > 0 ? (
        <div className='row p-2'>
          {data?.map((jobitem: any) => (
            <div
              className='col-xl-6 col-lg-6 col-md-12 mb-5 cursor-pointer'
              key={jobitem?.id}
              onClick={() => setJobId(jobitem?.id)}
            >
              <div className='card h-100 card border border-2 border-gray-300 border-hover'>
                <div className='card-body p-9 '>
                  <div className='d-flex fs-3 fw-bold text-dark justify-content-between align-items-center'>
                    <div>
                      {jobitem?.status === 'Completed' && (
                        <span className='badge badge-light-success fw-bold me-auto px-4 py-3'>
                          Completed
                        </span>
                      )}
                      {jobitem?.status === 'Draft' && (
                        <span className='badge badge-secondary fw-bold me-auto px-4 py-3'>
                          Draft
                        </span>
                      )}
                      {jobitem?.status === 'Open' && (
                        <span className='badge badge-light-primary fw-bold me-auto px-4 py-3'>
                          Open
                        </span>
                      )}
                      {jobitem?.status === 'Booked' && (
                        <span className='badge badge-light-info fw-bold me-auto px-4 py-3'>
                          Booked
                        </span>
                      )}
                      {jobitem?.status === 'In Progress' && (
                        <span className='badge badge-light-progress fw-bold me-auto px-4 py-3'>
                          In Progress
                        </span>
                      )}
                      {jobitem?.status === 'Canceled' && (
                        <span className='badge badge-light-warning fw-bold me-auto px-4 py-3'>
                          Canceled
                        </span>
                      )}
                      {jobitem?.status === 'Closed' && (
                        <span className='badge badge-light-danger fw-bold me-auto px-4 py-3'>
                          Closed
                        </span>
                      )}
                    </div>
                    <div className='d-flex flex-wrap'>
                      <div className='border border-gray-300 border-dashed rounded py-3 px-4 me-7'>
                        <div className='fs-6 text-gray-800 fw-bold'>
                          {jobitem?.rate !== 0 ? (
                            <span>
                              <i className='bi bi-star-fill text-warning' /> {jobitem?.rate}
                            </span>
                          ) : (
                            <span>
                              <i className='bi bi-star text-warning' /> -
                            </span>
                          )}
                        </div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded py-3 px-4'>
                        <div className='fs-6 text-gray-800 fw-bold'>
                          {jobitem.payout === null || jobitem.payout === undefined ? (
                            '-'
                          ) : (
                            <>
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              }).format(jobitem.payout)}{' '}
                              /
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              }).format(jobitem?.price)}
                            </>
                          )}
                          {/*? new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(jobitem?.price):''*/}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3 mb-3'>
                    {jobitem?.name}
                  </div>

                  <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                    {jobitem?.additionalDetails}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <Card className='h-100'>
            <Card.Body>
              <img
                alt='no Result'
                src={toAbsoluteUrl('/media/logos/NoResult.png')}
                className='logo-default w-100 mh-xl-400px mh-lg-400px h-100'
                style={{objectFit: 'contain'}}
              />
              <h1 className='text-muted fw-bold text-center'>No Results Found</h1>
            </Card.Body>
          </Card>
        </>
      )}
      {jobId !== null && jobId !== undefined && (
        <JobDetailsModal closeModal={setJobId} Id={jobId} />
      )}
      <div className='row'>
        <div className='col-sm-10 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start my-5'>
          <span className='me-5 pr-3'>Total rows: {dataa?.totalItemCount}</span>
          <div className='mr-5'>
            {/* Add the dropdown/select for selecting the number of rows */}
            <label className='me-3' htmlFor='rowsPerPageSelect'>
              Rows per page:
            </label>
            <select id='rowsPerPageSelect' value={rowsPerPage} onChange={handleRowsPerPageChange}>
              <option value={10}>10</option>
              <option value={26}>26</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end my-5 '>
          <div id='kt_table_users_paginate'>
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              pageCount={pagecount}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              previousLinkClassName={'pagination__link'}
              nextLinkClassName={'pagination__link'}
              disabledClassName={'pagination__link--disabled'}
              activeClassName={'pagination__link--active'}
            />
          </div>
        </div>
      </div>
      </div>}
    </KTCard>
  )
}
