import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import CheckoutForm from './CheckoutForm'
import {FC} from 'react'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripeKey = process.env.REACT_APP_STRIPE_KEY
const stripePromise = loadStripe(stripeKey as string)

type Props = {
  clientSecret: string
  handleSubmit: (data: any) => void
  billing: any
  formik?: any
}
const Payment: FC<Props> = ({clientSecret , handleSubmit , billing,formik}) => {
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  }

  return (
    <>
      {stripePromise && options?.clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm handleSubmit2={handleSubmit} billing={billing} formik={formik}/>
        </Elements>
      )}
    </>
  )
}
export default Payment
