/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useListView } from '../../core/ListViewProvider'
import { ID, KTIcon } from '../../../../../../_handld/helpers'
import { MenuComponent } from '../../../../../../_handld/assets/ts/components'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { handleApiErrors } from '../../../../../global/HandleApiErrors'
import QueryFetchPost from '../../../../../../global/index2'
import { useQueryResponse } from '../../core/QueryResponseProvider'
type Props = {
  id: ID
}
const BASE_URL = process.env.REACT_APP_BASE_URL
const UserActionsCell: FC<Props> = ({ id }) => {
  const { setItemIdForUpdate, setItemIdForUpdatePassword, itemIdForUpdate, itemIdForUpdatePassword } = useListView()
  const { refetch } = useQueryResponse()
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(id)
  }

  const openResetPasswordModal = () => {
    setItemIdForUpdatePassword?.(id)
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTIcon iconName='down' className='fs-5 m-0 pe-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-175px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Change Status
          </a>
        </div>
        {/* end::Menu item */}
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openResetPasswordModal}>
            Reset Password
          </a>
        </div>
        {/* end::Menu item */}
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <Link to={`/backoffice/handyman-management/details/${id}`} className='menu-link px-3'>
            Details
          </Link>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {/* <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='delete_row'
            onClick={async () => {
              Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
              }).then(async (result) => {
                if (result.isConfirmed) {
                  try {
                    QueryFetchPost({
                      link: `${BASE_URL}/api/BackOffice/DeleteHandler`,
                      data: {
                        id: id
                      },
                      method: 'DELETE',
                      key: 'RegisterEmail',
                      signal: undefined,
                      isFormData: false,
                    }).then((response: any) => {
                      if (response[0] === 200) {
                        Swal.fire({
                          text: 'Handyman has been deleted.',
                          icon: 'success',
                          showConfirmButton: true,
                          timer: 2000,
                          width: '25rem',
                        });
                        refetch()
                      }
                    })


                  } catch (error: any) {
                    // Handle the error here
                    handleApiErrors(error?.response?.data)

                  }

                }
              })
            }}
          >
            Delete
          </a>
        </div> */}
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}

export { UserActionsCell }
