import Swal from "sweetalert2";

export const handleApiErrors = (object: any) => {
  console.log(object)
    if(object.status ===402){

        Swal.fire({
            text: "You have to subscribe first",
            icon: 'error',
            showConfirmButton: true,
            width: '25rem',
        })

       }else{
        if (object.errors) {
            const errorKeys = Object.keys(object.errors);
          console.log(errorKeys)
            if (Array.isArray(object.errors[errorKeys[0]])) {
              // Scenario 1: Errors as an object with arrays of error messages
              for (const key of errorKeys) {
                const errorMessages = object.errors[key];
                console.log(key)
                console.log(errorMessages)
                if (Array.isArray(errorMessages) && errorMessages.length > 0) {
                  for (const errorMessage of errorMessages) {
                    console.log(errorMessage);
       
                    Swal.fire({
                      text: errorMessage,
                      icon: "error",
                      showConfirmButton: true,
                      width: "25rem",
                    });
                  }
                }
              }
            } else {
              // Scenario 2: Errors as an array of objects with code and description
              for (const error of object.errors) {
                if (error.description) {
                  console.log(error.description);
          
                  Swal.fire({
                    text: error.description,
                    icon: "error",
                    showConfirmButton: true,
                    width: "25rem",
                  });
                }
              }
            }
          }
        else if (object.message) {
            Swal.fire({
                text: object.message,
                icon: 'error',
                showConfirmButton: true,
                width: '25rem',
            })
        }
        else if (object.title) {
            Swal.fire({
                text: object.title,
                icon: 'error',
                showConfirmButton: true,
                width: '25rem',
            })
        }
        else{
            Swal.fire({
                text: object,
                icon: 'error',
                showConfirmButton: true,
                width: '25rem',
            })
        }
    }
  
}