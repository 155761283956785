import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { Formik, useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import { Spinner } from 'react-bootstrap'
import Swal from 'sweetalert2'
import QueryFetchPost, { ModelStateMessage } from '../../../../global/index2'
import { toAbsoluteUrl } from '../../../../_handld/helpers'
import SVG from 'react-inlinesvg'
var url2 = window.location.search;
let param = url2?.replace("?code=", "");
let token = param?.split(",,,")[0];
var email = param?.split(",,,")[1];

const BASE_URL=process.env.REACT_APP_BASE_URL;
interface Model {
  password: string,
  confirmPassword: string
  email: string,
  token: string,
}
const initialValues = {
  email: email,
  password: "",
  token: token,
  confirmPassword: "",
}

const forgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    )
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
    .required("Confirm Password is required"),
})

export function Resetpassword() {
  const [loading, setLoading] = useState(false)
  const [isShowPassword, setShowPassword] = useState(false)
  const [isShowCOPassword, setShowCOPassword] = useState(false)
const navigate=useNavigate();
const handleSubmit = async (values: Model) => {
    setLoading(true)
    try {
      await QueryFetchPost({
        link: `${BASE_URL}/api/Account/ResetPassword`,
        data: values,
        method: "POST",
        key: 'RessetPassword',
        signal: undefined,
        isFormData: false
      }).then((response) => {
        if (response[0] === 200) {
          Swal.fire({
            icon: 'success',
            title: 'success',
            text: 'Password reset successfully',
          })
          setTimeout(() => {
            navigate('/auth/login', {replace: true})
          }, 150);
          // setLoading(false);

          setLoading(false)
        }
        else {
          ModelStateMessage(response)
          setLoading(false)
        }
      });


    } catch (error) {
      Swal.fire('Error', 'An error occurred. please try again later.', 'error');
    }
  };
  return (
    <Formik<Model>
      validationSchema={forgotPasswordSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        handleSubmit(values)
      }}
    >
      {({
        handleSubmit,
        values,
        handleChange,
        handleBlur,
        setFieldValue,
        isSubmitting,
        isValid,
        touched,
        errors,
      }) => {
        return (<form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_password_reset_form'
          onSubmit={handleSubmit}
        >
          <div className='text-center mb-10'>
            <h1 className='text-dark fw-bolder mb-3'>Reset Password</h1>
          </div>
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6 required'>Password</label>
            <div className='input-group input-group-solid mb-5'>
              <input
                type={isShowPassword ? 'text' : 'password'}
                id='password'
                placeholder='Password'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                className={clsx(
                  'form-control bg-transparent',
                  { 'is-invalid': touched.password && errors.password },
                  {
                    'is-valid': touched.password && !errors.password,
                  }
                )}
              />
              <span className='input-group-text align-self-center cursor-pointer' onClick={(e) => setShowPassword(!isShowPassword)}>
                {!isShowPassword ? (
                  <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                ) : (
                  <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                )}
              </span>
            </div>
            {touched.password && errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.password}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-gray-900 fs-6 required'>Confirm Password</label>
            <div className='input-group input-group-solid mb-5'>
            <input
              type={isShowCOPassword ? 'text' : 'password'}
              id='confirmPassword'
              placeholder='Confirm Password'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.confirmPassword}
              className={clsx(
                'form-control bg-transparent',
                { 'is-invalid': touched.confirmPassword && errors.confirmPassword },
                {
                  'is-valid': touched.confirmPassword && !errors.confirmPassword,
                }
              )}
            />
            <span className='input-group-text align-self-center cursor-pointer' onClick={(e) => setShowCOPassword(!isShowCOPassword)}>
              {!isShowCOPassword ? (
                <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
              ) : (
                <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
              )}
            </span>
            </div>
            {touched.confirmPassword && errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
              <span className='indicator-label'>Submit</span>
              {loading && (
                <Spinner
                  as='span'
                  animation='border'
                  className='mb-1 ml-5'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_password_reset_form_cancel_button'
                className='btn btn-light'
                disabled={isSubmitting || !isValid}
              >
                Cancel
              </button>
            </Link>{' '}
          </div>
          {/* end::Form group */}
        </form>

        )
      }
      }
    </Formik>)
}
