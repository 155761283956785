import axios from 'axios'
import React, {FC, useEffect} from 'react'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'

const API_URL = process.env.REACT_APP_API_URL
type Props = {
  setStep: (step: number) => void
  jobId?: any
  jobs?: any
  address?: any
  quote?: any
  setQuote: (quote: any) => void
  isFirst: boolean
  setIsFirst: (isFirst: boolean) => void
}
const Quotes: FC<Props> = ({
  setStep,
  jobId,
  jobs,
  address,
  quote,
  setQuote,
  isFirst,
  setIsFirst,
}) => {
  const navigate = useNavigate()
  const handleGetQuotes = async (data: any) => {
    try {
      await axios.post(`${API_URL}/api/Jobs/GenerateQuote`, data).then((res) => {
        setQuote(res?.data)
        console.log('quote', res?.data)
      })
    } catch (error: any) {
      Swal.fire({
        text: error.response.data,
        icon: 'error',
        showConfirmButton: true,
        timer: 2000,
        width: '25rem',
      })
    } finally {
      console.log()
    }
  }
  useEffect(() => {
    if (isFirst) {
      handleGetQuotes({
        id: jobId,
        email: address?.email,
      })
      setIsFirst(false)
    }
  }, [jobId])

  return (
    <div className='row shadow'>
      <div className='col-xl-8 col-lg-8 col-md-12'>
        <div className='card'>
          <div className='card-body p-lg-20'>
            <div className='d-flex flex-column flex-xl-row'>
              <div className='flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0'>
                <div className='mt-n1'>
                  <div className='d-flex flex-stack pb-10'>
                    <div className='fw-bold fs-3 text-gray-800 mb-8'>
                      Quote #{quote?.id && quote?.id}
                    </div>
                  </div>
                  <div className='m-0'>
                    <div className='row g-5 mb-11'>
                      <div className='col-sm-6'>
                        <div className='fw-semibold fs-7 text-gray-600 mb-1'>Date:</div>
                        <div className='fw-bold fs-6 text-gray-800'>
                          {new Date().toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                          })}
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='fw-semibold fs-7 text-gray-600 mb-1'>Expire Date:</div>
                        <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap'>
                          <span className='pe-2'>
                            {new Date(
                              new Date().setDate(new Date().getDate() + 1)
                            ).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric',
                            })}
                          </span>
                          {/* <span className='fs-7 text-danger d-flex align-items-center'>
                            <span className='bullet bullet-dot bg-danger me-2' />
                            Expire in 1 day
                          </span> */}
                        </div>
                      </div>
                    </div>
                    <div className='row g-5 mb-12'>
                      <div className='col-sm-6'>
                        <div className='fw-semibold fs-7 text-gray-600 mb-1'>Location:</div>
                        <div className='fw-bold fs-6'>
                          <div className=''>
                            <p className='text-muted'>
                              {address?.address1}
                              <br />
                              {address.address2} {address.address2 && <br />} {address.city},{' '}
                              {address.state}, {address.zipcode}, {address.country}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='flex-grow-1 mb-5 pb-5'>
                      <div className='table-responsive border-bottom mb-9'>
                        <table className='table mb-3'>
                          <thead>
                            <tr className='border-bottom fs-6 fw-bold text-muted'>
                              <th className='min-w-175px pb-2'>Items</th>
                            </tr>
                          </thead>
                          <tbody>
                            {jobs?.map((job: any, index: any) => (
                              <tr className='fw-bold text-gray-700 fs-5 text-end' key={job.index}>
                                <td className='d-flex align-items-center pt-6'>
                                  {job?.serviceName}{' '}
                                  {job?.serviceCategoryName && <i className='fa-solid fa-arrow-right-long text-primary mx-3'></i>}{' '}
                                  {job?.serviceCategoryName}{' '}
                                  {job?.serviceTypeCategoryName && (
                                    <i className='fa-solid fa-arrow-right-long text-primary mx-3'></i>
                                  )}
                                  {job?.serviceTypeCategoryName}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <div className='mw-300px'>
                          <div className='d-flex flex-stack'>
                            <div className='fw-semibold pe-10 text-gray-600 fs-7'>Total</div>
                            {quote?.price && (
                              <div className='text-end fw-bold fs-6 text-gray-800'>
                                {new Intl.NumberFormat('en-US', {
                                  style: 'currency',
                                  currency: 'USD',
                                }).format(quote?.price)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className='fw-bold fs-5 text-gray-600 mb-1'>Note:</div>
                      <div className='fw-semibold fs-6 mb-5'>
                        The total amount payable includes the applicable Harmonized Sales Tax (HST)
                        based on your location.
                      </div>
                      <div className='fw-semibold fs-6'>
                        If you would like to book, please press 'Confirm'.
                      </div>
                    </div>

                    <div className='d-flex justify-content-between my-5 py-5'>
                      <button
                        onClick={() => {
                          document.getElementById('post-job-cancel-btn')?.click()
                          navigate('/customer/global-jobs')
                        }}
                        className='btn btn-sm btn-secondary px-5'
                      >
                        Cancel
                      </button>
                      <button onClick={() => setStep(4)} className='btn btn-sm btn-primary px-5'>
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Quotes
