import {useState} from 'react'
import {useAuth} from '../../../../app/modules/auth'
import PostJob from '../../../../app/modules/post-job/PostJob'
import {MenuItem} from './MenuItem'
import {useIntl} from 'react-intl'
import {Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import axios from 'axios'
import PostJobWrapper from '../../../../app/modules/post-job/PostJobWrapper'

export function MenuInner() {
  const intl = useIntl()
  const {currentUser} = useAuth()
  const {pathname} = window.location

  const handleCheckBuilVersion = async () => {
    const version = process.env.REACT_APP_VERSION
    const API_URL = process.env.REACT_APP_API_URL
    const res = await axios.get(`${API_URL}/api/Generic/AppVersion`)
    if (window.location.hostname !== 'localhost') {
      if (res.data !== version) {
        Swal.fire({
          text: 'New build version released. clearing cache now ...',
          icon: 'info',
          showConfirmButton: true,
          timer: 3000,
          width: '35rem',
        })
        setTimeout(function () {
          if (caches) {
            caches.keys().then((names) => {
              for (const name of names) {
                caches.delete(name)
              }
            })
          }
          window.location.reload()
        }, 3000)
      }
    }
  }

  return (
    <>
      {currentUser ? (
        <>
          {!pathname.includes('backoffice') ? (
            <>
              <button
                onClick={() => {
                  document.getElementById('post-job-btn')?.click()
                  document.getElementById('handleGetProfile')?.click()
                  handleCheckBuilVersion()
                }}
                className='btn btn-primary rounded-pill btn-sm mx-3 my-3'
              >
                Book a Handyman
              </button>
              <MenuItem title='My jobs' to='/customer/global-jobs' />
            </>
          ) : (
            <>
              <MenuItem
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                to='/backoffice/dashboard'
              />
              <MenuItem title='Quotes' to='/backoffice/Quotes-management' />
              <MenuItem title='Jobs' to='/backoffice/jobs-management' />
              <MenuItem title='Customers' to='/backoffice/customers-management' />
              <MenuItem title='Handymen' to='/backoffice/handyman-management' />
            </>
          )}
        </>
      ) : (
        <>
          <button
            onClick={() => {
              document.getElementById('post-job-btn')?.click()
              document.getElementById('handleGetProfile')?.click()
              handleCheckBuilVersion()
            }}
            className='btn btn-primary rounded-pill btn-sm mx-3 my-3'
          >
            Book a Handyman
          </button>
          <Link
            onClick={() => {
              handleCheckBuilVersion()
            }}
            to='/become-a-handyman'
            className='btn btn-light-primary rounded-pill btn-sm mx-3 my-3'
          >
            Become a Handyman
          </Link>
          <MenuItem title='Login' to='/auth/login' />
          <MenuItem title='Sign up' to='/auth/registration' />
        </>
      )}
      <PostJobWrapper />
    </>
  )
}
