/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../../_handld/layout/core'
import axios from 'axios'
import ReactPaginate from 'react-paginate'
import {Link} from 'react-router-dom'
import Select from 'react-select'
import {Card, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../../_handld/helpers'
import { getFromatdate } from '../../../../_handld/helpers/GlobalHelpers'
import moment from 'moment'
import SVG from "react-inlinesvg";

const API_URL = process.env.REACT_APP_API_URL

export function GlobalJobs() {
  const GlobaljobsBreadcrumbs = [
    {
      title: 'Global Jobs',
      path: '/customer/global-jobs',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  const [myJobs, setMyJob] = useState<any>([])
  const [pagination, setPagination] = useState<any>([])
  const [status, setStatus] = useState<any>([])
  const [statusId, setStatusId] = useState<any>('')
  const [search, setSearch] = useState<any>('')
  const [pageNumber, setPageNumber] = useState<any>(1)
  const [pageSize, setPageSize] = useState<any>(12)
  const [isLoading, setIsLoading] = useState<any>(true)

  const handleGetJobs = () => {
    setIsLoading(true)
    axios
      .get(
        `${API_URL}/api/Jobs/Groups?statusId=${statusId}&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}&sortOrder=desc`
      )
      .then((res) => {
        setMyJob(res.data.groups)
        setPagination(res?.data?.pagination)
        setIsLoading(false)
      })
  }

  const handleGetStatus = () => {
    axios.get(`${API_URL}/api/Jobs/GetViewModel`).then((res) => {
      const status = res?.data?.status.map((item: any) => {
        return {value: item.id, label: item.name}
      })
      setStatus(status)
    })
  }
  useEffect(() => {
    handleGetStatus()
  }, [])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      handleGetJobs()
    }, 500)

    // Clean up the timeout on unmount
    return () => clearTimeout(timeOutId)
  }, [search, pageNumber, pageSize, statusId])

  // useEffect(() => {
  //   handleGetJobs()
  // }, [pageNumber])

  const PER_PAGE = pageSize
  function handlePageClick({selected: selectedPage}: any) {
    setPageNumber(selectedPage + 1)
  }
  const pageCount = Math.ceil(pagination?.totalItemCount / PER_PAGE)

  const handleStatus = (
    jobCount: any,
    hasCompleted: any,
    isClosed: any,
    status: any,
    oneStatus?: boolean
  ) => {
    if (jobCount > 0) {
      if (hasCompleted) {
        return 'Completed'
      } else {
        if (isClosed) {
          return 'Closed'
        } else {
          if (oneStatus == true) {
            return status
          }
          return 'Processing...'
        }
      }
    } else {
      return status
    }
  }
  const handleBadge = (jobCount: any, hasCompleted: any, isClosed: any, status: any) => {
    if (jobCount > 0) {
      if (hasCompleted) {
        return 'success'
      } else {
        if (isClosed) {
          return 'danger'
        } else {
          if (status === 'Draft') {
            return 'secondary'
          } else if (status === 'Processing') {
            return 'processing'
          } else if (status === 'Open') {
            return 'primary'
          } else if (status === 'Canceled') {
            return 'warning'
          } else if (status === 'Completed') {
            return 'success'
          } else if (status === 'Closed') {
            return 'danger'
          }
        }
      }
    } else {
      if (status === 'Open') {
        return 'primary'
      }
      if (status === 'Closed') {
        return 'danger'
      }
      if (status === 'Completed') {
        return 'success'
      }
      if (status === 'Canceled') {
        return 'warning'
      }
      if (status === 'Processing') {
        return 'processing'
      }
      if (status === 'In Progress') {
        return 'progress'
      }
      if (status === 'Draft') {
        return 'secondary'
      }
      if (status === 'Booked') {
        return 'info'
      }
    }
  }

  const formatDate = (date: any) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })
  }
  return (
    <>
      <button
        hidden
        id='refresh-jobs-btn'
        onClick={handleGetJobs}
        type='button'
        className='btn btn-primary rounded-pill btn-sm me-3'
      >
        Refresh Job
      </button>
      <PageTitle breadcrumbs={GlobaljobsBreadcrumbs}> Global Jobs</PageTitle>
      <div className='d-flex justify-content-between align-items-center'>
        <div className='col-md-2 mb-8'>
          {/* <label className='mb-3'>Search:</label> */}
          <input
            type='text'
            className='form-control form-control-sm '
            placeholder='Search...'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className='col-md-2 mb-8'>
          <Select
            placeholder='Filter by'
            options={status}
            value={
              status?.filter(function (option: any) {
                return option.value === statusId
              })[0]
            }
            onChange={(e) => {
              if (e) {
                setStatusId(e.value)
              } else {
                setStatusId('')
              }
            }}
            isClearable={true}
          />
        </div>
      </div>

      <div className='row g-6 g-xl-9'>
        {!isLoading &&
          myJobs?.map((group: any, index: any) => {
            return (
              <div className='col-md-6 col-xl-4' key={index}>
                <Link
                  to={`/customer/jobs/details/${group?.id}`}
                  className='card border border-2 border-gray-300 border-hover h-100'
                >
                  <div className='card-header border-0 pt-9'>
                    <div className='card-title m-0'>
                      <div className='symbol symbol-50px w-50px bg-light'>
                        <span
                          className={`badge badge-${handleBadge(
                            group?.jobsCount,
                            group?.hasCompleted,
                            group?.isClosed,
                            group?.status
                          )} fw-bold me-auto px-4 py-3`}
                        >
                          {handleStatus(
                            group?.jobsCount,
                            group?.hasCompleted,
                            group?.isClosed,
                            group?.status,
                            group?.oneStatus
                          )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className='card-body px-9'>
                    <div className='fs-3 fw-bolder text-dark d-flex align-items-center'>
                      <span className='me-5'>{group?.service}</span>{' '}
                      {group?.jobsCount > 0 && (
                        <span className='badge badge-success'> + {group?.jobsCount}</span>
                      )}
                    </div>
                    <div className='d-flex flex-wrap my-5'>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                      <div className="d-flex align-items-center">
                          <div className='fs-6 text-gray-800 fw-bold'>
                            {group?.created ? moment(group?.created).format(getFromatdate()!) : '---'}
                          </div>
                          <div className="font-weight-bolder">
                            <OverlayTrigger
                              key={"right"}
                              placement={"right"}
                              overlay={
                                <Tooltip id={`tooltip-${"placement"}`}>
                                  {getFromatdate()}
                                </Tooltip>
                              }
                            >
                              <span
                                style={{ cursor: "pointer" }}
                                className="svg-icon menu-icon svg-icon-info"
                              >
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/Code/Info-circle.svg"
                                  )}
                                />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </div>
                        <div className='fw-semibold text-gray-400'>Created date</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                        <div className='fs-6 text-gray-800 fw-bold'>
                          {new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency: 'USD',
                          }).format(group?.price || 0)}
                        </div>
                        <div className='fw-semibold text-gray-400'>Total</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
        {isLoading && (
          <div className='d-flex align-items-center justify-content-center h-250px'>
            <h1 className='text-muted'>Loading...</h1>
          </div>
        )}
        {!isLoading && myJobs?.length === 0 && (
          <Col xl='12' lg='12'>
            <Card className='h-100'>
              <Card.Body>
                <img
                  alt='no Jobs Found'
                  src={toAbsoluteUrl('/media/logos/NoResult.png')}
                  className='logo-default w-100 mh-xl-300px mh-lg-300px h-100'
                  style={{objectFit: 'contain'}}
                />
                <h1 className='text-muted fw-bold text-center'>No Jobs Found</h1>
              </Card.Body>
            </Card>
          </Col>
        )}
      </div>

      {myJobs?.length > 0 && (
        <div className='d-flex flex-stack flex-wrap pt-10'>
          <div className='dataTables_length'>
            <label>
              <select
                className='form-select form-select-sm'
                onChange={(e) => {
                  setPageSize(e.target.value)
                  setPageNumber(1)
                }}
              >
                <option value={12}>12</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </label>
          </div>

          <ReactPaginate
            previousLabel={'Previous'}
            nextLabel={'Next'}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            previousLinkClassName={'pagination__link'}
            nextLinkClassName={'pagination__link'}
            disabledClassName={'pagination__link--disabled'}
            activeClassName={'pagination__link--active'}
          />
        </div>
      )}
    </>
  )
}
