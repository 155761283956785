/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import SVG from 'react-inlinesvg'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import {Link, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {PasswordMeterComponent} from '../../../../_handld/assets/ts/components'
import {toAbsoluteUrl} from '../../../../_handld/helpers'
import QueryFetchPost, {ModelStateMessage} from '../../../../global/index2'
import {handleApiErrors} from '../../../global/HandleApiErrors'
import {Button, Modal} from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'

const BASE_URL = process.env.REACT_APP_BASE_URL
const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  phoneNumber: '',
  password: '',
  confirmPassword: '',
  acceptTerms: false,
}
type initialValues = {
  firstname: string
  lastname: string
  email: string
  phoneNumber: string
  password: string
  confirmPassword: string
  acceptTerms: boolean
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: true,
}
export function Registration() {
  const [loading, setLoading] = useState(false)
  const [isShowPassword, setShowPassword] = useState(false)
  const [isShowCPassword, setShowCPassword] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState('ca') // Initialize with 'ca'
  const [company, setcompany] = useState('')
  const navigate = useNavigate()
  const Registration = async (credentials: typeof initialValues) => {
    setLoading(true)

    await QueryFetchPost({
      link: `${BASE_URL}/api/Account/RegisterClient`,
      data: {
        firstName: credentials.firstname,
        lastName: credentials.lastname,
        email: credentials.email,
        password : credentials.password,
        confirmPassword : credentials.confirmPassword,
        phoneNumber:credentials.phoneNumber,
        phoneNumberPrefix:selectedCountry,
      },
      method: 'POST',
      key: 'RegisterEmail',
      signal: undefined,
      isFormData: false,
    }).then((response: any) => {
      if (response[0] === 200) {
        setLoading(false)
        navigate('/auth/activateaccount', {replace: true})
      } else {
        setLoading(false)
        ModelStateMessage(response)
      }
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      Registration(values)
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])
  const [accepteTerms, setAccepteTerms] = useState(false)
  const [show, setShow] = useState(false)
  const handleShow = () => setShow(true)
  const handleClose = () => setShow(false)
  const [showPrivacy, setShowPrivacy] = useState(false)
  const handleShowPrivacy = () => setShowPrivacy(true)
  const handleClosePrivacy = () => setShowPrivacy(false)
  const handleCheckboxChange = () => {
    setAccepteTerms(!accepteTerms)
    formik.setFieldValue('acceptTerms', !accepteTerms)
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} className='xl '>
        <Modal.Header closeButton className='py-4 px-3'>
          <Modal.Title className='ml-10 h3'>Terms of service</Modal.Title>
        </Modal.Header>

        <Modal.Body className='p-0'>
          <div className='login-form login-signin' style={{display: 'block'}}>
            <div className='card card-custom card-stretch' id='kt_page_stretched_card'>
              <PerfectScrollbar
                options={perfectScrollbarOptions}
                className='scroll px-5'
                style={{maxHeight: '50vh', overflow: 'hidden'}}
              >
                <div className='card-body px-3'>
                  <>
                    <div className='rich-text-block-2 w-richtext'>
                      <p>
                        <strong>Welcome to Handyman.</strong>
                      </p>
                      <p>Terms of Service | Independent Contractor Agreement | Privacy Policy</p>
                      <p>
                        <strong>ACCEPTANCE OF TERMS:</strong> These Terms of Service constitute an
                        agreement that is legally binding between you and Handyman Inc. (“Company” or
                          Handyman) administering your use of the Handyman Platform (as defined below),
                        Company’s website (<a href='https://www.gohandyman.ca'>https://www.gohandyman.ca</a>
                        ). The Handyman Inc. Platform and all related services provided by Company,
                        and the website, including without limitation communications and information
                        (whether in writing, orally, or other means) provided by Company to
                        potential and current Handyman’s and Clients (both as defined below) in
                        relation to the services, are together hereinafter collectively referred to
                        as the Handyman Platform.” Personal data that you submit, or that we collect
                        about you is governed by the Handyman Privacy Policy (“Privacy Policy”). By
                        using the Handyman Platform, you have acknowledged that you have reviewed the
                        Privacy Policy. Your use of the Handyman Platform constitutes your acceptance
                        of and agreement to all the terms and conditions in these Terms of Service,
                        Privacy Policy, Independent Contractor Agreement, and any future amendments
                        and additions to this Agreement (as defined below) as we may update from
                        time to time.
                      </p>
                      <p>
                        BY ENTERING INTO THIS AGREEMENT, YOU CLEARLY AND EXPRESSLY ACKNOWLEDGE THAT
                        YOU HAVE READ, UNDERSTAND, AND AGREE, WITHOUT LIMITATION OR QUALIFICATION,
                        TO BE BOUND BY THIS AGREEMENT. IF YOU DO NOT AGREE TO BE BOUND BY THIS
                        AGREEMENT, YOU MAY NOT USE OR ACCESS THE HANDYMAN PLATFORM. You must be at
                        least 18 years of age and capable and competent in entering into binding
                        contracts in order to use and access the Handyman Platform. If you are under
                        the age of 18, you may not use or access the Handyman Platform.
                      </p>
                      <p>
                        <strong>ACKNOWLEDGMENTS :</strong>
                      </p>
                      <p>
                        User hereby acknowledges that:
                        <ul>
                          <li>
                            Your agreement that Handyman’s are not employees of the Handyman Platform.
                            Handyman’s are their own independent contractors. (See Independent
                            Contractor Agreement)
                          </li>
                          <li>
                            Your acknowledgement and agreement that Clients and not Handyman,
                            supervise and monitor a Handyman’s work.
                          </li>
                          <li>
                            Your acknowledgment that it is the responsibility of Client and Handyman
                            to notify (via the Handyman Platform) when a job is completed.
                          </li>
                          <li>
                            Your acknowledgement in becoming a Handyman that you are capable and
                            competent to meet the needs in each job you accept.
                          </li>
                          <li>
                            Your acknowledgement as a Client that it is your responsibility to
                            ensure a safe and adequate working area for Handyman.
                          </li>
                          <li>
                            Your acknowledgement of any disputes to be resolved on a case by case
                            basis by Handyman Inc.
                          </li>
                        </ul>
                      </p>
                      <p>
                        <strong>DESCRIPTION OF SERVICES:</strong> Handyman Inc is an online
                        communications platform which provides an avenue for Clients to connect with
                        Handyman’s. “Clients” are individuals and/or businesses looking to hire a
                        Handyman to pick-up, deliver, and assemble their product(s). “Handyman’s” are
                        individuals who possess the ability and the means to pick-up, deliver, and
                        assemble a customer’s product(s). Hereinafter, unless otherwise specified,
                        both Clients and Handyman’s will be referred to as Users. Once the terms of a
                        job are accepted with another User, both Users form a Service Agreement
                        directly between the two.
                      </p>
                      <p>
                        <strong>USER VETTING AND REPRESENTATIONS:</strong>
                      </p>
                      <p>
                        In the sole discretion of Handyman Inc, Users may be subjected to an extensive
                        vetting process before they can use the Handyman Platform. Vetting process may
                        include, but not limited to police background checks, employment history,
                        and related work experience. Although Handyman Inc confirms the identity of
                        each Handyman upon registration, Handyman cannot confirm that each User who
                        responds to a job is who they claim to be. Any person who agrees to and
                        responds to a job in the guise of registered Handyman, will be prosecuted to
                        the fullest extent of the law. It is the sole responsibility of the Client
                        to confirm that the Handyman is who they say they are. Any interaction with
                        other Users requires the need to exercise caution and common sense, in order
                        to protect personal safety, data, and property. Handyman will not be liable
                        for any false or misleading statements made by Users on the Handyman Platform.
                      </p>
                      <p>
                        <strong>DISCLAIMER OF WARRANTY; LIMITATION OF LIABILITY:</strong>
                      </p>
                      <ul role='list'>
                        <li>
                          USER EXPRESSLY AGREES THAT USE OF{' '}
                          <a href='https://www.gohandyman.ca'>https://www.gohandyman.ca</a> IS AT USER'S
                          SOLE RISK. NEITHER HANDLD , ITS AFFILIATES NOR ANY OF THEIR RESPECTIVE
                          EMPLOYEES, AGENTS, THIRD PARTY CONTENT PROVIDERS OR LICENSORS WARRANT THAT
                          <a href='https://www.gohandyman.ca'> https://www.gohandyman.ca</a> WILL BE
                          UNINTERRUPTED OR ERROR FREE; NOR DO THEY MAKE ANY WARRANTY AS TO THE
                          RESULTS THAT MAY BE OBTAINED FROM USE OF
                          <a href='https://www.gohandyman.ca'> https://www.gohandyman.ca</a>, OR AS TO THE
                          ACCURACY, RELIABILITY OR CONTENT OF ANY INFORMATION, SERVICE, OR
                          MERCHANDISE PROVIDED THROUGH
                          <a href='https://www.gohandyman.ca'> https://www.gohandyman.ca</a>
                        </li>
                        <li>
                          <a href='https://www.gohandyman.ca'> https://www.gohandyman.ca</a> IS PROVIDED ON
                          AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
                          IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED
                          WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OTHER
                          THAN THOSE WARRANTIES WHICH ARE IMPLIED BY AND INCAPABLE OF EXCLUSION,
                          RESTRICTION OR MODIFICATION UNDER THE LAWS APPLICABLE TO THIS AGREEMENT.
                        </li>
                        <li>
                          THIS DISCLAIMER OF LIABILITY APPLIES TO ANY DAMAGES OR INJURY CAUSED BY
                          ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION,
                          DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION
                          LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO
                          <a href='https://www.gohandyman.ca'> https://www.gohandyman.ca</a>, ALTERATION OF,
                          OR USE OF RECORD, WHETHER FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR,
                          NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION. USER SPECIFICALLY
                          ACKNOWLEDGES THAT HANDYMAN IS NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE OR
                          ILLEGAL CONDUCT OF OTHER USERS OR THIRD-PARTIES AND THAT THE RISK OF
                          INJURY FROM THE FOREGOING RESTS ENTIRELY WITH USER.
                        </li>
                      </ul>
                      <h5>6. REPRESENTATIONS AND WARRANTIES</h5>
                      <p>User represents and acknowledges that:</p>
                      <ul>
                        <li>s/he is over the age of 18.</li>
                        <li>s/he is legally eligible to work in Canada.</li>
                        <li>s/he is capable of entering into binding contracts.</li>
                        <li>
                          User has the capability, ability, and the means to perform the agreed upon
                          duties and requirements in each job user accept.
                        </li>
                        <li>
                          Users will not be inebriated by any substance when fulfilling the
                          requirements and duties of the agreed upon job.
                        </li>
                        <li>
                          Where user enters into this Agreement on behalf of a company or other
                          organization, user warrants that s/he has authority to act on behalf of
                          that entity, and to bind that entity to this Agreement.
                        </li>
                        <li>
                          User further represents and warrants that user has read, understand, and
                          agrees to be bound by these Terms of Service, and Privacy Policy in order
                          to register, use, and access the Handyman Platform.
                        </li>
                        <li>
                          User hereby warrant and represent that user will respect the privacy,
                          property, and data protection rights of Users in respect to the
                          information provided on job detail agreements. Users will not save
                          information of other Users.
                        </li>
                        <li>
                          User warrants and represents that s/he will act professionally and
                          responsibly in user interactions with other Users.
                        </li>
                        <li>
                          User further represents and acknowledges that when using and accessing the
                          Handyman Platform, user will act in accordance to applicable Municipal,
                          Provincial, and Federal laws, and always in good faith.
                        </li>
                        <li>
                          User hereby warrants and represents that, other than as fully and promptly
                          disclosed to Handyman Inc as set forth below, users does not have any
                          motivation, status, or interest which Handyman may reasonably wish to know
                          about in connection with the Handyman Platform, including without
                          limitation, if user are using or will or intend to use the Handyman Platform
                          for any journalistic, investigative, or unlawful purpose.
                        </li>
                        <li>
                          User hereby warrant and represent that user will promptly disclose to
                          Handyman in writing any such motivation, status, or interest, whether
                          existing prior to registration or as arises during user use of the Handyman
                          Platform.
                        </li>
                        <li>
                          In addition, user represents and warrants that user will provide timely,
                          high-quality services to Handyman Clients.
                        </li>
                        <li>
                          User will only accept jobs that user have the availability, capability,
                          means, skills and expertise to complete jobs in a professional,
                          high-quality manner.
                        </li>
                        <li>
                          User also represents and warrants that s/he will provide the services
                          safely and in accordance with all applicable laws, including but not
                          limited to being in a sober state of mind upon arrival, and possessing
                          legal eligibility to work in Canada.
                        </li>
                      </ul>
                    </div>
                  </>
                </div>
              </PerfectScrollbar>
              <div className='card-footer'>
                <div className='form-group'>
                  <label className='form-check form-check-inline'>
                    <input
                      className={clsx(
                        'form-check-input me-3',
                        {'is-invalid': formik.touched.acceptTerms && formik.errors.acceptTerms},
                        {
                          '': formik.touched.acceptTerms && !formik.errors.acceptTerms,
                        }
                      )}
                      type='checkbox'
                      checked={accepteTerms}
                      onChange={handleCheckboxChange}
                    />
                    <span />I agree to the Terms &amp; Conditions
                  </label>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className='py-2'>
          <Button variant='link' onClick={handleClose} size='sm'>
            {'Close'}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showPrivacy} onHide={handleClosePrivacy} className='xl '>
        <Modal.Header closeButton className='py-4 px-3'>
          <Modal.Title className='ml-10 h3'>Privacy Policy</Modal.Title>
        </Modal.Header>

        <Modal.Body className='p-0'>
          <div className='login-form login-signin' style={{display: 'block'}}>
            <div className='card card-custom card-stretch' id='kt_page_stretched_card'>
              <PerfectScrollbar
                options={perfectScrollbarOptions}
                className='scroll px-5'
                style={{maxHeight: '60vh', overflow: 'hidden'}}
              >
                <div className='card-body px-3'>
                  <>
                    <div className='rich-text-block-2 w-richtext'>
                      <p>
                        <strong>Privacy Policy</strong>
                      </p>
                      <p>Effective date: [Insert Effective Date]</p>
                      <p>
                        This Privacy Policy describes how Handyman Inc. ("Company" or "Handyman")
                        collects, uses, and shares personal information when you use the Handyman
                        Platform (defined below) or visit our website at{' '}
                        <a href='https://www.gohandyman.ca'>https://www.gohandyman.ca</a>. By using the
                        Handyman Platform, you agree to the practices described in this policy.
                      </p>
                      <p>
                        <strong>Information We Collect</strong>
                      </p>
                      <p>We may collect and process the following information:</p>
                      <ul>
                        <li>
                          Your contact information, including name, email address, and phone number.
                        </li>
                        <li>
                          Information you provide when creating an account, including your username
                          and password.
                        </li>
                        <li>
                          Transaction information related to your use of the Handyman Platform,
                          including details about the jobs you complete or request, payment
                          information, and billing details.
                        </li>
                        <li>
                          Information related to your communications with Handyman and other users
                          through the Handyman Platform, including messages, chat transcripts, and
                          support requests.
                        </li>
                        <li>
                          Information collected through cookies and similar tracking technologies.
                        </li>
                        <li>
                          Location information, which may be collected through your device's GPS or
                          other location-based technologies when you use the Handyman mobile
                          application.
                        </li>
                        <li>
                          Information from third-party services, such as background checks, identity
                          verification services, and public databases.
                        </li>
                      </ul>
                      <p>
                        <strong>How We Use Your Information</strong>
                      </p>
                      <p>We may use your personal information for the following purposes:</p>
                      <ul>
                        <li>
                          To provide, maintain, and improve the Handyman Platform and our services.
                        </li>
                        <li>To facilitate job requests, job completion, and payments.</li>
                        <li>
                          To communicate with you, respond to your inquiries, and provide customer
                          support.
                        </li>
                        <li>To send you important notices, updates, and promotional materials.</li>
                        <li>
                          To verify your identity, conduct background checks, and ensure compliance
                          with our policies and legal requirements.
                        </li>
                        <li>
                          To monitor and analyze usage patterns, perform data analytics, and conduct
                          research.
                        </li>
                        <li>To detect and prevent fraud, abuse, and security incidents.</li>
                        <li>To comply with applicable laws and regulations.</li>
                      </ul>
                      <p>
                        <strong>How We Share Your Information</strong>
                      </p>
                      <p>We may share your personal information with:</p>
                      <ul>
                        <li>
                          Other users of the Handyman Platform, such as Clients and Handymen, as
                          necessary to facilitate job requests.
                        </li>
                        <li>
                          Service providers, contractors, and business partners who assist us in
                          providing the Handyman Platform and related services.
                        </li>
                        <li>
                          Law enforcement agencies, government authorities, or other third parties
                          in response to legal requests or to protect our rights and safety.
                        </li>
                        <li>
                          Third-party companies that provide identity verification, background
                          check, and similar services.
                        </li>
                        <li>Other third parties with your consent or as directed by you.</li>
                      </ul>
                      <p>
                        <strong>Your Choices</strong>
                      </p>
                      <p>You have choices regarding your personal information:</p>
                      <ul>
                        <li>
                          You can access and update some of your personal information through your
                          Handyman account settings.
                        </li>
                        <li>
                          You may choose to disable location tracking, but this may affect certain
                          features of the Handyman Platform.
                        </li>
                        <li>
                          You can opt-out of receiving promotional communications from us by
                          following the instructions in our emails or by contacting us directly.
                        </li>
                        <li>
                          You may request the deletion of your account and personal information,
                          subject to certain legal and operational requirements.
                        </li>
                      </ul>
                      <p>
                        <strong>Security</strong>
                      </p>
                      <p>
                        We take reasonable measures to protect your personal information, but no
                        data transmission over the internet is completely secure. You are
                        responsible for keeping your password confidential and for ensuring the
                        security of your Handyman account.
                      </p>
                      <p>
                        <strong>Changes to This Privacy Policy</strong>
                      </p>
                      <p>
                        We may update this Privacy Policy from time to time to reflect changes in
                        our practices or for other operational, legal, or regulatory reasons. We
                        will notify you of any material changes by posting the updated policy on our
                        website or through other appropriate means.
                      </p>
                      <p>
                        <strong>Contact Us</strong>
                      </p>
                      <p>
                        If you have any questions or concerns about this Privacy Policy or our
                        privacy practices, please contact us at [Insert Contact Information].
                      </p>
                    </div>
                  </>
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className='py-2'>
          <Button variant='link' onClick={handleClosePrivacy} size='sm'>
            {'Close'}
          </Button>
        </Modal.Footer>
      </Modal>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className='text-center'>
          {/* begin::Logo */}
          <Link to='/' className='mb-12 text-center'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/default-dark.svg')}
              className='theme-dark-show h-45px'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/default.svg')}
              className='theme-light-show h-45px mb-10'
            ></img>
          </Link>
        </div>
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6 required'>First name</label>
          <input
            placeholder='First name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstname')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.firstname && formik.errors.firstname,
              },
              {
                'is-valid': formik.touched.firstname && !formik.errors.firstname,
              }
            )}
          />
          {formik.touched.firstname && formik.errors.firstname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
        <div className='fv-row mb-8'>
          {/* begin::Form group Lastname */}
          <label className='form-label fw-bolder text-dark fs-6 required'>Last name</label>
          <input
            placeholder='Last name'
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('lastname')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.lastname && formik.errors.lastname,
              },
              {
                'is-valid': formik.touched.lastname && !formik.errors.lastname,
              }
            )}
          />
          {formik.touched.lastname && formik.errors.lastname && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lastname}</span>
              </div>
            </div>
          )}
          {/* end::Form group */}
        </div>

        {/* begin::Form group Email */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Email</label>
          <input
            placeholder='Email'
            type='email'
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group Phone */}
        <div className='fv-row mb-8'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Phone</label>
          <PhoneInput
                              preferredCountries={['us', 'ca', 'ma']}
                              inputStyle={{
                                width: '100%',
                                padding: '1.7rem',
                                paddingLeft: '4rem',
                                backgroundColor: '#f9f9f9',
                                borderColor: '#f9f9f9',
                              }}
                              country={'ca'}
                              value={company}
                              onChange={(value: any, country: any) => {
                                setSelectedCountry(country.countryCode)
                                setcompany(value)
                                formik.setFieldValue('phoneNumber', value) // Update formik value
                              }}
                            />
        </div>
        {/* end::Form group */}
        {/* begin::Form group Password */}
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-dark fs-6 required'>Password</label>
            <div className='position-relative mb-3'>
              <div className='input-group'>
                <input
                  type={isShowPassword ? 'text' : 'password'}
                  placeholder='Password'
                  autoComplete='off'
                  {...formik.getFieldProps('password')}
                  className={clsx(
                    'form-control bg-transparent',
                    {
                      'is-invalid': formik.touched.password && formik.errors.password,
                    },
                    {
                      'is-valid': formik.touched.password && !formik.errors.password,
                    }
                  )}
                />
                <span
                  className='input-group-text align-self-center cursor-pointer'
                  onClick={(e) => setShowPassword(!isShowPassword)}
                >
                  {!isShowPassword ? (
                    <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                  ) : (
                    <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                  )}
                </span>
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className='text-muted'>
            Use 8 or more characters with a mix of letters, numbers & symbols.
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-dark fs-6 required'>Confirm Password</label>
          <div className='input-group'>
            <input
              type={isShowCPassword ? 'text' : 'password'}
              placeholder='Password confirmation'
              autoComplete='off'
              {...formik.getFieldProps('confirmPassword')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
                },
                {
                  'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
                }
              )}
            />
            <span
              className='input-group-text align-self-center cursor-pointer'
              onClick={(e) => setShowCPassword(!isShowCPassword)}
            >
              {!isShowCPassword ? (
                <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
              ) : (
                <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
              )}
            </span>
          </div>
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.confirmPassword}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-8'>
          <div className='d-flex justify-content-start'>
            <label className='form-check form-check-inline me-1' htmlFor='kt_login_toc_agree'>
              <input
                className={clsx(
                  'form-check-input',
                  {'is-invalid': formik.touched.acceptTerms && formik.errors.acceptTerms},
                  {
                    '': formik.touched.acceptTerms && !formik.errors.acceptTerms,
                  }
                )}
                type='checkbox'
                id='kt_login_toc_agree'
                checked={accepteTerms}
                onChange={handleCheckboxChange}
              />
              <span></span>
            </label>
            <span>
              By clicking below and creating an account, I agree to Handyman’s
              <a type='button' onClick={handleShow} className='mx-1 link-primary fw-bold mb-0'>
                Terms of Service
              </a>
              and
              <a type='button' onClick={handleShowPrivacy} className='ms-1 link-primary fw-bold'>
                Privacy Policy
              </a>
              .
            </span>
          </div>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              Cancel
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
