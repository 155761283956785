import React, {FC, useEffect, useState} from 'react'
import Select from 'react-select'
import {useQuery} from 'react-query'
import axios from 'axios'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'
import {handleApiErrors} from '../../../global/HandleApiErrors'
import {ReactSelectStyles} from '../../../global/ReactSelectStyles'
import {ImageCardNumber} from '../../../../global/ImageCard'
import {KTIcon} from '../../../../_handld/helpers'
import {useAuth} from '../../auth'
import Payment from '../../../stripe/Payment'

const API_URL = process.env.REACT_APP_API_URL
const getCountries = (): Promise<any> => {
  return axios.get(`${API_URL}/api/Generic/Countries`)
}

const shema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
  address1: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('Province is required'),
  zipcode: Yup.string().required('Postal code is required'),
  cardName: Yup.string().required('Name on card is required'),
})

type Props = {
  setStep: (step: number) => void
  billing: any
  setBilling: (billing: any) => void
  address?: any
  handleClose: () => void
  clientSecret: string
  handleGetJobs: () => void
}
const Billing: FC<Props> = ({
  setStep,
  billing,
  setBilling,
  handleClose,
  clientSecret,
  handleGetJobs,
}) => {
  const [states, setStates] = useState<any>([])
  const [discount, setDiscount] = useState<any>('')
  const [profile, setProfile] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [loadingDiscount, setLoadingDiscount] = useState(false)
  const [isDiscountApplied, setIsDiscountApplied] = useState(false)

  const {data: countries, refetch: refetchCou} = useQuery('countries', getCountries, {
    refetchOnWindowFocus: false,
  })

  const handleGetStates = (country: number) => {
    axios.get(`${API_URL}/api/Generic/States?name=${country}`).then((res) => {
      setStates(res.data.states)
    })
  }

  const applyCoupon = (price: number, coupon: number) => {
    setLoadingDiscount(true)
    axios
      .get(`${API_URL}/api/Jobs/ValidateCoupon?price=${price}&coupon=${coupon}`)
      .then((res) => {
        setDiscount(res.data)
        setIsDiscountApplied(true)
        Swal.fire({
          text: 'Coupon applied successfully',
          icon: 'success',
          showConfirmButton: true,
          timer: 2000,
          width: '25rem',
        })
      })
      .catch((err) => {
        handleApiErrors(err?.response?.data)
      })
      .finally(() => {
        setLoadingDiscount(false)
      })
  }

  useEffect(() => {
    if (billing?.country) {
      handleGetStates(billing?.country)
    }
  }, [billing?.country])

  const countriesOptions = countries?.data?.countries?.map((country: any) => {
    return {
      label: country.name,
      value: country.id,
    }
  })
  const statesOptions = states?.map((state: any) => {
    return {
      label: state.name,
      value: state.name,
    }
  })

  const handleSubmit = async (data: any) => {
    setLoading(true)
    try {
      await axios.post(`${API_URL}/api/Jobs/ConfirmQuote`, data).then((res) => {
        Swal.fire({
          text: 'Your booking has been confirmed',
          icon: 'success',
          showConfirmButton: true,
          timer: 2000,
          width: '25rem',
        }).then(() => {
          document.getElementById('post-job-cancel-btn')?.click()
          handleGetJobs()
          handleClose()
        })
      })
    } catch (error: any) {
      handleApiErrors(error?.response?.data)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (billing?.sameAddress && profile?.profile) {
      setBilling({
        ...billing,
        address1: profile?.profile?.address1,
        address2: profile?.profile?.address2,
        city: profile?.profile?.city,
        state: profile?.profile?.state || 'Ontario',
        zipcode: profile?.profile?.zipCode,
        country: profile?.profile?.country || 'Canada',
        email: profile?.profile?.email,
      })
      formik.setFieldValue('address1', profile?.profile?.address1)
      formik.setFieldValue('address2', profile?.profile?.address2)
      formik.setFieldValue('city', profile?.profile?.city)
      formik.setFieldValue('state', profile?.profile?.state || 'Ontario')
      formik.setFieldValue('zipcode', profile?.profile?.zipCode)
      formik.setFieldValue('country', profile?.profile?.country || 'Canada')
      formik.setFieldValue('email', profile?.profile?.email)
    } else {
      setBilling({
        ...billing,
        address1: '',
        address2: '',
        city: '',
        state: 'Ontario',
        zipcode: '',
        country: 'Canada',
        email: '',
      })
      formik.setFieldValue('address1', '')
      formik.setFieldValue('address2', '')
      formik.setFieldValue('city', '')
      formik.setFieldValue('state', 'Ontario')
      formik.setFieldValue('zipcode', '')
      formik.setFieldValue('country', 'Canada')
      formik.setFieldValue('email', '')
    }
  }, [billing?.sameAddress && profile?.profile])

  const handleGetProfile = async () => {
    const profile = await axios.get(`${API_URL}/api/Account/Profile`)
    setProfile(profile.data)
  }

  useEffect(() => {
    if (billing?.sameAddress) {
      handleGetProfile()
    }
  }, [billing?.sameAddress])

  const formik = useFormik({
    initialValues: billing,
    validationSchema: shema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      if(values?.isCoupon && !discount){
        Swal.fire({
          text: 'Please apply coupon',
          icon: 'info',
          showConfirmButton: true,
          timer: 2000,
          width: '25rem',
        })
        return
      }
      document.getElementById('pay_now')?.click()
      // handleSubmit(values)
    },
  })

  return (
    <div className='p-6 mx-5'>
      <div>
        <div className='mb-8'>
          <h3 className='ml-5'>Billing Address</h3>
          <label className='fw-semibold text-muted'>
            Please provide information for the cardholder
          </label>
        </div>
      </div>

      <div className='col my-5'>
        <label className='form-check form-check-inline form-check-solid me-5'>
          <input
            className='form-check-input'
            name='sameAsBilling'
            type='checkbox'
            checked={billing.sameAddress}
            onChange={(e) => {
              setBilling({...billing, sameAddress: e.target.checked})
              formik.setFieldValue('sameAddress', e.target.checked)
            }}
          />
          <span className='fw-bold ps-2 fs-6'>
            Use the same address on my profile as my billing address
          </span>
        </label>
      </div>

      <div className={`card-body`}>
        <div className='row mb-7'>
          <div className='row mb-7'>
            <label className='fw-bold fs-5 mb-2 required'>Email</label>
            <input
              type='text'
              className='form-control form-control-solid '
              placeholder='Email'
              value={billing?.email}
              onChange={(e) => {
                setBilling({...billing, email: e.target.value})
                formik.setFieldValue('email', e.target.value)
              }}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <span className='text-danger'>{formik.errors.email as string}</span>
              </div>
            )}
          </div>
          <div className='row mb-7'>
            <label className='fw-bold fs-5 mb-5 required'>Address Line 1</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Address Line 1'
              value={billing.address1}
              onChange={(e) => {
                setBilling({...billing, address1: e.target.value})
                formik.setFieldValue('address1', e.target.value)
              }}
            />
            {formik.touched.address1 && formik.errors.address1 && (
              <div className='fv-plugins-message-container'>
                <span className='text-danger'>{formik.errors.address1 as string}</span>
              </div>
            )}
          </div>
          <div className='row mb-7'>
            <label className='fw-bold fs-5 mb-5'>Address Line 2</label>
            <input
              type='text'
              className='form-control form-control-lg form-control-solid '
              placeholder='Address Line 2'
              value={billing.address2}
              onChange={(e) => setBilling({...billing, address2: e.target.value})}
            />
          </div>
          <div className='row mb-7'>
            <div className='col-6'>
              <label className='fw-bold fs-5 mb-5 required'>City</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid '
                placeholder='City'
                value={billing.city}
                onChange={(e) => {
                  setBilling({...billing, city: e.target.value})
                  formik.setFieldValue('city', e.target.value)
                }}
              />
              {formik.touched.city && formik.errors.city && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger'>{formik.errors.city as string}</span>
                </div>
              )}
            </div>
            <div className='col-6'>
              <label className='fw-bold fs-5 mb-5 required'>Postal Code</label>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Postal Code'
                value={billing.zipcode}
                onChange={(e) => {
                  setBilling({...billing, zipcode: e.target.value})
                  formik.setFieldValue('zipcode', e.target.value)
                }}
              />
              {formik.touched.zipcode && formik.errors.zipcode && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger'>{formik.errors.zipcode as string}</span>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-7'>
            <div className='col-6'>
              <label className='fw-bold fs-5 mb-5 required'>Province</label>
              <Select
                options={statesOptions}
                styles={ReactSelectStyles}
                name='state'
                placeholder='Province'
                value={statesOptions?.find((state: any) => state.value == billing?.state) || null}
                onChange={(e) => {
                  setBilling({...billing, state: e?.value})
                  formik.setFieldValue('state', e?.value)
                }}
              />
              {formik.touched.state && formik.errors.state && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger'>{formik.errors.state as string}</span>
                </div>
              )}
            </div>
            <div className='col-6'>
              <label className='fw-bold fs-5 mb-5 required'>Country</label>
              <Select
                options={countriesOptions}
                styles={ReactSelectStyles}
                name='country'
                placeholder='Country'
                value={
                  countriesOptions?.find((country: any) => country.label == billing?.country) ||
                  null
                }
                onChange={(e) => {
                  setBilling({...billing, country: e?.label})
                  formik.setFieldValue('country', e?.label)
                }}
              />
              {formik.touched.country && formik.errors.country && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger'>{formik.errors.country as string}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className='separator separator-dashed text-gray-800 my-5'></div>

      <div>
        <div className='mb-8 mt-8'>
          <h3 className='ml-5'>Payment Information</h3>
          <label className='fw-semibold text-muted'>
            Please provide the requested contact details below
          </label>
        </div>
      </div>

      <div className='d-flex flex-column'>
        <div className='w-100'>
          <div className='d-flex flex-column mb-7 fv-row'>
            <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
              <span className='required'>Name On Card</span>
            </label>

            <input
              type='text'
              className='form-control form-control-solid required'
              placeholder='Enter card name'
              name='cardName'
              value={billing?.cardName}
              onChange={(e) => {
                setBilling({...billing, cardName: e.target.value})
                formik.setFieldValue('cardName', e.target.value)
              }}
            />
            {formik.touched.cardName && formik.errors.cardName && (
              <div className='fv-plugins-message-container'>
                <span className='text-danger'>{formik.errors.cardName as string}</span>
              </div>
            )}
          </div>
          <Payment
            clientSecret={clientSecret}
            handleSubmit={handleSubmit}
            billing={billing}
            formik={formik}
          />
          <div className='col-12 my-5'>
            <label className='form-check form-check-inline form-check-solid me-5  mt-5'>
              <input
                className='form-check-input'
                name='isCoupon'
                type='checkbox'
                checked={billing.isCoupon || false}
                onChange={(e) => {
                  setBilling({...billing, isCoupon: e.target.checked, coupon: ''})
                  formik.setFieldValue('isCoupon', e.target.checked)
                  setDiscount('')
                }}
              />
              <span className='fw-bold ps-2 fs-6'>Use coupon code</span>
            </label>
          </div>

          {formik.values?.isCoupon && (
            <div className='row'>
              <div className='col-4'>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='Enter your coupon code'
                  name='coupon'
                  value={billing?.coupon}
                  onChange={(e) => {
                    setBilling({...billing, coupon: e.target.value})
                    formik.setFieldValue('coupon', e.target.value)
                    if (isDiscountApplied) {
                      setDiscount('')
                    }
                  }}
                />
              </div>
              <div className='col-2'>
                <button
                  className='btn btn-primary '
                  disabled={loadingDiscount || !billing?.coupon}
                  onClick={() => {
                    applyCoupon(billing?.price, billing?.coupon)
                  }}
                >
                  Apply
                </button>
              </div>
              <div className='col-3'>
                <span className={`fw-bold ps-2 fs-6 text-muted`}>
                  Price:{' '}
                  <span
                    className={`fw-bold ps-2 fs-6 text-muted ${
                      discount !== '' && 'text-decoration-line-through'
                    }`}
                  >
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(billing?.price)}
                  </span>
                </span>
              </div>
              <div className='col-3'>
                {discount !== '' && (
                  <span className='fw-bold ps-2 fs-6 text-success'>
                    After Discount:{' '}
                    <span
                      className={`fw-bold ps-2 fs-6 text-success`}
                    >
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(discount)}
                    </span>
                  </span>
                )}
              </div>
            </div>
          )}
          {/* 
          <div className='d-flex flex-column mb-7 fv-row'>
            <label className='required fs-6 fw-bold form-label mb-2'>Card Number</label>

            <div className='position-relative'>
              <input
                type='text'
                maxLength={19}
                className='form-control form-control-solid'
                placeholder='Enter card number'
                name='cardNumber'
                value={billing?.cardNumber}
                onChange={(e) => {
                  setBilling({...billing, cardNumber: e.target.value})
                  formik.setFieldValue('cardNumber', e.target.value)
                }}
              />

              <div className='position-absolute translate-middle-y top-50 end-0 me-5'>
                {ImageCardNumber(billing?.cardNumber || '')}
              </div>

              {formik.touched.cardNumber && formik.errors.cardNumber && (
                <div className='fv-plugins-message-container'>
                  <span className='text-danger'>{formik.errors.cardNumber as string}</span>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-10'>
            <div className='col-md-8 fv-row'>
              <label className='required fs-6 fw-bold form-label mb-2'>Expiration Date</label>

              <div className='row fv-row'>
                <div className='col-6'>
                  <Select
                    placeholder='Month'
                    name='cardExpiryMonth'
                    options={months}
                    styles={ReactSelectStyles}
                    value={months.find((month) => month.value == billing?.cardExpiryMonth) || null}
                    onChange={(e) => {
                      setBilling({...billing, cardExpiryMonth: e?.value})
                      formik.setFieldValue('cardExpiryMonth', e?.value)
                    }}
                  />
                  {formik.touched.cardExpiryMonth && formik.errors.cardExpiryMonth && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger'>{formik.errors.cardExpiryMonth as string}</span>
                    </div>
                  )}
                </div>

                <div className='col-6'>
                  <Select
                    placeholder='Year'
                    name='cardExpiryYear'
                    options={years}
                    styles={ReactSelectStyles}
                    value={years.find((year) => year.value == billing?.cardExpiryYear) || null}
                    onChange={(e) => {
                      setBilling({...billing, cardExpiryYear: e?.value})
                      formik.setFieldValue('cardExpiryYear', e?.value)
                    }}
                  />
                  {formik.touched.cardExpiryYear && formik.errors.cardExpiryYear && (
                    <div className='fv-plugins-message-container'>
                      <span className='text-danger'>{formik.errors.cardExpiryYear as string}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className='col-md-4 fv-row'>
              <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                <span className='required'>CVV</span>
              </label>

              <div className='position-relative'>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  placeholder='CVV'
                  name='cardCvc'
                  maxLength={4}
                  value={billing?.cardCvc}
                  onChange={(e) => {
                    setBilling({...billing, cardCvc: e.target.value})
                    formik.setFieldValue('cardCvc', e.target.value)
                  }}
                />

                <div className='position-absolute translate-middle-y top-50 end-0 me-3'>
                  <KTIcon iconName='credit-cart' className='fs-2hx' />
                </div>

                {formik.touched.cardCvc && formik.errors.cardCvc && (
                  <div className='fv-plugins-message-container'>
                    <span className='text-danger'>{formik.errors.cardCvc as string}</span>
                  </div>
                )}
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className='d-flex justify-content-between my-5 py-5 '>
        <button onClick={() => setStep(1)} className='btn btn-sm btn-secondary px-5'>
          Back
        </button>
        <button
          type='submit'
          className='btn btn-primary btn-sm px-5'
          disabled={loading}
          onClick={() => {
            formik.handleSubmit()
          }}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

export default Billing
