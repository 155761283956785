import React, {FC, useState, useEffect} from 'react'
import {toAbsoluteUrl} from '../../../../_handld/helpers'
import Select from 'react-select'
import {ReactSelectStyles} from '../../../global/ReactSelectStyles'
import {useQueryClient} from 'react-query'
import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

type Props = {
  index: number
  job: any
  handleChange: (index: number, field: string, value: any) => void
}
const MovingHelp: FC<Props> = ({job, handleChange, index}) => {
  const [states, setStates] = useState<any>([])
  const queryClient = useQueryClient()
  const oldData: any = queryClient.getQueryData('countries')

  const handleGetStates = (country: number) => {
    axios.get(`${API_URL}/api/Generic/States?name=${country}`).then((res) => {
      setStates(res.data.states)
    })
  }

  const countriesOptions = oldData.data.countries?.map((country: any) => {
    return {
      label: country.name,
      value: country.id,
    }
  })

  useEffect(() => {
    if (job?.EndCountry) {
      handleGetStates(job?.EndCountry)
    }
  }, [job?.EndCountry])

  const statesOptions = states?.map((state: any) => {
    return {
      label: state.name,
      value: state.name,
    }
  })

  return (
    <div className={`card-body`}>
      <div className='row mb-7'>
        <label className='fw-semibold text-muted'>
          Provide some more details about the selected service
        </label>
      </div>
      <div className='row'>
        <div className='row mb-7 pb-3'>
          <label className='fw-bold fs-5 mb-3 required'>
            Boxes: How many boxes do you need moved?
          </label>
          <div
            id='kt_content_container'
            className='d-flex flex-column-fluid align-items-start container-xxl'
          >
            <div className='content flex-row-fluid' id='kt_content'>
              <div className='d-flex flex-column flex-xl-row'>
                <div className='d-flex flex-row-fluid me-xl-9 mb-10 mb-xl-0'>
                  <div className='card card-flush card-p-0 bg-transparent border-0'>
                    <div className='card-body'>
                      <ul className='nav nav-pills d-flex justify-content-between nav-pills-custom gap-3 mb-6'>
                        <li
                          className='nav-item mb-3 me-0'
                          onClick={() =>
                            handleChange(index, 'size', '20 to 40 boxes (1 to 2 people)')
                          }
                        >
                          <a
                            className={`nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg ${
                              job?.size === '20 to 40 boxes (1 to 2 people)' && 'active'
                            }`}
                            data-bs-toggle='pill'
                            style={{width: 200, height: 230}}
                          >
                            <div className='nav-icon mb-3'>
                              <img
                                src={toAbsoluteUrl('/media/SmallBoxes.png')}
                                className='w-150px h-160px'
                                alt=''
                              />
                            </div>
                            <div className=''>
                              {/* <span className='text-gray-800 fw-bold fs-2 d-block'>Small</span> */}
                              <span className='text-gray-400 fw-semibold fs-7'>
                                20 to 40 boxes (1 to 2 people)
                              </span>
                            </div>
                          </a>
                        </li>
                        <li
                          className='nav-item mb-3 me-0'
                          onClick={() =>
                            handleChange(index, 'size', '40 to 80 boxes (2 to 3 people)')
                          }
                        >
                          <a
                            className={`nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg ${
                              job?.size === '40 to 80 boxes (2 to 3 people)' && 'active'
                            }`}
                            data-bs-toggle='pill'
                            style={{width: 200, height: 230}}
                          >
                            <div className='nav-icon mb-3'>
                              <img
                                src={toAbsoluteUrl('/media/BigBoxes.png')}
                                className='w-150px h-160px'
                                alt=''
                              />
                            </div>
                            <div className=''>
                              {/* <span className='text-gray-800 fw-bold fs-2 d-block'>Medium</span> */}
                              <span className='text-gray-400 fw-semibold fs-7'>
                                40 to 80 boxes (2 to 3 people)
                              </span>
                            </div>
                          </a>
                        </li>
                        <li
                          className='nav-item mb-3 me-0'
                          onClick={() =>
                            handleChange(index, 'size', 'over 80 boxes (3 to 4 people)')
                          }
                        >
                          <a
                            className={`nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg ${
                              job?.size === 'over 80 boxes (3 to 4 people)' && 'active'
                            }`}
                            data-bs-toggle='pill'
                            style={{width: 200, height: 230}}
                          >
                            <div className='nav-icon mb-3'>
                              <img
                                src={toAbsoluteUrl('/media/LargeBoxes.png')}
                                className='w-150px h-160px'
                                alt=''
                              />
                            </div>
                            <div className=''>
                              {/* <span className='text-gray-800 fw-bold fs-2 d-block'>Large</span> */}
                              <span className='text-gray-400 fw-semibold fs-7'>
                                over 80 boxes (3 to 4 people)
                              </span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='row mb-7 pb-3'>
          <label className='fw-bold fs-5 mb-3 required'>
            Vehicle Requirements : What type of vehicle do you need?
          </label>
          <div
            id='kt_content_container'
            className='d-flex flex-column-fluid align-items-start container-xxl'
          >
            <div className='content flex-row-fluid' id='kt_content'>
              <div className='d-flex flex-column flex-xl-row'>
                <div className='d-flex flex-row-fluid me-xl-9 mb-10 mb-xl-0'>
                  <div className='card card-flush card-p-0 bg-transparent border-0'>
                    <div className='card-body'>
                      <ul className='nav nav-pills d-flex justify-content-between nav-pills-custom gap-3 mb-6'>
                        <li
                          className='nav-item mb-3 me-0'
                          onClick={() =>
                            handleChange(index, 'vehiculeNeeded', 'Not needed for task')
                          }
                        >
                          <a
                            className={`nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg ${
                              job?.vehiculeNeeded === 'Not needed for task' && 'active'
                            }`}
                            data-bs-toggle='pill'
                            style={{width: 200, height: 230}}
                          >
                            <div className='nav-icon mb-3'>
                              <img
                                src={toAbsoluteUrl('/media/none.png')}
                                className='w-100px h-130px'
                                alt=''
                              />
                            </div>
                            <div className=''>
                              <span className='text-gray-800 fw-bold fs-7'>
                                Not needed for task
                              </span>
                            </div>
                          </a>
                        </li>
                        <li
                          className='nav-item mb-3 me-0'
                          onClick={() =>
                            handleChange(index, 'vehiculeNeeded', 'Task requires a car')
                          }
                        >
                          <a
                            className={`nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg ${
                              job?.vehiculeNeeded === 'Task requires a car' && 'active'
                            }`}
                            data-bs-toggle='pill'
                            style={{width: 200, height: 230}}
                          >
                            <div className='nav-icon mb-3'>
                              <img
                                src={toAbsoluteUrl('/media/carL2.png')}
                                className='w-100'
                                alt=''
                              />
                            </div>
                            <div className=''>
                              <span className='text-gray-800 fw-semibold fs-7'>
                                Task requires a car
                              </span>
                            </div>
                          </a>
                        </li>
                        <li
                          className='nav-item mb-3 me-0'
                          onClick={() =>
                            handleChange(index, 'vehiculeNeeded', 'Task requires a truck')
                          }
                        >
                          <a
                            className={`nav-link nav-link-border-solid btn btn-outline btn-flex btn-active-color-primary flex-column flex-stack pt-9 pb-7 page-bg ${
                              job?.vehiculeNeeded === 'Task requires a truck' && 'active'
                            }`}
                            data-bs-toggle='pill'
                            style={{width: 200, height: 230}}
                          >
                            <div className='nav-icon mb-3'>
                              <img
                                src={toAbsoluteUrl('/media/carL3.png')}
                                className='w-100'
                                alt=''
                              />
                            </div>
                            <div className=''>
                              <span className='text-gray-800 fw-semibold fs-7'>
                                Task requires a truck
                              </span>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-2'>
        <label className='fw-bold fs-5'>Provide some more details about the end address</label>
      </div>

      <div className='row mb-7 col-md-7'>
        <label className='fw-bold fs-5 mb-5 required'>Address Line 1</label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid '
          placeholder='Address Line 1'
          value={job?.EndAddress1}
          onChange={(e) => handleChange(index, 'EndAddress1', e.target.value)}
        />
      </div>
      <div className='row mb-7 col-md-7'>
        <label className='fw-bold fs-5 mb-5'>Address Line 2</label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid '
          placeholder='Address Line 2'
          value={job?.EndAddress2}
          onChange={(e) => handleChange(index, 'EndAddress2', e.target.value)}
        />
      </div>
      <div className='row mb-7 col-md-7'>
        <div className='col-6 pr-2'>
          <label className='fw-bold fs-5 mb-5 required'>City</label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid '
            placeholder='City'
            value={job?.EndCity}
            onChange={(e) => handleChange(index, 'EndCity', e.target.value)}
          />
        </div>
        <div className='col-6 p-0'>
          <label className='fw-bold fs-5 mb-5 required'>Postal Code</label>
          <input
            type='text'
            className='form-control form-control-lg form-control-solid '
            placeholder='Postal Code'
            value={job?.EndZipcode}
            onChange={(e) => handleChange(index, 'EndZipcode', e.target.value)}
          />
        </div>
      </div>
      <div className='row mb-7 col-md-7 p-0'>
        <div className='col-6'>
          <label className='fw-bold fs-5 mb-5 required'>Province</label>
          <Select
            options={statesOptions}
            styles={ReactSelectStyles}
            name='EndState'
            placeholder='State'
            value={statesOptions?.filter((option: any) => option.value === job?.EndState)}
            onChange={(e) => {
              handleChange(index, 'EndState', e?.label)
            }}
          />
        </div>
        <div className='col-6 p-0'>
          <label className='fw-bold fs-5 mb-5 required'>Country</label>
          <Select
            options={countriesOptions}
            styles={ReactSelectStyles}
            name='EndCountry'
            placeholder='Country'
            value={countriesOptions?.filter((option: any) => option.label === job?.EndCountry)}
            onChange={(e) => {
              handleChange(index, 'EndCountry', e?.label)
            }}
          />
        </div>
      </div>

      <div className='row mb-7 pb-3 col-md-7 '>
        <div className='col-12 px-0'>
          <label className='fw-bold fs-5 mb-3'>Additional Notes/Info</label>
          <textarea
            className='form-control form-control-lg form-control-solid '
            rows={4}
            name='additionalDetails'
            value={job?.additionalDetails}
            onChange={(e) => handleChange(index, 'additionalDetails', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default MovingHelp
