import axios from 'axios'
import {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import Swal from 'sweetalert2'

const API_URL = process.env.REACT_APP_API_URL
type Props = {
  showCancel: boolean
  handleCloseCancel: () => void
  id: any
  email: any
  handleGetJobs: () => void
}
const CancelModal: FC<Props> = ({showCancel, handleCloseCancel, id, email, handleGetJobs}) => {
  const [cancelationReason, setCancelationReason] = useState<any>('')
  const [loading, setLoading] = useState(false)
  const handleCancelJob = (data: any) => {
    setLoading(true)
    axios.post(`${API_URL}/api/Jobs/CancelJob`, data).then((res) => {
      handleCloseCancel()
      // cancelationReason('')
      handleGetJobs()
      Swal.fire({
        text: 'Job cancelled successfully',
        icon: 'success',
        showConfirmButton: true,
        timer: 2000,
        width: '25rem',
      })
      setLoading(false)
    })
  }

  return (
    <div>
      <Modal show={showCancel} onHide={handleCloseCancel} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea
            className='form-control'
            placeholder='Reason for cancellation'
            rows={5}
            value={cancelationReason}
            onChange={(e) => setCancelationReason(e.target.value)}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <button
            disabled={cancelationReason === '' || loading}
            onClick={() => {
              handleCancelJob({id, email, cancelationReason})
            }}
            className='btn btn-primary'
          >
            Cancel Job
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CancelModal
