import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../../../_handld/helpers'
import {JobDetailsModalForm} from './JobDetailsModalForm'
import QueryFetchPost from '../../../../../global/index2'

type Props = {
  closeModal: (id: any) => void
  Id:any
}
const JobDetailsModal: FC<Props> = ({closeModal,Id}) => {
  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const[data,setdata]=useState<any>()
  const[customerId,setcustomerId]=useState<any>()
  const GetJobsdetails = async (id: any) => {
    await QueryFetchPost({
      link: `${BASE_URL}/api/BackOffice/DetailsJob/${id}`,
      data: undefined ,
      method: 'GET',
      key: 'GetJobs',
      signal: undefined,
      isFormData: false,
    }).then((response: any) => {
      setdata(response[1])
      setcustomerId(response[1]?.data?.customerId)
    })
  }
  useEffect(()=>{
    GetJobsdetails(Id)
  },[Id])


  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        <div className='modal-dialog modal-dialog-centered mw-950px'>
          <div className='modal-content'>
            <div className='modal-header'>
              {/* begin::Modal title */}
              <h2 className='fw-bolder'>Job Details</h2>
              {/* end::Modal title */}

              {/* begin::Close */}
              <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => {
                  closeModal(undefined)
                }}
                style={{cursor: 'pointer'}}
              >
                <KTIcon iconName='cross' className='fs-1' />
              </div>
              {/* end::Close */}
            </div>
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
             {data!=null || data!=undefined ? <JobDetailsModalForm customerId={customerId} data={data}/> :''}
            </div>
          </div>
        </div>
      </div>
      <div className='modal-backdrop fade show'></div>
    </>
  )
}

export {JobDetailsModal}
