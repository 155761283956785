import {Fragment, useEffect, useState} from 'react'
import {Card, Form, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Link, useParams} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_handld/helpers'
import {PageTitle} from '../../../../_handld/layout/core'
import axios from 'axios'
import Swal from 'sweetalert2'
import AccepteQuteModal from './AccepteQuteModal'
import CancelModal from './CancelModal'
import {ThemeModeSwitcher} from '../../../../_handld/partials'
import {DropdownHover} from './DropdownHover'
import {
  ConvertToDateCurrentTimeZoneDateTime,
  getFormatDateTime,
  getFromatdate,
} from '../../../../_handld/helpers/GlobalHelpers'
import moment from 'moment'
import SVG from 'react-inlinesvg'
import {TaskImageViewerModal} from '../../../pages/handlr-management/images-viewer/ImageViewerModal'

const API_URL = process.env.REACT_APP_API_URL
const JobsDetails = () => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingClose, setLoadingClose] = useState(false)
  const [showCancel, setShowCancel] = useState(false)
  const [jobsList, setJobsList] = useState<any>([])
  const [data, setData] = useState<any>({})
  const [quotes, setQuotes] = useState([])
  const [quoteId, setQuoteId] = useState('')
  const [price, setPrice] = useState('')
  const [rate, setRate] = useState(null)
  const [jobRates, setJobRates] = useState<any>({})
  const [notes, setNotes] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const {id} = useParams<{id: string}>()
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleCloseCancel = () => setShowCancel(false)
  const handleShowCancel = () => setShowCancel(true)
  const [availableTimesArray, setAvailableTimesArray] = useState<string[]>([])
  const [imageId, setImageId] = useState<any>('')
  const [imageSrc, setImageSrc] = useState<any>()

  const [billing, setBilling] = useState<any>({
    cardToken: '',
    cardId: '',
    address1: '',
    address2: '',
    zipcode: '',
    city: '',
    state: 'Ontario',
    country: 'Canada',
    coupon: '',
    jobsGroupId: id,
    quotesGroupId: '',
    email: '',
    requestedDate: '',
    availableTimes: '',
    dateType: '',
    countryId: 40,
    sameAddress: false,
    isCoupon: false,
    price: '',
  })
  const jobDetailsBreadcrumbs = [
    {
      title: 'Job',
      path: '/customer/jobs/details/:id',
      isSeparator: false,
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const handleBadge = (status: any) => {
    if (status === 'Open') {
      return 'primary'
    }
    if (status === 'Closed') {
      return 'danger'
    }
    if (status === 'In Progress') {
      return 'progress'
    }
    if (status === 'Booked') {
      return 'info'
    }
    if (status === 'Completed') {
      return 'success'
    }
    if (status === 'Canceled') {
      return 'warning'
    }
    if (status === 'Processing') {
      return 'processing'
    }
    if (status === 'Draft') {
      return 'secondary'
    }
  }

  const handleGetJobs = () => {
    setLoading(true)
    axios.get(`${API_URL}/api/Jobs/DetailsJobsGroup/${id}`).then((res) => {
      setJobsList(res.data.jobs)
      setData(res.data)
      setQuotes(res.data.quotes)
      setLoading(false)
    })
    
  }
  useEffect(() => {
    handleGetJobs()
  }, [])

  useEffect(() => {
    if (price) {
      setBilling({...billing, price: price})
    }
  }, [price])

  const formatDate = (date: any) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }

  const formatCurrency = (num: any) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }

  const handleRejectquote = (data: any) => {
    axios.post(`${API_URL}/api/Jobs/RejectQuote`, data).then((res) => {
      handleGetJobs()
      Swal.fire({
        text: 'Quote rejected successfully',
        icon: 'success',
        showConfirmButton: true,
        timer: 2000,
        width: '25rem',
      })
    })
  }

  const handleCloseJob = (data: any) => {
    setLoadingClose(true)
    axios.post(`${API_URL}/api/Jobs/Close`, data).then((res) => {
      handleGetJobs()
      setNotes('')
      Swal.fire({
        text: 'Job Closed successfully',
        icon: 'success',
        showConfirmButton: true,
        timer: 2000,
        width: '25rem',
      })
      setLoadingClose(false)
    })
  }

  const handleRatingChange = (newRating: any, jobId: any) => {
    setJobRates((prevRates: any) => ({
      ...prevRates,
      [jobId]: newRating,
    }))
  }

  useEffect(() => {
    if (jobsList?.length > 0) {
      jobsList?.map((job: any) => {
        setJobRates((prevRates: any) => ({
          ...prevRates,
          [job.id]: job?.review?.rate,
        }))
      })
    }
  }, [jobsList])

  const GeneratePaymentIntent = (data: any) => {
    axios.post(`${API_URL}/api/Jobs/GeneratePaymentIntent`, data).then((res) => {
      setClientSecret(res.data.clientSecret)
    })
  }

  const handleStatus = (
    jobCount: any,
    hasCompleted: any,
    isClosed: any,
    status: any,
    oneStatus?: boolean
  ) => {
    if (jobCount > 0) {
      if (hasCompleted) {
        return 'Completed'
      } else {
        if (isClosed) {
          return 'Closed'
        } else {
          if (oneStatus == true) {
            return status
          }
          return 'Processing...'
        }
      }
    } else {
      return status
    }
  }

  useEffect(() => {
    if (quoteId && data?.email) {
      GeneratePaymentIntent({
        jobsGroupId: id,
        quotesGroupId: quoteId,
        email: data?.email,
      })
    }
  }, [quoteId, data?.email])

  const requesteddate = jobsList?.requestedDate
  let date = '---' // Default value if requesteddate is null or undefined

  if (requesteddate) {
    const splitdate = requesteddate.split('T')
    const datee = splitdate[0]
    // date = moment(datee).format("MM-DD-YYYY");
    const format = getFromatdate()
    if (format) {
      date = moment(datee).format(format)
    }
  }
  return (
    <>
      <PageTitle breadcrumbs={jobDetailsBreadcrumbs}>Details</PageTitle>
      { loading? 
          <div className='d-flex align-items-center justify-content-center h-250px'>
            <h1 className='text-muted'>Loading...</h1>
          </div>
           :
      <div className='row'>
        <div className='col-xl-8 col-lg-8 col-12'>
          
            <Card className='h-100'>
            <Card.Body>
              <div className='d-flex flex-end align-items-center'>
                {jobsList?.length > 1 && (
                  <button
                    disabled={true}
                    className={`btn btn-${handleBadge(jobsList[0]?.status)}  btn-sm ms-5`}
                  >
                    {handleStatus(
                      data?.jobs?.length - 1,
                      data?.hasCompleted,
                      data?.isClosed,
                      jobsList[0]?.status,
                      data?.oneStatus
                    )}
                  </button>
                )}
                {data?.canCancel && (
                  // <button
                  //   onClick={handleShowCancel}
                  //   className='btn btn-danger fw-bolder btn-sm rating-label ms-5'
                  // >
                  //   Cancel
                  // </button>
                  <DropdownHover handleShowCancel={handleShowCancel} />
                )}
              </div>
              <div className='separator separator-dashed text-gray-800 my-5'></div>
              {jobsList?.map((job: any, index: number) => (
                <>
                  <div className='row'>
                    <div className='col-xl-8 col-lg-8 col-12'>
                      <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
                        <>
                          <div key={index}>
                            <div>
                              <div className='mx-5 mb-3 d-flex justify-content-between align-items-center'>
                                <div className='d-flex align-items-center'>
                                  <h3 className='me-5'>Service</h3>
                                  <span className='badge badge-light-danger fs-8 fw-bold'>
                                    {' '}
                                    {index + 1}
                                  </span>
                                </div>
                                <span
                                  className={`badge badge-${handleBadge(
                                    job?.status
                                  )} fw-bold px-4 py-3`}
                                >
                                  {job?.status}
                                </span>
                              </div>
                              <div className='d-flex row'>
                                <div className='col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>Name</label>
                                    <p className='text-muted'>{job?.servicePath}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className='separator separator text-gray-800 my-5'></div>
                            <div>
                              <div className='mx-5 mb-'>
                                <h3 className='me-5'>Service Details</h3>
                              </div>
                              <div className='d-flex row'>
                                {job?.itemsFrom && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mx-10'>
                                      {' '}
                                      <label className=''>
                                        {job?.serviceParentCode === 'BEDS' && 'Bed is from'}
                                        {job?.serviceParentCode === 'SOFA' && 'Sofa is from'}
                                        {(job?.serviceParentCode === 'DINIF' ||
                                          job?.serviceParentCode === 'TABL' ||
                                          job?.serviceCode === 'CONS' ||
                                          job?.serviceParentCode === 'SEAT') &&
                                          'Table & chair from:'}
                                        {job.serviceParentCode === 'FDESK' && 'Desk & chair from:'}
                                        {job?.serviceParentCode === 'BEDR' && '(Item) is from'}
                                        {job?.serviceCode === 'SHEL' && 'Shelves are from'}
                                        {job?.serviceCode === 'CABI' && 'Cabinets are from'}
                                        {job?.serviceCode === 'WALLL' && 'Wall-Lamp(s) are from'}
                                        {job?.serviceCode === 'WALLMI' && 'Model Name/Number'}
                                      </label>
                                      <p className='text-muted'>{job?.itemsFrom}</p>
                                    </div>
                                  </div>
                                )}
                                {job?.model && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>Model Name/Number</label>
                                      <p className='text-muted'>{job?.model}</p>
                                    </div>
                                  </div>
                                )}

                                {job?.serviceCode === 'TVWA' && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>{job?.serviceCode === 'TVWA' && 'Size of TV'}</label>
                                      <p className='text-muted'>{job?.sizeInch} inches</p>
                                    </div>
                                  </div>
                                )}

                                {job?.serviceCode === 'TVWA' && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>
                                        {job?.serviceCode === 'TVWA' && 'Wall-Mount on hand'}
                                      </label>
                                      <p className='text-muted'>
                                        {job?.wallMountOnHand ? 'Yes' : 'No'}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {job?.serviceCode === 'TVWA' && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>
                                        {job?.serviceCode === 'TVWA' &&
                                          'Confirm Wall-Mount is correct specific to size of their TV:'}
                                      </label>
                                      <p className='text-muted'>
                                        {job?.wallSpecificSize ? 'Yes' : 'No'}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {job?.wallType && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>
                                        {job?.serviceCode === 'TVWA' &&
                                          'Wall Info TV is being mounted to:'}
                                        {job?.serviceCode === 'SHEL' &&
                                          'Wall Info Shelf are being mounted to:'}
                                        {job?.serviceCode === 'ARTP' &&
                                          'Wall Info Art/Picture(s)  is being mounted to:'}
                                        {job?.serviceCode === 'CABI' &&
                                          'Wall Info Cabinet(s) are being mounted to:'}
                                        {job?.serviceCode === 'WALLMI' &&
                                          'Wall Info Wall-Mirror(s)  being mounted to:'}
                                        {job?.serviceCode === 'CURT' &&
                                          'Wall Info Curtain Rod(s)  being mounted to:'}
                                        {job?.serviceCode === 'SMAL' &&
                                          'Wall Info: item(s)  being mounted to:'}
                                      </label>
                                      <p className='text-muted'>{job?.wallType}</p>
                                    </div>
                                  </div>
                                )}
                                {job?.numberOfItems !== 0 && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>
                                        {job?.serviceCode === 'TVWA' && 'Number of Tvs'}
                                        {job?.serviceCode === 'SHEL' && 'Number of Shelves'}
                                        {job?.serviceCode === 'SMAL' && 'Number of small items'}
                                        {job?.serviceCode === 'ARTP' && 'Number of pictures'}
                                        {(job?.serviceCode === 'CURT' ||
                                          job?.serviceCode === 'WALLMI') &&
                                          'Number of Curtain Rods'}
                                        {job?.serviceCode === 'OTLI' &&
                                          'Number of outlets to be installed?'}
                                        {job?.serviceCode === 'LFRPL' &&
                                          'Number of light fixtures to be replaced?'}
                                        {job?.serviceCode === 'CLFI' &&
                                          'Number of ceiling fans to be installed?'}

                                        {job?.serviceCode === 'FRPRP' &&
                                          'Number of faucets to be repaired or replaced?'}
                                        {job?.serviceCode === 'TOIRP' &&
                                          'Number of toilets to be replaced?'}
                                        {job?.serviceCode === 'LKRP' &&
                                          'Number of leaks to be repaired?'}
                                        {job?.serviceCode === 'IDWMC' &&
                                          'Number of appliances to be installed?'}
                                        {job?.serviceCode === 'HWHIM' &&
                                          'Number of hot water heaters to be inspected or maintained?'}
                                        {job?.serviceCode === 'PPINS' &&
                                          'Number of pipes to be insulated?'}
                                        {job?.serviceCode === 'SHRP' &&
                                          'Number of shower heads to be replaced?'}
                                      </label>
                                      <p className='text-muted'>{job?.numberOfItems}</p>
                                    </div>
                                  </div>
                                )}
                                {job?.length !== 0 && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>
                                        {job.serviceCode === 'SHEL' && 'Length of Shelves'}
                                      </label>
                                      <p className='text-muted'>{job?.length}</p>
                                    </div>
                                  </div>
                                )}
                                {job?.size &&
                                  job.serviceCode !== 'RCLN' &&
                                  job.serviceCode !== 'DCLN' &&
                                  job.serviceCode !== 'SCLN' &&
                                  job.serviceCode !== 'MHLP' &&
                                  job.serviceParentCode !== 'YRDGRD' &&
                                  job.serviceCode !== 'GRDPLN' && (
                                    <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                      <div className='fw-bold fs-6 mb-2 ms-10'>
                                        {' '}
                                        <label>Size</label>
                                        <p className='text-muted'>{job?.size}</p>
                                      </div>
                                    </div>
                                  )}

                                {job?.size &&
                                  (job.serviceCode === 'RCLN' ||
                                    job.serviceCode === 'DCLN' ||
                                    job.serviceCode === 'SCLN') && (
                                    <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                      <div className='fw-bold fs-6 mb-2 ms-10'>
                                        {' '}
                                        <label>
                                          Surface: What type of surface do you need cleaned?
                                        </label>
                                        <p className='text-muted'>{job?.size}</p>
                                      </div>
                                    </div>
                                  )}
                                {job?.size && job.serviceCode === 'MHLP' && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>Boxes: How many boxes do you need moved?</label>
                                      <p className='text-muted'>{job?.size}</p>
                                    </div>
                                  </div>
                                )}
                                {job?.vehiculeNeeded && job.serviceCode === 'MHLP' && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>
                                        Vehicle Requirements : What type of vehicle do you need?
                                      </label>
                                      <p className='text-muted'>{job?.vehiculeNeeded}</p>
                                    </div>
                                  </div>
                                )}
                                {job?.endAddress1 && job.serviceCode === 'MHLP' && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>End address</label>
                                      <p className='text-muted'>
                                        {job?.endAddress1}, {job?.endAddress2 && job?.endAddress2 +','}{' '}
                                        {job?.endCity}, {job?.endState}, {job?.endZipCode},{' '}
                                        {job?.endCountry}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {job?.coatType &&
                                  (job.serviceParentCode === 'PNT' ||
                                    job.serviceCode === 'PNT') && (
                                    <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                      <div className='fw-bold fs-6 mb-2 ms-10'>
                                        <label>Type of Coat:</label>
                                        <p className='text-muted'>{job?.coatType}</p>
                                      </div>
                                    </div>
                                  )}
                                {job?.type &&
                                  (job.serviceParentCode === 'PNT' ||
                                    job.serviceCode === 'PNT') && (
                                    <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                      <div className='fw-bold fs-6 mb-2 ms-10'>
                                        <label>Type:</label>
                                        <p className='text-muted'>{job?.type}</p>
                                      </div>
                                    </div>
                                  )}
                                {job?.size && job.serviceParentCode === 'YRDGRD' && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      <label>Surface:</label>
                                      <p className='text-muted'>{job?.size}</p>
                                    </div>
                                  </div>
                                )}
                                {job?.serviceCode === 'ELEC' && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>Assembly of Fireplace required:</label>
                                      <p className='text-muted'>
                                        {job?.assemblyRequired ? 'Yes' : 'No'}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {job?.serviceCode === 'CABI' && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>Assembly of Cabinet Required:</label>
                                      <p className='text-muted'>
                                        {job?.assemblyRequired ? 'Yes' : 'No'}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {job?.itemLink && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>
                                        {job?.serviceCode === 'ELEC' &&
                                          'Link input for electric fireplace make/model'}
                                      </label>
                                      <p className='text-muted'>{job?.itemLink}</p>
                                    </div>
                                  </div>
                                )}
                                {job?.serviceCode === 'ELEC' && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>
                                        {job?.serviceCode === 'ELEC' &&
                                          'Depending on height/placement will someone be on hand to assist Handyman in lifting fireplace to the mounting bracket:'}
                                      </label>
                                      <p className='text-muted'>
                                        {job?.assistHandlr ? 'Yes' : 'No'}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {job?.numberOfWindows !== 0 && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>
                                        {job?.serviceCode === 'CURT' && 'Number of Windows'}
                                      </label>
                                      <p className='text-muted'>{job?.numberOfWindows}</p>
                                    </div>
                                  </div>
                                )}
                                {job?.width !== 0 && job?.width !== null && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>Width</label>
                                      <p className='text-muted'>{job?.width}</p>
                                    </div>
                                  </div>
                                )}
                                {job?.height !== 0 && job?.height !== null && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>Height</label>
                                      <p className='text-muted'>{job?.height}</p>
                                    </div>
                                  </div>
                                )}
                                {job?.items && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>
                                        {job?.serviceCode === 'SMAL' && 'Items/Description'}
                                      </label>
                                      <p className='text-muted'>{job?.items}</p>
                                    </div>
                                  </div>
                                )}
                                {job?.serviceParentCode === 'BEDS' && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>
                                        Does this Model require Headboard to be Wall-Mounted?
                                      </label>
                                      <p className='text-muted'>
                                        {job?.headboardWallMounted ? 'Yes' : 'No'}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                {(job?.serviceCode === 'KING' ||
                                  job?.serviceCode === 'QUEE' ||
                                  job?.serviceCode === 'FULL') && (
                                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>
                                      Confirmation NO storage drawers on this model
                                      </label>
                                      <p className='text-muted'>
                                        {job?.storageDrawers ? 'Yes' : 'No'}
                                      </p>
                                    </div>
                                  </div>
                                )}
                                <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                                  {job?.placementDifficulty && job?.placementDifficultyPicture && (
                                    <div
                                      className='fw-bold fs-6 mb-2 ms-10'
                                      onClick={() => {
                                        setImageSrc(job?.placementDifficultyPicture)
                                      }}
                                    >
                                      {' '}
                                      <label>Placement:</label> <br />
                                      <img
                                        src={job?.placementDifficultyPicture}
                                        alt='placement difficulty'
                                        className='w-80px h-80px rounded mt-2 cursor-pointer'
                                      />
                                    </div>
                                  )}
                                  {job?.requestedDate && (
                                    <div className='col-12'>
                                      <div className='fw-bold fs-6 mb-2 ms-10'>
                                        {' '}
                                        <label>Date to be completed</label>
                                        <div className='d-flex align-items-center'>
                                          <p className='text-muted'>
                                            {' '}
                                            {job?.requestedDate
                                              ? moment(job?.requestedDate).format(getFromatdate()!)
                                              : '---'}
                                          </p>
                                          <div className='fw-bolder mb-5'>
                                            <OverlayTrigger
                                              key={'right'}
                                              placement={'right'}
                                              overlay={
                                                <Tooltip id={`tooltip-${'placement'}`}>
                                                  {getFromatdate()}
                                                </Tooltip>
                                              }
                                            >
                                              <span
                                                style={{cursor: 'pointer'}}
                                                className='svg-icon menu-icon svg-icon-info'
                                              >
                                                <SVG
                                                  src={toAbsoluteUrl(
                                                    '/media/svg/Code/Info-circle.svg'
                                                  )}
                                                />
                                              </span>
                                            </OverlayTrigger>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  {job?.availableTimes && (
                                    <div className='col-12'>
                                      <div className='fw-bold fs-6 mb-2 ms-10'>
                                        {' '}
                                        <label>Available times</label>
                                        <p className='text-muted'>{job?.availableTimes}</p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className='ms-10 mb-8 mt-3'>
                                <div className='fw-bold fs-6 mb-2'>
                                  {' '}
                                  <label>Additional Notes/Info</label>
                                  <p className='text-muted'>
                                    {' '}
                                    {job?.additionalDetails
                                      ? job?.additionalDetails
                                      : 'No additional details'}
                                  </p>
                                </div>
                              </div>
                              {/* <div className='separator separator-solid text-gray-800 my-5'></div> */}
                            </div>
                            {job?.statusId === 5 && (
                              <>
                                <div className='mx-5 mb-8 d-flex justify-content-between align-items-center'>
                                  <h4>Review</h4>
                                </div>
                                <div className='fw-bold fs-6 mb-2 ms-10'>
                                  {' '}
                                  <h5>Add Rate </h5>
                                  <div className='rating'>
                                    {[1, 2, 3, 4, 5].map((value: any) => (
                                      <Fragment key={value}>
                                        <input
                                          disabled={job?.statusId === 6}
                                          className='rating-input'
                                          name={`rating_${job.id}`}
                                          value={value.toString()}
                                          type='radio'
                                          id={`kt_rating_input_${job.id}_${value}`}
                                          checked={jobRates[job.id] === value}
                                          onChange={() => handleRatingChange(value, job.id)}
                                        />
                                        <label
                                          className='rating-label d-flex'
                                          htmlFor={`kt_rating_input_${job.id}_${value}`}
                                        >
                                          {jobRates[job.id] !== undefined &&
                                          value <= jobRates[job.id] ? (
                                            <i className='bi bi-star-fill fs-5 pt-1 text-warning me-2'></i>
                                          ) : (
                                            <i className='bi bi-star fs-5 pt-1 text-secondary me-2'></i>
                                          )}
                                        </label>
                                      </Fragment>
                                    ))}
                                  </div>
                                </div>
                              </>
                            )}
                            {job?.statusId === 6 && (
                              <>
                                <h4 className='mx-5'>Review</h4>
                                <div className='d-flex align-items-center bg-light-info rounded p-5 m-5 mt-2 col-8'>
                                  <span className=' text-info me-5'>
                                    <i className='ki-duotone ki-abstract-26 text-info fs-1 me-5'>
                                      <span className='path1' />
                                      <span className='path2' />
                                    </i>
                                  </span>
                                  <div className='flex-grow-1 me-2'>
                                    <div className='fw-bold fs-6 mb-2 ml-5'>
                                      <div className='rating'>
                                        {[1, 2, 3, 4, 5].map((value: any) => (
                                          <Fragment key={value}>
                                            <input
                                              disabled={job?.statusId === 6}
                                              className='rating-input'
                                              name={`rating_${job.id}`}
                                              value={value.toString()}
                                              type='radio'
                                              id={`kt_rating_input_${job.id}_${value}`}
                                              checked={jobRates[job.id] === value}
                                              onChange={() => handleRatingChange(value, job.id)}
                                            />
                                            <label
                                              className='rating-label d-flex'
                                              htmlFor={`kt_rating_input_${job.id}_${value}`}
                                            >
                                              {jobRates[job.id] !== undefined &&
                                              value <= jobRates[job.id] ? (
                                                <i className='bi bi-star-fill fs-5 pt-1 text-warning me-2'></i>
                                              ) : (
                                                <i className='bi bi-star fs-5 pt-1 text-secondary me-2'></i>
                                              )}
                                            </label>
                                          </Fragment>
                                        ))}
                                      </div>
                                    </div>
                                    <span className='text-muted fw-semibold d-block'>
                                      {job?.review?.notes || 'No notes'}
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                            {job?.statusId === 5 && (
                              <div className='fw-bold fs-6 mb-2 ms-10 mt-5'>
                                {' '}
                                <h5 className='required'>Add Note </h5>
                                <Form.Group
                                  className='mb-3'
                                  controlId='exampleForm.ControlTextarea1'
                                >
                                  <Form.Control
                                    as='textarea'
                                    placeholder='Leave a note to handyman'
                                    rows={3}
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                  />
                                </Form.Group>
                                <div className='d-flex flex-end'>
                                  <button
                                    disabled={notes === '' || loadingClose}
                                    onClick={() => {
                                      handleCloseJob({
                                        id: job?.id,
                                        rate: jobRates[job.id],
                                        notes: notes,
                                        email: data?.email,
                                      })
                                    }}
                                    className='btn btn-info fw-bolder btn-sm rating-label ms-5'
                                  >
                                    Close Job
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className='separator separator-dashed text-gray-800 my-5'></div>
                        </>
                        <div>
                          <div className='mx-5 mb-8'>
                            <h3>Service Address</h3>
                          </div>
                          <div>
                            <div className='fw-bold fs-6 mb-2 ms-10'>
                              {' '}
                              <label>Address </label>
                              <p className='text-muted'>
                                {data?.address1}
                                <br />
                                {data.address2} {data.address2 && <br />} {data?.city},{' '}
                                {data?.state}, {data?.zipCode}, {data?.country}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-4 col-lg-4 col-12 border-start-1'>
                      <div className='mx-5 mb-8'>
                        <h3>History</h3>
                      </div>
                      <div className='timeline timeline-6 mt-3 ms-8'>
                        {job?.statusesHistory?.map((status: any, index: number) => {
                          let iconClass = ''

                          if (status.name === 'Completed') {
                            iconClass = 'success'
                          } else if (status.name === 'Draft') {
                            iconClass = 'secondary'
                          } else if (status.name === 'In Progress') {
                            iconClass = 'progress'
                          } else if (status.name === 'Booked') {
                            iconClass = 'info'
                          } else if (status.name === 'Canceled') {
                            iconClass = 'warning'
                          } else if (status.name === 'Closed') {
                            iconClass = 'danger'
                          } else if (status.name === 'Open' || status.name === 'New') {
                            iconClass = 'primary'
                          }
                          return (
                            <div className='timeline-item align-items-start mb-3' key={index}>
                              <div className='timeline-label fw-bolder text-dark-75 font-size-lg pe-3 me-8'>
                                <span className={`badge badge-${iconClass}`}>{status?.name}</span>
                              </div>
                              <div className='timeline-badge'>
                                <i className={`fa fa-genderless icon-xl text-${iconClass}`} />
                              </div>
                              <div className='fw-bold font-size-lg timeline-content text-muted ps-3'>
                                <div className='d-flex align-items-center'>
                                  {status?.created
                                    ? ConvertToDateCurrentTimeZoneDateTime(status?.created)
                                    : '---'}
                                  <div className='fw-bolder mb-0'>
                                    <OverlayTrigger
                                      key={'right'}
                                      placement={'right'}
                                      overlay={
                                        <Tooltip id={`tooltip-${'placement'}`}>
                                          {getFormatDateTime()}
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        style={{cursor: 'pointer'}}
                                        className='svg-icon menu-icon svg-icon-info'
                                      >
                                        <SVG
                                          src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')}
                                        />
                                      </span>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  {index !== jobsList.length - 1 && (
                    <div className='separator separator-dashed text-gray-800 mt-5 mb-10'></div>
                  )}
                </>
              ))}
            </Card.Body>
          </Card>
        </div>

        <div className='col-lg-4 col-lg-4 col-12'>
          {quotes &&
            quotes.length > 0 &&
            quotes.map((quote: any, index: any) => (
              <div className='card card-flush shadow-sm mb-5' key={index}>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2 className='me-5'>Quote #{quote?.id} </h2>
                    {quote?.isAccepted === true && (
                      <div className='mb-0 d-flex justify-content-between flex-wrap'>
                        <span className='badge badge-success'>Accepted</span>
                      </div>
                    )}
                    {quote?.isAccepted === false && (
                      <div className='mb-0 d-flex justify-content-between flex-wrap'>
                        <span className='badge badge-danger'>Rejected</span>
                      </div>
                    )}
                  </div>
                  <div className='card-toolbar'>
                    <div className='d-flex align-items-center'>
                      <div className='fw-bold fs-6 text-gray-800'>
                        {quote?.created ? moment(quote?.created).format(getFromatdate()!) : '---'}
                      </div>
                      <div className='fw-bolder '>
                        <OverlayTrigger
                          key={'top'}
                          placement={'top'}
                          overlay={
                            <Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>
                          }
                        >
                          <span
                            style={{cursor: 'pointer'}}
                            className='svg-icon menu-icon svg-icon-info'
                          >
                            <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                          </span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-body pt-0 fs-6'>
                  <div className='mb-7'>
                    <h5 className='mb-4'>Items</h5>
                    {jobsList?.map((job: any, index: number) => {
                      return (
                        <div key={index}>
                          <div className='mb-0 ms-5'>
                            <span className='fw-semibold text-gray-600 '>{job?.servicePath}</span>
                          </div>
                          <div className='separator separator-dashed my-5' />
                        </div>
                      )
                    })}
                  </div>
                  <table className='table fs-6 fw-semibold gs-0 gy-2 gx-2'>
                    <tbody>
                      <tr className=''>
                        <td className='text-gray-400'>Total</td>
                        <td className='text-gray-800 fs-3 text-end fw-bolder'>
                          {quote?.hasDiscount ? (
                            <>
                              <span className=''>{formatCurrency(quote?.discountPrice)}</span> /{' '}
                              <span className='text-danger ml-2 text-decoration-line-through'>
                                {formatCurrency(quote?.price)}
                              </span>
                            </>
                          ) : (
                            <>{formatCurrency(quote?.price)}</>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  {data?.allowToAcceptQuote && (
                    <>
                      {quote?.isAccepted === null && (
                        <div className='mb-0 d-flex justify-content-between flex-wrap'>
                          <button
                            className='btn btn-link btn-color-danger btn-active-color-primary me-5 mb-2 btn-sm'
                            onClick={() =>
                              Swal.fire({
                                title: 'Are you sure?',
                                text: 'Are you sure you want to reject this quote?',
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: '#d33',
                                cancelButtonColor: '#3085d6',
                                confirmButtonText: 'Yes, reject it!',
                              }).then((result: any) => {
                                if (result.isConfirmed) {
                                  handleRejectquote({
                                    jobsGroupId: id,
                                    quotesGroupId: quote?.id,
                                    email: data?.email,
                                  })
                                }
                              })
                            }
                          >
                            Reject
                          </button>
                          <button
                            onClick={() => {
                              handleShow()
                              setQuoteId(quote?.id)
                              setPrice(quote?.price)
                              setBilling({...billing, quotesGroupId: quote?.id})
                            }}
                            className='btn btn-light-success btn-sm'
                          >
                            Accept
                          </button>
                        </div>
                      )}
                      {quote?.isAccepted === false && (
                        <div className='mb-0 d-flex justify-content-end flex-wrap'>
                          <button
                            onClick={() => {
                              handleShow()
                              setQuoteId(quote?.id)
                            }}
                            className='btn btn-light-success btn-sm'
                          >
                            Accept
                          </button>
                        </div>
                      )}
                    </>
                  )}
                  {/*end::Actions*/}
                </div>
              </div>
            ))}
        </div>

        {/* <div className='col-xl-8 col-lg-8 col-12'>
          <div className='d-flex justify-content-between mt-5 '>
            <Link
              to='/customer/global-jobs'
              className='btn btn-secondary fs-6 rounded-pill btn-sm '
            >
              Back
            </Link>
            <button className='btn btn-light-primary fs-6 rounded-pill btn-sm'>In Progress</button>
          </div>
        </div> */}
      </div>}
      <AccepteQuteModal
        show={show}
        handleClose={handleClose}
        billing={billing}
        setBilling={setBilling}
        clientSecret={clientSecret}
        handleGetJobs={handleGetJobs}
        availableTimesArray={availableTimesArray}
        setAvailableTimesArray={setAvailableTimesArray}
        quoteId={quoteId}
      />
      <CancelModal
        handleGetJobs={handleGetJobs}
        showCancel={showCancel}
        handleCloseCancel={handleCloseCancel}
        id={id}
        email={data?.email}
      />

      {imageSrc && (
        <TaskImageViewerModal
          imageId={imageId}
          setImageId={setImageId}
          attachments={[{path: imageSrc}]}
          setImageSrc={setImageSrc}
        />
      )}
    </>
  )
}
export default JobsDetails
