import { Dispatch, SetStateAction } from 'react'

export type ID = undefined | null | number

export type PaginationState = {
  page: number
  pageNumber: number
  items_per_page: 10 | 30 | 50 | 100
  pageSize: 10 | 30 | 50 | 100
  links?: Array<{ label: string; active: boolean; url: string | null; page: number | null }>
  count: number
  pageCount: number
  hasNextPage: boolean,
}
export type TeamPassword = {
  id?: ID
  newPassword?: string
  confirmpassword?: string
}
// export type ChangeStatus = {
//   id?: ID
//   newPassword?: string
//   confirmpassword?: string
// }
export type SortState = {
  sort?: string
  sortField?: string
  order?: 'asc' | 'desc'
  sortOrder?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

export type Response<T> = {
  quotes?: T
  status?: T
  data?: T
  users?: T
  pagination: PaginationState
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  items_per_page: 10,
  pageSize: 10,
  pageNumber: 1,
  count: 0,
  pageCount: 0,
  hasNextPage: false,

}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => { },
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '' }

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID

  passwordIdForUpdate?: ID
  itemIdForUpdatePassword?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>

  setPasswordIdForUpdate?: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
  setItemIdForUpdatePassword?: Dispatch<SetStateAction<ID>>
}

export const initialListView: ListViewContextProps = {
  setItemIdForUpdatePassword: () => { },
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setPasswordIdForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
}
