/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {Card2} from '../../../../_handld/partials/content/cards/Card2'
import {IconUserModel} from '../../profile/ProfileModels'
import {PageTitle} from '../../../../_handld/layout/core'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_handld/helpers'

const itemsPerPage = 3 // Number of items to display per page

export function Jobs() {
  const [currentPage, setCurrentPage] = useState(1)
  const jobListBreadcrumbs = [
    {
      title: 'Global Jobs',
      path: '/customer/jobs/:id',
      isSeparator: false,
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]
  // Dummy data for your Card2 components
  const jobs = [
    {
      badgeColor: 'danger',
      status: 'In Progress',
      title: 'TV Wall-Mounts',
      description: 'Refers to a professional service or...',
      date: 'May 30, 2023',
      budget: '150',
      handlrAvatar: '/media/avatars/300-6.jpg',
      handlrName: 'Karina Clark',
      handlrSkills: 'Plumbing, Electrical',
      users: users1,
    },
    {
      badgeColor: 'success',
      status: 'Completed',
      title: 'Electric Fireplace',
      description: 'Refers to a professional service or...',
      date: 'July 30, 2023',
      budget: '100',
      handlrAvatar: '/media/avatars/300-3.jpg',
      handlrName: 'Neil Owen',
      handlrSkills: 'Electrical, Handyman',
      users: users1,
    },
    {
      badgeColor: 'danger',
      status: 'In Progress',
      title: 'BEDS',
      description: 'King with Storage',
      date: 'July 27, 2023',
      budget: '200',
      handlrAvatar: '/media/avatars/300-15.jpg',
      handlrName: 'Robert Doe',
      handlrSkills: 'FURNITURE ASSEMBLY',
      users: users1,
    },
    {
      badgeColor: 'danger',
      status: 'In Progress',
      title: 'SOFAS',
      description: 'Sectional w Storage',
      date: 'July 29, 2023',
      budget: '300',
      handlrAvatar: '/media/avatars/300-17.jpg',
      handlrName: 'Sean Bean',
      handlrSkills: 'FURNITURE ASSEMBLY',
      users: users1,
    },
    // ... Add more job objects here
  ]

  // Calculate the indexes of the items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = jobs.slice(indexOfFirstItem, indexOfLastItem)

  // Function to handle page changes
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber)
  }

  return (
    <>
      {/* ... Your existing JSX ... */}
      <PageTitle breadcrumbs={jobListBreadcrumbs}>Jobs list</PageTitle>
      {/* Display the items for the current page */}
      <Link to='/customer/global-jobs' className='btn btn-transparent fs-5 rounded-pill btn-sm mb-3 px-0'>
        <KTSVG
          path='/media/icons/duotune/arrows/arr021.svg'
          className='svg-icon svg-icon-2x me-1'
        />{' '}
        Back
      </Link>
      <div className='row g-6 g-xl-9'>
        {currentItems.map((job, index) => (
          <div className='col-md-6 col-xl-4' key={index}>
            <Card2
              badgeColor={job.badgeColor}
              status={job.status}
              title={job.title}
              description={job.description}
              date={job.date}
              budget={job.budget}
              handlrAvatar={job.handlrAvatar}
              handlrName={job.handlrName}
              handlrSkills={job.handlrSkills}
              users={job.users}
            />
          </div>
        ))}
      </div>

      {/* ... Your existing JSX ... */}

      {/* Pagination */}
      <div className='d-flex flex-stack flex-wrap pt-10'>
        <div className='fs-6 fw-bold text-gray-700'>
          Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, jobs.length)} of{' '}
          {jobs.length} entries
        </div>

        <ul className='pagination'>
          <li className='page-item previous'>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              className='page-link'
              disabled={currentPage === 1}
            >
              <i className='previous'></i>
            </button>
          </li>

          {/* Create pagination items */}
          {Array.from({length: Math.ceil(jobs.length / itemsPerPage)}).map((_, index) => (
            <li className={`page-item ${currentPage === index + 1 ? 'active' : ''}`} key={index}>
              <button onClick={() => handlePageChange(index + 1)} className='page-link'>
                {index + 1}
              </button>
            </li>
          ))}

          <li className='page-item next'>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              className='page-link'
              disabled={currentPage === Math.ceil(jobs.length / itemsPerPage)}
            >
              <i className='next'></i>
            </button>
          </li>
        </ul>
      </div>
    </>
  )
}

const users1: Array<IconUserModel> = [{name: 'Emma Smith', avatar: '/media/avatars/300-6.jpg'}]

const users2 = [
  {name: 'Alan Warden', initials: 'A', color: 'warning'},
  {name: 'Brian Cox', avatar: '/media/avatars/300-5.jpg'},
]
