import moment from 'moment'

export function getFromatdate(): string | null {
  if (
    localStorage.getItem('dateFormat') === null ||
    localStorage.getItem('dateFormat') === undefined ||
    localStorage.getItem('dateFormat') === ''
  ) {
    return 'DD/MM/YYYY'
  } else {
    return localStorage.getItem('dateFormat')
  }
}

export function getFormatDateTime(): string | null {
  let dateFormat = localStorage.getItem('dateFormat')
  let timeFormat = localStorage.getItem('timeFormat')

  if (!dateFormat || dateFormat === '') {
    dateFormat = 'DD/MM/YYYY'
  }

  if (!timeFormat || timeFormat === '') {
    timeFormat = 'hh:mm A'
  }

  // Combine the date and time formats, separated by a space.
  return `${dateFormat} ${timeFormat}`
}

export function ConvertToDateCurrentTimeZoneDateTime(params: any) {
  if (params === null || params === '' || params === undefined) {
    return ''
  } else {
    //params = params.replace("GMT", "");

    let newDate = moment(params).format('YYYY/MM/DD HH:mm:ss [GMT]')
    let dateConverted = new Date(newDate).toLocaleString('en-US', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
    return moment(dateConverted).format(`${getFromatdate()}, h:mm A`)
  }
}
