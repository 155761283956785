import React, { useEffect, useState } from 'react'
import { KTCard, toAbsoluteUrl } from '../../../../_handld/helpers'
import { CustomerDetailsModal } from './customer--details-modal/CustomerDetailsModal'
import { Link, useParams } from 'react-router-dom'
import QueryFetchPost from '../../../../global/index2'
import ReactPaginate from 'react-paginate'
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CustomerJobGroup } from './CustomerJobGroup'
import Select from 'react-select'
import axios from 'axios'
import moment from 'moment'
import { getFromatdate } from '../../../../_handld/helpers/GlobalHelpers'
import SVG from "react-inlinesvg";

export const Customers = () => {
  const [jobId, setJobId] = useState<any>()
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const params = useParams<any>()
  const [dataa, setdataa] = useState<any>()
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const { id } = params
  const [data, setdata] = useState<any>()
  const [pagecount, setpagecount] = useState<any>()
  const [search, setSearch] = useState<string>('')
  const [statusId, setStatusId] = useState<string>('')
  const [status, setStatus] = useState<any>([])

  const handleGetStatus = () => {
    axios.get(`${BASE_URL}/api/Jobs/GetViewModel`).then((res) => {
      const status = res?.data?.status.map((item: any) => {
        return { value: item.id, label: item.name }
      })
      setStatus(status)
    })
  }

  useEffect(() => {
    handleGetStatus()
  }, [])

  const GetJobs = async (id: any) => {
    try {
      setLoading(true)
      await QueryFetchPost({
        link: `${BASE_URL}/api/BackOffice/Groups?customerId=${id}&statusId=${statusId}&search=${search}&pageNumber=${currentPage}&pageSize=${rowsPerPage}`,
        data: undefined,
        method: 'GET',
        key: 'GetJobs',
        signal: undefined,
        isFormData: false,
      }).then((response: any) => {
        setdata(response[1].groups)
        setdataa(response[1].pagination)
        setpagecount(response[1].pagination.pageCount)
      })
    } catch (error) {
      // Handle error if needed
    } finally {
      setLoading(false) // End loading
    }
  }
  useEffect(() => {
    if ((loading === false && data === null) || []) {
      setCurrentPage(1)
      setpagecount(1)
    }
  }, [rowsPerPage])

  function handlePageClick({ selected: selectedPage }: any) {
    setCurrentPage(selectedPage + 1)
  }
  const handleRowsPerPageChange = (event: any) => {
    const newValue = parseInt(event.target.value)
    setRowsPerPage(newValue)
  }
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      GetJobs(id)
    }, 500)
    return () => clearTimeout(timeOutId)
  }, [id, currentPage, rowsPerPage, statusId, search])

  const handleStatus = (
    jobCount: any,
    hasCompleted: any,
    isClosed: any,
    status: any,
    oneStatus?: boolean
  ) => {
    if (jobCount > 0) {
      if (hasCompleted) {
        return 'Completed'
      } else {
        if (isClosed) {
          return 'Closed'
        } else {
          if (oneStatus === true) {
            return status
          }
          return 'Processing...'
        }
      }
    } else {
      return status
    }
  }
  const handleBadge = (jobCount: any, hasCompleted: any, isClosed: any, status: any) => {
    if (jobCount > 0) {
      if (hasCompleted) {
        return 'success'
      } else {
        if (isClosed) {
          return 'danger'
        } else {
          if (status === 'Draft') {
            return 'secondary'
          } else if (status === 'Processing') {
            return 'processing'
          } else if (status === 'Open') {
            return 'primary'
          } else if (status === 'Canceled') {
            return 'warning'
          } else if (status === 'Completed') {
            return 'success'
          } else if (status === 'Closed') {
            return 'danger'
          }
        }
      }
    } else {
      if (status === 'Open') {
        return 'primary'
      }
      if (status === 'Closed') {
        return 'danger'
      }
      if (status === 'Completed') {
        return 'success'
      }
      if (status === 'Canceled') {
        return 'warning'
      }
      if (status === 'Processing') {
        return 'processing'
      }
      if (status === 'In Progress') {
        return 'progress'
      }
      if (status === 'Draft') {
        return 'secondary'
      }
      if (status === 'Booked') {
        return 'info'
      }
    }
  }
  return (
    <KTCard className='bg-transparent shadow-none'>
      { loading? 
          <div className='d-flex align-items-center justify-content-center h-250px'>
            <h1 className='text-muted'>Loading...</h1>
          </div>
           :
        <div>

      <div className='d-flex justify-content-between align-items-center'>
        <div className='col-4 mb-8'>
          {/* <label className='mb-3'>Search:</label> */}
          <input
            type='text'
            className='form-control form-control-sm '
            placeholder='Search...'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className='col-4 mb-8'>
          <Select
            placeholder='Filter by'
            options={status}
            value={
              status?.filter(function (option: any) {
                return option.value === statusId
              })[0]
            }
            onChange={(e) => {
              if (e) {
                setStatusId(e.value)
              } else {
                setStatusId('')
              }
            }}
            isClearable={true}
          />
        </div>
      </div>
      {Array.isArray(data) && data.length > 0 ? (
        <div className='row p-2'>
          {data?.map((jobitem: any) => (
            <div className='col-xl-6 col-lg-6 col-md-12 mb-5' onClick={() => setJobId(jobitem?.id)}>
              <Link
                to={`/backoffice/customers-management/Groupdetails/${jobitem?.id}`}
                className='menu-link'
              >
                <div
                  className='card h-100 cursor-pointer card border border-2 border-gray-300 border-hover'
                  key={jobitem?.id}
                >
                  <div className='card-body p-9   border-gray-300'>
                    <div className='d-flex fs-3 fw-bold text-dark justify-content-between align-items-center'>
                      <span
                        className={`badge badge-${handleBadge(
                          jobitem?.jobsCount,
                          jobitem?.hasCompleted,
                          jobitem?.isClosed,
                          jobitem?.status
                        )} fw-bold me-auto px-4 py-3`}
                      >
                        {handleStatus(
                          jobitem?.jobsCount,
                          jobitem?.hasCompleted,
                          jobitem?.isClosed,
                          jobitem?.status,
                          jobitem?.oneStatus
                        )}
                      </span>
                    </div>
                    <div>
                      <div className='fs-3 fw-bolder text-dark d-flex align-items-center mt-5'>
                        <span className='me-5'>{jobitem?.service}</span>{' '}
                        {jobitem?.jobsCount > 0 && (
                          <span className='badge badge-success'> + {jobitem?.jobsCount}</span>
                        )}
                      </div>
                    </div>
                    <p className='text-gray-400 fw-semibold fs-5 mt-1 mb-7'>
                      {jobitem?.additionalDetails}
                    </p>

                    <div className='d-flex flex-wrap mb-5'>
                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                        <div className="d-flex align-items-center">
                          <div className='fs-6 text-gray-800 fw-bold'>
                            {jobitem?.created ? moment(jobitem?.created).format(getFromatdate()!) : '---'}
                          </div>
                          <div className="font-weight-bolder">
                            <OverlayTrigger
                              key={"right"}
                              placement={"right"}
                              overlay={
                                <Tooltip id={`tooltip-${"placement"}`}>
                                  {getFromatdate()}
                                </Tooltip>
                              }
                            >
                              <span
                                style={{ cursor: "pointer" }}
                                className="svg-icon menu-icon svg-icon-info"
                              >
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/Code/Info-circle.svg"
                                  )}
                                />
                              </span>
                            </OverlayTrigger>
                          </div>
                        </div>

                        <div className='fw-semibold text-gray-400'>Created Date</div>
                      </div>

                      <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                        <div className='fs-6 text-gray-800 fw-bold'>
                          {jobitem?.price
                            ? new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            }).format(jobitem?.price)
                            : '--'}
                        </div>
                        <div className='fw-semibold text-gray-400'>Price</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      ) : (
        <>
          <Card className='h-100'>
            <Card.Body>
              <img
                alt='no Result'
                src={toAbsoluteUrl('/media/logos/NoResult.png')}
                className='logo-default w-100 mh-xl-3500px mh-lg-350px h-100'
                style={{ objectFit: 'contain' }}
              />
              <h1 className='text-muted fw-bold text-center'>No Results Found</h1>
            </Card.Body>
          </Card>
        </>
      )}
      {jobId !== null && jobId !== undefined && (
        <Link
          to={`/backoffice/customers-management/Groupdetails/${jobId}`}
          className='menu-link px-3'
        >
          <CustomerJobGroup />
        </Link>
      )}
      <div className='row'>
        <div className='col-sm-10 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start my-5'>
          <span className='me-5 pr-3 fw-bold'>Total rows: {dataa?.totalItemCount}</span>
          <div className='mr-5'>
            {/* Add the dropdown/select for selecting the number of rows */}
            <label className='me-3 fw-bold' htmlFor='rowsPerPageSelect'>
              Rows per page:
            </label>
            <select id='rowsPerPageSelect' value={rowsPerPage} onChange={handleRowsPerPageChange}>
              <option value={10}>10</option>
              <option value={26}>26</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          </div>
        </div>
        <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end mb-5'>
          <div id='kt_table_users_paginate'>
            <ReactPaginate
              previousLabel={'Previous'}
              nextLabel={'Next'}
              pageCount={pagecount}
              onPageChange={handlePageClick}
              containerClassName={'pagination'}
              previousLinkClassName={'pagination__link'}
              nextLinkClassName={'pagination__link'}
              disabledClassName={'pagination__link--disabled'}
              activeClassName={'pagination__link--active'}
            />
          </div>
        </div>
      </div>
                
      </div>}
    </KTCard>
  )
}
