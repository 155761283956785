import moment from "moment";

export const AppString = {
  ID: "id",
  PHOTO_URL: "photoUrl",
  PUSH_TOKEN: "pushToken",
  NICKNAME: "nickname",
  ABOUT_ME: "aboutMe",
  NODE_MESSAGES: "messages",
  NODE_NOTIFICATIONS: "notifications",
  NODE_USERS: "users",
  NODE_JOBS: "jobs",
  NODE_LOADS: "loads",
  UPLOAD_CHANGED: "state_changed",
  DOC_ADDED: "added",
  PREFIX_IMAGE: "image/",
};
export function truncateString(str, num) {
  try {
    if (str?.length > num) {
      return str?.slice(0, num) + "...";
    } else {
      return str;
    }
  } catch (err) {

  }
}

export function timeSince(dt) {
  var date = new Date(moment(Number(dt)).format(
    "lll"
  ));
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " year";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " month";
  }
  interval = seconds / 86400;
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + " day";
  }else if(interval > 2){
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval >= 1 && interval < 2) {
    return Math.floor(interval) + " hr";
  }else if(interval > 2){
    return Math.floor(interval) + " hrs";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " min";
  }
  return "just now";
}
