/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {toAbsoluteUrl} from '../../../helpers'
import QueryFetchPost, {ModelStateMessage} from '../../../../global/index2'
const AUTH_LOCAL_STORAGE_KEY = 'User'
const BASE_URL = process.env.REACT_APP_BASE_URL

interface Model {
  firstName: string
  lastName: string
  email: string
  profilePicture: null
  token: string
}
const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const [showPassword, setShowPassword] = useState<Model | undefined>()
  const [dataa, setdataa] = useState<any>()
  const [pic, setpic] = useState<any | undefined>()
  const [imageApi, setimageApi] = useState<any>(null)
  const GetProfile = async () => {
    localStorage.removeItem('profilePicture')
    document?.getElementById('testtest')?.click()
    setShowPassword(undefined)
    await QueryFetchPost({
      link: `${BASE_URL}/api/Account/Profile`,
      data: undefined,
      method: 'GET',
      key: 'Profile',
      signal: undefined,
      isFormData: false,
    }).then((response) => {
      if (response[0] === 200) {
        setShowPassword(response[1].profile)
        setdataa(response[1].profile)
        if (response[1].profile.profilePicture) {
          setShowPassword(response[1].profile)
          setimageApi(response[1].profile.profilePicture)
        } else {
          setimageApi(null)
        }
      } else {
        ModelStateMessage(response)
      }
    })
  }
  useEffect(() => {
    setpic(localStorage.getItem('profilePicture'))
    GetProfile()
  }, [])

  // Use the useLocation hook to get the current pathname
  const {pathname} = useLocation()
  const isBackOffice = currentUser && !pathname.includes('backoffice')
  const baseUrl = isBackOffice ? '/customer/account' : '/backoffice/account'
  const linkTo = `${baseUrl}/settings`
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{backgroundImage: 'url(/media/svg/avatars/blank.svg)'}}
            >
              {(pic !== null && pic !== 'null') || imageApi !== null ? (
                <img
                  alt='Logo'
                  className='image-input-wrapper w-50px h-50px'
                  src={toAbsoluteUrl(imageApi)}
                />
              ) : (
                <img
                  alt='Logo'
                  className='image-input-wrapper w-50px h-50px'
                  src={toAbsoluteUrl('/media/svg/avatars/blank.svg')}
                />
              )}
            </div>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bold d-flex align-items-center fs-5'>
              {dataa?.firstName} {dataa?.lastName}
            </div>
            <a href='#' className='fw-semibold text-muted text-hover-primary fs-7  text-break'>
              {dataa?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'>
        <button
          id='testtest'
          onClick={() => {
            document?.getElementById('testtest2')?.click()
            setpic(localStorage.getItem('profilePicture'))
            GetProfile()
            setShowPassword(JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}'))
          }}
          className='btn mt-1 bg-white'
        ></button>
      </div>

      {/* <Languages /> */}

      <div className='menu-item px-5 my-1'>
        <Link to={linkTo} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
