import firebase from "firebase";
import { AppString } from "./Component/Const";
import { myFirebase, myFirestore } from "./Config/firebase";
import low from "lowdb";
import LocalStorage from "lowdb/adapters/LocalStorage";
import moment from "moment";
import { v1 as uuidv1 } from 'uuid';
const adapter = new LocalStorage("db");
const db = low(adapter);

const registerWithEmailAndPassword = async (name, email, password, userApi) => {
  try {
    myFirebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (result) => {
        let user = result.user
        if (user) {
          const result = await myFirestore
            .collection(AppString.NODE_USERS)
            .where(AppString.ID, '==', user.uid)
            .get()

          if (result.docs.length === 0) {
            // Set new data since this is a new user
            await myFirestore
              .collection('users')
              .doc(user.uid)
              .set({
                id: user.uid,
                email: email,
                nickname: `${userApi?.firstName} ${userApi?.lastName}`,
                photoUrl: userApi?.profilePicture,
                aboutMe: '',
              })
              .then((data) => {
                // Write user info to local
                localStorage.setItem(AppString.ID, user.uid)
                localStorage.setItem(AppString.NICKNAME, user.displayName)
                localStorage.setItem(AppString.PHOTO_URL, user.photoURL)
              })
            await fetchPushToken(user.uid, localStorage.getItem(AppString.PUSH_TOKEN))
          } else {
            // Write user info to local
            localStorage.setItem(AppString.ID, result.docs[0].data().id)
            localStorage.setItem(AppString.NICKNAME, result.docs[0].data().nickname)
            localStorage.setItem(AppString.PHOTO_URL, result.docs[0].data().photoUrl)
            localStorage.setItem(AppString.ABOUT_ME, result.docs[0].data().aboutMe)
            await fetchPushToken(
              result.docs[0].data().id,
              localStorage.getItem(AppString.PUSH_TOKEN)
            )
          }
        } else {
          console.log('User info not available')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  } catch (err) {
    console.error('err', err)
  }
}
function FetchTokenApi(id, token) {
  var myHeaders = new Headers();
  const tokenServer = db.read().getState()?.Token;
  myHeaders.append("Authorization", `Bearer ${db.read().getState()?.Token}`);
  myHeaders.append("Content-Type", `application/json`);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify({
      Token: token,
      UserId: id,
    }),
    redirect: "follow",
  };
  if (tokenServer !== null && tokenServer !== undefined && tokenServer !== "") {
    fetch(`${window.$apiurl}/api/Account/setFirebaseToken`, requestOptions)
      .then(async (response) => {
        const statusCode = await response.status;
        const contentType = response.headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then((data) => [statusCode, data]);
        } else {
          return response.text().then((text) => [statusCode, { Message: "" }]);
        }
      })
      .then(async (result) => {
       console.log(result);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

}
const updateProfilePicture = async (link, name) => {
  try {
    const id = localStorage.getItem(AppString.ID);
    const result = await myFirestore
      .collection(AppString.NODE_USERS)
      .where(AppString.ID, "==", id)
      .get();
    if (result.docs.length > 0) {
      const currentData = await myFirestore
        .collection(AppString.NODE_LOADS)
        .where('shipperId', "==", id)
        .get();
      if (currentData?.docs?.length > 0) {
        currentData.forEach((s) => {
          myFirestore
            .collection("loads")
            .doc(`${s?.data()?.id}-${s?.data()?.userId}-${s?.data()?.shipperId}`)
            .update({
              nickname: name,
              photoUrl: link ?? "",
            });
        });
      }
      myFirestore
        .collection("users")
        .doc(id)
        .update({
          photoUrl: link,
          nickname: name,
        })
        .then((data) => {
          localStorage.setItem(AppString.PHOTO_URL, link);
          localStorage.setItem(AppString.NICKNAME, name);
        });
    }
  } catch (err) {
    console.error("err", err);
  }
};
async function fetchPushToken(id, token) {
  try {
    const result = await myFirestore
      .collection(AppString.NODE_USERS)
      .where(AppString.ID, "==", id)
      .get();
    if (result.docs.length > 0) {
      myFirestore
        .collection("users")
        .doc(id)
        .update({
          pushToken: token,
        })
        .then((data) => {
          localStorage.setItem(AppString.PUSH_TOKEN, token);
        });
    }

    //fetch api token
    FetchTokenApi(id, token);
  } catch (err) {
    console.error("err", err);
  }
}

async function fetchDataToggle(id, data) {
  try {
    let dataPost = {...data};
      myFirestore
        .collection(AppString.NODE_NOTIFICATIONS)
        .doc(id)
        .set(dataPost)
        .then((data) => {
          // localStorage.setItem(AppString.NODE_NOTIFICATIONS, dataPost);
        });

  } catch (err) {
    console.error("err", err);
  }
}

const logInWithEmailAndPassword = async (email, password,userApi) => {
  try {
    myFirebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(async (result) => {
        let user = result.user;
        if (user) {
          
          await myFirestore
            .collection(AppString.NODE_USERS)
            .doc(user.uid)
            .update({
              nickname: `${userApi?.firstName} ${userApi?.lastName}`,
              photoUrl: userApi?.profilePicture,
            })
            .then((data) => {
              console.log(data)
            })
            const result = await myFirestore
            .collection(AppString.NODE_USERS)
            .where(AppString.ID, '==', user.uid)
            .get()
          // Write user info to local
          localStorage.setItem(AppString.ID, result.docs[0].data().id);
          localStorage.setItem(
            AppString.NICKNAME,
            result.docs[0].data().nickname
          );
          localStorage.setItem(
            AppString.PHOTO_URL,
            result.docs[0].data().photoUrl
          );
          localStorage.setItem(
            AppString.ABOUT_ME,
            result.docs[0].data().aboutMe
          );
          await fetchPushToken(
            user.uid,
            localStorage.getItem(AppString.PUSH_TOKEN)
          );
        } else {
          console.log("User info not available");
        }
        //update pushToken
      })
      .catch(async (err) => {
        if (err.code === "auth/user-not-found" || err.code === "auth/internal-error") {
          await registerWithEmailAndPassword(
            email,
            email,
            `pass-${email?.replace(' ', '')}`,
            userApi
          )
        }
        console.log('err',err);
      });
  } catch (err) {
    alert(2)
    console.error(err);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  firebase.auth().signOut();
};
export {
  registerWithEmailAndPassword,
  logInWithEmailAndPassword,
  updateProfilePicture,
  sendPasswordReset,
  logout,
  fetchDataToggle,
  fetchPushToken,
};
