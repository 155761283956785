import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate, useLocation} from 'react-router-dom'
import {MasterLayout} from '../../_handld/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_handld/assets/ts/_utils'
import {WithChildren} from '../../_handld/helpers'
import HandlrPage from '../pages/handlr-management/HandlrPage'
import {LandingPage} from '../modules/landing/landing'
import DetailsPage from '../pages/handlr-management/handlr-details/DetailsPage'
import {Jobs} from '../modules/landing/my-jobs/Jobs'
import JobsDetails from '../modules/landing/my-jobs/jobsdetails'
import JobPage from '../pages/job-management/JobPage'
import CustomerPage from '../pages/customer-management/CustomerPage'
import CustomerDetailsPage from '../pages/customer-management/customer-details/DetailsPage'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import AboutUs from '../modules/landing/components/Aboutus'
import ContactUs from '../modules/landing/components/Contact'
import {FAQ} from '../modules/landing/components/faq'
import {GlobalJobs} from '../modules/landing/my-jobs/GlobalJobs'
import BecomeHandlr from '../modules/landing/components/BecomeHandlr'
import QuotesPage from '../pages/job-management copy/QuotesPage'
import {CustomerJobGroup} from '../pages/customer-management/customer-details/CustomerJobGroup'
import {useAuth} from '../modules/auth'
import {PrivacyPolicy} from '../modules/landing/components/PrivacyPolicy'
import {TermsAndConditions} from '../modules/landing/components/TermsAndConditions'

const PrivateRoutes = () => {
  const {currentUser} = useAuth()
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const ChatPageFirebase = lazy(() => import('../modules/apps/ChatComp/Component/Main/Main'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const {pathname} = useLocation()
  // Determine if the user is in the backoffice section
  const isBackOffice = currentUser && pathname.includes('backoffice')

  // Define the path based on the user's section
  const pathAccount = isBackOffice ? '/backoffice/account/*' : '/customer/account/*'
  const pathChat = isBackOffice ? '/backoffice/chat/*' : '/customer/chat/*'
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/landing' />} />
        {/* Pages */}
        {currentUser?.roles[0] === 'Client admin' && (
          <Route path='landing/*' element={<LandingPage />} />
        )}
        {currentUser?.roles[0] === 'Client admin' && (
          <Route path='services' element={<LandingPage />} />
        )}
        <Route path='become-a-handyman' element={<BecomeHandlr />} />
        {currentUser?.roles[0] === 'Client admin' && <Route path='faq' element={<FAQ />} />}
        {currentUser?.roles[0] === 'Client admin' && (
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
        )}
        {currentUser?.roles[0] === 'Client admin' && (
          <Route path='terms-and-conditions' element={<TermsAndConditions />} />
        )}
        {currentUser?.roles[0] === 'Client admin' && (
          <Route path='About-us' element={<AboutUs />} />
        )}
        {currentUser?.roles[0] === 'Client admin' && (
          <Route path='Contact-us' element={<ContactUs />} />
        )}
        {/* Lazy Modules */}
        {currentUser?.roles[0] === 'Client admin' && (
          <Route
            path='customer/profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path={pathAccount}
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path={pathChat}
          element={
            <SuspensedView>
              <ChatPageFirebase />
            </SuspensedView>
          }
        />
        {currentUser?.roles[0] === 'Super admin' && (
          <Route
            path='backoffice/*'
            element={
              <SuspensedView>
                <HandlrPage />
              </SuspensedView>
            }
          />
        )}
        {currentUser?.roles[0] === 'Super admin' && (
          <Route
            path='backoffice/jobs-management'
            element={
              <SuspensedView>
                <JobPage />
              </SuspensedView>
            }
          />
        )}
        {currentUser?.roles[0] === 'Super admin' && (
          <Route
            path='backoffice/quotes-management'
            element={
              <SuspensedView>
                <QuotesPage />
              </SuspensedView>
            }
          />
        )}
        {currentUser?.roles[0] === 'Super admin' && (
          <Route
            path='backoffice/customers-management'
            element={
              <SuspensedView>
                <CustomerPage />
              </SuspensedView>
            }
          />
        )}
        {currentUser?.roles[0] === 'Super admin' && (
          <Route
            path='backoffice/handyman-management/details/:id'
            element={
              <SuspensedView>
                <DetailsPage />
              </SuspensedView>
            }
          />
        )}
        {currentUser?.roles[0] === 'Super admin' && (
          <Route
            path='backoffice/customers-management/Groupdetails/:id'
            element={
              <SuspensedView>
                <CustomerJobGroup />
              </SuspensedView>
            }
          />
        )}
        {currentUser?.roles[0] === 'Super admin' && (
          <Route
            path='backoffice/customers-management/details/:id'
            element={
              <SuspensedView>
                <CustomerDetailsPage />
              </SuspensedView>
            }
          />
        )}
        {currentUser?.roles[0] === 'Client admin' && (
          <Route
            path='customer/jobs/:id'
            element={
              <SuspensedView>
                <Jobs />
              </SuspensedView>
            }
          />
        )}
        {currentUser?.roles[0] === 'Client admin' && (
          <Route
            path='customer/global-jobs'
            element={
              <SuspensedView>
                <GlobalJobs />
              </SuspensedView>
            }
          />
        )}
        <Route
          path='become-handyman'
          element={
            <SuspensedView>
              <BecomeHandlr />
            </SuspensedView>
          }
        />
        {currentUser?.roles[0] === 'Client admin' && (
          <Route
            path='customer/jobs/details/:id'
            element={
              <SuspensedView>
                <JobsDetails />
              </SuspensedView>
            }
          />
        )}
        {currentUser?.roles[0] === 'Super admin' && (
          <Route
            path='backoffice/dashboard'
            element={
              <SuspensedView>
                <DashboardWrapper />
              </SuspensedView>
            }
          />
        )}
        {/* <Route
          path='customer/post-job'
          element={
            <SuspensedView>
              <PostJob />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
