import axios from 'axios'
import React, {FC} from 'react'
import {handleApiErrors} from '../../global/HandleApiErrors'

const API_URL = process.env.REACT_APP_API_URL

type Props = {
  setStep: (step: number) => void
  jobsList: any
  address: any
  auth?: any
  setJobId: (quoteId: any) => void
}
const Confirmation: FC<Props> = ({setStep, address, jobsList, auth, setJobId}) => {
  const [loading, setLoading] = React.useState(false)
  const handleSubmit = async (data: any) => {
    setLoading(true)
    try {
      await axios.post(`${API_URL}/api/Jobs`, data).then((res) => {
        setJobId(res?.data?.id)
        handlePostAttachments(jobsList, res?.data?.jobsIds)
        setStep(3)
        if (
          window.location.pathname.includes('customer/jobs/details') ||
          window.location.pathname.includes('customer/global-jobs')
        ) {
          document.getElementById('refresh-jobs-btn')?.click()
        }
      })
    } catch (error: any) {
      handleApiErrors(error?.response?.data)
    } finally {
      setLoading(false)
    }
  }

  const handlePostAttachments = async (jobs: [], ids: []) => {
    jobs.map((job: any, index: any) => {
      if (job?.files?.length > 0 && job?.placementDifficulty) {
        job?.files?.map((file: any) => {
          const formData = new FormData()
          formData.append('file', file)
          formData.append('id', ids[index])
          formData.append('email', address?.email)
          axios.post(
            `${API_URL}/api/Jobs/UploadPlacementDifficultyAttachment/${ids[index]}`,
            formData
          )
        })
      }
    })
  }

  return (
    <div className='shadow p-6'>
      <div>
        <div className='mx-5 mb-8 mt-8'>
          <h3 className='ml-5'>Booking Confirmation</h3>
          <label className='fw-semibold text-muted'>
            Secure Your Appointment and Provide Contact and Payment Information
          </label>
        </div>
      </div>
      <div className='separator separator-solid text-gray-800 my-5'></div>
      {jobsList?.map((job: any, index: number) => {
        return (
          <div key={index}>
            <div>
              <div className='mx-5 mb-3 d-flex align-items-center'>
                <h3 className='me-5'>Service</h3>
                <span className='badge badge-light-danger fs-8 fw-bold'> {index + 1}</span>
              </div>
              <div className='row'>
                <div className='col-xl-12 col-lg-12 col-md-12'>
                  <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                    {' '}
                    <label>Name</label>
                    <p className='text-muted'>
                      {job?.serviceName}{' '}
                      {job?.serviceCategoryName && (
                        <i className='fa-solid fa-arrow-right-long text-primary mx-3'></i>
                      )}{' '}
                      {job?.serviceCategoryName}{' '}
                      {job?.serviceTypeCategoryName && (
                        <i className='fa-solid fa-arrow-right-long text-primary mx-3'></i>
                      )}
                      {job?.serviceTypeCategoryName}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed text-gray-800 my-5'></div>
            <div>
              <div className='mx-5 mb-3'>
                <h3 className='me-5'>Service Details</h3>
              </div>
              <div className='row'>
                {job?.itemsFrom && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mx-10'>
                      {' '}
                      <label className=''>
                        {job.category === 'BEDS' && 'Bed is from'}
                        {job.category === 'SOFA' && 'Sofa is from'}
                        {(job.category === 'DINIF' ||
                          job.category === 'TABL' ||
                          job.category === 'SEAT') &&
                          'Table & chair from:'}
                        {job.category === 'FDESK' && 'Desk & chair from:'}
                        {job.category === 'BEDR' && '(Item) is from'}
                        {job.category === 'SHEL' && 'Shelves are from'}
                        {job.category === 'CABI' && 'Cabinets are from'}
                        {job.category === 'WALLL' && 'Wall-Lamp(s) are from'}
                        {job.category === 'WALLMI' && 'Model Name/Number'}
                      </label>
                      <p className='text-muted'>{job?.itemsFrom}</p>
                    </div>
                  </div>
                )}
                {job?.model && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>Model Name/Number</label>
                      <p className='text-muted'>{job?.model}</p>
                    </div>
                  </div>
                )}

                {job?.category === 'TVWA' && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>{job.category === 'TVWA' && 'Size of TV'}</label>
                      <p className='text-muted'>{job?.sizeInch} inches</p>
                    </div>
                  </div>
                )}

                {job.category === 'TVWA' && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>{job.category === 'TVWA' && 'Wall-Mount on hand'}</label>
                      <p className='text-muted'>{job?.wallMountOnHand ? 'Yes' : 'No'}</p>
                    </div>
                  </div>
                )}
                {job.category === 'TVWA' && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>
                        {job.category === 'TVWA' &&
                          'Confirm Wall-Mount is correct specific to size of their TV:'}
                      </label>
                      <p className='text-muted'>{job?.wallSpecificSize ? 'Yes' : 'No'}</p>
                    </div>
                  </div>
                )}
                {job?.wallType && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>
                        {job.category === 'TVWA' && 'Wall Info TV is being mounted to:'}
                        {job.category === 'SHEL' && 'Wall Info Shelf are being mounted to:'}
                        {job.category === 'ARTP' &&
                          'Wall Info Art/Picture(s)  is being mounted to:'}
                        {job.category === 'CABI' && 'Wall Info Cabinet(s) are being mounted to:'}
                        {job.category === 'WALLMI' && 'Wall Info Wall-Mirror(s)  being mounted to:'}
                        {job.category === 'CURT' && 'Wall Info Curtain Rod(s)  being mounted to:'}
                        {job.category === 'SMAL' && 'Wall Info: item(s)  being mounted to:'}
                      </label>
                      <p className='text-muted'>{job?.wallType}</p>
                    </div>
                  </div>
                )}
                {job?.numberOfItems !== 0 && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>
                        {job.category === 'TVWA' && 'Number of Tvs'}
                        {job.category === 'SMAL' && 'Number of small items'}
                        {job.category === 'SHEL' && 'Number of Shelves'}
                        {job.category === 'ARTP' && 'Number of pictures'}
                        {(job.category === 'CURT' || job.category === 'WALLMI') &&
                          'Number of Curtain Rods'}
                        {job?.category === 'OTLI' && 'Number of outlets to be installed?'}
                        {job?.category === 'LFRPL' && 'Number of light fixtures to be replaced?'}
                        {job?.category === 'CLFI' && 'Number of ceiling fans to be installed?'}
                        {job?.category === 'FRPRP' &&
                          'Number of faucets to be repaired or replaced?'}
                        {job?.category === 'TOIRP' && 'Number of toilets to be replaced?'}
                        {job?.category === 'LKRP' && 'Number of leaks to be repaired?'}
                        {job?.category === 'IDWMC' && 'Number of appliances to be installed?'}
                        {job?.category === 'HWHIM' &&
                          'Number of hot water heaters to be inspected or maintained?'}
                        {job?.category === 'PPINS' && 'Number of pipes to be insulated?'}
                        {job?.category === 'SHRP' && 'Number of shower heads to be replaced?'}
                      </label>
                      <p className='text-muted'>{job?.numberOfItems}</p>
                    </div>
                  </div>
                )}
                {job?.length !== 0 && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>{job.category === 'SHEL' && 'Length of Shelves'}</label>
                      <p className='text-muted'>{job?.length}</p>
                    </div>
                  </div>
                )}
                {job?.size &&
                  job.category !== 'RCLN' &&
                  job.category !== 'DCLN' &&
                  job.category !== 'SCLN' &&
                  job.category !== 'GRDPLN' && 
                  job.code !== 'YRDGRD' && 
                  job.code !== 'MHLP' && (
                    <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                      <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                        {' '}
                        <label>Size</label>
                        <p className='text-muted'>{job?.size}</p>
                      </div>
                    </div>
                  )}

                {job?.size &&
                  (job.category === 'RCLN' ||
                    job.category === 'DCLN' ||
                    job.category === 'SCLN') && (
                    <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                      <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                        {' '}
                        <label>Surface: What type of surface do you need cleaned?</label>
                        <p className='text-muted'>{job?.size}</p>
                      </div>
                    </div>
                  )}
                {job?.size && job.code === 'MHLP' && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>Boxes: How many boxes do you need moved?</label>
                      <p className='text-muted'>{job?.size}</p>
                    </div>
                  </div>
                )}
                {job?.vehiculeNeeded && job.code === 'MHLP' && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>Vehicle Requirements : What type of vehicle do you need?</label>
                      <p className='text-muted'>{job?.vehiculeNeeded}</p>
                    </div>
                  </div>
                )}
                {job?.EndAddress1 && job.code === 'MHLP' && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>End address:</label>
                      <p className='text-muted'>
                        {job?.EndAddress1}, {job?.EndAddress2 && job?.EndAddress2 + ','} {job?.EndCity},{' '}
                        {job?.EndState}, {job?.EndZipcode}, {job?.EndCountry}
                      </p>
                    </div>
                  </div>
                )}
                {job?.CoatType && job.code === 'PNT' && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      <label>Type of Coat:</label>
                      <p className='text-muted'>{job?.CoatType}</p>
                    </div>
                  </div>
                )}
                {job?.Type && job.code === 'PNT' && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      <label>Type:</label>
                      <p className='text-muted'>{job?.Type}</p>
                    </div>
                  </div>
                )}
                {job?.size && job.code === 'YRDGRD' && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      <label>Surface:</label>
                      <p className='text-muted'>{job?.size}</p>
                    </div>
                  </div>
                )}
                {job.category === 'ELEC' && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>Assembly of Fireplace required:</label>
                      <p className='text-muted'>{job?.assemblyRequired ? 'Yes' : 'No'}</p>
                    </div>
                  </div>
                )}
                {job.category === 'CABI' && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>Assembly of Cabinet Required:</label>
                      <p className='text-muted'>{job?.assemblyRequired ? 'Yes' : 'No'}</p>
                    </div>
                  </div>
                )}
                {job?.itemLink && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>
                        {job.category === 'ELEC' && 'Link input for electric fireplace make/model'}
                      </label>
                      <p className='text-muted'>{job?.itemLink}</p>
                    </div>
                  </div>
                )}
                {job?.category === 'ELEC' && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>
                        {job.category === 'ELEC' &&
                          'Depending on height/placement will someone be on hand to assist Handyman in lifting fireplace to the mounting bracket:'}
                      </label>
                      <p className='text-muted'>{job?.assistHandlr ? 'Yes' : 'No'}</p>
                    </div>
                  </div>
                )}
                {job?.numberOfWindows !== 0 && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>{job.category === 'CURT' && 'Number of Windows'}</label>
                      <p className='text-muted'>{job?.numberOfWindows}</p>
                    </div>
                  </div>
                )}
                {job?.width !== 0 && job?.width !== null && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>Width</label>
                      <p className='text-muted'>{job?.width}</p>
                    </div>
                  </div>
                )}
                {job?.height !== 0 && job?.height !== null && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>Height</label>
                      <p className='text-muted'>{job?.height}</p>
                    </div>
                  </div>
                )}
                {job?.items && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>{job.category === 'SMAL' && 'Items/Description'}</label>
                      <p className='text-muted'>{job?.items}</p>
                    </div>
                  </div>
                )}
                {job?.category === 'BEDS' && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>Does this Model require Headboard to be Wall-Mounted?</label>
                      <p className='text-muted'>{job?.headboardWallMounted ? 'Yes' : 'No'}</p>
                    </div>
                  </div>
                )}
                {(job?.typeCode === 'KING' ||
                  job?.typeCode === 'QUEE' ||
                  job?.typeCode === 'FULL') && (
                  <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                      {' '}
                      <label>Confirmation NO storage drawers on this model</label>
                      <p className='text-muted'>{job?.storageDrawers ? 'Yes' : 'No'}</p>
                    </div>
                  </div>
                )}
              </div>
              <div className='row'>
                <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                  <div className='ms-10 mb-8 mt-3'>
                    <div className='fw-bold fs-6 mb-2'>
                      {' '}
                      <label>Additional Notes/Info</label>
                      <p className='text-muted'>
                        {' '}
                        {job.additionalDetails ? job.additionalDetails : 'No additional details'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12 col-12'>
                  {job?.placementDifficulty && job?.files?.length > 0 && (
                    <div className='mx-5 mb-8 mt-3'>
                      <div className='fw-bold fs-6 mb-2'>
                        <label>Placement:</label> <br />
                        <img
                          src={URL.createObjectURL(job?.files[0])}
                          alt='placement difficulty'
                          className='w-80px h-80px rounded mt-2'
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='separator separator-solid text-gray-800 my-5'></div>
            </div>
          </div>
        )
      })}
      <div>
        <div className='mx-5 mb-8'>
          <h3>Service Address</h3>
        </div>
        <div>
          <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
            {' '}
            <span className='text-muted'>{address.address1}</span>{', '}
            {address.address2 && (
              <>
                <span className='text-muted'>{address.address2}</span>
                <br />
              </>
            )}
            <span className='text-muted'>
              {address.city}, {address.state}, {address.zipcode}
            </span>{' '}
            <br />
            <span className='text-muted'>{address.country} </span>
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-between my-5 py-5'>
        <button onClick={() => setStep(1)} className='btn btn-sm btn-secondary px-5'>
          Back
        </button>
        <button
          className='btn btn-primary btn-sm px-5'
          disabled={loading}
          onClick={() => {
            const jobs = [...jobsList]
            handleSubmit({...address, jobs})
          }}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
    </div>
  )
}

export default Confirmation
