/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../_handld/partials/layout/theme-mode/ThemeModeProvider'
import {getCSS, getCSSVariableValue} from '../../../_handld/assets/ts/_utils'
import { handleApiErrors } from '../../global/HandleApiErrors'
import QueryFetchPost from '../../../global/index2'
import { FlatpickrRange } from '../../../global/FlatpickrRange'
import flatpickr from 'flatpickr'
import moment from 'moment'

type Props = {
  className: string
}

function generateShadesWithOpacities(baseColor: string, numShades: number, minOpacity: number, maxOpacity: number): string[] {
  const getColorShade = (color: string, opacity: number): string => {
    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);
    console.log(`rgba(${r}, ${g}, ${b}, ${opacity})`)
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const colorShades: string[] = [];
  const opacityStep: number = (maxOpacity - minOpacity) / (numShades - 1);

  for (let i = 0; i < numShades; i++) {
    const opacity: number = minOpacity + i * opacityStep;
    colorShades.push(getColorShade(baseColor, opacity));
  }

  return colorShades;
}
const StatisticsChart: React.FC<Props> = ({className}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [series, setSeries] = React.useState<any[]>([])
  const [data, setdata] = React.useState<any>()
  const [customers, setcustomers] = React.useState<any>()
  const [handlr, sethandlr] = React.useState<any>()
  const [jobs, setjobs] = React.useState<any>()
  const [revenue, setrevenue ] = React.useState<any>()
  const [startDate, setStartDate] = React.useState<any>('')
  const [endDate, setEndDate] = React.useState<any>('')
  const flatpickrInstanceRef = React.useRef<flatpickr.Instance | null>(null)
  const Getstatistics = async () => {
    try {

      await QueryFetchPost({
        link: `${BASE_URL}/api/BackOffice/Statistics?startDate=${startDate}&endDate=${endDate}`,
        data: undefined,
        method: 'GET',
        key: 'GetJobs',
        signal: undefined,
        isFormData: false,
      }).then((response: any) => {
        setdata(response[1])
        setcustomers(response[1]?.customers)
        sethandlr(response[1]?.handlr)
        setjobs(response[1]?.jobs)
        setrevenue(response[1]?.revenue)
        
      })
    }
    catch (error) {
      handleApiErrors(error)
    }
  }
  useEffect(() => {
    Getstatistics()
  }, [startDate, endDate])
  useEffect(() => {
    if(data === null){
 Getstatistics()
    }
   
  }, [data])
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(chartRef.current, getChartOptions(height, {
      jobs,
      customers,
      handlr,
      revenue,
    }));
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode , data])

  type RevenueData = { year: number; month: number; count: number };

  const getMonthNames = (data: RevenueData[]) => {
    const monthNamesArray: string[] = [];
    data?.forEach((item: RevenueData) => {
      const monthName = getMonthWithYear(item.month, item.year);
      monthNamesArray.push(monthName);
    });
    return monthNamesArray;
  };
  
  const getMonthWithYear = (month: number, year: number) => {
    const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthAbbreviation = monthAbbreviations[month - 1] || '';
    return `${monthAbbreviation} '${year}`;
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Statistics</span>
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
        <div className='d-flex align-items-center'>
            <label className='form-label mb-6 mx-2' >Date Range:</label>
            <div className='w-100'>
            <FlatpickrRange
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate ? moment(startDate).format('MM-DD-YYYY') : ''}
              endDate={endDate ? moment(endDate).format('MM-DD-YYYY') : ''}
              flatpickrInstanceRef={flatpickrInstanceRef}
            />
            </div>
          </div>
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_6_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )




function getChartOptions(height: number , data: { jobs: any[], customers: any[], handlr: any[], revenue: any[] }): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')

  // const colorsWithOpacities = generateShadesWithOpacities('#f87060', 5, 0.2, 0.9);

  
  return {
    series: [
      {
        name: 'Jobs',
        type: 'line',
        data: jobs?.map((item: any) => Number(item.count).toFixed(0)),
      },
      {
        name: 'Customers',
        type: 'line',
        data: customers?.map((item: any) => Number(item.count).toFixed(0)),
      },
      {
        name: "Handymen",
        type: 'line',
        data: handlr?.map((item: any) => Number(item.count).toFixed(0)),
      },
      // {
      //   name: 'Revenue',
      //   type: 'line',
      //   data: revenue?.map((item: any) => Number(item.count).toFixed(0)),
      // },
    ],
    chart: {
      height: 350,
      type: 'line',
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 2,
    },
    xaxis: {
      categories: getMonthNames(revenue),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },

      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: [{
      title: {
        text: '', 
        // Cost Differential
      },
    
    }, {
      opposite: true,
      title: {
        text: ''
        // Hours Differential
      }
    }],
    fill: {
      type: 'solid',
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val, opts) {
          // Check if the series name is "Revenue"
          if (opts.seriesName === 'Revenue') {
            // Convert the number value to a string before concatenating
            return '$' + String(val) + ''
          }
          return String(val) // Convert other values to string
        },
      },
    },
    colors:[ '#f87060', '#071952', '#088395', '#9400FF'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  }
}
}
export {StatisticsChart}