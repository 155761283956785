import React, {FC, useEffect, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {toAbsoluteUrl} from '../_handld/helpers'

const thumbsContainer: any = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
}

const thumb: any = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

const thumbInner: any = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
}

const img: any = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

const deleteButton: any = {
  position: 'relative',
  top: '-85px',
  right: '20px',
  padding: '4px 6px 4px 6px',
  background: '#f1416c',
  color: 'black',
  height: '21.13px',
  borderRadius: '50%',
  cursor: 'pointer',
}
type Props = {
  files: any
  setFiles: any
}
const DropeZoneV2: FC<Props> = ({files, setFiles}) => {
  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpg': ['.jpg'],
      'image/jpeg': ['.jpeg'],
      'application/pdf': ['.pdf'],
    },
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles: any) =>
        prevFiles.concat(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        )
      )
    },
  })
  const renderFileThumbnail = (file: any) => {
    if (file.type === 'application/pdf') {
      // PDF file
      return (
        <div>
          <a
            href={file.preview}
            target='_blank'
            onLoad={() => {
              URL.revokeObjectURL(file.preview)
            }}
            rel='noreferrer'
          >
            <img alt='Pdf' src={toAbsoluteUrl('/media/svg/files/pdf.svg')} style={img} />
          </a>
        </div>
      )
    } else if (file.type.includes('image')) {
      // Image file (png, jpg, jpeg)
      return (
        <div>
          <img
            src={file.preview}
            style={img}
            onLoad={() => {
              URL.revokeObjectURL(file.preview)
            }}
            alt=''
          />
        </div>
      )
    } else {
      // Unknown file type
      return <div>Unknown file type</div>
    }
  }
  const deleteImage = (name: any) => {
    const updatedFiles = files.filter((file: any) => file.name !== name)
    setFiles(updatedFiles)
  }

  const thumbs = files.map((file: any) => (
    <div key={file.name}>
      <div style={thumb}>
        <div style={thumbInner}>{renderFileThumbnail(file)}</div>
      </div>
      <i
        style={deleteButton}
        onClick={() => deleteImage(file.name)}
        className='fas fa-times text-danger bg-light-danger text-hover-danger'
      ></i>
    </div>
  ))

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview))
  }, [])
  return (
    <div>
      <section className='container'>
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <div className='dz-message needsclick'>
            <i className='ki-duotone ki-file-up text-primary fs-3x'>
              <span className='path1'></span>
              <span className='path2'></span>
            </i>
            <div className='ms-4'>
              <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                Drop photos here or click to upload.
              </h3>
              <span className='fs-7 fw-semibold text-gray-400'>
                Click or drag a file to this area to upload.
              </span>
            </div>
          </div>
        </div>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </section>
    </div>
  )
}

export default DropeZoneV2
