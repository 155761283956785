// @ts-nocheck
import {Column} from 'react-table'
import {UserInfoCell} from './UserInfoCell'
import {UserLastLoginCell} from './UserLastLoginCell'
import {UserTwoStepsCell} from './UserTwoStepsCell'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {User} from '../../core/_models'
import { Emailpage } from './Emailpage'
import { Phonepage } from './Phonepage'
import { useEffect } from 'react'
import { UserLastLoginCell2 } from './UserLastLoginCell2'

const usersColumns: ReadonlyArray<Column<User>> = [

  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name & Skills' className='min-w-125px' />,
    id: 'firstName',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header:
      (props) => <UserCustomHeader tableProps={props} title='Email' className='min-w-125px' />,
      id:'email',
      Cell: ({...props}) => <Emailpage user={props.data[props.row.index]} />,
    },
    {
      Header:
        (props) => <UserCustomHeader tableProps={props} title='Phone' className='min-w-125px' />,
        id:'phoneNumber',
        Cell: ({...props}) => <Phonepage user={props.data[props.row.index]} />,
      },
      {
        Header: (props) => <UserCustomHeader tableProps={props} title='Creation date' className='min-w-125px' />,
        id: 'created',
        Cell: ({...props}) => <UserLastLoginCell2 created={props.data[props.row.index].created} />,
      },
      {
        Header: (props) => <UserCustomHeader tableProps={props} title='Jobs' className='min-w-125px' />,
        accessor: 'jobsCount',
      },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Rating' className='min-w-125px' />
    ),
    id: 'rate',
    Cell: ({...props}) => <UserLastLoginCell rate={props.data[props.row.index].rate} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Status' className='min-w-125px' />
    ),
    id: 'status',
    Cell: ({...props}) => <UserTwoStepsCell status={props.data[props.row.index].status} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
