/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from '../../../_handld/partials/layout/theme-mode/ThemeModeProvider'
import {getCSS, getCSSVariableValue} from '../../../_handld/assets/ts/_utils'
import QueryFetchPost from '../../../global/index2'
import {handleApiErrors} from '../../global/HandleApiErrors'
import {FlatpickrRange} from '../../../global/FlatpickrRange'
import moment from 'moment'
import flatpickr from 'flatpickr'

type Props = {
  className: string
}

const JobsStatus: React.FC<Props> = ({className}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [series, setSeries] = React.useState<any[]>([])
  const [data, setdata] = React.useState<any>([])
  const {mode} = useThemeMode()
  const [startDate, setStartDate] = React.useState<any>('')
  const [endDate, setEndDate] = React.useState<any>('')
  const flatpickrInstanceRef = React.useRef<flatpickr.Instance | null>(null)

  const GetLatestJobs = async () => {
    try {
      await QueryFetchPost({
        link: `${BASE_URL}/api/BackOffice/JobsStatuses?startDate=${startDate}&endDate=${endDate}`,
        data: undefined,
        method: 'GET',
        key: 'GetJobs',
        signal: undefined,
        isFormData: false,
      }).then((response: any) => {
        setdata(response[1].statuses)
      })
    } catch (error) {
      handleApiErrors(error)
    }
  }
  useEffect(() => {
    GetLatestJobs()
  }, [startDate, endDate])
  useEffect(() => {
    if (data != null || data != undefined) {
      const series = [
        data[0]?.count,
        data[1]?.count,
        data[2]?.count,
        data[3]?.count,
        data[4]?.count,
        data[5]?.count,
        data[6]?.count,
      ]
      setSeries(series)
    } else if (data === undefined || data === null) {
      GetLatestJobs()
    }
  }, [data])

  useEffect(() => {
    if (series === undefined) {
      GetLatestJobs()
    }
  }, [series])
  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, series, data])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Job Statuses</span>
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          <div className='d-flex align-items-center'>
            <label className='form-label mb-6 mx-2' >Date Range:</label>
            <div className='w-100'>
            <FlatpickrRange
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate ? moment(startDate).format('MM-DD-YYYY') : ''}
              endDate={endDate ? moment(endDate).format('MM-DD-YYYY') : ''}
              flatpickrInstanceRef={flatpickrInstanceRef}
            />
            </div>
          </div>
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_10_chart' style={{height: '200px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )

  function getChartOptions(height: number): ApexOptions {
    const NewColor = getCSSVariableValue('--bs-secondary')
    const OpenColor = getCSSVariableValue('--bs-primary')
    const cancel = getCSSVariableValue('--bs-warning')
    const BookedColor = getCSSVariableValue('--bs-info')
    const InProgressColor = '#9400FF'
    const CompletedColor = getCSSVariableValue('--bs-success')
    const ClosedColor = getCSSVariableValue('--bs-danger')

    return {
      series: series,
      chart: {
        type: 'donut',
        height: '200px',
        toolbar: {
          show: false,
        },
      },
      labels: ['Draft', 'Open', 'Booked', 'In Progress', 'Completed', 'Closed', 'Canceled'],
      legend: {
        show: true,
        formatter: function (val, opts) {
          return val + ' : ' + opts?.w?.globals?.series[opts?.seriesIndex]
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
        show: true,
        width: 2,
      },

      colors: [
        NewColor,
        OpenColor,
        BookedColor,
        InProgressColor,
        CompletedColor,
        ClosedColor,
        cancel,
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    }
  }
}
export {JobsStatus}
