/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_handld/helpers'
import QueryFetchPost from '../../../../global/index2'
import {handleApiErrors} from '../../../global/HandleApiErrors'
import {Link} from 'react-router-dom'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

type Props = {
  className: string
}
const BASE_URL = process.env.REACT_APP_BASE_URL

const TableLatest: React.FC<Props> = ({className}) => {
  const [data, setdata] = useState<any[]>([])
  const [Customers, setCustomers] = useState<any[]>([])
  const [handlrs, sethandlrs] = useState<any[]>([])

  const GetLatestJobs = async () => {
    try {
      await QueryFetchPost({
        link: `${BASE_URL}/api/BackOffice/LatestJobs`,
        data: undefined,
        method: 'GET',
        key: 'GetJobs',
        signal: undefined,
        isFormData: false,
      }).then((response: any) => {
        setdata(response[1].jobs)
      })
    } catch (error) {
      handleApiErrors(error)
    }
  }
  const GetLatestcustomers = async () => {
    try {
      await QueryFetchPost({
        link: `${BASE_URL}/api/BackOffice/LatestCustomers`,
        data: undefined,
        method: 'GET',
        key: 'GetJobs',
        signal: undefined,
        isFormData: false,
      }).then((response: any) => {
        setCustomers(response[1].customer)
      })
    } catch (error) {
      handleApiErrors(error)
    }
  }
  const GetLatestHandlrs = async () => {
    try {
      await QueryFetchPost({
        link: `${BASE_URL}/api/BackOffice/LatestHandlr`,
        data: undefined,
        method: 'GET',
        key: 'GetJobs',
        signal: undefined,
        isFormData: false,
      }).then((response: any) => {
        sethandlrs(response[1].handlr)
      })
    } catch (error) {
      handleApiErrors(error)
    }
  }

  useEffect(() => {
    GetLatestJobs()
    GetLatestcustomers()
    GetLatestHandlrs()
  }, [])
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Latest</span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_1'
              >
                Handymen
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_2'
              >
                Customers
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
                data-bs-toggle='tab'
                href='#kt_table_widget_5_tab_3'
              >
                Jobs
              </a>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 min-w-200px rounded-start'>Name & Skills</th>
                    <th className='min-w-100px'>Jobs</th>
                    <th className='min-w-150px'>Rating</th>
                    <th className='min-w-150px'>Status</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {handlrs?.map((handlr: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <div className='d-flex align-items-center'>
                          <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                            <a href='#'>
                              {handlr.profilePicture ? (
                                <div className='symbol-label'>
                                  <img
                                    src={`${handlr.profilePicture}`}
                                    alt={handlr.name}
                                    className='w-100'
                                    style={{objectFit: 'contain'}}
                                  />
                                </div>
                              ) : (
                                <div className='symbol-label'>
                                  <img
                                    src={toAbsoluteUrl('/media/svg/avatars/blank.svg')}
                                    alt={handlr.name}
                                    className='w-100'
                                    style={{objectFit: 'contain'}}
                                  />
                                </div>
                              )}
                            </a>
                          </div>
                          <div className='d-flex justify-content-start flex-column'>
                            <Link
                              to={`/backoffice/handyman-management/details/${handlr?.id}`}
                              className='menu-link fw-bold text-hover-primary mb-1 fs-6'
                            >
                              {handlr?.firstName + ' ' + handlr?.lastName}
                            </Link>

                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {handlr?.skills?.length > 20 ? (
                                <OverlayTrigger
                                  key='copy-to-clipboard'
                                  placement='top'
                                  overlay={
                                    <Tooltip id='tooltip-copy-to-clipboard' className='fw-bold '>
                                      {handlr?.skills}
                                    </Tooltip>
                                  }
                                >
                                  <div className='d-flex justify-content-start'>
                                    <span className='text-truncate mw-200px'>{handlr?.skills}</span>
                                    <span>
                                      {' '}
                                      <KTIcon
                                        iconName='information-4'
                                        className='fs-2 pe-0 cursor-pointer'
                                      />
                                    </span>
                                  </div>
                                </OverlayTrigger>
                              ) : (
                                <span className='text-truncate mw-200px'>{handlr?.skills}</span>
                              )}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className=''>{handlr?.jobsCount}</td>
                      <td>
                        {handlr?.rate === 0 ? (
                          <div className='rating-label checked'>
                            <div className='d-flex rating-label me-2 checked'>
                              <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                <i className='bi bi-star text-warning' />
                                {''} -
                              </span>
                            </div>
                          </div>
                        ) : (
                          <div className='rating-label checked'>
                            <i className='bi bi-star-fill text-warning' />
                            {''} {handlr?.rate}
                          </div>
                        )}
                      </td>
                      <td>
                        {handlr?.status == 'New' && (
                          <div className='badge badge-light-primary fs-7 fw-semibold'>New</div>
                        )}
                        {handlr?.status == 'In Review' && (
                          <div className='badge badge-light-warning fs-7 fw-semibold'>
                            In Review
                          </div>
                        )}
                        {handlr?.status == 'Approved' && (
                          <div className='badge badge-light-success fs-7 fw-semibold'>Approved</div>
                        )}
                        {handlr?.status == 'Rejected' && (
                          <div className='badge badge-light-danger fs-7 fw-semibold'>Rejected</div>
                        )}
                        {handlr?.status == 'Disabled' && (
                          <div className='badge badge-ligh fs-7 fw-semiboldt'>Disabled</div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          </div>
          <div className='tab-pane fade' id='kt_table_widget_5_tab_2'>
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 min-w-200px rounded-start'>Full name</th>
                    <th className='min-w-150px'>Jobs</th>
                    <th className='min-w-150px'>Total</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {Customers?.map((customer: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to={`/backoffice/customers-management/details/${customer?.id}`}
                            className='menu-link fw-bold text-hover-primary mb-1 fs-6'
                          >
                            {customer?.firstName + ' ' + customer?.lastName}
                          </Link>
                        </div>
                      </td>
                      <td className='d-flex align-items-center'>
                        <span className='text-muted fw-semibold text-muted d-block fs-7 mt-5'>
                          {customer?.jobs ? customer?.jobs : '--'}
                        </span>
                      </td>
                      <td>
                        <span className='text-muted fw-semibold text-muted d-block fs-7 mt-5'>
                          {customer?.total
                            ? new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              }).format(customer.total)
                            : '--'}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          </div>
          <div className='tab-pane fade' id='kt_table_widget_5_tab_3'>
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted bg-light'>
                    <th className='ps-4 min-w-200px rounded-start'>Items & Description</th>
                    <th className='min-w-150px'>Price</th>
                    <th className='min-w-150px'>Status</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {data?.map((jobitem: any, index: number) => (
                    <tr key={index}>
                      <td>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to={`/backoffice/customers-management/Groupdetails/${jobitem?.groupId}`}
                            className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                          >
                            {jobitem?.name}
                          </Link>
                          <span className='text-muted fw-semibold text-muted d-block fs-7'>
                            {jobitem?.additionalDetails}
                          </span>
                        </div>
                      </td>
                      <td className=''>
                        <span className='text-muted fw-semibold text-muted d-block fs-7 mt-5'>
                          {jobitem?.price
                            ? new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              }).format(jobitem?.price)
                            : '--'}
                        </span>
                      </td>
                      <td>
                        {jobitem?.status === 'Completed' && (
                          <span className='badge badge-success fs-7 fw-semibold'>Completed</span>
                        )}
                        {jobitem?.status === 'Draft' && (
                          <span className='badge badge-secondary fs-7 fw-semibold'>Draft</span>
                        )}
                        {jobitem?.status === 'Open' && (
                          <span className='badge badge-primary fs-7 fw-semibold'>Open</span>
                        )}
                        {jobitem?.status === 'Booked' && (
                          <span className='badge badge-info fs-7 fw-semibold'>Booked</span>
                        )}
                        {jobitem?.status === 'In Progress' && (
                          <span className='badge badge-progress fs-7 fw-semibold'>In Progress</span>
                        )}
                        {jobitem?.status === 'Canceled' && (
                          <span className='badge badge-warning fs-7 fw-semibold'>Canceled</span>
                        )}
                        {jobitem?.status === 'Closed' && (
                          <span className='badge badge-danger fs-7 fw-semibold'>Closed</span>
                        )}
                      </td>
                    </tr>
                  ))}
                  {/* <tr>
                    <td>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          Shelve
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          Assembly and installation of shelves for storage...
                        </span>
                      </div>
                    </td>
                    <td className=''>
                      <span className='text-muted fw-semibold text-muted d-block fs-7 mt-5'>
                        $50.00
                      </span>
                    </td>
                    <td>
                      <span className='badge badge-light-success fs-7 fw-semibold'>Completed</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          FURNITURE ASSEMBLY
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          Assembly of various types of furniture, including..
                        </span>
                      </div>
                    </td>
                    <td className=''>
                      <span className='text-muted fw-semibold text-muted d-block fs-7 mt-5'>
                        $300.00
                      </span>
                    </td>
                    <td>
                      <span className='badge badge-light-warning fs-7 fw-semibold'>Booked</span>
                    </td>
                  </tr> */}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          </div>
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TableLatest}
