import {useQuery} from 'react-query'
import {StatusEditModalForm} from './StatusEditModalForm'
import {useListView} from '../core/ListViewProvider'
import {Getviewmodel, getUserById} from '../core/_requests'
import {QUERIES, isNotEmpty} from '../../../../../_handld/helpers'
import { useEffect, useState } from 'react'
import axios, {AxiosResponse} from 'axios'
import { UsersQueryResponse } from '../core/_models'
import QueryFetchPost from '../../../../../global/index2'
const StatusEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const [userStatus, setUserStatus] = useState<any>(null);
  const [statusResponse, setstatusResponse] = useState<any>([])
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {
    isLoading,
    data: user,
    error,
  } = useQuery(
    `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
    () => {
      return getUserById(itemIdForUpdate)
    },
    {
      cacheTime: 0,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
      },
    }
  )
  const Getviewmodel = async () => {
    await QueryFetchPost({
      link: `${BASE_URL}/api/BackOffice/GetViewModel`,
      data: undefined,
      method: 'GET',
      key: 'RegisterEmail',
      signal: undefined,
      isFormData: false,
    }).then((response: any) => {
setstatusResponse(response[1].status)
    })
  }
    useEffect(()=>{
      Getviewmodel()
    },[itemIdForUpdate])

    return  <>
    {statusResponse.length > 0 && (
      <StatusEditModalForm
      statusOptions={statusResponse}
        isUserLoading={isLoading}
        user={{ id: itemIdForUpdate  }}
      />
    )}
</>
}

export {StatusEditModalFormWrapper}
