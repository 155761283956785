import React, {useEffect, useState} from 'react'
import {KTCard, toAbsoluteUrl} from '../../../../_handld/helpers'
import QueryFetchPost from '../../../../global/index2'
import {useParams} from 'react-router-dom'
import formatPhoneNumber from '../../../../global/formatPhoneNumber'

export const Basics = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [data, setdata] = useState<any>()
  const params = useParams<any>()
  const {id} = params
  const getUserStatusById = async (id: any) => {
    await QueryFetchPost({
      link: `${BASE_URL}/api/BackOffice/DetailsCustomer/${id}`,
      data: undefined,
      method: 'GET',
      key: 'getuserbyid',
      signal: undefined,
      isFormData: false,
    }).then((response: any) => {
      setdata(response[1])
    })
  }
  useEffect(() => {
    getUserStatusById(id)
  }, [id])

  return (
    <KTCard>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          {data?.profilePicture ? (
            <div className='symbol symbol-60px symbol-lg-90px symbol-fixed position-relative me-4'>
              <img src={toAbsoluteUrl(data?.profilePicture)} alt={data?.profilePicture} style={{objectFit:"cover"}} />
            </div>
          ) : (
            <>
              <div className='symbol symbol-60px symbol-lg-90px symbol-fixed position-relative me-4'>
                <img src={toAbsoluteUrl('/media/svg/avatars/blank.svg')} alt="default" style={{objectFit:"cover"}} />
              </div>
            </>
          )}
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a className='text-gray-900  fs-2 fw-bold me-1'>
                    {data?.firstName} {''} {data?.lastName}
                  </a>
                </div>
                <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                  <span className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'>
                    <i className='ki-duotone ki-sms fs-4'>
                      <span className='path1' />
                      <span className='path2' />
                    </i>{' '}
                    <span className='text-break'><a href={`mailto:${data?.email}`}>{data?.email}</a></span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='d-print-none card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten'>
        {/* <div className='mb-6'>
          <div className='fw-semibold text-gray-600 fs-7'>Name:</div>
          <div className='fw-bold text-gray-800 fs-6'>{data?.firstName} {''} {data?.lastName}</div>
        </div> */}
        <div className='mb-6'>
          <div className='fw-semibold text-gray-600 fs-7'>Address:</div>
          <div className='fw-bold text-gray-800 fs-6'>
            {data?.address1 ?? ''} <br />
            {data?.address2 ? <>{data?.address2} <br/></> :''} 
            {data?.city && `${data.city}, `}
            {data?.state && `${data.state}, `}
            {data?.zipCode && `${data.zipCode}, `}
            {data?.country && data.country}
          </div>
        </div>
        {/* <div className='mb-6'>
          <div className='fw-semibold text-gray-600 fs-7'>Email:</div>
          <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center'>
            {data?.email}
          </div>
        </div> */}
        <div className='mb-6'>
          <div className='fw-semibold text-gray-600 fs-7'>Phone:</div>
          <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center'>
            {formatPhoneNumber(data?.phoneNumber)}
          </div>
        </div>
        <div className='mb-6'>
          <div className='fw-semibold text-gray-600 fs-7'>Total Jobs:</div>
          <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center'>
            {data?.jobsCount ?data?.jobsCount :'---'}
          </div>
        </div>
        <div className='mb-6'>
          <div className='fw-semibold text-gray-600 fs-7'>Total Price:</div>
          <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center'>
            {
                            data?.totalQuotes ? (
                              new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              }).format(data?.totalQuotes)
                            ) : (
                              '---'
                            )
                          }
          </div>
        </div>
      </div>
    </KTCard>
  )
}
