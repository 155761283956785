import Swal from 'sweetalert2'
import {useEffect, useState} from 'react'
import React from 'react'
import {Row, Form, Col} from 'react-bootstrap'
import Select, {ActionMeta, SingleValue} from 'react-select'
const BASE_URL = process.env.REACT_APP_BASE_URL

interface queryData {
  link: string
  data: any
  method: string
  key: string
  signal?: AbortSignal
  isFormData?: boolean
}

export default async function QueryFetchPost(props: queryData) {
  const AUTH_LOCAL_STORAGE_KEY = 'User'
  const token = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}')
  return await fetch(props?.link, {
    method: props?.method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token.token,
      'Accept-Language': 'en',
    },
    signal: props?.signal,
    body: props?.isFormData ? props?.data : JSON.stringify(props?.data),
  })
    .then(async (response) => {
      const statusCode = response.status
      const contentType = response.headers.get('content-type')
      if (contentType && contentType.indexOf('application/json') !== -1) {
        return response.json().then((data) => [statusCode, data])
      } else {
        return response.text().then((data) => [statusCode, data])
      }
    })
    .then((data: any) => {
      return data
    })
    .catch((error) => {
      console.log(error)
    })
}

export function ModelStateMessage400(result: any, setListFun: any) {
  try {
    /* if (!!result[1]) {
        Swal.fire("Oops?", result[1], "error");
        return false;
      } */
    if (!!result[1]?.message) {
      Swal.fire('Oops?', result[1].message, 'error')
      return false
    }
    if (!result[1]?.errors) result[1] = JSON.parse(result[1])
    if (!!result[1]?.errors) {
      var p = result[1]?.errors
      var list = []
      var list2 = []
      for (var key in p) {
        if (p.hasOwnProperty(key)) {
          list2.push({key: key, value: p[key]})

          var a = list.findIndex((x,key) => x === `<div class="col-12">${p[key]}</div>`)
          if (a === -1) list.push(`<div class="col-12">${p[key]}</div>`)
        }
      }
      if (!!setListFun) setListFun(list2)

      Swal.fire({
        title:
          '<i class="fas fa-exclamation-circle text-danger"></i> <strong class="text-danger h4">Required</strong>',
        icon: 'error',
        html: `<div class="row p-0 m-0">${list.join(' ')}</div>`,
        showCloseButton: true,
        confirmButtonText: 'OK!',
      })
    } else {
      Swal.fire('Oops?', 'Bad_request', 'error')
    }
  } catch (err) {
    Swal.fire('Oops?', 'Bad_request', 'error')
  }
}

export function ModelStateMessage(result: any) {
  try {
    if (
      result[1].modelState !== null &&
      result[1].modelState !== undefined &&
      result[1].modelState !== ''
    ) {
      let modelState = result[1].modelState
      if (modelState)
        Object.keys(modelState).forEach(function (k) {
          modelState[k].forEach((element: any) => {
            Swal.fire('Oops?', element, 'error')
          })
        })
    } else if (
      result[0] === 401 &&
      result[1].message !== null &&
      result[1].message !== undefined &&
      result[1].message !== ''
    ) {
      Swal.fire('Oops?', result[1].message, 'error')
      setTimeout(() => {
        localStorage.clear()
        window.location.href = '/auth/login'
      }, 1000)
    } else if (
      result[1].message !== null &&
      result[1].message !== undefined &&
      result[1].message !== ''
    ) {
      Swal.fire('Oops?', result[1].message, 'error')
    }else if (
      result[0].message === 404
    ) {
      Swal.fire('Something went wrong?', result[1].message, 'error')
    } else if (
      result[1].error_description !== null &&
      result[1].error_description !== undefined &&
      result[1].error_description !== ''
    ) {
      Swal.fire('Oops?', result[1].error_description, 'error')
    } else if (result[0] === 400) {
      ModelStateMessage400(result, null)
    } else if (result[0] === 402) {
      Swal.fire('Oops?', 'You have to subscribe first', 'error')
    } else {
      Swal.fire('Oops?', 'Bad request', 'error')
    }
  } catch (err) {}
}
type OptionType = {value: string; label: string}

type CountryStateGlobalProps = {
  values: {country: string | undefined; state: string | undefined}
  setFieldValue: any
  className?: string
}

export const CountryStateGlobal: React.FC<CountryStateGlobalProps> = ({
  values,
  setFieldValue,
  className = '',
}) => {
  const [countries, setCountries] = useState<OptionType[]>([])
  const [states, setStates] = useState<OptionType[]>([])

  useEffect(() => {
    console.log(values.country)
    console.log(values.state)
    handleGetCoutries()
  }, [])

  const handleGetCoutries = async () => {
    await QueryFetchPost({
      link: `${BASE_URL}/api/Generic/Countries`,
      data: undefined,
      method: 'GET',
      key: 'handleGetCoutries',
      signal: undefined,
      isFormData: false,
    })
      .then((data) => {
        if (data[0] === 200) {
          const countriesList = data[1]?.countries?.map((country: any) => {
            return {value: country?.name, label: country?.name}
          })
          setCountries(countriesList || [])
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleGetStates = async (value: any) => {
    await QueryFetchPost({
      link: `${BASE_URL}/api/Generic/States?name=${value}`,
      data: undefined,
      method: 'GET',
      key: 'handleGetStates',
      signal: undefined,
      isFormData: false,
    })
      .then((data) => {
        if (data[0] === 200) {
          const stateList = data[1]?.states?.map((state: any) => {
            return {value: state?.name, label: state?.name}
          })
          setStates(stateList || [])
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleCountryChange = (
    selectedOption: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    setFieldValue('country', selectedOption?.value)
    setTimeout(() => {
      setFieldValue('state', '')
    }, 500)
    handleGetStates(selectedOption?.value)
  }

  const handleStateChange = (
    selectedOption: SingleValue<OptionType>,
    actionMeta: ActionMeta<OptionType>
  ) => {
    setFieldValue('state', selectedOption?.value)
  }
  useEffect(() => {
    if (values.country) {
      handleGetStates(values.country)
    }
  }, [])
  return (
    <Row className={className}>
      <Col>
        <Form.Group controlId='formCountry'>
          <Form.Label className='mb-1 font-size-sm'>Country</Form.Label>

          <Select
            value={countries.find((option) => option.value === values.country)}
            options={countries}
            onChange={handleCountryChange}
            isClearable
            isSearchable
          />
        </Form.Group>
      </Col>
      <Col>
        <Form.Group controlId='formState'>
          <Form.Label className='mb-1 font-size-sm'>State</Form.Label>

          <Select
            value={states.find((option) => option.value === values.state)}
            options={states}
            onChange={handleStateChange}
            isClearable
            isSearchable
          />
        </Form.Group>
      </Col>
    </Row>
  )
}


interface Props {
  gmtDate: string; // Date string in GMT format (e.g., '2023-05-26T00:00:00')
  timeZone: string ; // Selected time zone in GMT offset format (e.g., 'GMT-5', 'GMT-6')
}

export const TimeConversion = (gmtDate: string, timeZone: string | null) => {
  if (timeZone === null) {
    timeZone = "GMT-5";
  }


  const offset = parseInt(timeZone.replace('GMT', ''));

  // Parse the input date string
  const gmtDateObj = new Date(gmtDate);

  // Get the UTC time by adding the offset
  const utcTime = gmtDateObj.getTime() + (offset * 60 * 60 * 1000);

  // Create a new Date object with the UTC time
  const utcDateObj = new Date(utcTime);

  // Format the UTC date as a string
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    timeZone: "UTC",
  };

  const utcDateString = utcDateObj.toLocaleString(undefined, options);

  // Get the local time by applying the user's time zone offset
  const localTime = utcDateObj.getTime() - (utcDateObj.getTimezoneOffset() * 60 * 1000);

  // Create a new Date object with the local time
  const localDateObj = new Date(localTime);

  // Format the local date as a string
  const localDateString = localDateObj.toLocaleString(undefined, options);

  return localDateString;
};



export function convertToGMT(date: string, timeZone: string): string {
  if(timeZone===null){
    timeZone="GMT-5"
  }
  const offset = parseInt(timeZone.replace('GMT', ''));
  const localDateObj = new Date(date);
  const localTime = localDateObj.getTime();
  const localOffset = localDateObj.getTimezoneOffset() * 60000;
  const utcTime = localTime + localOffset;
  const convertedTime = utcTime + offset * 3600000;
  const convertedDate = new Date(convertedTime);

  return convertedDate.toISOString();
}