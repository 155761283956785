import { useRef, useEffect, FC } from 'react';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';

type Props = {
    selectedDate: string
    setSelectedDate: (date: string) => void
  }

const Flatpickr: FC<Props> = ({selectedDate, setSelectedDate}) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            const picker = flatpickr(inputRef.current, {
                enableTime: false,
                // dateFormat: 'm-d-Y h:i K',
                onChange: function(selectedDates, dateStr) {
                    setSelectedDate(dateStr);
                }
            });
            
            return () => {
                picker.destroy();
            }
        }
    }, [inputRef, setSelectedDate]);

    return (
        <div className="fv-row mb-8">
            <div className="position-relative d-flex align-items-center">
                <i className="ki-duotone ki-calendar-8 fs-2 position-absolute mx-4">
                    <span className="path1" />
                    <span className="path2" />
                    <span className="path3" />
                    <span className="path4" />
                    <span className="path5" />
                    <span className="path6" />
                </i>
                <input
                    className="form-control form-control-solid ps-12"
                    placeholder="Pick date"
                    name="settings_release_date"
                    ref={inputRef}
                    type='text'
                    value={selectedDate}
                />
            </div>
            {/* <p>Selected date: {selectedDate}</p> */}
        </div>
    );
}

export { Flatpickr };
