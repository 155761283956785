import Swal from 'sweetalert2'

export const validateFromBeforeSubmit = (jobs: any) => {
  let isValid = true
  jobs?.map((job: any) => {
    if (job?.serviceId === null || job?.serviceId === '' || job?.serviceId === 0) {
      isValid = false
      Swal.fire('Info', 'Please select a service', 'info')
    }

    if (job?.code === 'FURN') {
      if (job?.category === '' || job?.category === null) {
        isValid = false
        Swal.fire('Info', 'Please select a category', 'info')
        return
      }
      if (job?.typeCode === '' || job?.typeCode === null) {
        isValid = false
        Swal.fire('Info', 'Please select a type of category', 'info')
        return
      }
    }
    if (job?.code === 'PNT') {
      if (job?.category === '' || job?.category === null) {
        isValid = false
        Swal.fire('Info', 'Please select a category', 'info')
        return
      }
    }
    if (job?.code === 'YRDGRD') {
      if (job?.category === '' || job?.category === null) {
        isValid = false
        Swal.fire('Info', 'Please select a category', 'info')
        return
      }
    }
    if (job?.code === 'WALLM') {
      if (job?.category === '' || job?.category === null) {
        isValid = false
        Swal.fire('Info', 'Please select a category', 'info')
        return
      }
    }

    if (job?.code === 'PLHLP') {
      if (job?.category === '' || job?.category === null) {
        isValid = false
        Swal.fire('Info', 'Please select a category', 'info')
        return
      }
    }

    if (job?.code === 'ELHLP') {
      if (job?.category === '' || job?.category === null) {
        isValid = false
        Swal.fire('Info', 'Please select a category', 'info')
        return
      }
    }

    if (job?.code === 'HCLN') {
      if (job?.category === '' || job?.category === null) {
        isValid = false
        Swal.fire('Info', 'Please select a category', 'info')
        return
      }
    }

    // TV Wall Mount

    if (job?.code === 'WALLM' && job?.category === 'TVWA') {
      if (job?.sizeInch === null || job?.sizeInch === '' || job?.sizeInch === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the size', 'info')
      }
      if (job?.numberOfItems === null || job?.numberOfItems === '' || job?.numberOfItems === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter number of items', 'info')
      }
    }

    if (
      job?.code === 'ELHLP' &&
      (job?.category === 'OTLI' || job?.category === 'LFRPL' || job?.category === 'CLFI')
    ) {
      if (job?.numberOfItems === null || job?.numberOfItems === '' || job?.numberOfItems === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter number of items', 'info')
      }
    }
    if (job?.code === 'PNT' && (job?.category === 'PNTSR' || job?.category === 'PNTMR')) {
      if (job?.Type === null || job?.Type === '' || job?.Type === 0) {
        isValid = false
        Swal.fire('Info', 'Please select the type', 'info')
      }
      if (job?.CoatType === null || job?.CoatType === '' || job?.CoatType === 0) {
        isValid = false
        Swal.fire('Info', 'Please select the type of coat', 'info')
      }
    }
    if (
      job?.code === 'YRDGRD' &&
      (job?.category === 'LWNMWN' || job?.category === 'LWCFRT' || job?.category === 'GRDPLN')
    ) {
      if (job?.size === null || job?.size === '' || job?.size === 0) {
        isValid = false
        Swal.fire('Info', 'Please select the surface', 'info')
      }
    }
    if (
      job?.code === 'PLHLP' &&
      (job?.category === 'FRPRP' ||
        job?.category === 'TOIRP' ||
        job?.category === 'LKRP' ||
        job?.category === 'IDWMC' ||
        job?.category === 'HWHIM' ||
        job?.category === 'PPINS' ||
        job?.category === 'SHRP')
    ) {
      if (job?.numberOfItems === null || job?.numberOfItems === '' || job?.numberOfItems === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter number of items', 'info')
      }
    }

    if (job?.code === 'WALLM' && job?.category === 'SHEL') {
      if (job?.itemsFrom === null || job?.itemsFrom === '' || job?.itemsFrom === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter item from', 'info')
      }
      if (job?.model === null || job?.model === '' || job?.model === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the model name', 'info')
      }
      if (job?.numberOfItems === null || job?.numberOfItems === '' || job?.numberOfItems === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter number of items', 'info')
      }
      if (job?.length === null || job?.length === '' || job?.length === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the length', 'info')
      }
    }

    if (job?.code === 'WALLM' && job?.category === 'ARTP') {
      if (job?.numberOfItems === null || job?.numberOfItems === '' || job?.numberOfItems === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter number of items', 'info')
      }
      if (job?.size === null || job?.size === '' || job?.size === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the size', 'info')
      }
    }

    if (job?.code === 'WALLM' && job?.category === 'CABI') {
      if (job?.itemsFrom === null || job?.itemsFrom === '' || job?.itemsFrom === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter item from', 'info')
      }
      if (job?.model === null || job?.model === '' || job?.model === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the model name', 'info')
      }
    }

    if (job?.code === 'WALLM' && job?.category === 'ELEC') {
      if (job?.itemLink === null || job?.itemLink === '' || job?.itemLink === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter item link', 'info')
      }
    }

    if (job?.code === 'WALLM' && job?.category === 'WALLL') {
      if (job?.itemsFrom === null || job?.itemsFrom === '' || job?.itemsFrom === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter item from', 'info')
      }
      if (job?.model === null || job?.model === '' || job?.model === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the model name', 'info')
      }
    }

    if (job?.code === 'WALLM' && job?.category === 'CURT') {
      if (job?.numberOfItems === null || job?.numberOfItems === '' || job?.numberOfItems === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter number of items', 'info')
      }
      if (
        job?.numberOfWindows === null ||
        job?.numberOfWindows === '' ||
        job?.numberOfWindows === 0
      ) {
        isValid = false
        Swal.fire('Info', 'Please enter the number of windows', 'info')
      }
      if (job?.width === null || job?.width === '' || job?.width === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the width', 'info')
      }
      if (job?.height === null || job?.height === '' || job?.height === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the height', 'info')
      }
    }

    if (job?.code === 'WALLM' && job?.category === 'WALLMI') {
      if (job?.model === null || job?.model === '' || job?.model === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the model name', 'info')
      }
      if (job?.numberOfItems === null || job?.numberOfItems === '' || job?.numberOfItems === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the number of items', 'info')
      }
      if (job?.width === null || job?.width === '' || job?.width === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the width', 'info')
      }
      if (job?.height === null || job?.height === '' || job?.height === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the height', 'info')
      }
    }

    if (job?.code === 'WALLM' && job?.category === 'SMAL') {
      if (job?.items === null || job?.items === '' || job?.items === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter item discription', 'info')
      }
    }

    // Furniture Assembly
    if (
      job?.code === 'FURN' &&
      job?.category === 'BEDS' &&
      (job?.typeCode === 'KINGWS' ||
        job?.typeCode === 'KING' ||
        job?.typeCode === 'QUEEWS' ||
        job?.typeCode === 'FULL' ||
        job?.typeCode === 'QUEE' ||
        job?.typeCode === 'FULLWS')
    ) {
      if (job?.itemsFrom === null || job?.itemsFrom === '' || job?.itemsFrom === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter item from', 'info')
      }
      if (job?.model === null || job?.model === '' || job?.model === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the model name', 'info')
      }
    }

    if (
      job?.code === 'FURN' &&
      job?.category === 'SOFA' &&
      (job?.typeCode === 'THRE' ||
        job?.typeCode === 'SECT' ||
        job?.typeCode === 'SECTWS' ||
        job?.typeCode === 'TWOP')
    ) {
      if (job?.itemsFrom === null || job?.itemsFrom === '' || job?.itemsFrom === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter item from', 'info')
      }
      if (job?.model === null || job?.model === '' || job?.model === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the model name', 'info')
      }
    }

    if (
      job?.code === 'FURN' &&
      job?.category === 'DINIF' &&
      (job?.typeCode === 'DINIT4C' || job?.typeCode === 'DESKT')
    ) {
      if (job?.itemsFrom === null || job?.itemsFrom === '' || job?.itemsFrom === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter item from', 'info')
      }
      if (job?.model === null || job?.model === '' || job?.model === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the model name', 'info')
      }
    }

    if (
      job?.code === 'FURN' &&
      job?.category === 'FDESK' &&
      (job?.typeCode === 'DESKAC' || job?.typeCode === 'DESKND' || job?.typeCode === 'DESKC')
    ) {
      if (job?.itemsFrom === null || job?.itemsFrom === '' || job?.itemsFrom === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter item from', 'info')
      }
      if (job?.model === null || job?.model === '' || job?.model === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the model name', 'info')
      }
    }

    if (
      job?.code === 'FURN' &&
      job?.category === 'TABL' &&
      (job?.typeCode === 'CONS' || job?.typeCode === 'COFF' || job?.typeCode === 'SIDE')
    ) {
      if (job?.itemsFrom === null || job?.itemsFrom === '' || job?.itemsFrom === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter item from', 'info')
      }
      if (job?.model === null || job?.model === '' || job?.model === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the model name', 'info')
      }
    }

    if (job?.code === 'FURN' && job?.category === 'SEAT' && job?.typeCode === 'BARS') {
      if (job?.itemsFrom === null || job?.itemsFrom === '' || job?.itemsFrom === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter item from', 'info')
      }
      if (job?.model === null || job?.model === '' || job?.model === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the model name', 'info')
      }
    }

    if (job?.code === 'MHLP') {
      if (job?.EndCity === null || job?.EndCity === '' || job?.EndCity === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the city', 'info')
      }
      if (job?.EndZipcode === null || job?.EndZipcode === '' || job?.EndZipcode === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the zipcode', 'info')
      }
      if (job?.EndState === null || job?.EndState === '' || job?.EndState === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the state', 'info')
      }
      if (job?.EndCountry === null || job?.EndCountry === '' || job?.EndCountry === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the country', 'info')
      }
      if (job?.EndAddress1 === null || job?.EndAddress1 === '' || job?.EndAddress1 === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the address line 1', 'info')
      }
      if (job?.size === null || job?.size === '' || job?.size === 0) {
        isValid = false
        Swal.fire('Info', 'Please select the number of boxes!', 'info')
      }
      if (job?.vehiculeNeeded === null || job?.vehiculeNeeded === '' || job?.vehiculeNeeded === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the vehicule needed', 'info')
      }
    }
    if (job?.code === 'HCLN') {
      if (job?.size === null || job?.size === '' || job?.size === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the surface size', 'info')
      }
    }

    if (
      job?.code === 'FURN' &&
      job?.category === 'BEDR' &&
      (job?.typeCode === 'NIGH' || job?.typeCode === 'DRES6' || job?.typeCode === 'DRES4')
    ) {
      if (job?.itemsFrom === null || job?.itemsFrom === '' || job?.itemsFrom === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter item from', 'info')
      }
      if (job?.model === null || job?.model === '' || job?.model === 0) {
        isValid = false
        Swal.fire('Info', 'Please enter the model name', 'info')
      }
    }
  })

  return isValid
}
