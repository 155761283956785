import {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import axios from 'axios'
import {toAbsoluteUrl} from '../../../../_handld/helpers'

export function ConfrmEmail() {
  const [accountverified, setaccountverified] = useState(false)
  const BASE_URL = process.env.REACT_APP_BASE_URL
  var url = new URL(window.location.href)
  var code = url?.searchParams?.get('code')
  useEffect(() => {
    sendCodeFunction(code)
  }, [code])

  async function sendCodeFunction(params: typeof code) {
    if (params !== null && params !== '' && params !== undefined) {
      var myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/json')
      var raw = JSON.stringify({
        Code: params,
      })

      await axios
        .post(`${BASE_URL}/api/Account/ConfirmEmail`, raw, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setaccountverified(true)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  return (
    <>
      <div className='text-center mb-10'>
        <Link to='/'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/footer-logo.png')} className='h-45px' />
        </Link>
      </div>
      <div className='card card-custom shadow'>
        <div className='card-header card-header-tabs-line'>
          <div className='d-flex flex-center flex-column mt-7 mx-auto'>
            {!accountverified ? (
              <>
                <h2 className='font-size-h1 mb-5 font-weight-bolder'>Verify your email</h2>
              </>
            ) : (
              <>
                <h2 className='font-size-h1 mb-5 font-weight-bolder'>Welcome To Handyman</h2>
              </>
            )}
          </div>
        </div>
        <div className='card-body'>
          <div className='login-signin'>
            <div className='text-center'>
              {!accountverified ? (
                <>
                  <div className='h4 text-muted font-weight-bold'>
                    {'We have sent an email from'}{' '}
                    <span className='text-primary text-decoration-none font-weight-bolder line-height-lg my-3'>
                      <a href='mailto:support@gohandyman.ca?subject=signup-issue'>
                        support@gohandyman.ca
                      </a>
                    </span>
                    <br />
                    {'to verify email'}
                    <br />
                    {"If you didn't receive any email. Please"}{' '}
                    <a href='mailto:support@gohandyman.ca?subject=signup-issue'>Contact us</a>
                  </div>
                  <div className='text-center my-10'>
                    <Link to='/auth/login' className='btn btn-lg btn-primary font-weight-bolder'>
                      {'Log In'}
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div className='h4 text-muted font-weight-bold'>
                    <span className='text-primary text-decoration-none font-weight-bolder line-height-lg my-3'>
                      {'Your account is ready'}
                    </span>
                    <br />
                    {'Please click login to access your account'}
                  </div>
                  <div className='text-center my-10'>
                    <Link to='/auth/login' className='btn btn-lg btn-primary font-weight-bolder'>
                      {'Log In'}
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
