import {Link} from 'react-router-dom'
import {User} from '../../core/_models'
import {FC, useEffect} from 'react'
import formatPhoneNumber from '../../../../../../global/formatPhoneNumber'

type Props = {
  user: User
}
const Phonepage: FC<Props> = ({user}) => {
  return (
    <>
      <a href={`tel:${user.phoneNumber}`}>{formatPhoneNumber(user.phoneNumber)}</a>{' '}
    </>
  )
}
export {Phonepage}
