import { stat } from 'fs'
import {FC} from 'react'

type Props = {
  status?: string
}

const UserTwoStepsCell: FC<Props> = ({status}) => (
  <> 
   {status === 'Completed' && <span className='badge badge-light-success fw-bold me-auto px-4 py-3'>Completed</span>}
                  {status === 'Draft' && <span className='badge badge-secondary fw-bold me-auto px-4 py-3'>Draft</span>}
                  {status === 'Open' && <span className='badge badge-light-primary fw-bold me-auto px-4 py-3'>Open</span>}
                  {status === 'Booked' && <span className='badge badge-light-info fw-bold me-auto px-4 py-3'>Booked</span>}
                  {status === 'In Progress' && <span className='badge badge-light-progress fw-bold me-auto px-4 py-3'>In Progress</span>}
                  {status === 'Canceled' && <span className='badge badge-light-warning fw-bold me-auto px-4 py-3'>Canceled</span>}
                  {status === 'Closed' && <span className='badge badge-light-danger fw-bold me-auto px-4 py-3'>Closed</span>}
  </>
) 

export {UserTwoStepsCell}
