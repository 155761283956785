import {FC, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../../_handld/helpers'
import {Link} from 'react-router-dom'
import QueryFetchPost, {ModelStateMessage} from '../../../../global/index2'
import {useListView} from '../jobs-list/core/ListViewProvider'
import Swal from 'sweetalert2'
import {OverlayTrigger, Spinner, Tooltip} from 'react-bootstrap'
import {useQueryResponse} from '../jobs-list/core/QueryResponseProvider'
import moment from 'moment'
import {getFromatdate} from '../../../../_handld/helpers/GlobalHelpers'
import SVG from 'react-inlinesvg'

type Props = {
  data: any
  refetch: () => void
}
const JobDetailsModalForm: FC<Props> = ({data, refetch}) => {
  const [loading, setLoading] = useState(false)
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  const BASE_URL = process.env.REACT_APP_BASE_URL

  const Retransfer = async (id: any) => {
    setLoading(true)
    await QueryFetchPost({
      link: `${BASE_URL}/api/BackOffice/ReTransfer?id=${itemIdForUpdate}`,
      data: undefined,
      method: 'POST',
      key: 'Retransfer',
      signal: undefined,
      isFormData: false,
    }).then((response: any) => {
      if (response[0] === 200) {
        Swal.fire({
          icon: 'success',
          title: 'success',
          text: 'Retransfer sent successfully',
        })
        setLoading(false)
        refetch()
      } else {
        setLoading(false)
        ModelStateMessage(response)
        refetch()
      }
    })
  }
  const requesteddate = data?.requestedDate
  let date = '---' // Default value if requesteddate is null or undefined

  if (requesteddate) {
    const splitdate = requesteddate.split('T')
    const datee = splitdate[0]
    const format = getFromatdate()
    if (format) {
      date = moment(datee).format(format)
    }
  }

  return (
    <>
      <div className='row'>
        <div className='d-flex justify-content-between mb-2'>
          <div className=''>
            <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center mb-2'>
              Customer:
            </div>
            {data?.customerId != null ? (
              <Link
                to={`/backoffice/customers-management/details/${data?.customerId}`}
                className='menu-link fw-semibold fs-6'
              >
                {data?.firstName === undefined ? '' : data?.firstName} {''}{' '}
                {data?.lastName === undefined ? '' : data?.lastName}
              </Link>
            ) : (
              <p className='text-gray-400 fw-semibold fs-6'>
                {data?.firstName === undefined ? '' : data?.firstName} {''}{' '}
                {data?.lastName === undefined ? '' : data?.lastName}
              </p>
            )}
          </div>
          <div className='col-2'>
            <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center'>Created date</div>
            <div className='d-flex align-items-center'>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.created ? moment(data?.created).format(getFromatdate() as string) : '---'}
              </p>
              <div className='font-weight-bolder mb-7'>
                <OverlayTrigger
                  key={'right'}
                  placement={'right'}
                  overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                >
                  <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                    <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                  </span>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
        {data?.serviceCode === 'TVWA' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Size of TV (inches)
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.sizeInch}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Wall-Mount on hand
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.wallMountOnHand === true ? 'Yes' : ''}{' '}
                {data?.wallMountOnHand === false ? 'No' : ''}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of Tvs
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Confirm Wall-Mount is correct specific to size of their TV
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.wallSpecificSize === true ? 'Yes' : ''}{' '}
                {data?.wallSpecificSize === false ? 'No' : ''}
              </p>
            </div>
            {data?.wallType && (
              <div className='col-4'>
                <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                  Wall Info TV is being mounted to
                </div>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.wallType}</p>
              </div>
            )}
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'SHEL' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Shelves are from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            {/* <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Custom
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>..</p>
            </div> */}
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of Shelves
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div>
            <div className='separator separator-dashed'></div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Length of Shelves
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.length}</p>
            </div>
            {data?.wallType && (
              <div className='col-4'>
                <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                  Wall Info Shelf is being mounted to
                </div>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.wallType}</p>
              </div>
            )}
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Placement
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.placementDifficulty === true ? (
                  <div className='symbol symbol-60px symbol-lg-90px symbol-fixed position-relative me-4'>
                    <img
                      src={toAbsoluteUrl(data?.placementDifficultyPicture)}
                      alt={data?.placementDifficultyPicture}
                    />
                  </div>
                ) : (
                  ''
                )}{' '}
                {data?.placementDifficulty === false ? 'No placement difficulty' : ''}
              </p>
            </div>
            <div className='separator separator-dashed'></div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'ARTP' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of Pictures
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>Size</div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.size}</p>
            </div>
            {data?.wallType && (
              <div className='col-4'>
                <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                  Wall Info Art/Picture(s) being mounted to
                </div>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.wallType}</p>
              </div>
            )}
            {/* <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Custom
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>..</p>
            </div> */}
            <div className='separator separator-dashed'></div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Placement
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.placementDifficulty === true ? (
                  <div className='symbol symbol-60px symbol-lg-90px symbol-fixed position-relative me-4'>
                    <img
                      src={toAbsoluteUrl(data?.placementDifficultyPicture)}
                      alt={data?.placementDifficultyPicture}
                    />
                  </div>
                ) : (
                  ''
                )}{' '}
                {data?.placementDifficulty === false ? 'No placement difficulty' : ''}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'CABI' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Cabinets are from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Assembly of Cabinet Required
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.assemblyRequired === true ? 'Yes' : ''}{' '}
                {data?.assemblyRequired === false ? 'No' : ''}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            {data?.wallType && (
              <div className='col-4'>
                <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                  Wall Info Cabinet(s) being mounted to
                </div>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.wallType}</p>
              </div>
            )}
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Placement:
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.placementDifficulty === true ? (
                  <div className='symbol symbol-60px symbol-lg-90px symbol-fixed position-relative me-4'>
                    <img
                      src={toAbsoluteUrl(data?.placementDifficultyPicture)}
                      alt={data?.placementDifficultyPicture}
                    />
                  </div>
                ) : (
                  ''
                )}{' '}
                {data?.placementDifficulty === false ? 'No placement difficulty' : ''}
              </p>{' '}
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address:
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'ELEC' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Link input for electric fireplace make/model
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7 text-break'>
                {data?.itemLink}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Assembly of Fireplace required
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.assemblyRequired === true ? 'Yes' : ''}{' '}
                {data?.assemblyRequired === false ? 'No' : ''}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Depending on height/placement will someone be on hand to assist Handyman in lifting
                fireplace to the mounting bracket
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.assistHandlr === true ? 'Yes' : ''}{' '}
                {data?.assistHandlr === false ? 'No' : ''}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address:
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'WALLL' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Wall-Lamp(s) are from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Placement
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.placementDifficulty === true ? (
                  <div className='symbol symbol-60px symbol-lg-90px symbol-fixed position-relative me-4'>
                    <img
                      src={toAbsoluteUrl(data?.placementDifficultyPicture)}
                      alt={data?.placementDifficultyPicture}
                    />
                  </div>
                ) : (
                  ''
                )}{' '}
                {data?.placementDifficulty === false ? 'No placement difficulty' : ''}
              </p>{' '}
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'CURT' && (
          <>
            {' '}
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of Curtain Rods
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of windows
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfWindows}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Sizes of windows (width and height)
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                Height:{data?.height} {'  '} / Width:{data?.width}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            {data?.wallType && (
              <div className='col-4'>
                <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                  Wall Info Curtain Rod(s) being mounted to
                </div>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.wallType}</p>
              </div>
            )}
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Placement
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.placementDifficulty === true ? (
                  <div className='symbol symbol-60px symbol-lg-90px symbol-fixed position-relative me-4'>
                    <img
                      src={toAbsoluteUrl(data?.placementDifficultyPicture)}
                      alt={data?.placementDifficultyPicture}
                    />
                  </div>
                ) : (
                  ''
                )}{' '}
                {data?.placementDifficulty === false ? 'No placement difficulty' : ''}
              </p>{' '}
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'WALLMI' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number{' '}
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of Curtain Rods
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Sizes of Wall-Mirror (width and height)
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                Height:{data?.height} {''} / Width:{data?.width}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            {data?.wallType && (
              <div className='col-4'>
                <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                  Wall Info Wall-Mirror(s) being mounted to
                </div>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.wallType}</p>
              </div>
            )}
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Placement
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.placementDifficulty === true ? (
                  <div className='symbol symbol-60px symbol-lg-90px symbol-fixed position-relative me-4'>
                    <img
                      src={toAbsoluteUrl(data?.placementDifficultyPicture)}
                      alt={data?.placementDifficultyPicture}
                    />
                  </div>
                ) : (
                  ''
                )}{' '}
                {data?.placementDifficulty === false ? 'No placement difficulty' : ''}
              </p>{' '}
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'SMAL' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Items/Description
              </div>
              {data?.items?.length > 20 ? (
                <OverlayTrigger
                  key='copy-to-clipboard'
                  placement='right'
                  overlay={
                    <Tooltip id='tooltip-copy-to-clipboard' className='fw-bold '>
                      {data?.items}
                    </Tooltip>
                  }
                >
                  <div className='d-flex justify-content-start'>
                    <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7 text-truncate mw-250px'>
                      {data?.items}
                    </p>
                    <span>
                      {' '}
                      <KTIcon iconName='information-4' className='fs-2 pe-0 cursor-pointer' />
                    </span>
                  </div>
                </OverlayTrigger>
              ) : (
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7 text-truncate mw-250px'>
                  {data?.items}
                </p>
              )}
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of small items
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.numberOfItems ? data?.numberOfItems : '---'}
              </p>
            </div>
            {/* <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of small items
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div> */}
            {data?.wallType && (
              <div className='col-4'>
                <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                  Wall Info: item(s) being mounted to
                </div>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.wallType}</p>
              </div>
            )}

            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'KINGWS' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Bed is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Does this Model require Headboard to be Wall-Mounted?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.headboardWallMounted === true ? 'Yes' : ''}{' '}
                {data?.headboardWallMounted === false ? 'No' : ''}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address:
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>

            <div className='separator separator-dashed'></div>
          </>
        )}
        {data?.serviceCode === 'KING' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Bed is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Confirmation NO storage drawers on this model
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.storageDrawers === true ? 'Yes' : ''}{' '}
                {data?.storageDrawers === false ? 'No' : ''}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Does this Model require Headboard to be Wall-Mounted?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.headboardWallMounted === true ? 'Yes' : ''}{' '}
                {data?.headboardWallMounted === false ? 'No' : ''}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'QUEEWS' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Bed is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Does this Model require Headboard to be Wall-Mounted?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.headboardWallMounted === true ? 'Yes' : ''}{' '}
                {data?.headboardWallMounted === false ? 'No' : ''}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'QUEE' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Bed is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Confirmation NO storage drawers on this model
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.storageDrawers === true ? 'Yes' : ''}{' '}
                {data?.storageDrawers === false ? 'No' : ''}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Does this Model require Headboard to be Wall-Mounted?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.headboardWallMounted === true ? 'Yes' : ''}{' '}
                {data?.headboardWallMounted === false ? 'No' : ''}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'FULLWS' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Bed is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Does this Model require Headboard to be Wall-Mounted?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.headboardWallMounted === true ? 'Yes' : ''}{' '}
                {data?.headboardWallMounted === false ? 'No' : ''}
              </p>
            </div>
            <div className='separator separator-dashed'></div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'FULL' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Bed is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Confirmation NO storage drawers on this model
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.storageDrawers === true ? 'Yes' : ''}{' '}
                {data?.storageDrawers === false ? 'No' : ''}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Does this Model require Headboard to be Wall-Mounted?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.headboardWallMounted === true ? 'Yes' : ''}{' '}
                {data?.headboardWallMounted === false ? 'No' : ''}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'SECTWS' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Sofa is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'SECT' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Sofa is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'THRE' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Sofa is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'TWOP' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Sofa is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'DINIT4C' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Table & chairs from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'DESKT' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Table & chairs from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'DESKAC' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Desk & chair from{' '}
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'DESKND' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Desk & chair from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'DESKC' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Desk & chair from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'CONS' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Table & chairs from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'COFF' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Table & chairs from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'SIDE' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Table & chairs from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'BARS' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Table & chairs from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'NIGH' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                (Item) is from
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'DRES6' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                (Item) is from{' '}
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'DRES4' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                (Item) is from{' '}
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.itemsFrom}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Model Name/Number
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.model}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}

        {data?.serviceCode === 'RCLN' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Surface: What type of surface do you need cleaned?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.size}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'DCLN' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Surface: What type of surface do you need cleaned?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.size}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'SCLN' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Surface: What type of surface do you need cleaned?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.size}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>

            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'MHLP' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Boxes: How many boxes do you need moved?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.size}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Vehicle Requirements
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.vehiculeNeeded ? data?.vehiculeNeeded : '--'}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                End address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.endAddress1} <br />
                {data?.endAddress2 ? <> {data?.endAddress2}, </> : ''}
                {data?.endCity}, {data?.endState} {data?.endZipCode}, {data?.endCountry}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'PNTSR' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Type of Coat: (Single Coat / Tow Coat)
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.coatType}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Type (without cleaning and surface / with cleaning and surface)
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.type}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'PNTMR' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Type of Coat: (Single Coat / Tow Coat)
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.coatType}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Type (without cleaning and surface / with cleaning and surface)
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.type}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.HandlerId && (
          <>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center mb-2'>
                Handyman
              </div>
              <Link
                to={`/backoffice/handyman-management/details/${data?.HandlerId}`}
                className='menu-link fw-semibold fs-6'
              >
                {data?.HandlerName === undefined ? '' : data?.HandlerName} {''}{' '}
              </Link>
            </div>
          </>
        )}
        {data?.serviceCode === 'OTLI' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of outlets to be installed?
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'LFRPL' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of light fixtures to be replaced?{' '}
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'CLFI' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of Ceiling fans to be installed
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'ELTAR' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'CBRPI' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'FRPRP' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of faucets to be repaired or replaced
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'TOIRP' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of toilets to be replaced
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'LKRP' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of leaks to be repaired
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'IDWMC' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of appliances to be installed
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'HWHIM' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of hot water heaters to be inspected or maintained
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'PPINS' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of pipes to be insulated
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'SHRP' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Number of shower heads to be replaced
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.numberOfItems}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'GRDPLN' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Surface
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.size}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'LWCFRT' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Surface{' '}
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.size}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.serviceCode === 'LWNMWN' && (
          <>
            <div className='col-8'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Service Name
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.serviceName}</p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Surface
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.size}</p>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Date to be completed
              </div>
              <div className='d-flex align-items-center'>
                <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                  {data?.requestedDate ? date : '  ---  '}
                </p>
                <div className='font-weight-bolder mb-7'>
                  <OverlayTrigger
                    key={'right'}
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>}
                  >
                    <span style={{cursor: 'pointer'}} className='svg-icon menu-icon svg-icon-info'>
                      <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                    </span>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Available Times
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.availableTimes ? data?.availableTimes : '  ---  '}
              </p>
            </div>
            <div className='separator separator-dashed'></div>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Address
              </div>

              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {' '}
                {data?.address1} <br />
                {data?.address2 ? (
                  <>
                    {' '}
                    {data?.address2}
                    <br />
                  </>
                ) : (
                  ''
                )}
                {data?.city}, {data?.state} {data?.zipCode}, {data?.country}
              </p>
            </div>
          </>
        )}
        {data?.handlerId && (
          <>
            <div className='col-4 mt-3'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center mb-2'>
                Handyman
              </div>
              <div className='d-flex align-items-center mb-2'>
                <div className='symbol symbol-50px  overflow-hidden me-3'>
                  {data?.handlerPicture ? (
                    <div className='symbol-label'>
                      <img
                        src={`${data?.handlerPicture}`}
                        className='w-100'
                        style={{objectFit: 'contain'}}
                      />
                    </div>
                  ) : (
                    <div className='symbol-label'>
                      <img
                        src={toAbsoluteUrl('/media/svg/avatars/blank.svg')}
                        className='w-100'
                        style={{objectFit: 'contain'}}
                      />
                    </div>
                  )}
                </div>
                <Link
                  to={`/backoffice/handyman-management/details/${data?.handlerId}`}
                  className='menu-link fw-semibold fs-6'
                >
                  {data?.handlerName === undefined ? '' : data?.handlerName} {''}{' '}
                </Link>
              </div>
            </div>
          </>
        )}

        <div className='separator separator-dashed'></div>
        <div className='col-4'>
          <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
            Additional information
          </div>
          <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
            {data?.additionalDetails ? data?.additionalDetails : 'No additional details'}
          </p>
        </div>
        {data?.skills && (
          <>
            <div className='col-4'>
              <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
                Skills needed{' '}
              </div>
              <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>
                {data?.skills.replace(/;/g, ', ')}
              </p>
            </div>
          </>
        )}
        <div className='separator separator-dashed'></div>
        <div className='col-12'>
          <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center my-3'>
            <span className='me-5'>Review</span>
            <div className='border border-gray-300 border-dashed rounded py-1 px-1 me-7'>
              <div className='fs-6 text-gray-800 fw-bold'>
                {data?.review?.rate === 0 || data?.review === null ? (
                  <span>
                    <i className='bi bi-star text-warning' /> -
                  </span>
                ) : (
                  <span>
                    <i className='bi bi-star-fill text-warning' /> {data?.review?.rate}
                  </span>
                )}
              </div>
            </div>
          </div>
          <p className='text-gray-400 fw-semibold fs-6 mt-1 mb-7'>{data?.review?.notes}</p>
        </div>
        {(data?.transferSuccess !== null || data?.transferSuccess !== null) && (
          <>
            <div className='separator separator-dashed'></div>
            <div className='col-12 mt-3'>
              <span className='me-5 fw-bold fs-6 text-gray-800'>Payout status</span>
              <div className='text-start my-3'>
                {data?.transferSuccess === true && (
                  <span className='badge badge-light-success fw-bold me-auto'>Payout success</span>
                )}
                {data?.transferSuccess !== true && data?.status === 'Closed' && (
                  <div className='space-between'>
                    <span className='text-gray-400 fw-semibold fs-6 me-4'>
                      {data?.transferError}
                    </span>
                    <OverlayTrigger
                      key={'right'}
                      placement={'right'}
                      overlay={<Tooltip id={`tooltip-${'placement'}`}>Retransfer</Tooltip>}
                    >
                      <button
                        disabled={loading}
                        onClick={Retransfer}
                        className='badge badge-light-warning fs-8 fw-bold cursor-pointer border-0'
                      >
                        {!loading ? (
                          <i className='fa-solid fa-rotate-right'></i>
                        ) : (
                          <Spinner
                            as='span'
                            animation='border'
                            className='mb-1 ml-5'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        )}
                      </button>
                    </OverlayTrigger>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export {JobDetailsModalForm}
