import React, {FC, useEffect} from 'react'
import {ReactSelectStyles} from '../../global/ReactSelectStyles'
import axios from 'axios'
import Select from 'react-select'
import {useFormik} from 'formik'
import FurnitureAssembly from './forms/FurnitureAssembly'
import WallMount from './forms/WallMount'
import {useAuth} from '../auth'
import Swal from 'sweetalert2'
import {validateFromBeforeSubmit} from './validateFormBeforeSubmit'
import HomeCleaning from './forms/HomeCleaning'
import MovingHelp from './forms/MovingHelp'
import ElectricalHelp from './forms/ElectricalHelp'
import PlumpinglHelp from './forms/PlumpinglHelp'
import Painting from './forms/Painting'
import Gardening from './forms/Gardening'
const BASE_URL = process.env.REACT_APP_API_URL

type Props = {
  hideSelectService: boolean
  setHideSelectService: (hideSelectService: boolean) => void
  hideServiceDetails: boolean
  setHideServiceDetails: (hideServiceDetails: boolean) => void
  hideServiceAddress: boolean
  setHideServiceAddress: (hideServiceAddress: boolean) => void
  jobs: any
  setJobs: (job: any) => void
  auth?: any
}

const AddJobs: FC<Props> = ({
  hideSelectService,
  setHideSelectService,
  hideServiceDetails,
  setHideServiceDetails,
  setHideServiceAddress,
  hideServiceAddress,
  jobs,
  setJobs,
  auth,
}) => {
  const [services, setServices] = React.useState<any>([])
  const {currentUser} = useAuth()
  const [isData, setIsData] = React.useState(false)
  const url = window.location.pathname
  const id = url.substring(url.lastIndexOf('/') + 1)

  const addJob = () => {
    setJobs([
      ...jobs,
      {
        additionalDetails: '',
        code: '',
        category: '',
        typeCode: '',
        serviceId: 0,
        seviceCategory: 0,
        seriveTypeCategory: 0,
        serviceFirstChildren: [],
        serviceSecondChildren: [],
        vehiculeNeeded: '',
        sizeInch: null,
        wallMountOnHand: true,
        wallSpecificSize: true,
        wallType: '',
        itemsFrom: '',
        model: '',
        numberOfItems: null,
        length: null,
        placementDifficulty: true,
        placementDifficultyPicture: '',
        size: '',
        assemblyRequired: true,
        itemLink: '',
        assistHandlr: true,
        numberOfWindows: null,
        width: null,
        height: null,
        items: '',
        headboardWallMounted: true,
        storageDrawers: true,
        EndAddress1: '',
        EndAddress2: '',
        EndCity: '',
        EndState: '',
        EndZipcode: '',
        EndCountry: '',
      },
    ])
  }

  const handleDeleteJob = (index: number) => {
    setJobs((prevJobs: any) => prevJobs.filter((_: any, i: any) => i !== index))
  }

  const handleChange = (index: number, field: string, value: any) => {
    setJobs((prevJobs: any) => {
      const updatedJobs = [...prevJobs]
      updatedJobs[index] = {
        ...updatedJobs[index],
        [field]: value,
      }
      return updatedJobs
    })
  }

  const handleGetServiceName = async () => {
    const serivces = await axios.get(`${BASE_URL}/api/Jobs/GetService`)
    const serviceName = serivces.data?.services.map((service: any) => {
      return {value: service.id, label: service.name, code: service.code}
    })
    setServices(serviceName)
  }

  const handleGetServiceNameFirstChildren = async (id: any, index: any) => {
    try {
      const serivces = await axios.get(`${BASE_URL}/api/Jobs/GetService?parentId=${id}`)
      const serviceName = serivces.data?.services.map((service: any) => {
        return {value: service.id, label: service.name, code: service.code}
      })
      handleChange(index, 'serviceFirstChildren', serviceName)
      setIsData(true)
    } catch (e) {
      console.log(e)
    } finally {
    }
  }

  const handleGetServiceNameSeondChildren = async (id: any, index: any) => {
    try {
      const serivces = await axios.get(`${BASE_URL}/api/Jobs/GetService?parentId=${id}`)
      const serviceName = serivces.data?.services.map((service: any) => {
        return {value: service.id, label: service.name, code: service.code}
      })
      handleChange(index, 'serviceSecondChildren', serviceName)
    } catch (e) {
      console.log(e)
    } finally {
    }
  }

  useEffect(() => {
    handleGetServiceName()
  }, [])

  const resetJob = (index: number) => {
    handleChange(index, 'additionalDetails', '')
    handleChange(index, 'vehiculeNeeded', '')
    handleChange(index, 'sizeInch', 0)
    handleChange(index, 'wallMountOnHand', false)
    handleChange(index, 'wallSpecificSize', false)
    handleChange(index, 'wallType', '')
    handleChange(index, 'itemsFrom', '')
    handleChange(index, 'model', '')
    handleChange(index, 'numberOfItems', 0)
    handleChange(index, 'length', 0)
    handleChange(index, 'placementDifficulty', false)
    handleChange(index, 'placementDifficultyPicture', '')
    handleChange(index, 'size', '')
    handleChange(index, 'assemblyRequired', false)
    handleChange(index, 'itemLink', '')
    handleChange(index, 'assistHandlr', false)
    handleChange(index, 'numberOfWindows', 0)
    handleChange(index, 'width', 0)
    handleChange(index, 'height', 0)
    handleChange(index, 'items', '')
    handleChange(index, 'headboardWallMounted', false)
    handleChange(index, 'storageDrawers', false)
    handleChange(index, 'EndAddress1', '')
    handleChange(index, 'EndAddress2', '')
    handleChange(index, 'EndCity', '')
    handleChange(index, 'EndState', 'Ontario')
    handleChange(index, 'EndZipcode', '')
    handleChange(index, 'EndCountry', 'Canada')
    handleChange(index, 'CoatType', '')
    handleChange(index, 'Type', '')
  }

  const formik = useFormik({
    initialValues: jobs,
    // validationSchema: selectServiceSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      if (validateFromBeforeSubmit(jobs)) {
        setHideSelectService(true)
        setHideServiceAddress(false)
      }
    },
  })

  useEffect(() => {
    if (jobs[0].serviceId === 0 || jobs[0].serviceId === null) {
      if (window.location.pathname.includes('/landing/service/')) {
        handleGetServiceNameFirstChildren(id, 0)
        handleChange(0, 'serviceId', parseInt(id))
        handleChange(0, 'serviceId2', parseInt(id))
      }
    }
  }, [])

  useEffect(() => {
    if (isData) {
      if (window.location.pathname.includes('/landing/service/')) {
        const code = services.find((option: any) => option.value === parseInt(id))
        handleChange(0, 'code', code?.code)
        handleChange(0, 'serviceName', code?.label)
      }
    }
  }, [isData])
  return (
    <>
      {jobs.map((job: any, index: any) => (
        <div key={index}>
          <div className='card shadow mb-5 border border-dashed'>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Select Service</h3>
              </div>
              <div className='d-flex justify-content-end'>
                {/* {hideSelectService && (
                  <button
                    onClick={() => {
                      setHideSelectService(false)
                      setHideServiceDetails(true)
                      setHideServiceAddress(true)
                    }}
                    className='btn btn-light-primary btn-sm align-self-center me-3'
                  >
                    Edit
                  </button>
                )} */}
                {jobs?.length > 1 && (
                  <button
                    onClick={() => {
                      handleDeleteJob(index)
                    }}
                    className='btn btn-light-primary btn-sm align-self-center'
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>

            <div className={`card-body p-9 ${hideSelectService && 'd-none'}`}>
              <div className='row mb-7'>
                <label className='fw-semibold text-muted'>Let's start with the basics</label>
              </div>
              <div className='row mb-7'>
                <div className='row mb-5'>
                  <div className='col-12'>
                    <label className='fw-bold fs-5 mb-3 required'>
                      Select the service that best fits your needs to proceed!
                    </label>
                    <div className='col-12'>
                      <Select
                        placeholder='Services'
                        name='services'
                        options={services}
                        onChange={(e) => {
                          console.log('code 1', e.code)
                          handleChange(index, 'serviceId', e?.value)
                          handleChange(index, 'serviceId2', e?.value)
                          handleChange(index, 'serviceName', e?.label)
                          handleChange(index, 'code', e?.code)
                          handleGetServiceNameFirstChildren(e?.value, index)
                          handleChange(index, 'serviceSecondChildren', [])
                          handleChange(index, 'seviceCategory', 0)
                          handleChange(index, 'category', '')
                          handleChange(index, 'seriveTypeCategory', 0)
                          handleChange(index, 'serviceTypeCategoryName', '')
                          resetJob(index)
                        }}
                        value={services.find((option: any) => option.value === job.serviceId2)}
                        styles={ReactSelectStyles}
                      />
                    </div>
                  </div>
                </div>
                {job?.serviceFirstChildren?.length > 0 && (
                  <div className='my-5'>
                    <span className='fw-bold fs-5 required'>Select the Category of furniture:</span>
                    <div className='d-flex flex-wrap p-3'>
                      {job?.serviceFirstChildren?.map((service: any) => {
                        return (
                          <div className='mt-3' key={service.value}>
                            <label className='form-check form-check-inline form-check-solid me-5'>
                              <input
                                className='form-check-input'
                                type='radio'
                                onChange={(e: any) => {
                                  handleChange(index, 'serviceId', parseInt(e?.target?.value))
                                  handleChange(index, 'seviceCategory', parseInt(e?.target?.value))
                                  handleChange(index, 'serviceCategoryName', service.label)
                                  handleChange(index, 'category', service.code)
                                  handleChange(index, 'seriveTypeCategory', 0)
                                  handleGetServiceNameSeondChildren(e?.target?.value, index)
                                  resetJob(index)
                                }}
                                value={service.value}
                                checked={job.seviceCategory === service.value}
                              />
                              <span className='fw-bold ps-2 fs-6'>{service.label}</span>
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}

                {job?.serviceSecondChildren?.length > 0 && (
                  <div>
                    <span className='fw-bold fs-5 required'>Type of category:</span>
                    <div className='col-lg-8 row p-3'>
                      {job?.serviceSecondChildren?.map((service: any) => {
                        return (
                          <div className='col-4 mt-3' key={service.value}>
                            <label className='form-check form-check-inline form-check-solid me-5'>
                              <input
                                className='form-check-input'
                                type='radio'
                                value={service.value}
                                checked={job.seriveTypeCategory === service.value}
                                onChange={(e: any) => {
                                  handleChange(index, 'serviceId', parseInt(e?.target?.value))
                                  handleChange(index, 'typeCode', service?.code)
                                  handleChange(index, 'serviceTypeCategoryName', service.label)
                                  handleChange(
                                    index,
                                    'seriveTypeCategory',
                                    parseInt(e?.target?.value)
                                  )
                                  resetJob(index)
                                }}
                              />
                              <span className='fw-bold ps-2 fs-6'>{service.label}</span>
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}

                {job?.seriveTypeCategory !== 0 && job.code === 'FURN' && (
                  <div>
                    <h3 className='fw-bolder mt-5 pt-5'>Service Details</h3>
                    <div className='separator my-5'></div>
                    <div className={``}>
                      <FurnitureAssembly job={job} handleChange={handleChange} index={index} />
                    </div>
                  </div>
                )}
                {job?.seviceCategory !== 0 && job.code === 'WALLM' && (
                  <div>
                    <h3 className='fw-bolder mt-5 pt-5'>Service Details</h3>
                    <div className='separator my-5'></div>
                    <div className={``}>
                      <WallMount job={job} handleChange={handleChange} index={index} />
                    </div>
                  </div>
                )}
                {job?.seviceCategory !== 0 && job.code === 'HCLN' && (
                  <div>
                    <h3 className='fw-bolder mt-5 pt-5'>Service Details</h3>
                    <div className='separator my-5'></div>
                    <div className={``}>
                      <HomeCleaning job={job} handleChange={handleChange} index={index} />
                    </div>
                  </div>
                )}
                {job?.seviceCategory !== 0 && job.code === 'ELHLP' && (
                  <div>
                    <h3 className='fw-bolder mt-5 pt-5'>Service Details</h3>
                    <div className='separator my-5'></div>
                    <div className={``}>
                      <ElectricalHelp job={job} handleChange={handleChange} index={index} />
                    </div>
                  </div>
                )}
                {job?.seviceCategory !== 0 && job.code === 'PLHLP' && (
                  <div>
                    <h3 className='fw-bolder mt-5 pt-5'>Service Details</h3>
                    <div className='separator my-5'></div>
                    <div className={``}>
                      <PlumpinglHelp job={job} handleChange={handleChange} index={index} />
                    </div>
                  </div>
                )}
                {job?.seviceCategory !== 0 && job.code === 'PNT' && (
                  <div>
                    <h3 className='fw-bolder mt-5 pt-5'>Service Details</h3>
                    <div className='separator my-5'></div>
                    <div className={``}>
                      <Painting job={job} handleChange={handleChange} index={index} />
                    </div>
                  </div>
                )}
                {job?.seviceCategory !== 0 && job.code === 'YRDGRD' && (
                  <div>
                    <h3 className='fw-bolder mt-5 pt-5'>Service Details</h3>
                    <div className='separator my-5'></div>
                    <div className={``}>
                      <Gardening job={job} handleChange={handleChange} index={index} />
                    </div>
                  </div>
                )}
                {job.code === 'MHLP' && (
                  <div>
                    <h3 className='fw-bolder mt-5 pt-5'>Service Details</h3>
                    <div className='separator my-5'></div>
                    <div className={``}>
                      <MovingHelp job={job} handleChange={handleChange} index={index} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
      {hideSelectService && (
        <div className='row mb-xl-5 pb-2'>
          <div className='col-12 d-flex justify-content-end'>
            <button
              onClick={() => {
                setHideSelectService(false)
                setHideServiceDetails(true)
                setHideServiceAddress(true)
              }}
              className='btn btn-light-primary btn-sm'
            >
              Edit Services
            </button>
          </div>
        </div>
      )}
      {!hideSelectService && (
        <div className='row mb-xl-5 pb-5'>
          <div className='col-12 d-flex justify-content-between'>
            <button
              onClick={() => {
                addJob()
              }}
              className='btn btn-light-primary btn-sm'
            >
              Additional Service
            </button>

            {currentUser ? (
              <button
                onClick={() => {
                  formik.handleSubmit()
                }}
                className='btn btn-primary btn-sm'
              >
                Next
              </button>
            ) : (
              <button
                onClick={() => {
                  // alert('Please login to continue')
                  if (validateFromBeforeSubmit(jobs)) {
                    !auth.firstName
                      ? document.getElementById('post-login-btn')?.click()
                      : formik.handleSubmit()
                  }
                }}
                className='btn btn-primary btn-sm'
              >
                Next
              </button>
            )}
            <button
              hidden
              id='post-next-btn'
              onClick={() => {
                formik.handleSubmit()
              }}
              className='btn btn-primary btn-sm'
            >
              Next
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default AddJobs
