import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_handld/helpers'
import {useQueryClient} from 'react-query'

type Props = {
  index: number
  job: any
  handleChange: (index: number, field: string, value: any) => void
}
const Painting: FC<Props> = ({job, handleChange, index}) => {
  return (
    <div className={`card-body`}>
      <div className='row mb-7'>
        <label className='fw-semibold text-muted'>
          Provide some more details about the selected service
        </label>
      </div>
      <>
        <div className='row mb-7 pb-3'>
          <label className='fw-bold fs-5 mb-3 required'>Type of Coat:</label>
          <div className='d-flex mx-5'>
            <label className='form-check form-check-inline form-check-solid me-5'>
              <input
                className='form-check-input'
                name={`CoatType${index}`}
                type='radio'
                checked={job?.CoatType === 'Single Coat'}
                value={job?.CoatType}
                onChange={(e) => handleChange(index, 'CoatType', 'Single Coat')}
              />
              <span className='fw-bold ps-2 fs-6'>Single Coat</span>
            </label>
            <label className='form-check form-check-inline form-check-solid me-5'>
              <input
                className='form-check-input'
                name={`CoatType ${index + 1}`}
                type='radio'
                checked={job?.CoatType === 'Tow Coat'}
                value={job?.CoatType}
                onChange={(e) => handleChange(index, 'CoatType', 'Tow Coat')}
              />
              <span className='fw-bold ps-2 fs-6'>Tow Coat</span>
            </label>
          </div>
        </div>

        <div className='row mb-7 pb-3'>
          <label className='fw-bold fs-5 mb-3 required'>Type:</label>
          <div className='d-flex mx-5'>
            <label className='form-check form-check-inline form-check-solid me-5'>
              <input
                className='form-check-input'
                name={`Type${index}`}
                type='radio'
                checked={job?.Type === 'without cleaning and surface'}
                value={job?.Type}
                onChange={(e) => handleChange(index, 'Type', 'without cleaning and surface')}
              />
              <span className='fw-bold ps-2 fs-6'>Without cleaning and surface</span>
            </label>
            <label className='form-check form-check-inline form-check-solid me-5'>
              <input
                className='form-check-input'
                name={`Type${index + 1}`}
                type='radio'
                checked={job?.Type === 'with cleaning and surface'}
                value={job?.Type}
                onChange={(e) => handleChange(index, 'Type', 'with cleaning and surface')}
              />
              <span className='fw-bold ps-2 fs-6'>With cleaning and surface</span>
            </label>
          </div>
        </div>
      </>
      <div className='row mb-7 pb-3'>
        <div className='col-12'>
          <label className='fw-bold fs-5 mb-3'>Additional Notes/Info</label>
          <textarea
            className='form-control form-control-lg form-control-solid '
            rows={4}
            name='additionalDetails'
            value={job?.additionalDetails}
            onChange={(e) => handleChange(index, 'additionalDetails', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default Painting
