/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {FC} from 'react'
import {User} from '../../core/_models'
import {KTIcon, toAbsoluteUrl} from '../../../../../../_handld/helpers'
import {Link} from 'react-router-dom'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

type Props = {
  user: User
}

const UserInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    {/* begin:: Avatar */}
    <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
      <a href='#'>
        {user.profilePicture ? (
          <div className='symbol-label'>
            <img
              src={`${user.profilePicture}`}
              alt={user.name}
              className='w-100'
              style={{objectFit: 'contain'}}
            />
          </div>
        ) : (
          <div className='symbol-label'>
            <img
              src={toAbsoluteUrl('/media/svg/avatars/blank.svg')}
              alt={user.name}
              className='w-100'
            />
          </div>
        )}
      </a>
    </div>
    <div className='d-flex flex-column text-start'>
      <Link to={`/backoffice/handyman-management/details/${user.id}`} className='menu-link'>
        {user.firstName + ' ' + user.lastName}
      </Link>
      {user?.skills?.length > 20 ? (
        <OverlayTrigger
          key='copy-to-clipboard'
          placement='top'
          overlay={<Tooltip id='tooltip-copy-to-clipboard' className='fw-bold '>{user?.skills}</Tooltip>}
        >
          <div className='d-flex justify-content-start'>
            <span className='text-truncate mw-200px'>{user?.skills}</span>
            <span>
              {' '}
              <KTIcon iconName='information-4' className='fs-2 pe-0 cursor-pointer' />
            </span>
          </div>
        </OverlayTrigger>
      ) : (
        <span className='text-truncate mw-200px'>{user?.skills}</span>
      )}
    </div>
  </div>
)

export {UserInfoCell}
