import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider, useQueryRequest} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {HandlrListHeader} from './components/header/HandlrListHeader'
import {HandlrTable} from './table/HandlrTable'
import {KTCard, KTIcon, useDebounce} from '../../../../_handld/helpers'
import {StatusEditModal} from './status-edit-modal/StatusEditModal'
import {PasswordEditModal} from './password-edit-modal/PasswordEditModal'
import QueryFetchPost from '../../../../global/index2'
import {FC, useEffect, useState} from 'react'
import Select from 'react-select'
import {ReactSelectStyles} from '../../../global/ReactSelectStyles'
import {TagsInput} from 'react-tag-input-component'
import {initialQueryState} from '../../../../_handld/helpers'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

type Props = {
  search?: string
  StatusId?: number | null | undefined
  SkillsToFilter?: string[] | null
}
const HandlrList:FC<Props> = ({search , StatusId , SkillsToFilter}) => {
  const {itemIdForUpdate, itemIdForUpdatePassword} = useListView()
  const {updateState} = useQueryRequest()
  const debouncedSearchTerm = useDebounce(search, 150)

  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && search !== undefined) {
        updateState({ search: debouncedSearchTerm, ...initialQueryState })
      }
    },
    [debouncedSearchTerm]
  )

  useEffect(() => {
    updateState({
      filter: {...initialQueryState, StatusId , SkillsToFilter},
    })
  }, [StatusId , SkillsToFilter])

  return (
    <>
      <KTCard>
        {/* <HandlrListHeader /> */}
        <HandlrTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <StatusEditModal />}
      {itemIdForUpdatePassword !== undefined && <PasswordEditModal />}
    </>
  )
}

const HandlrListWrapper = () => {
  const [SkillsToFilter, setSelectedSkills] = useState<string[] | null>([])
  const [showSearchComponent, setShowSearchComponent] = useState<any>(false)
  const [statuss, setstaus] = useState<any>()
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [StatusId, setStatusId] = useState<number | null | undefined>()
  const [statusOptions, setStatusOptions] = useState<any[]>([])
  const {updateState} = useQueryRequest()
  const [search, setSearch] = useState<string>('')

  const resetData = () => {
    setSearch('')
    setStatusId(null)
    setSelectedSkills(null)
  }
  const Getviewmodel = async () => {
    await QueryFetchPost({
      link: `${BASE_URL}/api/BackOffice/GetViewModel`,
      data: undefined,
      method: 'GET',
      key: 'Modelstatus',
      signal: undefined,
      isFormData: false,
    }).then((response: any) => {
      setstaus(response[1].status)
    })
  }
  useEffect(() => {
    Getviewmodel()
  }, [])
  useEffect(() => {
    if (statuss) {
      setStatusOptions(statuss.map((status: any) => ({value: status.id, label: status.name})))
    }
  }, [statuss])

  const filterData = () => {
    updateState({
      filter: {StatusId, SkillsToFilter, search},
      ...initialQueryState,
    })
  }
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <div className='row'>
            <div
              className={`col-xl-3 col-lg-3 col-md-12 ${
                showSearchComponent ? 'filter-container' : 'filter-container hidden'
              }`}
            >
              {showSearchComponent && (
                <KTCard>
                  <div className='px-7 py-5' data-kt-user-table-filter='form'>
                    {/* begin::Input group */}
                    <div>
                      <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold mt-5'>Search:</label>
                        <div className='d-flex align-items-center position-relative my-1'>
                          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                          <input
                            type='text'
                            data-kt-user-table-filter='search'
                            className='form-control form-control-solid  ps-14'
                            placeholder='Search...'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* end::Input group */}
                      {/* begin::Input group */}
                      <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Status:</label>
                        <Select
                          className='w-100'
                          options={statusOptions}
                          isClearable={true}
                          value={
                            StatusId
                              ? statusOptions?.find((status: any) => status.id === StatusId)
                              : null
                          }
                          onChange={(e: any) => {
                            if (e) {
                              setStatusId(e.value)
                            } else {
                              setStatusId(null)
                            }
                          }}
                          styles={ReactSelectStyles}
                        />
                      </div>
                      {/* end::Input group */}

                      {/* begin::Input group */}
                      <div className='mb-10'>
                        <label className='form-label fs-6 fw-bold'>Skills:</label>
                        <TagsInput
                          value={SkillsToFilter || []}
                          onChange={setSelectedSkills}
                          name='skills'
                          placeHolder='Enter Your skills'
                          classNames={{
                            input: 'form-control form-control-solid w-100 border-0', // Add your custom class name here
                            tag: 'me-2 mb-2 p-2 bg-light-primary text-dark fw-bold',// Add your custom class name here
                          }}
                        />
                      </div>
                      {/* end::Input group */}

                      {/* begin::Input group */}
                      {/* <div className='mb-10'>
                  <label className='form-label fs-6 fw-bold'>Rating:</label>
                  <select
                    className='form-select form-select-solid fw-bolder'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    data-kt-user-table-filter='two-step'
                    data-hide-search='true'
                  >
                    <option value=''></option>
                    <option value='Yesterday'>Blambing</option>
                  </select>
                </div> */}
                      {/* end::Input group */}

                      {/* begin::Actions */}
                      <div className='d-flex justify-content-end'>
                        <button
                          type='button'
                          onClick={resetData}
                          className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                          data-kt-menu-dismiss='true'
                          data-kt-user-table-filter='reset'
                        >
                          Reset
                        </button>
                        {/* <button
                          onClick={filterData}
                          type='button'
                          className='btn btn-primary fw-bold px-6'
                          data-kt-menu-dismiss='true'
                          data-kt-user-table-filter='filter'
                        >
                          Apply
                        </button> */}
                      </div>
                      {/* end::Actions */}
                    </div>
                  </div>
                </KTCard>
              )}
            </div>
            <div
              className={
                showSearchComponent
                  ? 'col-xl-9 col-lg-9 col-md-12'
                  : 'col-xl-12 col-lg-12 col-md-12'
              }
            >
              <OverlayTrigger
                key='copy-to-clipboard'
                placement='top'
                overlay={
                  <Tooltip id='tooltip-copy-to-clipboard'>
                    {showSearchComponent ? 'Close Filter' : 'Filter'}
                  </Tooltip>
                }
              >
                <button
                  type='button'
                  className={`btn btn-light-primary btn-sm px-2 position-absolute z-index-1 end-1 filter-button ${
                    showSearchComponent ? 'clicked' : ''
                  }`}
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  onClick={() => setShowSearchComponent(!showSearchComponent)}
                  style={{transform: 'translateX(-14px)'}}
                >
                  <KTIcon
                    iconName={showSearchComponent ? 'abstract-11' : 'filter'}
                    className='fs-2 pe-0'
                  />
                </button>
              </OverlayTrigger>

              <HandlrList search={search} StatusId={StatusId} SkillsToFilter={SkillsToFilter} />
            </div>
          </div>
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  )
}
export {HandlrListWrapper}
