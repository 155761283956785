import { FC } from 'react'
import { Fragment, useEffect, useState } from 'react'
import { Card, Form, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../_handld/helpers'
import { PageTitle } from '../../../../_handld/layout/core'
import axios from 'axios'
import Swal from 'sweetalert2'
import SVG from 'react-inlinesvg'
import {
  ConvertToDateCurrentTimeZoneDateTime,
  getFormatDateTime,
  getFromatdate,
} from '../../../../_handld/helpers/GlobalHelpers'
import moment from 'moment'
import { TaskImageViewerModal } from '../../handlr-management/images-viewer/ImageViewerModal'

const API_URL = process.env.REACT_APP_API_URL
const CustomerJobGroup = () => {
  const [show, setShow] = useState(false)
  const [loadingClose, setLoadingClose] = useState(false)
  const [showCancel, setShowCancel] = useState(false)
  const [jobsList, setJobsList] = useState([])
  const [data, setData] = useState<any>({})
  const [quotes, setQuotes] = useState<Quote[]>([])
  const [quoteId, setQuoteId] = useState('')
  const [rate, setRate] = useState(null)
  const [jobRates, setJobRates] = useState<any>({})
  const [notes, setNotes] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const { id } = useParams<{ id: string }>()
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const handleCloseCancel = () => setShowCancel(false)
  const handleShowCancel = () => setShowCancel(true)
  const [imageId, setImageId] = useState<any>('')
  const [imageSrc, setImageSrc] = useState<any>()
  type ApiResponse = {
    firstName: string
    lastName: string
    email: string
    address1: string
    address2: string
    city: string
    state: string
    zipCode: string
    country: string
    price: number
    isClosed: boolean
    jobs: Array<{
      status: string
      requestedDate: any

      // ... other job properties
    }>
    quotes: Array<{
      created: string
      id: number
      price: number
      isAccepted: boolean | null
      // ... other quote properties
    }>
  }
  const jobDetailsBreadcrumbs = [
    {
      title: 'Job',
      path: '/customers-management/Groupdetails/${id}',
      isSeparator: false,
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const handleBadge = (status: any) => {
    if (status === 'Completed') {
      return 'success'
    }
    if (status === 'Canceled') {
      return 'warning'
    }
    if (status === 'In Progress') {
      return 'progress'
    }
    if (status === 'Booked') {
      return 'info'
    }
    if (status === 'Open') {
      return 'primary'
    }
    if (status === 'Draft') {
      return 'secondary'
    }
    if (status === 'Processing') {
      return 'processing'
    }
    if (status === 'Closed') {
      return 'danger'
    }
  }
  interface Quote {
    created: string
    id: number
    price: number
    isAccepted: boolean | null
    // Add other properties as needed
  }
  const handleGetJobs = () => {
    axios.get(`${API_URL}/api/BackOffice/DetailsJobsGroup/${id}`).then((res) => {
      const apiResponse: ApiResponse = res.data
      setJobsList(res.data.jobs)
      setData(res.data)
      setQuotes(apiResponse.quotes)
    })
  }
  useEffect(() => {
    handleGetJobs()
  }, [id])

  const formatDate = (date: any) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }

  const formatCurrency = (num: any) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(num)
  }
  useEffect(() => {
    if (jobsList?.length > 0) {
      jobsList?.map((job: any) => {
        setJobRates((prevRates: any) => ({
          ...prevRates,
          [job.id]: job?.review?.rate,
        }))
      })
    }
  }, [jobsList])
  const hasAcceptedQuote = quotes.some((quote) => quote.isAccepted === true)

  return (
    <>
      <PageTitle breadcrumbs={jobDetailsBreadcrumbs}>Details</PageTitle>
      <div className='row'>
        <div className='col-xl-8 col-lg-8 col-12'>
          <Card className='h-100'>
            <Card.Body>
              {jobsList?.map((job: any, index: number) => (
                <>
                  <div className='row'>
                    <div className='col-xl-8 col-lg-8 col-12'>
                      <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
                        <div key={index}>
                          <div>
                            <div className='mx-5 mb-3 d-flex justify-content-between align-items-center'>
                              <div className='d-flex align-items-center'>
                                <h3 className='me-5'>Service</h3>
                                <span className='badge badge-light-danger fs-8 fw-bold'>
                                  {' '}
                                  {index + 1}
                                </span>
                              </div>
                              <span
                                className={`badge badge-${handleBadge(
                                  job?.status
                                )} fw-bold px-4 py-3`}
                              >
                                {job?.status}
                              </span>
                            </div>
                            <div className='d-flex row'>
                              <div className='col-xl-12 col-lg-12 col-md-12'>
                                <div className='fw-bold fs-6 mb-2 ms-10'>
                                  {' '}
                                  <label>Name</label>
                                  <p className='text-muted'>{job?.service}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='separator separator text-gray-800 my-5'></div>
                          <div>
                            <div className='mx-5 mb-'>
                              <h3 className='me-5'>Service Details</h3>
                            </div>
                            <div className='d-flex row'>
                              {job?.itemsFrom && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mx-10'>
                                    {' '}
                                    <label className='fw-bold fs-5 mb-3'>
                                      {job?.serviceCode === 'KING' && 'Bed is from'}
                                      {job?.serviceCode === 'FULLWS' && 'Bed is from'}
                                      {job?.serviceCode === 'FULL' && 'Bed is from'}
                                      {job?.serviceCode === 'QUEE' && 'Bed is from'}
                                      {job?.serviceCode === 'KINGWS' && 'Bed is from'}
                                      {job?.serviceCode === 'QUEEWS' && 'Bed is from'}

                                      {job?.serviceCode === 'SOFA' && 'Sofa is from'}
                                      {job?.serviceCode === 'SECTWS' && 'Sofa is from'}
                                      {job?.serviceCode === 'SECT' && 'Sofa is from'}
                                      {job?.serviceCode === 'THRE' && 'Sofa is from'}
                                      {job?.serviceCode === 'TWOP' && 'Sofa is from'}

                                      {job?.serviceCode === 'DINIT4C' && 'Table & chair from'}
                                      {job?.serviceCode === 'DESKT' && 'Table & chair from'}
                                      {job?.serviceCode === 'DESKAC' && 'Desk & chair from'}
                                      {job?.serviceCode === 'DESKND' && 'Desk & chair from'}
                                      {job?.serviceCode === 'DESKC' && 'Desk & chair from'}
                                      {job?.serviceCode === 'CONS' && 'Table & chair from'}
                                      {job?.serviceCode === 'COFF' && 'Table & chair from'}
                                      {job?.serviceCode === 'SIDE' && 'Table & chair from'}
                                      {job?.serviceCode === 'BARS' && 'Table & chair from'}

                                      {job?.serviceCode === 'NIGH' && '(Item) is from'}
                                      {job?.serviceCode === 'DRES6' && '(Item) is from'}
                                      {job?.serviceCode === 'DRES4' && '(Item) is from'}
                                      {job?.serviceCode === 'SHEL' && 'Shelves are from'}
                                      {job?.serviceCode === 'CABI' && 'Cabinets are from'}
                                      {job?.serviceCode === 'WALLL' && 'Wall-Lamp(s) are from'}
                                      {job?.serviceCode === 'WALLMI' && 'Model Name/Number'}
                                    </label>
                                    <p className='text-muted'>{job?.itemsFrom}</p>
                                  </div>
                                </div>
                              )}
                              {job?.model && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>Model Name/Number</label>
                                    <p className='text-muted'>{job?.model}</p>
                                  </div>
                                </div>
                              )}
                              {job?.serviceCode === 'TVWA' && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>{job?.serviceCode === 'TVWA' && 'Size of TV'}</label>
                                    <p className='text-muted'>{job?.sizeInch} inches</p>
                                  </div>
                                </div>
                              )}
                              {job?.serviceCode === 'TVWA' && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      {job?.serviceCode === 'TVWA' && 'Wall-Mount on hand'}
                                    </label>
                                    <p className='text-muted'>
                                      {job?.wallMountOnHand ? 'Yes' : 'No'}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {job?.serviceCode === 'TVWA' && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      {job?.serviceCode === 'TVWA' &&
                                        'Confirm Wall-Mount is correct specific to size of their TV:'}
                                    </label>
                                    <p className='text-muted'>
                                      {job?.wallSpecificSize ? 'Yes' : 'No'}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {job?.wallType && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      {job?.serviceCode === 'TVWA' &&
                                        'Wall Info TV is being mounted to'}
                                      {job?.serviceCode === 'SHEL' &&
                                        'Wall Info Shelf are being mounted to'}
                                      {job?.serviceCode === 'ARTP' &&
                                        'Wall Info Art/Picture(s)  is being mounted to'}
                                      {job?.serviceCode === 'CABI' &&
                                        'Wall Info Cabinet(s) are being mounted to'}
                                      {job?.serviceCode === 'WALLMI' &&
                                        'Wall Info Wall-Mirror(s)  being mounted to'}
                                      {job?.serviceCode === 'CURT' &&
                                        'Wall Info Curtain Rod(s)  being mounted to'}
                                      {job?.serviceCode === 'SMAL' &&
                                        'Wall Info: item(s)  being mounted to'}
                                    </label>
                                    <p className='text-muted'>{job?.wallType}</p>
                                  </div>
                                </div>
                              )}
                              {/* {job?.numberOfItems === 0 && (
                            <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                              <div className='fw-bold fs-6 mb-2 ms-10'>
                                {' '}
                                <label>
                                {job?.serviceCode === 'SMAL' && 'Number of Items'}
                                  {job?.serviceCode === 'TVWA' && 'Number of Tvs'}
                                  {job?.serviceCode === 'SHEL' && 'Number of Shelves'}
                                  {job?.serviceCode === 'ARTP' && 'Number of pictures'}
                                  {(job?.serviceCode === 'CURT' || job?.serviceCode === 'WALLMI') &&
                                    'Number of Curtain Rods'}
                                </label>
                                <p className='text-muted'>---</p>
                              </div>
                            </div>
                          )} */}
                              {job?.numberOfItems !== 0 && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      {job?.serviceCode === 'SMAL' && 'Number of small items'}
                                      {job?.serviceCode === 'TVWA' && 'Number of Tvs'}
                                      {job?.serviceCode === 'SHEL' && 'Number of Shelves'}
                                      {job?.serviceCode === 'ARTP' && 'Number of pictures'}
                                      {(job?.serviceCode === 'CURT' ||
                                        job?.serviceCode === 'WALLMI') &&
                                        'Number of Curtain Rods'}
                                      {job?.serviceCode === 'OTLI' &&
                                        'Number of outlets to be installed?'}
                                      {job?.serviceCode === 'LFRPL' &&
                                        'Number of light fixtures to be replaced?'}
                                      {job?.serviceCode === 'CLFI' &&
                                        'Number of ceiling fans to be installed?'}
                                      {job?.serviceCode === 'FRPRP' &&
                                        'Number of faucets to be repaired or replaced?'}
                                      {job?.serviceCode === 'TOIRP' &&
                                        'Number of toilets to be replaced?'}
                                      {job?.serviceCode === 'LKRP' &&
                                        'Number of leaks to be repaired?'}
                                      {job?.serviceCode === 'IDWMC' &&
                                        'Number of appliances to be installed?'}
                                      {job?.serviceCode === 'HWHIM' &&
                                        'Number of hot water heaters to be inspected or maintained?'}
                                      {job?.serviceCode === 'PPINS' &&
                                        'Number of pipes to be insulated?'}
                                      {job?.serviceCode === 'SHRP' &&
                                        'Number of shower heads to be replaced?'}
                                    </label>
                                    <p className='text-muted'>{job?.numberOfItems}</p>
                                  </div>
                                </div>
                              )}
                              {job?.length !== 0 && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      {job.serviceCode === 'SHEL' && 'Length of Shelves'}
                                    </label>
                                    <p className='text-muted'>{job?.length}</p>
                                  </div>
                                </div>
                              )}
                              {job?.serviceCode === 'ELEC' && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>Assembly of Fireplace required:</label>
                                    <p className='text-muted'>
                                      {job?.assemblyRequired ? 'Yes' : 'No'}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {job?.serviceCode === 'CABI' && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>Assembly of Cabinets required</label>
                                    <p className='text-muted'>
                                      {job?.assemblyRequired ? 'Yes' : 'No'}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {job?.coatType &&
                                (job.serviceCode === 'PNTMR' ||
                                  job.serviceCode === 'PNTSR' ||
                                  job.serviceCode === 'PNT') && (
                                  <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      <label>Type of Coat:</label>
                                      <p className='text-muted'>{job?.coatType}</p>
                                    </div>
                                  </div>
                                )}
                              {job?.type &&
                                (job.serviceCode === 'PNTSR' ||
                                  job.serviceCode === 'PNTMR' ||
                                  job.serviceCode === 'PNT') && (
                                  <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      <label>Type:</label>
                                      <p className='text-muted'>{job?.type}</p>
                                    </div>
                                  </div>
                                )}
                              {job?.type &&
                                (job.serviceCode === 'GRDPLN' ||
                                  job.serviceCode === 'LWCFRT' ||
                                  job.serviceCode === 'LWNMWN') && (
                                  <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      <label>Type of size:</label>
                                      <p className='text-muted'>{job?.type}</p>
                                    </div>
                                  </div>
                                )}
                              {job?.size &&
                                job.serviceCode !== 'RCLN' &&
                                job.serviceCode !== 'DCLN' &&
                                job.serviceCode !== 'SCLN' &&
                                job.serviceCode !== 'MHLP' &&
                                job.serviceCode !== 'GRDPLN' &&
                                job.serviceCode !== 'LWNMWN' &&
                                job.serviceCode !== 'LWCFRT' && (
                                  <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ml-5 ms-10'>
                                      {' '}
                                      <label>Size</label>
                                      <p className='text-muted'>{job?.size}</p>
                                    </div>
                                  </div>
                                )}
                              {job?.size &&
                                (job.serviceCode === 'RCLN' ||
                                  job.serviceCode === 'DCLN' ||
                                  job.serviceCode === 'SCLN') && (
                                  <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>
                                        Surface: What type of surface do you need cleaned?
                                      </label>
                                      <p className='text-muted'>{job?.size}</p>
                                    </div>
                                  </div>
                                )}
                              {job?.size &&
                                (job.serviceCode === 'GRDPLN' ||
                                  job.serviceCode === 'LWNMWN' ||
                                  job.serviceCode === 'LWCFRT') && (
                                  <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2  ms-10'>
                                      <label>Surface</label>
                                      <p className='text-muted'>{job?.size}</p>
                                    </div>
                                  </div>
                                )}
                              {job?.size && job.serviceCode === 'MHLP' && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>Boxes: How many boxes do you need moved?</label>
                                    <p className='text-muted'>{job?.size}</p>
                                  </div>
                                </div>
                              )}
                              {job?.vehiculeNeeded && job.serviceCode === 'MHLP' && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      Vehicle Requirements : What type of vehicle do you need?
                                    </label>
                                    <p className='text-muted'>{job?.vehiculeNeeded}</p>
                                  </div>
                                </div>
                              )}
                              {job?.handlerId && (
                                <>
                                  <div className='col-xl-6 col-lg-10 col-md-12 col-12 ms-10'>
                                    <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center mb-2 '>
                                      Handyman
                                    </div>
                                    <div className='d-flex align-items-center mb-2'>
                                      <div className='symbol symbol-60px symbol-lg-60px symbol-fixed position-relative me-4'>
                                        {job?.handlerPicture ? (
                                          <img
                                            src={`${job?.handlerPicture}`}
                                            style={{ objectFit: 'cover' }}
                                            alt='Handyman pic'
                                          />
                                        ) : (
                                          <img
                                            src={toAbsoluteUrl('/media/svg/avatars/blank.svg')}
                                            style={{ objectFit: 'cover' }}
                                            alt='Handyman pic'
                                          />
                                        )}
                                      </div>
                                      <Link
                                        to={`/backoffice/handyman-management/details/${job?.handlerId}`}
                                        className='menu-link fw-semibold fs-6'
                                      >
                                        {job?.handlerName === undefined ? '' : job?.handlerName}{' '}
                                        {''}{' '}
                                      </Link>
                                    </div>
                                  </div>
                                </>
                              )}
                              {job?.endAddress1 && job.serviceCode === 'MHLP' && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>End address</label>
                                    <p className='text-muted'>
                                      {job?.endAddress1},{' '}
                                      {job?.endAddress2 ? <>{job?.endAddress2}, </> : ''}
                                      {job?.endCity}, {job?.endState}, {job?.endZipCode},{' '}
                                      {job?.endCountry}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {job?.itemLink && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      {job?.serviceCode === 'ELEC' &&
                                        'Link input for electric fireplace make/model'}
                                    </label>
                                    <p className='text-muted'>{job?.itemLink}</p>
                                  </div>
                                </div>
                              )}
                              {job?.serviceCode === 'ELEC' && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      {job?.serviceCode === 'ELEC' &&
                                        'Depending on height/placement will someone be on hand to assist Handyman in lifting fireplace to the mounting bracket:'}
                                    </label>
                                    <p className='text-muted'>{job?.assistHandlr ? 'Yes' : 'No'}</p>
                                  </div>
                                </div>
                              )}
                              {job?.numberOfWindows !== 0 && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      {job?.serviceCode === 'CURT' && 'Number of Windows'}
                                    </label>
                                    <p className='text-muted'>{job?.numberOfWindows}</p>
                                  </div>
                                </div>
                              )}
                              {job?.width !== 0 &&
                                (job?.width !== null && (
                                  <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>Width</label>
                                      <p className='text-muted'>{job?.width}</p>
                                    </div>
                                  </div>
                                ))}
                              {job?.height !== 0 &&
                                (job?.height !== null && (
                                  <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>Height</label>
                                      <p className='text-muted'>{job?.height}</p>
                                    </div>
                                  </div>
                                ))}
                              {job?.items && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      {job?.serviceCode === 'SMAL' && 'Items/Description'}
                                    </label>
                                    <p className='text-muted'>{job?.items}</p>
                                  </div>
                                </div>
                              )}
                              {job?.serviceCode === 'QUEE' && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      Does this Model require Headboard to be Wall-Mounted?
                                    </label>
                                    <p className='text-muted'>
                                      {job?.headboardWallMounted ? 'Yes' : 'No'}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {job?.serviceCode === 'QUEEWS' && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      Does this Model require Headboard to be Wall-Mounted?
                                    </label>
                                    <p className='text-muted'>
                                      {job?.headboardWallMounted ? 'Yes' : 'No'}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {job?.serviceCode === 'KINGWS' && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      Does this Model require Headboard to be Wall-Mounted?
                                    </label>
                                    <p className='text-muted'>
                                      {job?.headboardWallMounted ? 'Yes' : 'No'}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {job?.serviceCode === 'FULL' && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      Does this Model require Headboard to be Wall-Mounted?
                                    </label>
                                    <p className='text-muted'>
                                      {job?.headboardWallMounted ? 'Yes' : 'No'}
                                    </p>
                                  </div>
                                </div>
                              )}{' '}
                              {job?.serviceCode === 'FULLWS' && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      Does this Model require Headboard to be Wall-Mounted?
                                    </label>
                                    <p className='text-muted'>
                                      {job?.headboardWallMounted ? 'Yes' : 'No'}
                                    </p>
                                  </div>
                                </div>
                              )}
                              {job?.serviceCode === 'KING' && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>
                                      Does this Model require Headboard to be Wall-Mounted?
                                    </label>
                                    <p className='text-muted'>
                                      {job?.headboardWallMounted ? 'Yes' : 'No'}
                                    </p>
                                  </div>
                                </div>
                              )}

                              {job?.requestedDate && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>Date to be completed</label>
                                    <div className='d-flex align-items-center'>
                                      <p className='text-muted'>
                                        {job?.requestedDate
                                          ? moment(job?.requestedDate).format(getFromatdate()!)
                                          : '---'}
                                      </p>
                                      <div className='font-weight-bolder mb-5'>
                                        <OverlayTrigger
                                          key={'right'}
                                          placement={'right'}
                                          overlay={
                                            <Tooltip id={`tooltip-${'placement'}`}>
                                              {getFromatdate()}
                                            </Tooltip>
                                          }
                                        >
                                          <span
                                            style={{ cursor: 'pointer' }}
                                            className='svg-icon menu-icon svg-icon-info'
                                          >
                                            <SVG
                                              src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')}
                                            />
                                          </span>
                                        </OverlayTrigger>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {job?.availableTimes && (
                                <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                  <div className='fw-bold fs-6 mb-2 ms-10'>
                                    {' '}
                                    <label>Available times</label>
                                    <p className='text-muted'>{job?.availableTimes}</p>
                                  </div>
                                </div>
                              )}
                              {(job?.serviceCode === 'KING' ||
                                job?.serviceCode === 'FULL' ||
                                job?.serviceCode === 'QUEE' ||
                                job?.serviceCode === 'FULLWS') && (
                                  <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                                    <div className='fw-bold fs-6 mb-2 ms-10'>
                                      {' '}
                                      <label>
                                      Confirmation NO storage drawers on this model
                                      </label>
                                      <p className='text-muted'>
                                        {job?.storageDrawers ? 'Yes' : 'No'}
                                      </p>
                                    </div>
                                  </div>
                                )}
                            </div>
                            <div className='col-xl-6 col-lg-10 col-md-12 col-12'>
                              {job?.placementDifficulty && job?.placementDifficultyPicture && (
                                <div
                                  className='fw-bold fs-6 mb-2 ms-10 '
                                  onClick={() => {
                                    setImageSrc(job?.placementDifficultyPicture)
                                  }}
                                >
                                  {' '}
                                  <label>Placement:</label> <br />
                                  <img
                                    src={job?.placementDifficultyPicture}
                                    alt='placement difficulty'
                                    className='w-80px h-80px rounded mt-2 cursor-pointer'
                                  />
                                </div>
                              )}
                            </div>
                            <div className='ms-10 mb-8 mt-4'>
                              <div className='fw-bold fs-6 mb-2'>
                                {' '}
                                <label>Additional Notes/Info</label>
                                <p className='text-muted'>
                                  {' '}
                                  {job?.additionalDetails
                                    ? job?.additionalDetails
                                    : 'No additional details'}
                                </p>
                              </div>
                            </div>
                            <div className='separator separator-solid text-gray-800 my-5'></div>
                          </div>
                          {/* <>  
                       <div className='mx-5 mb-8 d-flex justify-content-between align-items-center'>
                      <h4>Reviews</h4>
                      </div>
                      </> */}
                          {job?.status === 'Closed' && (
                            <>
                              <h4 className='mx-5'>Review</h4>
                              <div className='d-flex align-items-center bg-light-info rounded p-5 m-5 mt-2 col-8'>
                                <span className=' text-info me-5'>
                                  <i className='ki-duotone ki-abstract-26 text-info fs-1 me-5'>
                                    <span className='path1' />
                                    <span className='path2' />
                                  </i>
                                </span>
                                <div className='flex-grow-1 me-2'>
                                  <div className='fw-bold fs-6 mb-2 ml-5'>
                                    <div className='rating'>
                                      {[1, 2, 3, 4, 5].map((value: any) => (
                                        <Fragment key={value}>
                                          <label
                                            className='rating-label d-flex'
                                            htmlFor={`kt_rating_input_${job.id}_${value}`}
                                          >
                                            {jobRates[job.id] !== undefined &&
                                              value <= jobRates[job.id] ? (
                                              <i className='bi bi-star-fill fs-5 pt-1 text-warning me-2'></i>
                                            ) : (
                                              <i className='bi bi-star fs-5 pt-1 text-secondary me-2'></i>
                                            )}
                                          </label>
                                        </Fragment>
                                      ))}
                                    </div>
                                  </div>
                                  <span className='text-muted fw-semibold d-block'>
                                    {job?.review?.notes || 'No notes'}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                          {/* {job?.status === 'Closed' && (
                        <div className='fw-bold fs-6 mb-2 ms-10 mt-5'>
                     <h5>Notes </h5>
                          <Form.Group className='mb-3' controlId='exampleForm.ControlTextarea1'>
                            <Form.Control
                            disabled={true}
                              as='textarea'
                              placeholder='No available notes'
                              rows={3}
                              value={job?.review?.notes}
                            />
                          </Form.Group>
                        </div>
                      )}  */}
                        </div>
                        <div>
                          <div className='mx-5 mb-8'>
                            <h3>Service Address</h3>
                          </div>
                          <div>
                            <div className='fw-bold fs-6 mb-2 ms-10'>
                              {' '}
                              <label>Address </label>
                              <p className='text-muted'>
                                {data?.address1}
                                <br />
                                {data.address2 ? (
                                  <>
                                    {data.address2}
                                    <br />
                                  </>
                                ) : (
                                  ''
                                )}
                                {data?.city}, {data?.state}, {data?.zipCode}, {data?.country}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='col-xl-4 col-lg-4 col-12 border-start-1'>
                      <div className='mx-5 mb-8'>
                        <h3>History</h3>
                      </div>
                      <div className='timeline timeline-6 mt-3 ms-8'>
                        {job?.statusesHistory?.map((status: any, index: number) => {
                          let iconClass = ''

                          if (status.name === 'Completed') {
                            iconClass = 'success'
                          } else if (status.name === 'Draft') {
                            iconClass = 'secondary'
                          } else if (status.name === 'In Progress') {
                            iconClass = 'progress'
                          } else if (status.name === 'Booked') {
                            iconClass = 'info'
                          } else if (status.name === 'Canceled') {
                            iconClass = 'warning'
                          } else if (status.name === 'Closed') {
                            iconClass = 'danger'
                          } else if (status.name === 'Open' || status.name === 'New') {
                            iconClass = 'primary'
                          }
                          return (
                            <div className='timeline-item align-items-start mb-3' key={index}>
                              <div className='timeline-label fw-bolder text-dark-75 font-size-lg pe-3 me-8'>
                                <span className={`badge badge-${iconClass}`}>{status?.name}</span>
                              </div>
                              <div className='timeline-badge'>
                                <i className={`fa fa-genderless icon-xl text-${iconClass}`} />
                              </div>
                              <div className='fw-bold font-size-lg timeline-content text-muted ps-3'>
                                <div className='d-flex align-items-center'>
                                  {status?.created
                                    ? ConvertToDateCurrentTimeZoneDateTime(status?.created)
                                    : '---'}
                                  <div className='fw-bolder mb-0'>
                                    <OverlayTrigger
                                      key={'right'}
                                      placement={'right'}
                                      overlay={
                                        <Tooltip id={`tooltip-${'placement'}`}>
                                          {getFormatDateTime()}
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        style={{ cursor: 'pointer' }}
                                        className='svg-icon menu-icon svg-icon-info'
                                      >
                                        <SVG
                                          src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')}
                                        />
                                      </span>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  {index !== jobsList.length - 1 && (
                    <div className='separator separator-dashed text-gray-800 mt-5 mb-10'></div>
                  )}
                </>
              ))}
            </Card.Body>
          </Card>
        </div>

        <div className='col-lg-4 col-lg-4 col-12'>
          {quotes &&
            quotes.length > 0 &&
            quotes.map((quote: any, index: any) => (
              <div className='card card-flush shadow-sm mb-5' key={index}>
                <div className='card-header'>
                  <div className='card-title'>
                    <h2 className='me-5'>Quote #{quote?.id} </h2>
                    {quote?.isAccepted === true && (
                      <div className='mb-0 d-flex justify-content-between flex-wrap'>
                        <span className='badge badge-success'>Accepted</span>
                      </div>
                    )}
                    {quote?.isAccepted === false && (
                      <div className='mb-0 d-flex justify-content-between flex-wrap'>
                        <span className='badge badge-danger'>Rejected</span>
                      </div>
                    )}
                    {quote?.isAccepted === null && !hasAcceptedQuote ? (
                      <div className='mb-0 d-flex justify-content-between flex-wrap'>
                        <span className='badge badge-secondary'>Pending</span>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className='card-toolbar'>
                    <div className='d-flex align-items-center'>
                      <div className='fw-bold fs-6 text-gray-800'>
                        {quote?.created ? moment(quote?.created).format(getFromatdate()!) : '---'}
                      </div>
                      <div className='font-weight-bolder '>
                        <OverlayTrigger
                          key={'top'}
                          placement={'top'}
                          overlay={
                            <Tooltip id={`tooltip-${'placement'}`}>{getFromatdate()}</Tooltip>
                          }
                        >
                          <span
                            style={{ cursor: 'pointer' }}
                            className='svg-icon menu-icon svg-icon-info'
                          >
                            <SVG src={toAbsoluteUrl('/media/svg/Code/Info-circle.svg')} />
                          </span>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-body pt-0 fs-6'>
                  <div className='mb-7'>
                    <h5 className='mb-4'>Items</h5>
                    {jobsList?.map((job: any, index: number) => {
                      return (
                        <div key={index}>
                          <div className='mb-0 ms-5'>
                            <span className='fw-semibold text-gray-600 '>{job?.service}</span>
                          </div>
                          <div className='separator separator-dashed my-5' />
                        </div>
                      )
                    })}
                  </div>
                  <table className='table fs-6 fw-semibold gs-0 gy-2 gx-2'>
                    <tbody>
                      <tr className=''>
                        <td className='text-gray-400'>Total</td>
                        <td className='text-gray-800 fs-3 text-end fw-bolder'>
                          {quote?.hasDiscount ? (
                            <>
                              <span className=''>
                                {formatCurrency(quote?.discountPrice)}
                              </span>{' '}
                              /{' '}
                              <span className='text-danger ml-2 text-decoration-line-through'>
                                {formatCurrency(quote?.price)}
                              </span>
                            </>
                          ) : (
                            <>{formatCurrency(quote?.price)}</>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
        </div>
      </div>
      {imageSrc && (
        <TaskImageViewerModal
          imageId={imageId}
          setImageId={setImageId}
          attachments={[{ path: imageSrc }]}
          setImageSrc={setImageSrc}
        />
      )}
    </>
  )
}
export { CustomerJobGroup }
