// @ts-nocheck
import {Column} from 'react-table'
import {UserActionsCell} from './UserActionsCell'
import {UserSelectionCell} from './UserSelectionCell'
import {UserCustomHeader} from './UserCustomHeader'
import {UserSelectionHeader} from './UserSelectionHeader'
import {Totalprice} from './Totalprice'

import {User} from '../../core/_models'
import {UserInfoCell} from './UserInfoCell'
import {Emailpage} from './Emailpage'
import {Phonepage} from './Phonepage'
import { UserLastLoginCell2 } from './UserLastLoginCell2'
const usersColumns: ReadonlyArray<Column<User>> = [
  {
    Header: (props) => <UserSelectionHeader tableProps={props} />,
    id: 'selection',
    Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Full Name' className='min-w-125px' />
    ),
    id: 'firstName',
    Cell: ({...props}) => <UserInfoCell user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Email' className='min-w-125px' />
    ),
    id: 'email',
    Cell: ({...props}) => <Emailpage user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Phone' className='min-w-125px' />
    ),
    id: 'phoneNumber',
    Cell: ({...props}) => <Phonepage user={props.data[props.row.index]} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Creation date' className='min-w-125px' />,
    id: 'created',
    Cell: ({...props}) => <UserLastLoginCell2 created={props.data[props.row.index].created} />,
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Jobs' className='min-w-125px' />,
    accessor: 'jobs',
  },
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Total' className='min-w-125px' />
    ),
    id: 'total',
    Cell: ({...props}) => (
      <span>
        {new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
        }).format(props.data[props.row.index].total)}
      </span>
    ),
  },
  // {
  //   Header: (props) => (
  //     <UserCustomHeader tableProps={props} title='Total' className='min-w-125px' />
  //   ),
  //   accessor: 'total',
  // },

  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title='Actions' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  },
]

export {usersColumns}
