/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {User} from '../../core/_models'
import { Link } from 'react-router-dom'

type Props = {
  user: User
}

const UserInfoCell: FC<Props> = ({user}) => (
  <div className='d-flex align-items-center'>
    <div className='d-flex flex-column'>
      
{ user.customerId !=null ?  <Link to={`/backoffice/customers-management/details/${user.customerId}`} className='menu-link px-3'>
        {user.firstName + ' ' + user.lastName}
      </Link>:<span className='menu-link px-3'> {user.firstName + ' ' + user.lastName}</span>}
      <span className='text-muted'>{user.email}</span>
    </div>
  </div>
)

export {UserInfoCell}
