import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {initialUser, User} from '../core/_models'
import {useListView} from '../core/ListViewProvider'
import {createUser, updateUser, updateUserPassword} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {TeamPassword, isNotEmpty, toAbsoluteUrl} from '../../../../../_handld/helpers'
import {UsersListLoading} from '../../../../modules/apps/user-management/users-list/components/loading/UsersListLoading'
import clsx from 'clsx'
import SVG from 'react-inlinesvg'

import {handleApiErrors} from '../../../../global/HandleApiErrors'
import Swal from 'sweetalert2'
type Props = {
  isUserLoading: boolean
  user: User
}
const initialValues: TeamPassword = {
  newPassword: '',
  confirmpassword: '',
}
const editUserSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), undefined], 'Passwords must match')
    .required('Confirm Password is required'),
})

const PasswordEditModalForm: FC<Props> = ({user, isUserLoading}) => {
  const {setItemIdForUpdatePassword, itemIdForUpdatePassword} = useListView()
  const {refetch} = useQueryResponse()
  const [isShowPassword, setShowPassword] = useState(false)
  const [isShowCOPassword, setShowCOPassword] = useState(false)

  // const [userForEdit] = useState<User>({
  //   ...user,
  //   avatar: user.avatar || initialUser.avatar,
  //   role: user.role || initialUser.role,
  //   position: user.position || initialUser.position,
  //   name: user.name || initialUser.name,
  //   email: user.email || initialUser.email,
  // })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdatePassword?.(undefined)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        const data = {...values, id: itemIdForUpdatePassword}
        await updateUserPassword(data).then((res) => {
          Swal.fire({
            text: 'Handyman password has been updated.',
            icon: 'success',
            showConfirmButton: true,
            timer: 2000,
            width: '25rem',
          })

          cancel(true)
        })
      } catch (error: any) {
        console.log('ll',error)
        handleApiErrors(error?.response.data.errors)
      } finally {
        setSubmitting(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y me-n7 pe-7'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div
            className='row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-9'
            data-kt-buttons='true'
            data-kt-buttons-target="[data-kt-button='true']"
            data-kt-initialized={1}
          >
            <div className='card-body pt-0 mx-5'>
              <div className='text-muted fs-7 mb-3'>
                Are you sure you want to reset password for handyman?
              </div>
              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Password</label>
                <div className='input-group '>
                  <input
                    type={isShowPassword ? 'text' : 'password'}
                    autoComplete='off'
                    {...formik.getFieldProps('newPassword')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                      },
                      {
                        'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                      }
                    )}
                  />
                  <span
                    className='input-group-text align-self-center cursor-pointer'
                    onClick={(e) => setShowPassword(!isShowPassword)}
                  >
                    {!isShowPassword ? (
                      <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                    ) : (
                      <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                    )}
                  </span>
                </div>
                {formik.touched.newPassword && formik.errors.newPassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.newPassword}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='fv-row mb-8'>
                <label className='form-label fs-6 fw-bolder text-dark'>Confirm Password</label>
                <div className='input-group '>
                  <input
                    type={isShowCOPassword ? 'text' : 'password'}
                    autoComplete='off'
                    {...formik.getFieldProps('confirmpassword')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid':
                          formik.touched.confirmpassword && formik.errors.confirmpassword,
                      },
                      {
                        'is-valid':
                          formik.touched.confirmpassword && !formik.errors.confirmpassword,
                      }
                    )}
                  />
                  <span
                    className='input-group-text align-self-center cursor-pointer'
                    onClick={(e) => setShowCOPassword(!isShowCOPassword)}
                  >
                    {!isShowCOPassword ? (
                      <SVG src={toAbsoluteUrl('/media/svg/input/eye-solid.svg')} />
                    ) : (
                      <SVG src={toAbsoluteUrl('/media/svg/input/eye-slash-solid.svg')} />
                    )}
                  </span>
                </div>
                {formik.touched.confirmpassword && formik.errors.confirmpassword && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.confirmpassword}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end::Scroll */}

        {/* begin::Actions */}
        <div className='d-flex justify-content-end pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isUserLoading}
          >
            Cancel
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isUserLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Apply</span>
            {(formik.isSubmitting || isUserLoading) && (
              <span className='indicator-progress'>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isUserLoading) && <UsersListLoading />}
    </>
  )
}

export {PasswordEditModalForm}
