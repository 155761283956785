import { stat } from 'fs'
import {FC} from 'react'

type Props = {
  status?: string
}

const UserTwoStepsCell: FC<Props> = ({status}) => (
  <> 
  {status=='New' && <div className='badge badge-light-primary fw-bolder'>New</div>}
  {status=='In Review' && <div className='badge badge-light-warning fw-bolder'>In Review</div>}
  {status=='Approved' && <div className='badge badge-light-success fw-bolder'>Approved</div>}
  {status=='Rejected' && <div className='badge badge-light-danger fw-bolder'>Rejected</div>}
  {status=='Disabled' && <div className='badge badge-light'>Disabled</div>}
  </>
) 

export {UserTwoStepsCell}
