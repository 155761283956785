import React, {FC} from 'react'

type Props = {
  index: number
  job: any
  handleChange: (index: number, field: string, value: any) => void
}
const PlumpinglHelp: FC<Props> = ({job, handleChange, index}) => {
  return (
    <div className={`card-body`}>
      <div className='row mb-7'>
        <label className='fw-semibold text-muted'>
          Provide some more details about the selected service
        </label>
      </div>

      <div className='row mb-7 pb-3'>
        <label className='fw-bold fs-5 mb-3 required'>
          {job?.category === 'FRPRP' && 'Number of faucets to be repaired or replaced?'}
          {job?.category === 'TOIRP' && 'Number of toilets to be replaced?'}
          {job?.category === 'LKRP' && 'Number of leaks to be repaired?'}
          {job?.category === 'IDWMC' && 'Number of appliances to be installed?'}
          {job?.category === 'HWHIM' &&
            'Number of hot water heaters to be inspected or maintained?'}
          {job?.category === 'PPINS' && 'Number of pipes to be insulated?'}
          {job?.category === 'SHRP' && 'Number of shower heads to be replaced?'}
        </label>
        <input
          type='text'
          className='form-control form-control-lg form-control-solid '
          placeholder='Enter number of items'
          value={job?.numberOfItems || ''}
          onChange={(e) => {
            const newValue = e.target.value
            if (newValue === '' || /^\d+$/.test(newValue)) {
              handleChange(index, 'numberOfItems', newValue)
            }
          }}
        />
      </div>

      <div className='row mb-7 pb-3'>
        <div className='col-12'>
          <label className='fw-bold fs-5 mb-3'>Additional Notes/Info</label>
          <textarea
            className='form-control form-control-lg form-control-solid '
            rows={4}
            name='additionalDetails'
            value={job?.additionalDetails}
            onChange={(e) => handleChange(index, 'additionalDetails', e.target.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default PlumpinglHelp
