import React from 'react'
import { Jobs } from './Jobs'
import { Basics } from './Basics'

const WraperDetails = () => {
  return (
    <>
      <div className='row'>
        <div className='col-xl-4 col-lg-4 col-md-12'>
          <Basics />
        </div>
        <div className='col-xl-8 col-lg-8 col-md-12'>
            <Jobs />
        </div>
      </div>
    </>
  )
}

export default WraperDetails
