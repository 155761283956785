import {FC} from 'react'
import { User } from '../../core/_models'

type Props = {
    price?:any 
  }
const Pricecopy: FC<Props> = ({price}) => {
   return <>
    {
                            price ? (
                              new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              }).format(price)
                            ) : (
                              '--'
                            )
                          }
</>
   }
   export {Pricecopy}
   