import React, { useEffect, useState } from 'react'
import { KTCard, toAbsoluteUrl } from '../../../../_handld/helpers'
import QueryFetchPost from '../../../../global/index2'
import { useParams } from 'react-router-dom'
import { TaskImageViewerModal } from '../images-viewer/ImageViewerModal'
import formatPhoneNumber from '../../../../global/formatPhoneNumber'
export const Basics = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  const [data, setdata] = useState<any>()
  const [imageId, setImageId] = useState<any>()
  const [imageSrc, setImageSrc] = useState<any>()
  const params = useParams<any>()
  const { id } = params
  const getUserStatusById = async (id: any) => {
    await QueryFetchPost({
      link: `${BASE_URL}/api/BackOffice/DetailsHandlr/${id}`,
      data: undefined,
      method: 'GET',
      key: 'getuserbyid',
      signal: undefined,
      isFormData: false,
    }).then((response: any) => {
      setdata(response[1])
    })
  }
  useEffect(() => {
    getUserStatusById(id)
  }, [id])

  return (
    <KTCard>
      <div className='d-print-none card-rounded h-lg-100 min-w-md-350px p-9 bg-lighten'>
        {/* <div className='mb-8'>
          {data?.status == 'New' && <div className='badge badge-light-primary fw-bolder'>New </div>}
          {data?.status == 'In Review' && <div className='badge badge-light-warning fw-bolder'>In Review </div>}
          {data?.status == 'Approved' && <div className='badge badge-light-success fw-bolder'>Approved </div>}
          {data?.status == 'Rejected' && <div className='badge badge-light-danger fw-bolder'>Rejected </div>}
          {data?.status == 'Disabled' && <div className='badge badge-light'>Disabled </div>}
          <span>   {''} {''} </span> {data?.rate !== 0 ? (
            <span>
              <i className='bi bi-star-fill text-warning' /> {data?.rate}
            </span>
          ) : (
            <span>
              <i className='bi bi-star text-warning' /> -
            </span>
          )}
        </div> */}
        <div className='card-body pt-0 pb-0 ps-0'>
          <div className='d-flex flex-wrap flex-sm-nowrap'>
            {data?.profilePicture ? (
              <div className='symbol symbol-90px symbol-lg-125px symbol-fixed position-relative me-4'>
                <img
                  src={toAbsoluteUrl(data?.profilePicture)}
                  alt={data?.profilePicture}
                  style={{ objectFit: 'cover' }}
                />
              </div>
            ) : (
              <>
                <div className='symbol symbol-90px symbol-lg-125px symbol-fixed position-relative me-4'>
                  <img
                    src={toAbsoluteUrl('/media/svg/avatars/blank.svg')}
                    alt='default'
                    style={{ objectFit: 'cover' }}
                  />
                </div>
              </>
            )}
            <div className='flex-grow-1'>
              <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                <div className='d-flex flex-column'>
                  <div className='mb-2'>
                    {data?.status == 'New' && (
                      <div className='badge badge-light-primary fw-bolder'>New </div>
                    )}
                    {data?.status == 'In Review' && (
                      <div className='badge badge-light-warning fw-bolder'>In Review </div>
                    )}
                    {data?.status == 'Approved' && (
                      <div className='badge badge-light-success fw-bolder'>Approved </div>
                    )}
                    {data?.status == 'Rejected' && (
                      <div className='badge badge-light-danger fw-bolder'>Rejected </div>
                    )}
                    {data?.status == 'Disabled' && (
                      <div className='badge badge-light'>Disabled </div>
                    )}
                    <span>
                      {' '}
                      {''} {''}{' '}
                    </span>{' '}
                    {data?.rate !== 0 ? (
                      <span>
                        <i className='bi bi-star-fill text-warning' /> {data?.rate}
                      </span>
                    ) : (
                      <span>
                        <i className='bi bi-star text-warning' /> -
                      </span>
                    )}
                  </div>
                  <div className='d-flex align-items-center mb-2'>
                    <span className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'>
                      {data?.firstName} {''} {data?.lastName}
                    </span>
                  </div>
                  <div className='d-flex flex-wrap fw-semibold fs-6 pe-2'>
                    <span className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'>
                      <i className='ki-duotone ki-sms fs-4 me-1'>
                        <span className='path1' />
                        <span className='path2' />
                      </i>{' '}
                      <span className='text-break'>
                        <a href={`mailto:${data?.email}`}>{data?.email}</a>
                      </span>
                    </span>
                  </div>
                  <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                    <span className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'>
                      <i className='bi bi-telephone fs-6 me-1' />
                      <span className='text-break'>
                        <a href={`tel:${data?.phoneNumber}`}>{formatPhoneNumber(data?.phoneNumber)}</a>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='mb-6'>
          <div className='fw-semibold text-gray-600 fs-7'>Name:</div>
          <div className='fw-bold text-gray-800 fs-6'>{data?.firstName} {''} {data?.lastName}</div>
        </div> */}
        <div className='mb-6'>
          <div className='fw-semibold text-gray-600 fs-7'>Address:</div>
          <div className='fw-bold text-gray-800 fs-6'>
            {data?.address1} <br />
            {data?.address2 ? (
              <>
                {' '}
                {data?.address2} <br />
              </>
            ) : (
              ''
            )}
            {data?.city && `${data.city}, `}
            {data?.state && `${data.state}, `}
            {data?.zipCode && `${data.zipCode}, `}
            {data?.country && data.country}
          </div>
        </div>
        {/* <div className='mb-6'>
          <div className='fw-semibold text-gray-600 fs-7'>Email:</div>
          <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center text-break'>
            {data?.email}
          </div>
        </div> */}
        <div className='mb-6'>
          <div className='fw-semibold text-gray-600 fs-7'>
            Are you legally entitled to work in Canada?
          </div>
          {data?.legalWorkCanada === true ? (
            <div className='fw-bold fs-6 text-gray-800'>Yes</div>
          ) : (
            <div className='fw-bold fs-6 text-gray-800'>No</div>
          )}
        </div>
        {/*end::Item*/}
        {/*begin::Item*/}
        <div className='mb-6'>
          <div className='fw-semibold text-gray-600 fs-7'>
            Have you ever been convicted of a criminal offence?
          </div>
          {data?.criminalConviction === true ? (
            <div className='fw-bold fs-6 text-gray-800'>Yes</div>
          ) : (
            <div className='fw-bold fs-6 text-gray-800'>No</div>
          )}
        </div>
        {/*end::Item*/}
        {/*begin::Item*/}
        <div className='mb-6'>
          <div className='fw-semibold text-gray-600 fs-7'>
            Do you have your own tools and equipment to complete jobs for Handyman?
          </div>
          {data?.ownToolsEquipment === true ? (
            <div className='fw-bold fs-6 text-gray-800'>Yes</div>
          ) : (
            <div className='fw-bold fs-6 text-gray-800'>No</div>
          )}
        </div>

        <div className='mb-6'>
          <div className='fw-semibold text-gray-600 fs-7'>
            Are you willing to complete a police background check?
          </div>
          {data?.backgroundCheck === true ? (
            <div className='fw-bold fs-6 text-gray-800'>Yes</div>
          ) : (
            <div className='fw-bold fs-6 text-gray-800'>No</div>
          )}
        </div>
        <div className='mb-6'>
          <div className='fw-semibold text-gray-600 fs-7'>Skills:</div>
          <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center'>
            {data?.skills}{' '}
          </div>
        </div>
        {/*end::Item*/}
        {/*begin::Item*/}
        <div className='mb-6'>
          <div className='fw-semibold text-gray-600 fs-7 mb-2'>Files</div>
          <div className='fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap'>
            {data?.attachedFiles && data?.attachedFiles.map((attachment: any, index: number) => (
              <div
                className='symbol symbol-70px mb-3 cursor-pointer'
                key={index}
                onClick={() => {
                  setImageId(attachment.id)
                  setImageSrc(attachment?.path)
                }}
              >
                <img
                  key={attachment.name} // Make sure to set a unique key for each item
                  src={toAbsoluteUrl(attachment.path)}
                  alt={attachment.name}
                  style={{ margin: '4px', height: '72px', width: '85px' }} // Add some margin between images
                  className='symbol symbol mb-6'
                />
              </div>
            ))}
          </div>
        </div>
        {/*end::Item*/}
      </div>
      {imageId && (
        <TaskImageViewerModal
          imageId={imageId}
          setImageId={setImageId}
          attachments={data?.attachedFiles}
          setImageSrc={setImageSrc}
        />
      )}
    </KTCard>
  )
}
