import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_handld/helpers'
import {Container, Row} from 'react-bootstrap'
import {useAuth} from '../../auth'

const Error404: FC = () => {
  const {currentUser} = useAuth()

  return (
    <>
      <Container>
        <Row className='pt-20 text-center'>
          {/* begin::Title */}
          <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='fw-semibold fs-6 text-gray-500 mb-7'>We can't find that page.</div>
          {/* end::Text */}

          {/* begin::Illustration */}
          <div className='mb-3'>
            <img
              src={toAbsoluteUrl('/media/auth/404-error.png')}
              className='mw-100 mh-300px theme-light-show'
              alt=''
            />
            <img
              src={toAbsoluteUrl('/media/auth/404-error-dark.png')}
              className='mw-100 mh-300px theme-dark-show'
              alt=''
            />
          </div>
          {/* end::Illustration */}

          {/* begin::Link */}
          <div className='mb-0'>
            {currentUser?.roles[0] === 'Client admin' && (
              <Link to='/landing' className='btn btn-sm btn-primary'>
                Return Home
              </Link>
            )}
            {currentUser?.roles[0] === 'Super admin' && (
              <Link to='/backoffice/dashboard' className='btn btn-sm btn-primary'>
                Return Home
              </Link>
            )}
          </div>
          {/* end::Link */}
        </Row>
      </Container>
    </>
  )
}

export {Error404}
